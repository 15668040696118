import { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Row, Col, Space, Dropdown, Button } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { Helmet } from 'react-helmet-async';
import { HibraryRootState } from '../../../redux/rootReducer';
import { action } from '../../redux/request';
import { MemoContentCollectionView } from '../../components/contents';
import { Loading } from '../../customUI/loading';
import { BreadcrumbLink, BreadCrumbView } from '../../components/breadcrumb';
import { useLocation } from 'react-router-dom';
import { MagazineSetCover } from '../home/magazine/magazineSetCover';
import { EmptyDataView } from '../../components/nodata';
import { setContentType } from '../../redux/utilities';
import { isRentAble, QueryParam, SortType } from '../../../interface/listEbook';
import { CategoryCover } from '../home/categoryHome/categoryCover';
import {
   ContentType,
   LicenseType,
   TKCategory,
   ContentMetaData,
   SegmentType,
   SetType,
   MagazineSetResponse
} from '../../../interface/home';
import {
   ListContentResponse,
   category,
   subcategory,
   ListAllSubcategoryResponse,
   ListAllCategoryResponse,
   ListContentWriterResponse
} from '../../../interface/request';
import {
   getContentList,
   getContentListRelate,
   getContentListContent,
   getContentListCategory,
   fetchCategory,
   getContentListSubCategory,
   getAllContent,
   getContentListWriter,
   getContentListPublisher,
   getContentListMagazineSet,
   fetchMagazineSet
} from '../../redux/store/storeCrud';

import SVG from 'react-inlinesvg';
import Pattern4 from '../home/ebook/pattern-4';
import VideoPattern1 from '../home/video/videoPattern-1';
import './index.scss';
import '../home/pattern.scss';
import 'swiper/swiper.scss';

export interface ViewAllContentProps {
   contentType: ContentType;
   segmentType?: SegmentType;
   licenseType?: LicenseType;
   uid?: string;
   subcatId?: string;
   catId?: string;
   writerUID?: string;
   keyword?: string;
   publisherUID?: string;
   setUID?: string;
   fromBanner?: string;
}

type ResponseModel = ListContentResponse | ListAllSubcategoryResponse | ListContentWriterResponse;

export const ViewAllContent = (props: ViewAllContentProps) => {
   const getInitTitle = () => {
      const title = props.keyword ? `คำสำคัญ "${props.keyword}"` : '';
      return title;
   };
   const pageId = 'ViewAllContent';
   const dispatch = useDispatch();
   const location = useLocation();
   const [contentList, setContentList] = useState<ResponseModel>();
   const [categories, setCategories] = useState<category[]>();
   const [subCategories, setSubCategories] = useState<subcategory[]>();
   const [breadCrumbs, setBreadCrumbs] = useState<BreadcrumbLink[]>();
   const [title, setTitle] = useState<string>(getInitTitle());
   const [categoryButton, setCategoryButton] = useState<string>();
   const [subCategoryButton, setSubCategoryButton] = useState<string>();
   const [sortButton, setSortButton] = useState<string>('วันที่เพิ่มล่าสุด');
   const [filterRentButton, setFilterRentButton] = useState<isRentAble>(isRentAble.RentUnable);
   const [queryParam, setQueryParam] = useState<QueryParam>();
   const { environment, request, home } = useSelector(
      (state: HibraryRootState) => ({
         environment: state.environment,
         request: state.fetch,
         home: state.home
      }),
      shallowEqual
   );

   useEffect(() => {
      setTitle(getInitTitle());
   }, []);

   useEffect(() => {
      fetchContent(false);
   }, [queryParam, location]);

   useEffect(() => {
      if (environment.endOfScreen) {
         if (contentList?.lastEvaluatedKey !== undefined && !request.loading) {
            fetchContent(true);
         }
      }
   }, [environment]);

   const compareQuery = (query1?: QueryParam, query2?: QueryParam) => {
      if (query1?.categoryUID !== query2?.categoryUID) {
         return false;
      } else if (query1?.subCategoryUID !== query2?.subCategoryUID) {
         return false;
      } else if (query1?.sortType !== query2?.sortType) {
         return false;
      } else if (query1?.isRentAble !== query2?.isRentAble) {
         return false;
      }
      return true;
   };

   const validateFilter = (query: QueryParam) => {
      if (!compareQuery(queryParam, query)) {
         setContentList(undefined);
      }
      setQueryParam(query);
   };

   const fetchContent = (appendData: boolean) => {
      if (props.segmentType === SegmentType.RelateContent) {
         fetchListContentByRelate();
      } else if (props.segmentType === SegmentType.ContentList) {
         fetchListContentByContentList();
      } else if (props.segmentType === SegmentType.AllContent) {
         fetchAllContent(appendData);
      } else if (props.catId && props.subcatId) {
         fetchListContentBYSubcategories(appendData);
      } else if (props.writerUID) {
         fetchListContentByWriter(appendData);
      } else if (props.publisherUID) {
         fetchListContentByPublisher(appendData);
      } else if (props.setUID) {
         fetchListContentByMagazineSet(appendData);
      } else {
         fetchListContent();
      }
   };

   const fetchListContent = async () => {
      try {
         dispatch(action.setProcess(true, pageId));
         const data = await getContentList(props.segmentType!, props.contentType, queryParam);

         if (data) {
            setContentList(data as ListContentResponse);
            setCategories(data.categories);
            if (home.title) {
               setTitle(home.title);
               if (!breadCrumbs) {
                  setBreadCrumbs([{ title: home.title ?? '' }]);
               }
            }

            dispatch(action.setProcess(false, pageId));
         }
      } catch (error) {
         dispatch(action.setProcess(false, pageId));
      }
   };

   const fetchListContentByRelate = async () => {
      try {
         dispatch(action.setProcess(true, pageId));
         const data = await getContentListRelate(props.contentType, props.uid!, queryParam!);

         setContentList(data as ListContentResponse);
         setCategories(data?.categories);
         if (home.title) {
            setTitle(home.title);
            if (!breadCrumbs) {
               setBreadCrumbs([{ title: home.title ?? '' }]);
            }
         }

         dispatch(action.setProcess(false, pageId));
      } catch (error) {
         dispatch(action.setProcess(false, pageId));
      }
   };

   const fetchListContentByContentList = async () => {
      try {
         const banner = parseInt(props.fromBanner ?? '0');
         dispatch(action.setProcess(true, pageId));
         const data = await getContentListContent(
            props.contentType,
            props.uid!,
            banner,
            queryParam!
         );

         setContentList(data as ListContentResponse);
         setCategories(data?.categories);
         if (home.title) {
            setTitle(home.title);
            if (!breadCrumbs) {
               setBreadCrumbs([{ title: home.title ?? '' }]);
            }
         }

         dispatch(action.setProcess(false, pageId));
      } catch (error) {
         dispatch(action.setProcess(false, pageId));
      }
   };

   const fetchListContentBYSubcategories = async (appendData: boolean) => {
      try {
         dispatch(action.setProcess(true, pageId));
         const lastEvaluatedKey = appendData ? contentList?.lastEvaluatedKey : undefined;

         const data = await getContentListSubCategory(
            props.contentType,
            props.catId!,
            props.subcatId!,
            lastEvaluatedKey,
            queryParam!
         );

         if (appendData) {
            setListModel(data!, appendData);
         } else {
            if (data?.title) {
               setTitle(data.contents[0].subcatName);
               if (!breadCrumbs) {
                  setBreadCrumbs([{ title: data.contents[0].subcatName ?? '' }]);
               }
            }
            setContentList(data);
         }

         dispatch(action.setProcess(false, pageId));
      } catch (error) {
         dispatch(action.setProcess(false, pageId));
      }
   };

   const fetchListContentByWriter = async (appendData: boolean) => {
      try {
         dispatch(action.setProcess(true, pageId));
         const lastEvaluatedKey = appendData ? contentList?.lastEvaluatedKey : undefined;

         const data = await getContentListWriter(
            props.contentType,
            lastEvaluatedKey!,
            props.writerUID!,
            queryParam!
         );

         if (appendData) {
            setListModel(data!, appendData);
         } else {
            setContentList(data);
            setTitle(
               `${setContentType(props.contentType) + `ของ`} ${data?.contents[0].writerName}`
            );

            if (!breadCrumbs) {
               setBreadCrumbs([{ title: data?.contents[0].writerName[0] ?? '' }]);
            }
         }
         dispatch(action.setProcess(false, pageId));
      } catch (error) {
         dispatch(action.setProcess(false, pageId));
      }
   };

   const fetchListContentByPublisher = async (appendData: boolean) => {
      try {
         dispatch(action.setProcess(true, pageId));
         const lastEvaluatedKey = appendData ? contentList?.lastEvaluatedKey : undefined;
         const data = await getContentListPublisher(
            props.contentType,
            lastEvaluatedKey!,
            props.publisherUID!,
            queryParam!
         );

         if (appendData) {
            setListModel(data!, appendData);
         } else {
            setContentList(data as ListContentWriterResponse);
            setTitle(`สำนักพิมพ์ "${data?.contents[0].publisherName}"`);

            if (!breadCrumbs) {
               setBreadCrumbs([{ title: data?.contents[0].publisherName ?? '' }]);
            }
         }
         dispatch(action.setProcess(false, pageId));
      } catch (error) {
         dispatch(action.setProcess(false, pageId));
      }
   };

   const fetchListContentByMagazineSet = async (appendData: boolean) => {
      try {
         dispatch(action.setProcess(true, pageId));
         const lastEvaluatedKey = appendData ? contentList?.lastEvaluatedKey : undefined;
         const data = await getContentListMagazineSet(props.setUID!, lastEvaluatedKey!, queryParam);

         if (appendData) {
            setListModel(data!, appendData);
         } else {
            if (home.title) {
               setTitle(home.title);
               if (!breadCrumbs) {
                  setBreadCrumbs([{ title: home.title ?? '' }]);
               }
            }
            setCategories(data?.categories);
            setContentList(data);
         }
         dispatch(action.setProcess(false, pageId));
      } catch (error) {
         dispatch(action.setProcess(false, pageId));
      }
   };

   const fetchAllContent = async (appendData: boolean) => {
      try {
         dispatch(action.setProcess(true, pageId));
         const lastEvaluatedKey = appendData ? contentList?.lastEvaluatedKey : undefined;
         const data = await getAllContent(props.contentType, lastEvaluatedKey, queryParam!);

         if (appendData) {
            setListModel(data!, appendData);
         } else {
            if (home.title!) {
               setTitle(home.title);
               if (!breadCrumbs) {
                  setBreadCrumbs([{ title: home.title ?? '' }]);
               }
            }
            setCategories(data?.categories);
            setContentList(data);
         }

         dispatch(action.setProcess(false, pageId));
      } catch (error) {
         dispatch(action.setProcess(false, pageId));
      }
   };

   const setListModel = (data: ResponseModel, appendData: boolean) => {
      if (props.publisherUID || props.writerUID) {
         const current = contentList as ListContentWriterResponse;
         const currentData = data as ListContentWriterResponse;
         const newArray = appendData ? current.contents : [];
         setContentList({
            contents: newArray.concat(currentData?.contents ?? []),
            lastEvaluatedKey: currentData?.lastEvaluatedKey
         });
      } else {
         const current = contentList as ListContentResponse;
         const currentData = data as ListContentResponse;
         const newArray = appendData ? current.contents : [];

         if (props.setUID) {
            setContentList({
               contents: newArray.concat(currentData?.contents ?? []),
               lastEvaluatedKey: currentData?.lastEvaluatedKey
            });
         } else {
            setContentList({
               contents: newArray.concat(currentData?.contents ?? []),
               categories: current.categories.concat(currentData?.categories ?? []),
               lastEvaluatedKey: currentData?.lastEvaluatedKey
            });
         }
      }
   };

   const renderContentList = () => {
      if (contentList) {
         const { contents } = contentList as ListContentResponse;

         return (
            <>
               {contents.length > 0 ? (
                  <Row gutter={[16, 24]} className="list-row">
                     {contents.map((e) => (
                        <Col
                           key={`col_${e.contentUID}`}
                           className={`col-${props.contentType} fadeIn`}
                        >
                           <MemoContentCollectionView
                              content={e as ContentMetaData}
                              licenseType={props.licenseType!}
                           />
                        </Col>
                     ))}
                  </Row>
               ) : null}
               {!request.loading && contents.length === 0 ? <EmptyDataView minHeight={55} /> : null}
            </>
         );
      }
   };

   const breadcrumbs = useMemo(() => {
      return <BreadCrumbView addLinks={breadCrumbs} />;
   }, [breadCrumbs]);

   const FilterButton = () => {
      const dropdownCategory = (
         <div className="drop-down">
            <div
               key={`all-cat`}
               onClick={() => {
                  validateFilter({
                     ...queryParam,
                     categoryUID: undefined,
                     subCategoryUID: undefined
                  });
                  setCategoryButton(undefined);
                  setSubCategoryButton(undefined);
               }}
            >
               {`หมวดหมู่ทั้งหมด`}
            </div>
            {categories?.map((item, index) => (
               <div
                  key={index}
                  onClick={() => {
                     setSubCategories(item.subcategory);
                     setCategoryButton(item.categoryName);
                     validateFilter({
                        ...queryParam,
                        categoryUID: item.categoryUID,
                        subCategoryUID: undefined
                     });
                     setSubCategoryButton('');
                  }}
               >
                  {item.categoryName}
               </div>
            ))}
         </div>
      );

      const dropdownSubCategory = (
         <div className="drop-down">
            <div
               key={`all-subcat`}
               onClick={() => {
                  validateFilter({
                     ...queryParam,
                     subCategoryUID: undefined
                  });

                  setSubCategoryButton(undefined);
               }}
            >
               {`หมวดหมู่ย่อยทั้งหมด`}
            </div>
            {subCategories?.map((item, index) => {
               if (subCategories === undefined) {
                  return <div>หมวดหมู่ทั้งหมด</div>;
               } else {
                  return (
                     <div
                        key={index}
                        onClick={() => {
                           setSubCategoryButton(item.subcategoryName);
                           validateFilter({
                              ...queryParam,
                              subCategoryUID: item.subcategoryUID
                           });
                        }}
                     >
                        {item.subcategoryName}
                     </div>
                  );
               }
            })}
         </div>
      );

      const sortContent = () => {
         const sortDropDown = [
            {
               text: 'วันที่เพิ่มล่าสุด',
               sortType: SortType.NewUpdateDate
            },
            {
               text: 'วันที่เพิ่มเก่าสุด',
               sortType: SortType.OldUpdateDate
            },
            {
               text: 'ปีที่พิมพ์ก่อน',
               sortType: SortType.NewPublisherDate
            },
            {
               text: 'ปีที่พิมพ์หลัง',
               sortType: SortType.OldPublisherDate
            },
            {
               text: 'ชื่อเรื่อง',
               sortType: SortType.Title
            },
            {
               text: 'ชื่อผู้แต่ง',
               sortType: SortType.WriterName
            },
            {
               text: 'ยอดนิยม',
               sortType:
                  props.segmentType! === SegmentType.Popular
                     ? SortType.popularPoint
                     : SortType.RentHistory
            }
         ];

         return (
            <div className="drop-down">
               {sortDropDown.map((item, index) => (
                  <div
                     key={index}
                     onClick={() => {
                        setSortButton(item.text);
                        validateFilter({
                           ...queryParam,
                           sortType: item.sortType
                        });
                     }}
                  >
                     {item.text}
                  </div>
               ))}
            </div>
         );
      };

      return (
         <div className="filter-section">
            {categories && (
               <>
                  <Dropdown
                     overlay={dropdownCategory}
                     placement="bottomRight"
                     trigger={['click']}
                     arrow={true}
                  >
                     <Button shape="round" className="grey7-background-button">
                        {categoryButton ? categoryButton : 'หมวดหมู่'}
                        <SVG src="/images/tk-icon/arrow-icon.svg" />
                     </Button>
                  </Dropdown>
                  {subCategories ? (
                     <Dropdown
                        overlay={dropdownSubCategory}
                        placement="bottomRight"
                        trigger={['click']}
                        arrow={true}
                     >
                        <Button shape="round" className="grey7-background-button">
                           {subCategoryButton ? subCategoryButton : 'หมวดหมู่ย่อย'}
                           <SVG src="/images/tk-icon/arrow-icon.svg" />
                        </Button>
                     </Dropdown>
                  ) : (
                     <Button shape="round" className="grey7-background-button" disabled>
                        หมวดหมู่ย่อย
                        <SVG src="/images/tk-icon/arrow-icon.svg" />
                     </Button>
                  )}
               </>
            )}
            <Button
               type="primary"
               shape="round"
               className={
                  categoryButton === undefined &&
                  subCategoryButton === undefined &&
                  filterRentButton != isRentAble.RentAble
                     ? `primary-outline-button`
                     : `grey7-background-button`
               }
               onClick={() => {
                  setQueryParam(undefined);
                  setCategoryButton(undefined);
                  setSubCategoryButton(undefined);
                  setSubCategories(undefined);
                  setFilterRentButton(isRentAble.RentUnable);
               }}
            >
               ทั้งหมด
            </Button>

            <Button
               type="ghost"
               shape="round"
               className={
                  filterRentButton === isRentAble.RentUnable
                     ? `grey7-background-button`
                     : `primary-outline-button`
               }
               onClick={() => {
                  setFilterRentButton(
                     filterRentButton === isRentAble.RentUnable
                        ? isRentAble.RentAble
                        : isRentAble.RentUnable
                  );
                  validateFilter(
                     filterRentButton === isRentAble.RentUnable
                        ? { ...queryParam, isRentAble: isRentAble.RentAble }
                        : { ...queryParam, isRentAble: isRentAble.RentUnable }
                  );
               }}
            >
               พร้อมยืม
            </Button>

            {props.segmentType !== SegmentType.NewContent ? (
               <Space size={5} className="sort-button">
                  <span className="text">Sort</span>
                  <Dropdown overlay={sortContent} trigger={['click']} arrow={true}>
                     <a>
                        {sortButton + ' '}
                        <DownOutlined />
                     </a>
                  </Dropdown>
               </Space>
            ) : null}
         </div>
      );
   };

   return (
      <>
         <div>{breadcrumbs}</div>
         <Helmet>
            <title>TK Read | {title ?? 'ห้องสมุดออนไลน์ (E-library)'}</title>
         </Helmet>
         <div className="store view-all-content fadeIn">
            <div className="title-content-section" style={{ marginTop: 0 }}>
               <h2 style={{ marginBottom: 0 }}>{title ?? 'All E-book'}</h2>
               <div>{FilterButton()}</div>
            </div>
            <div className="content-list" style={{ padding: 0 }}>
               {renderContentList()}
            </div>
         </div>
         <Loading id={pageId} />
      </>
   );
};

export interface CategoriesPageProps {
   contentType: ContentType;
}

export const CategoriesPage = (props: CategoriesPageProps) => {
   const pageId = 'CategoriesPage';
   const dispatch = useDispatch();
   const location = useLocation();
   const [categoriesList, setCategoriesList] = useState<TKCategory[]>();
   const { clientInfo, authen } = useSelector(
      (state: HibraryRootState) => ({
         clientInfo: state.clientInfo,
         authen: state.auth,
         environment: state.environment
      }),
      shallowEqual
   );

   useEffect(() => {
      fetchCategories();
   }, [clientInfo, authen, location]);

   const fetchCategories = async () => {
      try {
         dispatch(action.setProcess(true, pageId));
         const data = await fetchCategory(props.contentType);
         setCategoriesList(data?.category);
         dispatch(action.setProcess(false, pageId));
      } catch (error) {
         dispatch(action.setProcess(false, pageId));
      }
   };

   return (
      <>
         <BreadCrumbView />
         <div className="store view-all-content" key={`category_${props.contentType}`}>
            <Helmet>
               <title>TK Read | หมวดหมู่</title>
            </Helmet>
            <h1 className="view-all-category-title">หมวดหมู่</h1>
            <div className="content-list">
               <Row className="list-row-categories category-list fadeIn">
                  {categoriesList?.map((el) => (
                     <div key={el.uid} className="category-col fadeIn">
                        <CategoryCover category={el} contentType={props.contentType} />
                     </div>
                  ))}
               </Row>
            </div>
            <Loading id={pageId} absolute={true} />
         </div>
      </>
   );
};

interface CategoryContentViewProps {
   catId: string;
   contentType: ContentType;
}

export const CategoryContentPage = (props: CategoryContentViewProps) => {
   const pageId = 'CategoryContentView';
   const dispatch = useDispatch();
   const [categoriesContent, setCategoriesContent] = useState<ListAllCategoryResponse>();
   const [breadCrumbs, setBreadCrumbs] = useState<BreadcrumbLink[]>();

   useEffect(() => {
      fetchData();
   }, []);

   const fetchData = async () => {
      try {
         dispatch(action.setProcess(true, pageId));
         const data = await getContentListCategory(props.contentType, props.catId!);

         setBreadcrumbs(data?.title ?? '');
         setCategoriesContent(data);
         dispatch(action.setProcess(false, pageId));
      } catch (error) {
         dispatch(action.setProcess(false, pageId));
      }
   };

   const breadcrumbs = useMemo(() => {
      return <BreadCrumbView addLinks={breadCrumbs} />;
   }, [breadCrumbs]);
   const setBreadcrumbs = (catName: string) => {
      const breadcrumbs: BreadcrumbLink = {
         title: catName
      };
      setBreadCrumbs([breadcrumbs]);
   };

   return (
      <div className="store">
         <Helmet>
            <title>
               TK Read | {`${categoriesContent?.title ?? 'ห้องสมุดออนไลน์ (E-library)'}`}
            </title>
         </Helmet>
         {breadcrumbs}
         <h1 className="view-all-category-title">{categoriesContent?.title}</h1>
         <div className="segment-list">
            {categoriesContent?.contentsByCategory?.map((el) => {
               switch (props.contentType) {
                  case ContentType.video: {
                     return (
                        <VideoPattern1
                           title={el.subcategoryName}
                           contents={el.contents}
                           path={`/${props.contentType}/categories/${props.catId}/subcategories/${el.subcategoryUID}`}
                        />
                     );
                  }
                  default: {
                     return (
                        <Pattern4
                           title={el.subcategoryName}
                           contents={el.contents}
                           path={`/${props.contentType}/categories/${props.catId}/subcategories/${el.subcategoryUID}`}
                           limit={8}
                        />
                     );
                  }
               }
            })}
         </div>

         <Loading id={pageId} absolute={true} />
      </div>
   );
};

interface MagazineSetListPage {
   setType: SetType;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const MagazineSetListPage = (props: MagazineSetListPage) => {
   const pageId = 'MagazineSetPage';
   const dispatch = useDispatch();
   const [magazineSetList, setMagazineSetList] = useState<MagazineSetResponse>();
   const [breadCrumbs, setBreadCrumbs] = useState<BreadcrumbLink[]>();

   useEffect(() => {
      fetchMagazineContent();
   }, []);

   const fetchMagazineContent = async () => {
      try {
         dispatch(action.setProcess(true, pageId));
         const data = await fetchMagazineSet(props.setType);
         setMagazineSetList(data);
         dispatch(action.setProcess(false, pageId));
         setBreadcrumbs(data?.title ?? '');
      } catch (error) {
         dispatch(action.setProcess(false, pageId));
      }
   };

   const breadcrumbs = useMemo(() => {
      return <BreadCrumbView addLinks={breadCrumbs} />;
   }, [breadCrumbs]);

   const setBreadcrumbs = (catName: string) => {
      const breadcrumbs: BreadcrumbLink = {
         title: catName
      };
      setBreadCrumbs([breadcrumbs]);
   };

   return (
      <>
         {breadcrumbs}
         <div className="store view-all-content">
            <Helmet>
               <title>TK Read | ห้องสมุดออนไลน์ (E-library)</title>
            </Helmet>
            <h1 className="view-all-category-title">หมวดหมู่</h1>
            <div className="content-list">
               <Row className="list-row-categories category-list fadeIn">
                  {magazineSetList?.contents.map((el, index) => (
                     <div key={index} className="category-col fadeIn">
                        <MagazineSetCover content={el} contentType={ContentType.magazine} />
                     </div>
                  ))}
               </Row>
            </div>
            <Loading id={pageId} absolute={true} />
         </div>
      </>
   );
};
