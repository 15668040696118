import { useState, memo } from 'react';
import { Menu, Dropdown } from 'antd';
import { changeTimeStampToText } from './util';
import { ContentType, License, LicenseType, RentalStatus } from '../../../interface';
import { LoadingOutlined } from '@ant-design/icons';
import { useSelector, shallowEqual } from 'react-redux';
import { HibraryRootState } from '../../../redux/rootReducer';
import { Modal, Button } from 'antd';
import { setButtonText, setContentType } from '../../redux/utilities';
import { ButtonType } from './bookShelfView/contentCover';
import { bookShelfContent } from '../../../interface/bookShelf';

import './index.scss';

export interface BookShelfContentProps {
   data: License;
   onOpenContent: (licenseUID: string) => void;
   onReturnContent: (licenseUID: string) => void;
}

export const Rented: React.FC<BookShelfContentProps> = ({ data, onOpenContent }) => {
   const [isImgError, setIsImgError] = useState(false);
   // eslint-disable-next-line @typescript-eslint/no-unused-vars
   const [confirmDialog, setConfirmDialog] = useState(false);
   const imgErrorSrc = '/images/book-placeholder.jpg';
   const imgError = (image: any) => {
      setIsImgError(true);
      image.onError = '';
      return true;
   };
   const { request } = useSelector(
      (state: HibraryRootState) => ({
         request: state.fetch.id === data.licenseUID ? state.fetch : undefined
      }),
      shallowEqual
   );

   const dropdownBtn = (
      <Menu>
         <Menu.Item key="1" onClick={() => setConfirmDialog(true)}>
            คืนสื่อ
         </Menu.Item>
      </Menu>
   );

   return (
      <>
         <div className="bookshelf-collection">
            <div className={`cover ${request?.loading ? 'loading' : ''}`}>
               <div className={`filetype ${data.fileType}`}>{data.fileType}</div>
               <img
                  key={data.licenseUID}
                  alt=""
                  src={isImgError ? imgErrorSrc : data.coverImage}
                  className={`imageView`}
                  onError={imgError}
                  onClick={() => {
                     onOpenContent(data.licenseUID);
                  }}
               />
               {data.licenseType === LicenseType.Sample && (
                  <img alt="" src="/images/badge-sample.svg" className="badge-sample" />
               )}
               {request?.loading ? (
                  <div className="loading-container">
                     <LoadingOutlined className="content-loading" />
                  </div>
               ) : null}
            </div>
            <p className="title">
               {changeTimeStampToText(data.expireDate)}
               <Dropdown overlay={dropdownBtn} trigger={['click']} placement="bottomLeft">
                  <img alt="" src="/images/dot-bookshelf.svg" className="dot" />
               </Dropdown>
            </p>
         </div>
         {/* {confirmDialog && (
        <ConfirmModal
          setConfirmDialog={setConfirmDialog}
          data={data}
          nextFunction={onReturnContent}
        />
      )} */}
      </>
   );
};
export const MemoRented = memo(Rented);

export const Video: React.FC<BookShelfContentProps> = ({ data, onOpenContent }) => {
   const imgErrorSrc = '/images/book-placeholder.jpg';
   const [isImgError, setIsImgError] = useState(false);
   // eslint-disable-next-line @typescript-eslint/no-unused-vars
   const [confirmDialog, setConfirmDialog] = useState(false);

   const imgError = (image: any) => {
      setIsImgError(true);
      image.onError = '';
      return true;
   };

   const dropdownBtn = (
      <Menu>
         <Menu.Item key="1" onClick={() => setConfirmDialog(true)}>
            คืนสื่อ
         </Menu.Item>
      </Menu>
   );

   const { request } = useSelector(
      (state: HibraryRootState) => ({
         request: state.fetch.id === data.licenseUID ? state.fetch : undefined
      }),
      shallowEqual
   );

   return (
      <>
         <div className="video-collection">
            <div className={`cover video-content ${request?.loading ? 'loading' : ''}`}>
               <div className={`filetype ${data.fileType}`}>{data.fileType}</div>
               <img
                  key={data.licenseUID}
                  alt=""
                  src={isImgError ? imgErrorSrc : data.coverImage}
                  className={`imageView`}
                  onError={imgError}
                  onClick={() => {
                     onOpenContent(data.licenseUID);
                  }}
               />
               <div className="total-time">{data.duration}</div>
               {request?.loading ? (
                  <div className="loading-container">
                     <LoadingOutlined className="content-loading" />
                  </div>
               ) : null}
            </div>
            <p className="title">
               {changeTimeStampToText(data.expireDate)}
               <Dropdown overlay={dropdownBtn} trigger={['click']} placement="bottomLeft">
                  <img alt="" src="/images/dot-bookshelf.svg" className="dot" />
               </Dropdown>
            </p>
         </div>
      </>
   );
};
export const MemoVideo = memo(Video);

export const Podcast: React.FC<BookShelfContentProps> = ({
   data,
   onOpenContent,
   onReturnContent
}) => {
   const [isImgError, setIsImgError] = useState(false);
   // eslint-disable-next-line @typescript-eslint/no-unused-vars
   const [confirmDialog, setConfirmDialog] = useState(false);

   const imgError = (image: any) => {
      setIsImgError(true);
      image.onError = '';
      return true;
   };
   const imgErrorSrc = '/images/book-placeholder.jpg';
   const dropdownBtn = (
      <Menu>
         <Menu.Item
            key="1"
            onClick={() => {
               onReturnContent(data.licenseUID);
               setConfirmDialog(true);
            }}
         >
            คืนสื่อ
         </Menu.Item>
      </Menu>
   );

   const { request } = useSelector(
      (state: HibraryRootState) => ({
         request: state.fetch.id === data.licenseUID ? state.fetch : undefined
      }),
      shallowEqual
   );
   return (
      <>
         <div className="bookshelf-collection">
            <div className={`cover ${request?.loading ? 'loading' : ''}`}>
               <div className={`filetype ${data.fileType}`}>{data.fileType}</div>
               <img
                  key={data.licenseUID}
                  alt=""
                  src={isImgError ? imgErrorSrc : data.coverImage}
                  className={`imageView`}
                  onError={imgError}
                  onClick={() => {
                     onOpenContent(data.licenseUID);
                  }}
               />
               {request?.loading ? (
                  <div className="loading-container">
                     <LoadingOutlined className="content-loading" />
                  </div>
               ) : null}
            </div>
            <p className="title">
               {changeTimeStampToText(data.expireDate)}
               <Dropdown overlay={dropdownBtn} trigger={['click']} placement="bottomLeft">
                  <img alt="" src="/images/dot-bookshelf.svg" className="dot" />
               </Dropdown>
            </p>
         </div>
      </>
   );
};

export const ConfirmModal = ({
   setConfirmDialog,
   data,
   nextFunction,
   buttonType
}: {
   setConfirmDialog: React.Dispatch<React.SetStateAction<boolean>>;
   data: bookShelfContent;
   nextFunction: (licenseUID: string, contentType: ContentType) => void;
   buttonType?: ButtonType;
}) => {
   const getTitleModal = (licenseStatus: RentalStatus) => {
      switch (licenseStatus) {
         case RentalStatus.Rent:
         case RentalStatus.WaitingListed: {
            return 'คุณต้องการยกเลิกการจอง';
         }
         default: {
            return `คุณต้องการคืน${setContentType(data.contentType)}`;
         }
      }
   };

   return (
      <>
         <Modal
            className="rental-modal"
            onCancel={() => setConfirmDialog(false)}
            visible={true}
            title={getTitleModal(data.licenseStatus!)}
            footer={
               <>
                  <Button
                     size="middle"
                     type="ghost"
                     shape="round"
                     key="back"
                     className="cancel-button"
                     onClick={() => {
                        setConfirmDialog(false);
                     }}
                  >
                     ยกเลิก
                  </Button>
                  <Button
                     size="middle"
                     type="primary"
                     shape="round"
                     className="confirm-button"
                     key="confirm"
                     onClick={() => {
                        setConfirmDialog(false);
                        nextFunction(data.licenseUID, data.contentType);
                     }}
                  >
                     {buttonType === ButtonType.Cancel
                        ? 'ยืนยัน'
                        : setButtonText(
                             data.licenseType === LicenseType.Sample
                                ? (data.licenseType as LicenseType)
                                : (data.licenseStatus as RentalStatus),
                             data.contentType
                          )}
                  </Button>
               </>
            }
         >
            <div>
               <h3>{data.title}</h3>
            </div>
         </Modal>
      </>
   );
};
export const MemoPodcast = memo(Podcast);
