export interface HomeResponse {
   home: Home[];
   banners: Banner[];
}

export enum HomeType {
   ContentSet = 'ContentSet',
   CategoryEbook = 'CategoryEbook',
   SubCategoryEbook = 'SubCategoryEbook',
   AllContent = 'AllContent',
   Categories = 'Categories',
   BestSeller = 'BestSeller',
   NewContent = 'NewContent'
}

export enum ContentType {
   content = 'content',
   allContent = 'all',
   ebook = 'ebook',
   podcast = 'podcast',
   magazine = 'magazine',
   video = 'video',
   audioBook = 'audioBook'
}

export enum LicenseType {
   Sample = 'Sample',
   Rent = 'Rent',
   Buffet = 'Buffet',
   Private = 'Private',
   Free = 'Free'
}

export enum HomeSetType {
   Magazine = 'Magazine',
   Newspaper = 'Newspaper',
   eBook = 'eBook'
}

export enum SetType {
   Magazine = 'magazine',
   Newspaper = 'newspaper'
}

type HomeContent = VideoContent | Content | PodcastContent;

export interface Home {
   type?: HomeType;
   sets?: HomeSet[];
   setType?: HomeSetType;
   title?: string;
   contentType?: ContentType;
   ebooks?: Content[];
   videos?: VideoContent[];
   contents?: HomeContent[];
   catId?: string | number;
   subcatId?: string | number;
   category?: Category[];
}

export interface Category {
   catId: number;
   status?: number;
   catName: string;
   coverImage?: string;
   subCategory?: SubCategory[];
   licenseType: LicenseType;
   contentType?: ContentType;
}

export const getLicenseType = (licenseType: string): LicenseType => {
   switch (licenseType) {
      case 'sample':
         return LicenseType.Sample;
      case 'rent':
         return LicenseType.Rent;
      case 'buffet':
         return LicenseType.Buffet;
      case 'private':
         return LicenseType.Private;
      case 'free':
         return LicenseType.Free;
      default:
         return LicenseType.Rent;
   }
};

export interface SubCategory {
   subcatId: number;
   status: number;
   subcatName: string;
   coverImage: string;
}

export interface Content {
   contentUID: string;
   subcatId?: string;
   concurrent: number;
   catId?: string;
   creatorUID: string;
   title: string;
   authorName: string;
   coverImage: string;
   fileType: string;
   licenseType: LicenseType;
   contentType: ContentType;
   writerID?: number;
   status?: number;
   pageCount?: number;
   fileSize?: number;
   description?: string;
   publisherName?: string;
   catName?: string;
   subcatName?: string;
   rentalStatus?: RentalStatus;
   rentalPeriod?: number;
   channelName?: string;
   view?: number;
   uploadDate?: number;
   duration?: string;
}

export interface HomeSet {
   uid: string;
   title: string;
   imagePath: string;
}

export interface BannerResponse {
   banners: Banner[];
}

export interface ImageBanner {
   mobile?: string;
   tablet?: string;
   desktop?: string;
}

export interface Banner {
   contentType: ContentType;
   licenseType: string;
   subValue?: string;
   image: ImageBanner;
   imageUrl: string;
   type: BannerType;
   value?: string;
   status: number;
   title: string;
}

export enum BannerType {
   ContentDetail = 'contentDetail',
   Category = 'category',
   Subcategory = 'subcategory',
   Url = 'url',
   ContentList = 'contentList'
}

export enum FollowKey {
   writer = 'writer',
   publisher = 'publisher',
   category = 'category',
   subcategory = 'subcategory'
}

//new
export enum SegmentType {
   Recommend = 'recommend',
   NewContent = 'newContent',
   Category = 'category',
   Subcategory = 'subcategory',
   Popular = 'popular',
   ContentList = 'contentList',
   Banner = 'banner',
   AllContent = 'allContent',
   AllCategories = 'allCategories',
   RelateContent = 'relateContent',
   MagazineSet = 'magazineSet',
   NewspaperSet = 'newspaperSet'
}

export enum DisplayType {
   Content = 'content',
   ContentWithButton = 'contentWithButton',
   ContentSlideWithButtonDetail = 'contentSlideWithButtonDetail',
   ContentWithDetail = 'contentWithDetail',
   ContentWithButtonDetail = 'contentWithButtonDetail',
   Banner = 'banner',
   AllCategory = 'allCategory',
   VideoList = 'videList',
   VideoSlide = 'videoSlide',
   MagazineSet = 'magazineSet',
   NewspaperSet = 'newspaperSet'
}

export interface Ebook {
   contentUID: string;
   subcatId: number;
   activeLicense: number;
   concurrent: number;
   catId: number;
   creatorUID: string;
   title: string;
   authorName: string;
   isNew: boolean;
   isBestSeller: boolean;
   isPromotions: boolean;
   coverImage: string;
   fileType: string;
   channelName?: string;
}

export interface BestSellerContent extends Content {
   concurrent: number;
   writerID: number;
   title: string;
   fileType: string;
   status: number;
   pageCount: number;
   fileSize: number;
   description: string;
   publisherName: string;
   catName: string;
   subcatName: string;
   rentalStatus: RentalStatus;
   rentalPeriod: number;
   waitingAmount: number;
   remainConcurrent: number;
   licenseType: LicenseType;
   contentType: ContentType;
}

export interface ContentDetail extends Content {
   concurrent: number;
   pageCount: number;
   fileSize: number;
   description: string;
   publisherName: string;
   catName: string;
   subcatName: string;
   creatorUID: string;
   totalRent?: number;
   title: string;
   authorName: string;
   coverImage: string;
   coverImageWebp: string;
   fileType: string;
   relatedEbooks?: Content[];
   relatedContents?: Content[];
   rentalPeriod?: number;
   rentalStatus: RentalStatus;
   waitingAmount?: number;
   remainConcurrent?: number;
   licenseType: LicenseType;
}

export interface VideoContent extends Content {
   contentUID: string;
   subcatId: string;
   catId: string;
   creatorUID: string;
   title: string;
   coverImage: string;
   status: number;
   description: string;
   rentalPeriod: number;
   channelName: string;
   channelImage: string;
   duration: string;
   view?: number;
   uploadDate?: number;
   playlist: string;
   language: string;
   totalRent?: number;
   waitingAmount?: number;
   remainConcurrent?: number;
   relatedContents?: Content[];
   licenseType: LicenseType;
}

export interface PodcastContent extends Content {
   contentUID: string;
   subcatId: string;
   catId: string;
   creatorUID: string;
   title: string;
   coverImage: string;
   status: number;
   description: string;
   rentalPeriod: number;
   channelName: string;
   uploadDate: number;
   totalRent?: number;
   waitingAmount?: number;
   remainConcurrent?: number;
   relatedContents?: Content[];
   licenseType: LicenseType;
   episodes?: PodcastEpisode[];
   duration: string;
}

export interface PodcastEpisode {
   title: string;
   contentUID: string;
   description: string;
   uploadDate: number;
   duration: string;

   // sourceUrl?: string;
   // isPlaying?: boolean;
   // onPlayClick?: (target: any, episode: any) => void;
   // onPauseClick?: (target: any, episode: any) => void;
   // autoPlay?: boolean;
   // rentBtn_playpodcast?: any;
   // rentalStatus?: any;
   // licenseType?: string;
   // episodeUID?: string;
   // podcastUID?: string;
   // playlist?: string;
   // playingEp?: any;
   // setPlayingEp?: any;
   // ep?: any;
   // isPlay?: boolean;
   // isPlaylist?: boolean;
   // setIsPlaylist?: any;
}

export interface PodcastPlaylist {
   playlist: string;
}

export enum ContentViewType {
   List, // 0
   Collection, // 1
   BestSeller, // 2
   History, //3
   Queue, //4
   BookShelf, //5
   Detail
}

export enum RentalStatus {
   Rent = 'Rent',
   Rented = 'Rented',
   WaitingList = 'WaitingList',
   WaitingListed = 'WaitingListed',
   Returned = 'Returned',
   ReturnAble = 'ReturnAble',
   Block = 'Block'
}

export enum RentBtnTitle {
   Rent = 'ยืมสื่อ',
   Rented = 'ยืมแล้ว',
   WaitingList = 'จองคิว',
   WaitingListed = 'ยกเลิก'
}

export enum ContributorRole {
   Author = 'author',
   COAuthor = 'coAuthor',
   Narrator = 'narrator',
   CoNarrator = 'coNarrator',
   Editor = 'editor',
   Translator = 'translator',
   Illustrator = 'illustrator',
   Created = 'created',
   AudioNarrator = 'audioNarrator',
   MixEngineer = 'mix engineer',
   MotionAndAnimation = 'motion and animation'
}

export enum ContributorValue {
   Author = 'ผู้แต่ง',
   COAuthor = 'ผู้แต่งร่วม',
   Narrator = 'ผู้บรรยายหลัก',
   CoNarrator = 'ผู้บรรยายร่วม',
   Editor = 'บรรณาธิการ',
   Translator = 'ผู้แปล',
   Illustrator = 'ผู้วาดภาพประกอบ',
   Created = 'ผู้จัดทำ',
   AudioNarrator = 'ผู้บรรยาย',
   MixEngineer = 'mix engineer',
   MotionAndAnimation = 'motion and animation'
}

export interface CategoryContent {
   catId: number;
   subcats: SubCategoryContent[];
   catName: string;
   status: number;
}

export interface SubCategoryContent {
   subcatId: number;
   subcatName: string;
   ebooks?: Content[];
   status: number;
   contents?: Content[];
}

export interface TKCategory {
   uid: string;
   name: string;
   libraryValue: number;
   contentType: ContentType;
   status: number;
   coverImage: string;
   coverImageWebp: string;
   subcategoryValue?: number;
   subcategoryUID?: string;
   subcategoryName?: string;
}

export interface Contributor {
   role: ContributorRole;
   writerName: string;
   writerUID: string;
}

export interface ContentMetaData {
   contentUID: string;
   catUID: string;
   catName: string;
   subcatUID: string;
   subcatName: string;
   contentRating: string;
   contentType: ContentType;
   writerUID: string;
   writerName: any[];
   contributors?: Contributor[];
   coverImage: string;
   coverImageWebp: string;
   publisherUID: string;
   publisherName: string;
   description: string;
   ISBN: string;
   eISBN: string;
   pageCount: number;
   title: string;
   language: string;
   fileSize: number;
   fileType: string;
   publicationDate: number;
   concurrent: number;
   activeLicense: number;
   licenseStatus: RentalStatus;
   licenseSampleStatus: boolean;
   isFavorite: boolean;
   licenseType: LicenseType;
   licenseUID?: string;
   updateDate: number;
   publisherDate: number;
   rentHistory: number;
   expireDate?: number;
   queue: number;
   tags?: any[];
   duration?: string;
   mediaLength?: number;
   chanelName?: string;
   chanelUID?: string;
   uid?: string;
   isSampleFile: boolean;
   fileSample?: string;
   displayTitle?: DisplayTitle;
   forecastQueue?: string;
   forecastQueueTH?: string;
}

export interface HomeList {
   title: string;
   subtitle?: string;
   displayType: DisplayType;
   segmentType: SegmentType;
   contents: ContentMetaData[] | Banner[] | MagazineSetType[] | TKCategory[];
   uid?: string;
   categoryUID?: string;
   subcategoryUID?: string;
}

export interface DisplayTitle {
   title?: string;
   subtitle?: string;
}

export interface CategoryResponse {
   category: TKCategory[];
}

export interface MagazineSetResponse {
   contents: MagazineSetType[];
   title: string;
}

export interface HomeListResponse {
   home: HomeList[];
}

export interface ContentDetailResponse<T = ContentMetaData> {
   contentData: T;
   relatedContent: T[];
}

export interface MagazineSetType {
   catUID: string;
   contentType: ContentType;
   contributors: Contributor[];
   prefix: string;
   coverImage: string;
   coverImageWebp: string;
   publisherUID: string;
   setType: string;
   setUID: string;
   subcatUID: string;
   title: string;
}

export interface ContentByCategory {
   subcategoryUID: string;
   subcategoryName: string;
   contents: ContentMetaData[];
}

export interface VideoMetaData extends ContentMetaData {
   publisherImage?: string;
   publisherImageWebp?: string;
   language: string;
   updateDate: number;
   chanelUID: string;
   chanelName: string;
   duration: string;
   mediaLength: number;
   view: number;
   url?: string;
   chapters: ContentList[];
   watchProgress: WatchProgression;
}

export interface AudioMetaData extends ContentMetaData {
   setUID: string;
   chapters: ContentList[];
   listenProgress: ListenProgression;
   sampleChapter: SampleChapterType;
}

export interface SampleChapterType {
   chapterUID: string;
   fileSample: string;
   contentUpdated: number;
   duration: string;
   mediaLength: number;
   title: string;
}

export interface WatchProgression {
   lastProgress: number;
   timeWatching: number;
   uploadDate: number;
   uploadUID: string;
}

export interface ListenProgression {
   progressions: ListenChapter[];
   timeListen: number;
   uploadDate: number;
   uploadUID: string;
}

export interface ListenChapter {
   lastProgress: number;
   chapterUID: string;
   endDate: number;
}

export interface ContentList {
   index?: number;
   chapterUID: string;
   title: string;
   language?: string;
   contentType: string;
   contentUpdated?: number;
   contentRating?: string;
   status: number;
   duration?: string;
   mediaLength?: number;
   description?: string;
   content?: string;
   track?: number;
   progression?: ListenChapter;
}
