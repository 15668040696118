import { useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as auth from '../../redux/auth/authRedux';
import { signOut } from '../../redux/auth/authCrud';
import { HibraryRootState } from '../../../redux/rootReducer';
import { Loading } from '../../customUI/loading';
import * as request from '../../redux/request';
//testdeploy
const Logout = () => {
   const pageId = 'logout';
   const { authState } = useSelector(
      (state: HibraryRootState) => ({
         authState: state.auth
      }),
      shallowEqual
   );
   const dispatch = useDispatch();
   const history = useHistory();

   useEffect(() => {
      if (authState.authToken !== undefined) getSignOut();
      else history.push(`/`);
   }, [dispatch, history]);

   const getSignOut = async () => {
      try {
         dispatch(request.action.setProcess(true, pageId));
         const response = await signOut();
         if (response) {
            dispatch(auth.actions.logout());
            dispatch(request.action.setProcess(false, pageId));
            window.location.href = response.logoutURL;
         }
      } catch (error) {
         dispatch(request.action.setProcess(false, pageId));
         dispatch(auth.actions.logout());
         history.push(`/`);
      }
   };
   return (
      <div>
         <Loading id={'LogOut'} />
      </div>
   );
};
export default Logout;
