import { useSelector, shallowEqual } from 'react-redux';
import { HibraryRootState } from '../../../redux/rootReducer';
import { FunctionComponent, useMemo } from 'react';
import tkLoading2 from '../../assets/lotties/tak-loading2.json';
import Lottie from 'lottie-react';
import './index.scss';

interface LoadingProps {
   id: string;
   absolute?: boolean;
}

export const Loading: FunctionComponent<LoadingProps> = ({ id, absolute }) => {
   const { request } = useSelector(
      (state: HibraryRootState) => ({
         request: state.fetch
      }),
      shallowEqual
   );

   const loadingMemo = useMemo(
      () => (
         <>
            {request.loading && request.id === id ? (
               <div className={absolute ? 'component-loading-absolute' : 'component-loading'}>
                  <Lottie animationData={tkLoading2} className="loading" />
               </div>
            ) : null}
         </>
      ),
      [request]
   );
   return <>{loadingMemo}</>;
};

export const ComponentLoading = (props: LoadingProps) => {
   const { request } = useSelector(
      (state: HibraryRootState) => ({
         request: state.fetch
      }),
      (a) => {
         if (a.request.id === props.id) {
            return false;
         } else {
            return true;
         }
      }
   );
   return (
      <>
         {request.loading ? (
            <div className="in-component-loading">
               <Lottie animationData={tkLoading2} className="loading" />
            </div>
         ) : null}
      </>
   );
};
