import { PlayState } from '../player';

export interface VideoPlayerState {
   contentUID?: string;
   currentTime?: number;
   status: PlayState;
   track?: number;
   timeWatching?: number;
}

const initialData: VideoPlayerState = {
   status: PlayState.Stop
};

export interface VideoPlayerAction {
   payload?: PlayerActionPayload;
   type: VideoPlayerActionType;
}

export enum VideoPlayerActionType {
   Play = 'VideoPlayerActionType[Play]',
   Pause = 'VideoPlayerActionType[Pause]',
   Stop = 'VideoPlayerActionType[Stop]',
   Init = 'VideoPlayerActionType[Init]',
   SetupPlayer = 'PlayerActionType[SetupPlayer]',
   ChangeTrack = 'PlayerActionType[ChangeTrack]',
   UpdateCurrentTime = 'PlayerActionType[UpdateCurrentTime]',
   IncrementTime = 'PlayerActionType[IncrementTime]',
   ResetPlayer = 'PlayerAction[ResetPlayer]'
}

export interface PlayerActionPayload {
   contentUID?: string;
   currentTime?: number;
   track?: number;
   timeWatching?: number;
}

export const reducer = (state = initialData, action: VideoPlayerAction): VideoPlayerState => {
   switch (action.type) {
      case VideoPlayerActionType.Play: {
         return { ...state, status: PlayState.Playing };
      }
      case VideoPlayerActionType.Pause: {
         return { ...state, status: PlayState.Pause };
      }
      case VideoPlayerActionType.Init: {
         return { status: PlayState.Init };
      }
      case VideoPlayerActionType.IncrementTime: {
         return { ...state, timeWatching: state.timeWatching! + 1 };
      }
      case VideoPlayerActionType.Stop: {
         return { ...state, status: PlayState.Stop };
      }
      case VideoPlayerActionType.UpdateCurrentTime: {
         return { ...state, currentTime: action.payload?.currentTime };
      }
      case VideoPlayerActionType.SetupPlayer: {
         return {
            ...state,
            contentUID: action.payload?.contentUID,
            currentTime: action.payload?.currentTime
         };
      }
      case VideoPlayerActionType.ResetPlayer: {
         return initialData;
      }
      default:
         return state;
   }
};

const setPlay = (): VideoPlayerAction => {
   return {
      type: VideoPlayerActionType.Play
   };
};

const setPause = (): VideoPlayerAction => {
   return {
      type: VideoPlayerActionType.Pause
   };
};

const setStop = (): VideoPlayerAction => {
   return {
      type: VideoPlayerActionType.Stop
   };
};

const incrementTime = (): VideoPlayerAction => {
   return {
      type: VideoPlayerActionType.IncrementTime
   };
};

const updateCurrentTime = (currentTime = 0): VideoPlayerAction => {
   return {
      type: VideoPlayerActionType.UpdateCurrentTime,
      payload: { currentTime }
   };
};

const resetPlayer = (): VideoPlayerAction => {
   return {
      type: VideoPlayerActionType.ResetPlayer
   };
};

const setupPlayer = (contentUID: string, currentTime = 0, timeWatching = 0): VideoPlayerAction => {
   return {
      type: VideoPlayerActionType.SetupPlayer,
      payload: {
         contentUID,
         currentTime,
         timeWatching
      }
   };
};

export const action = {
   setupPlayer,
   setPlay,
   setPause,
   setStop,
   incrementTime,
   updateCurrentTime,
   resetPlayer
};
