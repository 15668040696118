import { FetchStatus } from '../request';
import axios from 'axios';
import { ContentType } from '../../../interface';
export interface WishListState {
   requestStatus: FetchStatus;
   contentUID?: string;
   isFavorite?: boolean;
}

const initialData: WishListState = {
   requestStatus: FetchStatus.Init
};

export interface WishListAction {
   payload?: WishListActionPayload;
   type: WishListActionType;
}

export enum WishListActionType {
   AddWishlist = 'WishListActionType[AddWishlist]',
   RemoveWishlist = 'WishListActionType[RemoveWishlist]',
   UpdateRequestStatus = 'WishListActionType[UpdateRequestStatus]'
}

export interface WishListActionPayload {
   requestStatus?: FetchStatus;
   contentUID?: string;
}

export const reducer = (state = initialData, action: WishListAction): WishListState => {
   switch (action.type) {
      case WishListActionType.AddWishlist: {
         return {
            ...state,
            requestStatus: FetchStatus.Complete,
            isFavorite: true
         };
      }
      case WishListActionType.RemoveWishlist: {
         return {
            ...state,
            requestStatus: FetchStatus.Complete,
            isFavorite: false
         };
      }
      case WishListActionType.UpdateRequestStatus: {
         return {
            ...state,
            requestStatus: action.payload?.requestStatus ?? FetchStatus.Init,
            contentUID: action.payload?.contentUID
         };
      }
      default:
         return state;
   }
};

const updateRequestStatus = (status: FetchStatus, contentUID: string): WishListAction => {
   return {
      type: WishListActionType.UpdateRequestStatus,
      payload: { requestStatus: status, contentUID }
   };
};

const addWishList = (contentUID: string): WishListAction => {
   return {
      type: WishListActionType.AddWishlist,
      payload: { contentUID }
   };
};

const removeWishList = (contentUID: string): WishListAction => {
   return {
      type: WishListActionType.RemoveWishlist,
      payload: { contentUID }
   };
};

const fetchWishlist = async (contentUID: string, contentType: ContentType, wishlist: boolean) => {
   if (wishlist) {
      await axios.post('license/favorite', {
         contentUID,
         contentType
      });
   } else {
      await axios.delete('license/favorite', {
         data: { contentUID, contentType }
      });
   }
};

export const request = {
   fetchWishlist
};

export const action = {
   updateRequestStatus,
   addWishList,
   removeWishList
};
