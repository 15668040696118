import { Tag } from 'antd';
import React, { memo, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { SearchPageType } from '.';
import { ContentType } from '../../../../interface';
import { HibraryRootState } from '../../../../redux/rootReducer';
import { EmptyDataView } from '../../../components/nodata';
import { action } from '../../../redux/request';
import { getSearchTag } from '../../../redux/store/storeCrud';

interface TagProps {
   contents?: string[];
   selectContentType?: ContentType;
   searchType: SearchPageType;
   keyword?: string;
   pageId?: string;
}

const SearchTags: React.FC<TagProps> = ({
   contents,
   selectContentType,
   keyword,
   pageId,
   searchType
}) => {
   const dispatch = useDispatch();
   const history = useHistory();
   const [tagList, setTagList] = useState<string[]>();

   const { request } = useSelector(
      (state: HibraryRootState) => ({
         request: state.fetch
      }),
      shallowEqual
   );

   useEffect(() => {
      if (!contents) {
         fetchTagList();
      }
   }, [selectContentType]);

   const fetchTagList = async () => {
      try {
         dispatch(action.setProcess(true, pageId));
         const response = await getSearchTag(selectContentType!, keyword!);
         setTagList(response?.tags);
         dispatch(action.setProcess(false, pageId));
      } catch (error) {
         dispatch(action.setProcess(false, pageId));
      }
   };

   return (
      <div className="tag-container">
         <div className="title-section margin-bottom-10">
            <h2
               style={
                  searchType === SearchPageType.All
                     ? { textAlign: 'left' }
                     : { width: '100%', textAlign: 'center', marginBottom: '16px' }
               }
            >
               แท็ก
            </h2>
         </div>
         <div className="tag-flex" style={{ justifyContent: 'flex-start', padding: 0 }}>
            {contents ? (
               <>
                  {contents.map((el, index) => (
                     <Tag
                        key={index}
                        className="search-tag"
                        style={{ backgroundColor: '#f2f2f2' }}
                        onClick={() => history.push(`/search/${el}/${SearchPageType.All}`)}
                     >
                        {el}
                     </Tag>
                  ))}
               </>
            ) : (
               <>
                  {tagList?.map((el, index) => (
                     <Tag
                        key={index}
                        className="search-tag"
                        style={{ backgroundColor: '#f2f2f2' }}
                        onClick={() => history.push(`/search/${el}/${SearchPageType.All}`)}
                     >
                        {el}
                     </Tag>
                  ))}

                  {tagList && tagList.length < 1 && (
                     <>{!request.loading && <EmptyDataView minHeight={50} />}</>
                  )}
               </>
            )}
         </div>
      </div>
   );
};
export const MemoSearchTag = memo(SearchTags);
