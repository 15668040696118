import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { HibraryRootState } from '../../../redux/rootReducer';
import './index.scss';

interface MainProps {
   absolute?: boolean;
}

export const MainLoading = (props: MainProps) => {
   const { darkMode } = useSelector(
      (state: HibraryRootState) => ({
         darkMode: state.darkMode
      }),
      shallowEqual
   );

   return (
      <div className="main-loading">
         <div className={props.absolute ? `container-absolute` : `container`}>
            {darkMode.darkMode ? (
               <img src="/images/tk-icon/tk-logo-dark-mode.svg" className="loading-image" />
            ) : (
               <img src="/images/tk-icon/tk-logo-normal-mode.svg" className="loading-image" />
            )}
         </div>
      </div>
   );
};
