import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';
import { eraseCookie } from '../../helpers/cookie';
import { setupAxiosByToken } from '../../../request';

export enum AuthenActionTypes {
   Login = '[Login] Authen',
   RefreshToken = '[RefreshToken] Authen',
   UpdateToken = '[UpdateToken] Authen',
   Logout = '[Logout] Authen'
}

export interface UserAuthentication {
   authToken?: string;
   pathToGo?: string;
   userInfo?: UserTokenInfo;
   isLogin: boolean;
}

export const getUserInfo = (userToken: string): UserTokenInfo => {
   const jwtObject: UserTokenInfo = jwt_decode(userToken);
   return jwtObject;
};

const initialAuthState: UserAuthentication = {
   authToken: undefined,
   pathToGo: undefined,
   userInfo: undefined,
   isLogin: false
};

export interface UserTokenInfo {
   memberID: string;
   role: string;
   expireDate: number;
   startDate: number;
   name: string;
   surname: string;
   staffID: string;
   email: string;
   storeType: string;
   storeStatus: string;
   packageUID: string;
   clientUID: string;
   clientName: string;
   companyName: string;
   clientLogo: string;
   clientPrefix: string;
   showClientLogo: boolean;
   isPrivateLibrary: boolean;
   isAnonymous: boolean;
   iat: number;
   exp: number;
}

interface AuthenAction {
   type: AuthenActionTypes;
   payload?: { userToken: string };
}

export const reducer = persistReducer(
   {
      storage,
      key: 'hibrary-web-auth',
      whitelist: ['authToken', 'userInfo', 'isLogin']
   },
   (state: UserAuthentication = initialAuthState, action: AuthenAction): UserAuthentication => {
      switch (action.type) {
         case AuthenActionTypes.Login: {
            const { userToken } = action.payload!;
            setupAxiosByToken(userToken);
            const userInfo = getUserInfo(userToken);
            return {
               authToken: userToken,
               userInfo: userInfo,
               isLogin: userInfo.role === 'guest' ? false : true
            };
         }
         case AuthenActionTypes.Logout: {
            eraseCookie(process.env.REACT_APP_READER_TOKEN!);
            eraseCookie(process.env.REACT_APP_READER_RETURN_URL!);
            const newState: UserAuthentication = Object.assign(state, {});
            newState.authToken = undefined;
            newState.userInfo = undefined;
            newState.isLogin = false;
            setupAxiosByToken(undefined);
            return { ...newState };
         }
         case AuthenActionTypes.UpdateToken: {
            const { userToken } = action.payload!;
            setupAxiosByToken(userToken);
            return {
               authToken: userToken,
               userInfo: getUserInfo(userToken),
               isLogin: true
            };
         }
         case AuthenActionTypes.RefreshToken: {
            const { userToken } = action.payload!;
            setupAxiosByToken(userToken);
            return {
               authToken: userToken,
               userInfo: getUserInfo(userToken),
               isLogin: true
            };
         }
         default:
            return state;
      }
   }
);

export const actions = {
   login: (userToken: string): AuthenAction => ({
      type: AuthenActionTypes.Login,
      payload: { userToken }
   }),
   updateToken: (userToken: string): AuthenAction => ({
      type: AuthenActionTypes.UpdateToken,
      payload: { userToken }
   }),
   refreshToken: (userToken: string) => ({
      type: AuthenActionTypes.RefreshToken,
      payload: { userToken: userToken }
   }),
   logout: (): AuthenAction => ({ type: AuthenActionTypes.Logout })
};
