import { Link } from 'react-router-dom';

const TermsAndCondition = () => {
   return (
      <div className="TermsAndConditionContainer">
         <div className="contentCol">
            <div>
               <h1 className="titleHeader">เงื่อนไขและข้อตกลงการใช้บริการ</h1>
               <h1 className="titleHeader marginBottom">Terms and Conditions</h1>
            </div>
            <div className="textIndent">
               ระบบห้องสมุดดิจิทัล TK Read (ซึ่งต่อไปนี้เรียกว่า “ห้องสมุดดิจิทัล TK Read”)
               จัดทำขึ้นโดย สถาบันอุทยานการเรียนรู้ (ซึ่งต่อไปนี้เรียกว่า“TK Park”)
               เพื่อเป็นพื้นที่การเรียนรู้สาธารณะดิจิทัล การใช้ห้องสมุดดิจิทัล TK Read
               ของผู้ใช้บริการจะอยู่ภายใต้เงื่อนไขและข้อตกลงนี้
               ดังนั้นผู้ใช้บริการจึงควรศึกษาเงื่อนไขและข้อตกลงฉบับนี้
               และ/หรือเงื่อนไขและข้อตกลงอื่นใดที่เราได้แจ้งให้ทราบบนห้องสมุดดิจิทัล TK Read
               โดยละเอียดก่อนการเข้าใช้ห้องสมุดดิจิทัล TK Read ทั้งนี้ การเข้าใช้ห้องสมุดดิจิทัล TK
               Read
               นี้ให้ถือว่าผู้ใช้บริการยอมรับและตกลงที่จะปฏิบัติตามเงื่อนไขและข้อตกลงดังกล่าวทั้งหมด
               ดังนี้
            </div>
            <div>
               <strong>1. การใช้บริการ</strong>
               <ul className="noDot">
                  <li className="textIndent">
                     TK Park จัดทำห้องสมุดดิจิทัล TK Read
                     ขึ้นเพื่อเป็นพื้นที่การเรียนรู้สาธารณะดิจิทัล ที่ให้บริการ ยืม-คืน
                     และอ่านหนังสือและสื่ออิเล็กทรอนิกส์ ในรูปแบบดิจิทัลแพลตฟอร์ม TK Park
                     ขอสงวนสิทธิในการระงับการเผยแพร่ เปลี่ยนแปลง แก้ไข
                     ยกเลิกหรือปรับปรุงข้อมูลเมื่อใดก็ได้ โดยไม่จำต้องแจ้งให้ทราบล่วงหน้า
                     ทั้งนี้หนังสือและสื่ออิเล็กทรอนิกส์ ที่ให้บริการในห้องสมุดดิจิทัล TK Read
                     จะสามารถอ่านผ่านแอปพลิเคชันหรือเว็บไซต์ในระบบปฏิบัติการต่างๆ ที่ทาง TK Park
                     กำหนดไว้ให้เท่านั้น และขอแจ้งให้ท่านทราบว่าท่านต้องดำเนินการ สมัครสมาชิก กับ TK
                     Park หรือการให้ข้อมูลส่วนบุคคลบางประการ
                     เพื่อเข้าใช้งานบริการเหล่านั้นแบบเต็มรูปแบบ โดยการจัดเก็บข้อมูลส่วนบุคคลของท่าน
                     TK Park จะดำเนินการให้เป็นไปตาม{' '}
                     <Link to="/privacyPolicy">นโยบายคุ้มครองข้อมูลส่วนบุคคล</Link>{' '}
                     ตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ ๒๕๖๒
                  </li>

                  <li className="redHightLight">
                     *
                     การสมัครสมาชิกเว็บไซต์ขอสงวนสิทธิการสมัครเฉพาะบุคคลสัญชาติไทยที่มีหมายเลขบัตรประชาชนเท่านั้น
                  </li>
               </ul>
            </div>
            <div>
               <div className="flexCol directionColumn">
                  <strong>2. ข้อจำกัดในการใช้งาน</strong>
                  <b>ผู้ใช้บริการตกลงที่จะไม่ใช้บริการในลักษณะดังนี้</b>
               </div>
               <ul className="noDot">
                  <li>
                     <b>(1)</b> ดำเนินการใด ๆ ที่เป็นการละเมิดหรือละเมิดสิทธิของผู้อื่น ละเมิดกฎหมาย
                     หรือละเมิดสัญญาหรือหน้าที่ตาม กฎหมายที่ท่านมีต่อบุคคลใด ๆ
                  </li>
                  <li>
                     <b>(2)</b> ละเมิดลิขสิทธิ์หรือละเมิดทรัพย์สินทางปัญญา และสิทธิต่าง ๆ ของ TK
                     Park และ/หรือบุคคลที่สาม (แล้วแต่กรณี)
                     เป็นเจ้าของลิขสิทธิ์หรือทรัพย์สินทางปัญญา
                  </li>
                  <li>
                     <b>(3)</b> กระทำการใด ๆ อันมีผลทำให้เป็นการรบกวนหรือแทรกแซงการทำงานของระบบ
                     คอมพิวเตอร์โดยการส่งไวรัส (Virus) หรือโปรแกรมคอมพิวเตอร์ในรูปแบบต่างๆ
                     ที่ออกแบบมาเพื่อขัดขวาง ทำลายจำกัดการ ทำงานของระบบคอมพิวเตอร์หรือ
                     คอมพิวเตอร์ฮาร์ดแวร์หรือโปรแกรมซอฟต์แวร์ในการสื่อสารของสมาชิกของ TK Park หรือ
                     ผู้ใช้บริการอินเทอร์เน็ตหรือบุคคลภายนอก
                  </li>
                  <li>
                     <b>(4)</b> ดำเนินการใด ๆ ที่ไม่ชอบด้วยกฎหมาย
                  </li>
                  <li>
                     <b>(5)</b> ถอดรหัสโปรแกรมแบบย้อนกลับ, ถอด, แยก
                     หรือพยายามที่จะค้นหารหัสหรือจะเข้าถึงแหล่งที่มาของความคิด
                     พื้นฐานหรือขั้นตอนวิธีการโครงสร้างของบริการของ TK park
                     ไม่ว่าจะทั้งหมดหรือบางส่วน หรือ
                  </li>
                  <li>
                     <b>(6)</b> ใช้ซอฟต์แวร์หรืออุปกรณ์ใด ๆ
                     (ไม่ว่าจะเป็นกระทำการเองหรือโดยอัตโนมัติ) เพื่อรวบรวมข้อมูลหรือเผยแพร่
                     ส่วนหนึ่งส่วนใดของเว็บไซต์ และ/หรือ แอปพลิเคชัน อาทิ
                     การจับภาพหรือบันทึกหน้าจอของหนังสือและสื่ออิเล็กทรอนิกส์ในห้องสมุดดิจิทัล TK
                     Read เป็นการกระทำอันเป็นการละเมิดลิขสิทธิ์หรือสิทธิในทรัพย์สินทางปัญญา TK Park
                     มีสิทธิหรืออำนาจควบคุมการใช้งานเนื้อหาของห้องสมุดดิจิทัล TK Read ห้ามมิให้
                     ทำซ้ำ คัดลอก ถ่ายสำเนา ดัดแปลง เผยแพร่ หรือกระทำการอื่นใด
                     เพื่อเผยแพร่ต่อสาธารณชน หรือเพื่อหาประโยชน์เชิงพานิชย์
                  </li>
               </ul>
            </div>
            <div>
               <strong>3. ลิขสิทธิ์และสิทธิในทรัพย์สินทางปัญญา</strong>
               <ul className="noDot textIndent">
                  TK Park และ/หรือบุคคลที่สาม (แล้วแต่กรณี)
                  เป็นเจ้าของลิขสิทธิ์หรือทรัพย์สินทางปัญญา ของเนื้อหาภายในห้องสมุดดิจิทัล TK Read
                  อันประกอบด้วย ข้อมูล บทความ งานเขียน รูปภาพ ภาพกราฟฟิก ข้อความ วีดิทัศน์ เสียง
                  สื่ออิเล็กทรอนิกส์ และส่วนประกอบอื่นๆ ที่อยู่บนห้องสมุดดิจิทัล TK Read นี้ โดย TK
                  Park มีสิทธิหรืออำนาจควบคุมการใช้งานเนื้อหาของห้องสมุดดิจิทัล TK Read ห้ามมิให้
                  ทำซ้ำ คัดลอก ถ่ายสำเนา ดัดแปลง เผยแพร่ หรือกระทำการอื่นใด เพื่อเผยแพร่ต่อสาธารณชน
                  หรือเพื่อหาประโยชน์เชิงพานิชย์
                  อันเป็นการละเมิดลิขสิทธิ์หรือสิทธิในทรัพย์สินทางปัญญา
                  โดยไม่ได้รับการยินยอมเป็นลายลักษณ์อักษรล่วงหน้าจาก TK Park
                  โดยหากสมาชิกปฏิบัติผิดเงื่อนไขดังกล่าว TK Park
                  มีสิทธิระงับการให้บริการชั่วคราวและถาวร บางส่วนหรือทั้งหมดในเวลาใดๆ ก็ได้
               </ul>
            </div>
            <div>
               <strong>4. การเชื่อมโยงกับเว็บไซต์ภายนอก</strong>
               <ul className="noDot textIndent">
                  ห้องสมุดดิจิทัล TK Read ของ TK Park
                  จัดให้มีการเชื่อมโยงกับเว็บไซต์และระบบภายนอกของบุคคลที่สาม
                  (ยกเว้นเว็บไซต์และระบบสารสนเทศอื่นๆ ในการพัฒนาของ TK Park) ทั้งนี้
                  เป็นเพียงการให้บริการเพื่อความสะดวกเท่านั้น ดังนั้น TK Park จึงไม่มีอำนาจควบคุม
                  รับรอง ยืนยันความถูกต้องหรือความน่าเชื่อถือ
                  หรือรับผิดชอบในเนื้อหาข้อมูลของเว็บไซต์และระบบภายนอกของบุคคลที่สาม นอกจากนี้
                  ข้อมูลดังกล่าวอาจมีข้อจำกัดในการที่ผู้ใช้จะนำไปเผยแพร่อีกต่อหนึ่งตามกฎหมายลิขสิทธิ์
                  ดังนั้นผู้ใช้จะต้องติดต่อขออนุญาตใช้ลิขสิทธิ์ในข้อมูลดังกล่าวจากเจ้าของข้อมูลโดยตรง
               </ul>
            </div>
            <div>
               <strong>5. ข้อสงวนสิทธิและข้อตกลงเงื่อนไขทั่วไป</strong>
               <ul className="noDot textIndent">
                  <li>
                     ผู้ใดแอบอ้างหรือกระทำการใดๆ อันเป็นการละเมิดสิทธิส่วนบุคคล
                     โดยใช้ข้อมูลของผู้อื่นมาแอบอ้างในการ ผู้ที่สมัครสมาชิก TK Park
                     /ลงทะเบียนร่วมกิจกรรม/ลงทะเบียนรับข้อมูลข่าวสาร/การติดต่อสอบถาม (แล้วแต่กรณี)
                     ถือเป็นความผิดและต้องรับโทษตามที่กฎหมายกำหนด
                  </li>
                  <li>
                     TK Park มีสิทธิระงับการให้บริการชั่วคราวและถาวร บางส่วนหรือทั้งหมดในเวลาใดๆ
                     ก็ได้ หากสมาชิกปฏิบัติผิดเงื่อนไขข้อหนึ่งข้อใดของข้อกำหนดและเงื่อนไขทั่วไป
                     หรือระบบการให้บริการของ สอร. ขัดข้องชั่วคราวหรือถาวร ไม่ว่าด้วยเหตุใดๆ
                     และหากระงับสิทธิ์ดังกล่าวนั้นเป็นการกระทำโดยไม่ได้ตั้งใจ
                     หรือมีเจตนาที่ไม่ดีที่จะทำให้เกิดการระเมิดข้อตกลง หรือความเสียหายใดๆ
                     ต่อระบบห้องสมุดดิจิทัล TK Read
                     สมาชิกสามารถติดต่อขออุทธรณ์การระงับสิทธิการใช้งานดังกล่าวได้ผ่านทางช่องทางที่ทาง
                     สอร. จัดเตรียมไว้ให้ โดยการพิจารณาดังกล่าวจะขึ้นอยู่กับดุลยพินิจของ สอร.
                  </li>
               </ul>
            </div>
            <div>
               <strong>6. ข้อจำกัดความรับผิดของ TK Park</strong>
               <ul className="noDot textIndent">
                  <li>
                     TK Park จะไม่รับผิดต่อความเสียหายใด ๆ รวมถึง ความเสียหาย
                     สูญเสียและค่าใช้จ่ายที่เกิดขึ้นไม่ว่าโดยตรงหรือโดยอ้อม
                     ที่เป็นผลหรือสืบเนื่องจากการที่ผู้ใช้เข้าใช้ห้องสมุดดิจิทัล TK Read
                     นี้หรือเว็บไซต์ที่เชื่อมโยงกับห้องสมุดดิจิทัล TK Read นี้ หรือต่อความเสียหาย
                     สูญเสียหรือค่าใช้จ่ายที่เกิดจากความล้มเหลวในการใช้งานความผิดพลาด การละเว้น
                     การหยุดชะงัก ข้อบกพร่อง ความไม่สมบูรณ์ คอมพิวเตอร์ไวรัส ถึงแม้ว่า TK Park
                     จะได้รับแจ้งว่าอาจจะเกิดความเสียหาย สูญเสียหรือค่าใช้จ่ายดังกล่าวขึ้น นอกจากนี้
                     TK Park ไม่รับผิดต่อผู้ใช้ห้องสมุดดิจิทัล TK Read หรือบุคคลจากการเรียกร้องใด ๆ
                     ที่เกิดขึ้นจากบนห้องสมุดดิจิทัล TK Read หรือเนื้อหาใด ๆ
                     ซึ่งรวมถึงการตัดสินใจหรือการกระทำใด ๆ
                     ที่เกิดจากความเชื่อถือในเนื้อหาดังกล่าวของผู้ใช้ห้องสมุดดิจิทัล TK Read
                     หรือในความเสียหายใด ๆ ไม่ว่าความเสียหายทางตรง หรือทางอ้อม
                     รวมถึงความเสียหายอื่นใดที่อาจเกิดขึ้นได้ผู้ใช้บริการยอมรับและตระหนักดีว่า TK
                     Park จะไม่ต้องรับผิดชอบต่อการกระทำใดของผู้ใช้บริการทั้งสิ้น
                  </li>
                  <li>
                     หาก TK Park ไม่สามารถให้บริการบางส่วนหรือทั้งหมด อันเนื่องจากระบบหรืออุปกรณ์ใดๆ
                     ของสมาชิกชำรุดหรือขัดข้อง หรือระบบโทรศัพท์
                     หรือระบบสื่อสารโทรคมนาคมขัดข้องหรือเหตุใดๆ ที่อยู่นอกเหนือการควบคุมของ TK Park
                     ทั้งนี้ TK Park ไม่ต้องรับผิดชอบในความเสียหายใดๆ
                  </li>
                  <li>
                     TK Park ขอสงวนสิทธิในการปรับปรุงหรือแก้ไขห้องสมุดดิจิทัล TK Read
                     หรือเนื้อหาบนห้องสมุดดิจิทัล TK Read นี้ ในเวลาใด ๆ
                     รวมทั้งขอสงวนสิทธิที่จะปฏิเสธหรือจำกัดการเข้าถึงห้องสมุดดิจิทัล TK Read
                     นี้ของบุคคลใด หรือจากเลขที่อยู่อินเทอร์เน็ตใด
                     โดยไม่จำเป็นต้องบอกกล่าวหรือระบุเหตุผลในการดำเนินการนั้น
                  </li>
               </ul>
            </div>
            <div>
               <strong>7. การโอนสิทธิ</strong>
               <ul className="noDot textIndent">
                  ผู้ที่สมัครสมาชิกเว็บไซต์/ผู้ที่ลงทะเบียนร่วมกิจกรรม/ผู้ที่ลงทะเบียนรับข้อมูลข่าวสาร
                  (แล้วแต่กรณี) อันเกี่ยวเนื่องกับการให้บริการใดๆ ในเว็บไซต์ ให้แก่บุคคลที่สาม
                  เว้นแต่จะได้รับความยินยอมเป็นลายลักษณ์อักษรจาก TK park โดย TK Park
                  มีสิทธิโอนสิทธิและ/หรือ หน้าที่ใดๆ อันเกี่ยวเนื่องกับการให้บริการใดๆ
                  ในเว็บไซต์ให้แก่บุคคลใดก็ได้
               </ul>
            </div>
            <div>
               <strong>8. การสิ้นสุดการให้บริการ</strong>
               <ul className="noDot textIndent">
                  <li>
                     การให้บริการนี้อาจสิ้นสุดลงในเวลาใดๆ ก็ได้ ดังนี้ ในกรณี ผู้ที่สมัครสมาชิก TK
                     Park/ผู้ที่ลงทะเบียนร่วมกิจกรรม/ผู้ที่ลงทะเบียนรับข้อมูลข่าวสาร (แล้วแต่กรณี)
                     แจ้งความประสงค์บอกเลิกบริการแต่ละประเภท โดยแจ้งเป็นลายลักษณ์อักษรมายัง TK Park
                     ตามแต่ละช่องทางที่ TK Park กำหนด โดยดำเนินการให้เป็นไปตาม
                     นโยบายคุ้มครองข้อมูลส่วนบุคคล ตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ ๒๕๖๒
                  </li>
                  <li>
                     หากท่านไม่ปฏิบัติตามกฎหรือระเบียบใดๆที่ TK Park กำหนด
                     หรือปฏิบัติผิดเงื่อนไขและข้อตกลงทั่วไป และหรือไม่ปฏิบัติตามกฎหมายใดๆ
                     หากการไม่ปฏิบัติหรือฝ่าฝืนหรือปฏิบัติผิดเงื่อนไขใดๆ สามารถแก้ไขได้
                     แต่สมาชิกไม่ทำการแก้ไขภายในระยะเวลาที่ TK Park กำหนด TK Park
                     มีสิทธิยกเลิกการให้บริการได้ทันทีโดยไม่ต้องบอกกล่าวก่อน
                  </li>
                  <li>
                     หากมีการเปลี่ยนแปลงการกรรมสิทธิ์หรือสิทธิใดๆ ในห้องสมุดดิจิทัล TK Read
                     ซึ่งทำให้ TK Park ไม่สามารถให้บริการได้อีกต่อไป
                  </li>
               </ul>
            </div>
            <div>
               <strong>9. กฎหมายที่ใช้บังคับ</strong>
               <ul className="noDot textIndent">
                  การใช้บริการเว็บไซต์นี้หรือการตีความข้อตกลงและเงื่อนไขการใช้บริการเว็บไซต์นี้ให้เป็นไปตามกฎหมายไทย
               </ul>
            </div>
         </div>
      </div>
   );
};

export default TermsAndCondition;
