import { useEffect, useState } from 'react';
import '../pattern.scss';
import { ContentMetaData } from '../../../../interface';
import ViewAllButton from '../../../customUI/viewAllButton';
import { RentBtn } from '../../../customUI/RentButton';
import { MemoContentCollectionView } from '../../../components/contents';
import { Space } from 'antd';

interface ContentProps {
   title: string;
   subtitle: string | undefined;
   contents: ContentMetaData[];
   path: string;
   limit?: number;
}

const Pattern5 = (props: ContentProps) => {
   const [bookList, setBookList] = useState<ContentMetaData[]>();

   useEffect(() => {
      setBookList(props.contents);
   }, []);

   return (
      <div className="pattern-5 fadeIn">
         <div className="title-section" style={{ marginBottom: '10px' }}>
            <div>
               <h2>{props.title}</h2>
               <p>{props.subtitle}</p>
            </div>
            {bookList && bookList.length >= props.limit! && (
               <ViewAllButton path={props.path} title={props.title} />
            )}
         </div>

         <div className="content-grid-container">
            {bookList?.map((e, index) => {
               if (index < props.limit!) {
                  return (
                     <Space size={10} direction="vertical">
                        <MemoContentCollectionView content={e as ContentMetaData} />
                        <RentBtn
                           content={e}
                           size="large"
                           contentType={e.contentType}
                           status={e.licenseStatus}
                           style={{ width: '100%' }}
                           licenseType={e.licenseType}
                           className="rent"
                        />
                     </Space>
                  );
               }
            })}
         </div>
      </div>
   );
};

export default Pattern5;
