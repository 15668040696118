import { Checkbox, Modal, ModalProps } from 'antd';
import { useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { HibraryRootState } from '../../../redux/rootReducer';
import { saveDisplayModal } from '../../redux/bookshelf/request';
import { bookShelfActions } from '../../redux/bookshelf/bookShelfRedux';
import SVG from 'react-inlinesvg';

const BookShelfReturnContentModal = () => {
    const dispatch = useDispatch();
    const [check, setCheck] = useState(false);

    const { authState, bookShelfState } = useSelector(
        (state: HibraryRootState) => ({
            authState: state.auth,
            bookShelfState: state.bookShelf
        }),
        shallowEqual
    );

    const modalProps: ModalProps = {
        open: bookShelfState.tooltipModal,
        onCancel: () => dispatch(bookShelfActions.setVisibleModal(false)),
        className: 'bookshelf-return-content__modal',
        footer: null,
        closeIcon: <SVG src="/images/tk-icon/close-modal-icon.svg" />,
        afterClose: () => {
            if (check) {
                handleDisplayModal();
                setCheck(false);
            }
        }
    };

    const handleDisplayModal = async () => {
        if (authState.isLogin) {
            await saveDisplayModal();
        } else {
            dispatch(bookShelfActions.setModalCheckBox(check));
            // dispatch(bookShelfActions.setVisibleModal(false));
        }
    };

    return (
        <Modal {...modalProps}>
            <SVG src="/images/tk-icon/return-content.svg" className="tooltip__image" />
            <div className="description__container">
                <div>
                    <h1 className="title__text">How to คืนหนังสือ และ ลบไฟล์?</h1>
                    <p className="description__text">
                        <span>กดที่ปุ่ม</span>
                        <SVG src="/images/tk-icon/bookshelf-button.svg" className="return__icon" />
                        <span>แล้วจะขึ้นเมนูเพิ่มเติมให้เลือก</span>
                    </p>
                </div>
                <Checkbox checked={check} onChange={(e) => setCheck(e.target.checked)}>
                    ไม่ต้องแสดงอีก
                </Checkbox>
            </div>
        </Modal>
    );
};

export default BookShelfReturnContentModal;
