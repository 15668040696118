import axios, { AxiosError } from 'axios';
import { RentalAction, RentalActionType } from '.';
import { bookShelfContent } from '../../../interface/bookShelf';
import { LicenseType, RentalStatus, ContentMetaData, ContentType } from '../../../interface/home';
import { AddLicenseResponse } from '../../../interface/rental';
import { HtiResponse } from '../../../interface/request';
import { ReduxDisPatch } from '../../../redux';

export const rentProcess = (content: ContentMetaData | bookShelfContent): RentalAction => {
   return {
      type: RentalActionType.Process,
      payload: { content }
   };
};

export const rentConfirm = (
   content: ContentMetaData | bookShelfContent,
   rentalStatus: RentalStatus
): RentalAction => {
   return {
      type: RentalActionType.Confirm,
      payload: { content, rentalStatus }
   };
};

export const rentComplete = (response: AddLicenseResponse): RentalAction => {
   return {
      type: RentalActionType.Complete,
      payload: { response }
   };
};

export const rentError = (error: any): RentalAction => {
   return {
      type: RentalActionType.Error,
      payload: { response: error }
   };
};

export const rentCancel = (): RentalAction => {
   return {
      type: RentalActionType.Cancel
   };
};

export const rentReset = (): RentalAction => {
   return {
      type: RentalActionType.Reset
   };
};

export const rentEbook =
   (
      content: ContentMetaData | bookShelfContent,
      contentType?: ContentType,
      licenseType?: LicenseType
   ) =>
   async (dispatch: ReduxDisPatch<RentalAction>) => {
      dispatch(rentProcess(content));
      try {
         const response = await requestFromServer(
            content.contentUID,
            content.licenseStatus,
            contentType,
            licenseType,
            content.licenseUID
         );
         if (response) dispatch(rentComplete(response.data));
      } catch (error) {
         const err = error as AxiosError<HtiResponse>;
         dispatch(rentError(err.response?.data));
      }
   };

export const requestFromServer = async (
   uid: string,
   rentalStatus?: RentalStatus,
   contentType?: ContentType,
   licenseType?: LicenseType,
   licenseUID?: string
) => {
   switch (rentalStatus) {
      case RentalStatus.Rent: {
         const path = `license/`;
         const qString: any = {
            contentUID: uid,
            contentType: contentType,
            licenseType: licenseType ? licenseType : LicenseType.Rent
         };
         const { data } = await axios.post<HtiResponse<AddLicenseResponse>>(path, qString);
         return data;
      }
      case RentalStatus.WaitingList: {
         const path = `license/booking`;
         const qString: any = {
            contentUID: uid,
            contentType: contentType
         };
         const { data } = await axios.post<HtiResponse>(path, qString);
         return data;
      }
      case RentalStatus.WaitingListed: {
         const qString: any = {
            contentUID: uid,
            contentType: contentType,
            licenseUID: licenseUID
         };

         const path = `license/booking`;
         const data = await axios.delete<HtiResponse>(path, {
            data: qString
         });
         return data;
      }
      case RentalStatus.Rented: {
         const path = `license/`;
         const qString: any = {
            contentUID: uid,
            contentType: contentType,
            licenseUID: licenseUID,
            licenseType: licenseType
         };
         const { data } = await axios.delete<HtiResponse>(path, {
            data: qString
         });
         return data;
      }
   }
};

interface ContentRentalExpireResponse {
   expireDate: number;
}

export const getContentRentalExpire = async (contentUID: string, contentType: ContentType) => {
   const path = `license/pre`;

   const {
      data: { data }
   } = await axios.post<HtiResponse<ContentRentalExpireResponse>>(path, {
      contentUID,
      contentType
   });
   return data;
};
