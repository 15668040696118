/* eslint-disable array-callback-return */
import { useEffect, useLayoutEffect, useState } from 'react';
import { Space, Tooltip, Button } from 'antd';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { ClientInfo } from '../../../interface/client';
import { HibraryRootState } from '../../../redux/rootReducer';
import { Input, AutoComplete } from 'antd';
import { ContentType, RentalStatus } from '../../../interface/home';
import { setDarkTheme } from '../../redux/theme/action';
import { signIn } from '../../redux/auth/authCrud';
import { MemberProfileType } from './menuTab';
import { isIPad13, isMobile } from 'react-device-detect';
import { SearchPageType } from '../set/search';
import { BookShelfTab, BookShelfTabWithoutLogin, CategoryTab, StoreTab } from './tab';
import { redirectURL } from '../../customUI/RentButton';
import {
   getDetailRoute,
   getSearchWriterRoute,
   getSearchKeywordRoute,
   getSearchPublisherRoute,
   getSearchCategoryRoute
} from '../../routes';
import {
   WriterResult,
   AutoCompleteKey,
   CategoryResult,
   fetchAutocomplete,
   KeywordResult,
   PublisherResult,
   SearchType,
   TitleResult
} from '../../redux/search/action';

import ScrollContainer from 'react-indiana-drag-scroll';
import SVG from 'react-inlinesvg';
import Notification from './notification';
import OpenAppTab from './openAppTab';

import './index.scss';

const TabBox = () => {
   const location = useLocation();

   const { authState } = useSelector(
      (state: HibraryRootState) => ({
         authState: state.auth
      }),
      shallowEqual
   );

   const useWindowSize = () => {
      const [size, setsize] = useState([0, 0]);
      useLayoutEffect(() => {
         const updateSize = () => {
            setsize([window.innerWidth, window.innerHeight]);
         };
         window.addEventListener('resize', updateSize);
         updateSize();
         return () => window.removeEventListener('resize', updateSize);
      }, []);

      return size;
   };

   const [width] = useWindowSize();

   const renderMenuTab = () => {
      switch (location.pathname) {
         case '/Returned':
         case '/WaitingList':
         case '/Rented': {
            if (width < 834.98) {
               if (authState.isLogin) {
                  return <>{BookShelfTab()}</>;
               } else {
                  return <>{BookShelfTabWithoutLogin()}</>;
               }
            } else {
               return <>{StoreTab()}</>;
            }
         }
         case `/${ContentType.ebook}/categories/all`:
         case `/${ContentType.magazine}/categories/all`:
         case `/${ContentType.audioBook}/categories/all`:
         case `/${ContentType.video}/categories/all`:
            if (width < 834.98) {
               return <>{CategoryTab()}</>;
            } else {
               return <>{StoreTab()}</>;
            }
         default: {
            return <>{StoreTab()}</>;
         }
      }
   };

   return <ScrollContainer className="nav-set">{renderMenuTab()}</ScrollContainer>;
};

export const SearchBox = () => {
   const intl = useIntl();
   const [options, setOption] = useState<any[]>();
   const [isLoading, setIsLoading] = useState(false);
   const history = useHistory();

   const renderTitle = (title: string) => (
      <div className="autocomplete-header">
         <p>{title}</p>
      </div>
   );

   const getHighlightedText = (text: string, highlight: string) => {
      const parts = new RegExp(`^(.*)(${highlight.toLowerCase()})(.*)$`);
      const str = text.match(parts);

      return (
         <span>
            {str?.map((str, i) => {
               if (i > 0) {
                  return (
                     <span
                        key={i}
                        style={
                           str.toLowerCase() === highlight.toLowerCase()
                              ? { fontWeight: 'bold', color: 'red' }
                              : {}
                        }
                     >
                        {str}
                     </span>
                  );
               }
            })}
         </span>
      );
   };

   const renderItem = (title: string, keyword: string, key?: string, link?: string) => {
      const text = getHighlightedText(title, keyword.trim());

      return {
         value: title,
         label: (
            <Link to={link ?? ''}>
               <div key={key} className="autocomplete-item">
                  {text}
               </div>
            </Link>
         ),
         id: key
      };
   };

   const onChange = async (el: string) => {
      try {
         if (el.length > 3) {
            setIsLoading(true);
            const result = await fetchAutocomplete(el);
            const optionConvert = createSearchAutoComplete(result! as any, el);
            setOption(optionConvert);
         } else {
            setOption([]);
         }
      } catch (error) {
         setOption([]);
      }
      setIsLoading(false);
   };

   const getAutocompleteLink = (
      data: WriterResult | TitleResult | PublisherResult | KeywordResult | CategoryResult,
      searchType: SearchType
   ) => {
      switch (searchType) {
         case SearchType.AUTHOR: {
            const getData = data as WriterResult;
            return getSearchWriterRoute(getData.keyword);
         }
         case SearchType.KEYWORD: {
            const getData = data as TitleResult;
            return getSearchKeywordRoute(getData.keyword);
         }
         case SearchType.TITLE: {
            const getData = data as TitleResult;
            return getDetailRoute(getData.contentUID, getData.contentType);
         }
         case SearchType.PUBLISHER: {
            const getData = data as PublisherResult;
            return getSearchPublisherRoute(getData.keyword);
         }
         case SearchType.CATEGORY: {
            const getData = data as CategoryResult;
            return getSearchCategoryRoute(getData.keyword);
         }
      }
   };

   const getSegmentTitle = (title: ContentType | string) => {
      switch (title) {
         case 'writers': {
            return 'นักเขียน';
         }
         case 'titles': {
            return `ชื่อเรื่อง`;
         }
         case 'publishers': {
            return 'สำนักพิมพ์';
         }
         case 'category': {
            return 'หมวดหมู่';
         }
         case 'keywords': {
            return 'แท็ก';
         }
         case 'categories': {
            return 'หมวดหมู่';
         }
         default:
            return '';
      }
   };

   const createSearchAutoComplete = (
      result: {
         [x: string]: AutoCompleteKey;
      },
      keyword: string
   ): any[] => {
      const finalData = [];
      for (const key in result) {
         const value = result[key];
         const title = renderTitle(getSegmentTitle(key));
         const options: any[] = [];
         value.forEach((el: any) => {
            switch (key) {
               case 'writers': {
                  const data = el as WriterResult;
                  options.push(
                     renderItem(
                        data.keyword,
                        keyword,
                        data.writerUID,
                        getAutocompleteLink(data, SearchType.AUTHOR)
                     )
                  );
                  break;
               }
               case 'titles': {
                  const data = el as TitleResult;
                  options.push(
                     renderItem(
                        data.keyword,
                        keyword,
                        data.contentUID,
                        getAutocompleteLink(data, SearchType.TITLE)
                     )
                  );
                  break;
               }
               case 'publishers': {
                  const data = el as PublisherResult;
                  options.push(
                     renderItem(
                        data.keyword,
                        keyword,
                        data.creatorUID,
                        getAutocompleteLink(data, SearchType.PUBLISHER)
                     )
                  );
                  break;
               }
               case 'keywords': {
                  const data = el as KeywordResult;
                  options.push(
                     renderItem(
                        data.keyword,
                        keyword,
                        data.contentType,
                        getAutocompleteLink(data, SearchType.KEYWORD)
                     )
                  );
                  break;
               }
               case 'categories': {
                  const data = el as CategoryResult;
                  options.push(
                     renderItem(
                        data.keyword,
                        keyword,
                        data.contentType,
                        getAutocompleteLink(data, SearchType.CATEGORY)
                     )
                  );
                  break;
               }
               default:
                  break;
            }
         });
         if (options.length > 0) {
            finalData.push({
               label: title,
               options: options
            });
         }
      }

      return finalData;
   };

   return (
      <div className="nav-search">
         <Input.Group>
            <AutoComplete
               className="nav-search-autocomplete"
               dropdownClassName="certain-category-search-dropdown"
               showSearch={true}
               options={options}
               dropdownMatchSelectWidth={300}
               notFoundContent="ไม่มีข้อมูล"
            >
               <Input.Search
                  className="nav-search-bar"
                  type="text"
                  autoComplete="off"
                  placeholder={intl.formatMessage({
                     id: 'SEARCH'
                  })}
                  allowClear
                  onSearch={(el) => {
                     if (el.length > 0) history.push(`/search/${el.trim()}/${SearchPageType.All}`);
                  }}
                  loading={isLoading}
                  onChange={(el) => {
                     onChange(' ' + el.target.value);
                  }}
               />
            </AutoComplete>
         </Input.Group>
      </div>
   );
};

const SignInButton = () => {
   const [loadingButton, setLoadingButton] = useState(false);

   const checkPath = () => {
      const path = localStorage.getItem('redirectURL')?.split('?');
      if (path && path?.length >= 1) {
         return path[0];
      } else {
         return localStorage.getItem('redirectURL');
      }
   };

   const getLoginPath = async () => {
      try {
         setLoadingButton(true);
         const response = await signIn(checkPath());
         if (response.data.data) {
            console.log(response.data.data);
            localStorage.setItem('redirectURL', response.data.data.redirectURL);
            window.location.href = response.data.data?.authorizeURL;
         }
         setLoadingButton(false);
      } catch (error) {
         setLoadingButton(false);
      }
   };

   return (
      <Button
         shape="round"
         loading={loadingButton}
         onClick={() => {
            redirectURL();
            getLoginPath();
         }}
         className="secondary-background-button"
      >
         เข้าสู่ระบบ
      </Button>
   );
};

export const NavBar = () => {
   const dispatch = useDispatch();
   const location = useLocation();
   const [showMenuTab, setShowMenuTab] = useState<boolean>(true);

   const { authState, theme, request } = useSelector(
      (state: HibraryRootState) => ({
         authState: state.auth,
         clientInfo: state.clientInfo as ClientInfo,
         theme: state.theme,
         request: state.fetch
      }),
      shallowEqual
   );

   const useWindowSize = () => {
      const [size, setsize] = useState([0, 0]);
      useLayoutEffect(() => {
         const updateSize = () => {
            setsize([window.innerWidth, window.innerHeight]);
         };
         window.addEventListener('resize', updateSize);
         updateSize();
         return () => window.removeEventListener('resize', updateSize);
      }, []);
      return size;
   };

   const [width] = useWindowSize();

   useEffect(() => {
      window.addEventListener('scroll', function () {
         const scroll = this.scrollY;
         if (scroll > 200) setShowMenuTab(false);
         else setShowMenuTab(true);
      });
   }, []);

   const handleChangeDarkMode = () => {
      if (!theme.darkMode) {
         dispatch(setDarkTheme(true));
      } else {
         dispatch(setDarkTheme(false));
      }
   };

   const setMobileHeight = () => {
      if (isMobile || isIPad13) {
         if (
            request.id === 'ContentDetail' ||
            request.id === 'VideoDetail' ||
            request.id === 'StoreHomeList'
         ) {
            return true;
         } else {
            return false;
         }
      } else {
         return false;
      }
   };

   const CheckMobileDevice = () => {
      const mobileCss = {
         height: '136px'
      };

      const tabletCss = {
         height: '105px'
      };

      if (isMobile && width < 834.98) {
         return mobileCss;
      } else {
         return tabletCss;
      }
   };

   const checkRenderSearch = () => {
      const path = location.pathname.split('/');
      if (path[1] === 'memberProfile') {
         return false;
      } else {
         return true;
      }
   };

   return (
      <div className="nav-bar" style={setMobileHeight() && showMenuTab ? CheckMobileDevice() : {}}>
         <div
            style={{
               width: '100%'
            }}
         >
            {setMobileHeight() && showMenuTab ? <OpenAppTab /> : null}
            <div className="user-info" style={isIPad13 ? { height: '50%' } : {}}>
               <div className="logo">
                  <Link to="/">
                     <img
                        src={
                           theme.darkMode
                              ? `/images/tk-icon/tk-logo-dark-mode.svg`
                              : `/images/tk-icon/tk-logo-normal-mode.svg`
                        }
                        className="image"
                        alt=""
                     />
                  </Link>
               </div>

               <TabBox />

               {width > 834.98 && (
                  <>
                     <Space size={16} className="menu-container">
                        {checkRenderSearch() ? <SearchBox /> : null}

                        {!authState.isLogin && <SignInButton />}

                        <Link to={`/${RentalStatus.Rented}`} className="book-shelf">
                           <Tooltip
                              placement="bottom"
                              arrowPointAtCenter={true}
                              title="ชั้นหนังสือ"
                           >
                              <SVG src="/images/tk-icon/book-shelf-icon.svg" />
                           </Tooltip>
                        </Link>

                        {authState.isLogin && (
                           <Tooltip
                              placement="bottom"
                              arrowPointAtCenter={true}
                              title="การแจ้งเดือน"
                           >
                              <Notification />
                           </Tooltip>
                        )}

                        <Tooltip
                           placement="bottomRight"
                           arrowPointAtCenter={true}
                           title={theme.darkMode ? `ปิดใช้งานโหมดกลางคืน` : `เปิดใช้งานโหมดกลางคืน`}
                        >
                           <SVG
                              src="/images/tk-icon/Vector.svg"
                              className="dark-mode-button"
                              onClick={() => handleChangeDarkMode()}
                           />
                        </Tooltip>

                        {authState.isLogin && (
                           <Link
                              to={`/memberProfile/${MemberProfileType.MemberData}`}
                              className="book-shelf"
                           >
                              <Tooltip placement="bottom" arrowPointAtCenter={true} title="เมนู">
                                 <SVG src="/images/tk-icon/menu-icon.svg" />
                              </Tooltip>
                           </Link>
                        )}
                     </Space>
                  </>
               )}
            </div>
         </div>
      </div>
   );
};
