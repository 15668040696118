import { FunctionComponent } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import { ContentType, MagazineSetType } from '../../../../interface/home';
import { MagazineSetCover } from './magazineSetCover';
import '../pattern.scss';
import SwiperCore, { Pagination } from 'swiper';
import ViewAllButton from '../../../customUI/viewAllButton';

SwiperCore.use([Pagination]);

interface MagazineSetSectionProps {
   contents: MagazineSetType[];
   contentType: ContentType;
   title: string;
}

const MagazineSetSection: FunctionComponent<MagazineSetSectionProps> = ({ contents, title }) => {
   const breakpoint = {
      '1': {
         slidesPerView: 1.7
      },
      '576.98': {
         slidesPerView: 3.5
      },
      '768.98': {
         slidesPerView: 4.5
      },
      '1024.98': {
         slidesPerView: 5.5
      },
      '1440.98': {
         slidesPerView: 6.5
      }
   };

   return (
      <div className="category-list fadeIn" style={{ marginBottom: '64px' }}>
         <div className="title-section">
            <div>
               <h2>{title}</h2>
            </div>
            {contents.length >= 7 && (
               <ViewAllButton path={`/magazine-set/${contents[0].setType}`} title={title} />
            )}
         </div>

         <Swiper
            className="segment-swiper"
            breakpoints={breakpoint}
            spaceBetween={8}
            pagination={{
               dynamicBullets: true
            }}
         >
            {contents.map((e, index) => (
               <SwiperSlide key={`banner_${index}`}>
                  <MagazineSetCover content={e} contentType={e.contentType} />
               </SwiperSlide>
            ))}
         </Swiper>
      </div>
   );
};

export default MagazineSetSection;
