import { Modal } from 'antd';
import React from 'react';
import { ListDevicesResponse } from '../../../interface';
import { ManageDevice } from '../../pages/devices';

interface ModalProps {
  visible: boolean;
  onClose: () => void;
  devices?: ListDevicesResponse;
}

const ManageDeviceModal = (props: ModalProps) => {
  return (
    <Modal
      visible={props.visible}
      onCancel={props.onClose}
      title={'จัดการอุปกรณ์'}
      footer={null}
      maskClosable={false}
    >
      <ManageDevice
        devices={props.devices?.devices}
        memberUID={props.devices?.memberID}
      />
    </Modal>
  );
};

export default ManageDeviceModal;
