import { memo, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { SearchPageType } from '.';
import {
   ListContentResponse,
   SearchPublisherType,
   SearchResultResponse,
   SearchWriterType
} from '../../../../interface';
import { ContentMetaData, ContentType } from '../../../../interface/home';
import { action } from '../../../redux/request';
import { getSearchAllContentList, getSearchResult } from '../../../redux/store/storeCrud';
import Pattern4 from '../../home/ebook/pattern-4';
import VideoPattern2 from '../../home/video/videoPattern-2';
import { MemoSearchCategoryGrid } from './searchCategory';
import { MemoSearchWriterView } from './searchWriter';

import '../index.scss';
import { MemoSearchTag } from './searchTags';
import { EmptyDataView } from '../../../components/nodata';
import { HibraryRootState } from '../../../../redux/rootReducer';
import { Col, Row } from 'antd';
import { ContentCollectionView } from '../../../components/contents';
import { setContentType } from '../../../redux/utilities';

interface SearchAllProps {
   pageId: string;
   keyword: string;
   selectContentType: ContentType;
   searchType: SearchPageType;
   searchAllTypeProps: SearchAllPageType;
   contentType: ContentType;
}

export enum SearchAllPageType {
   SearchAllSection = 'searchAllSection',
   SearchAllContentList = 'searchAllContentList'
}

const SearchAllView: React.FC<SearchAllProps> = ({
   pageId,
   keyword,
   selectContentType,
   searchType,
   searchAllTypeProps,
   contentType
}) => {
   const dispatch = useDispatch();
   const [resultList, setResultList] = useState<SearchResultResponse<ContentMetaData[]>>();
   const [searchAllType, setSearchAllType] = useState<SearchAllPageType>(searchAllTypeProps);

   useEffect(() => {
      if (resultList) {
         setResultList(undefined);
      }
      fetchResult();
   }, [keyword, selectContentType]);

   useEffect(() => {
      setSearchAllType(searchAllTypeProps);
   }, [searchAllTypeProps]);

   const fetchResult = async () => {
      try {
         dispatch(action.setProcess(true, pageId));
         const response = await getSearchResult(selectContentType, searchType, keyword.trim());

         setResultList({
            ebooks: response?.ebooks ?? [],
            audioBooks: response?.audioBooks ?? [],
            magazines: response?.magazines ?? [],
            videos: response?.videos ?? [],
            category: response?.category ?? [],
            subcategory: response?.subcategory ?? [],
            writers: response?.writers ?? [],
            publishers: response?.publishers ?? [],
            tags: response?.tags ?? []
         });
         dispatch(action.setProcess(false, pageId));
      } catch (error) {
         dispatch(action.setProcess(false, pageId));
      }
   };

   const onUpdateFollow = (index: number, followType: SearchPageType) => {
      switch (followType) {
         case SearchPageType.Writer: {
            const newWriterList = JSON.parse(JSON.stringify(resultList?.writers));
            newWriterList[index] = {
               ...newWriterList[index],
               followStatus: !resultList?.writers![index].followStatus
            };
            setResultList({
               ...resultList,
               writers: newWriterList as SearchWriterType[]
            });
            break;
         }
         case SearchPageType.Publisher: {
            const newPublisherList = JSON.parse(JSON.stringify(resultList?.publishers));
            newPublisherList[index] = {
               ...newPublisherList[index],
               followStatus: !resultList?.publishers![index].followStatus
            };
            setResultList({
               ...resultList,
               publishers: newPublisherList as SearchPublisherType[]
            });
            break;
         }
      }
   };

   const renderSearch = () => {
      switch (searchAllType) {
         case SearchAllPageType.SearchAllSection: {
            return (
               <div>
                  {resultList ? (
                     <MemoSearchAllContent
                        contents={resultList}
                        onUpdateFollow={onUpdateFollow}
                        searchType={searchType}
                        keyword={keyword}
                        limit={20}
                     />
                  ) : null}
               </div>
            );
         }
         case SearchAllPageType.SearchAllContentList: {
            return (
               <SearchAllContentList contentType={contentType} pageId={pageId} keyword={keyword} />
            );
         }
      }
   };

   return <>{renderSearch()}</>;
};

export default SearchAllView;

interface SearchAllContentProps {
   contents: SearchResultResponse<ContentMetaData[]>;
   limit?: number;
   searchType?: SearchPageType;
   keyword: string;
   onUpdateFollow?: (index: number, followType: SearchPageType) => void;
}

const SearchAllContent: React.FC<SearchAllContentProps> = ({
   contents,
   limit,
   searchType,
   onUpdateFollow,
   keyword
}) => {
   const { request } = useSelector(
      (state: HibraryRootState) => ({
         request: state.fetch
      }),
      shallowEqual
   );

   return (
      <>
         {contents.ebooks && contents.ebooks.length > 0 ? (
            <Pattern4
               title="หนังสือ"
               contents={contents.ebooks}
               path={`/search/${keyword}/all/searchAllContentList/${ContentType.ebook}`}
               limit={limit}
            />
         ) : null}
         {contents.magazines && contents.magazines.length > 0 ? (
            <Pattern4
               title="นิตยสาร"
               contents={contents.magazines}
               path={`/search/${keyword}/all/searchAllContentList/${ContentType.magazine}`}
               limit={limit}
            />
         ) : null}
         {contents.audioBooks && contents.audioBooks.length > 0 ? (
            <Pattern4
               title="หนังสือเสียง"
               contents={contents.audioBooks}
               path={`/search/${keyword}/all/searchAllContentList/${ContentType.audioBook}`}
               limit={limit}
            />
         ) : null}
         {contents.videos && contents?.videos.length > 0 ? (
            <VideoPattern2
               title="วิดีโอ"
               contents={contents.videos}
               path={`/search/${keyword}/all/searchAllContentList/${ContentType.video}`}
               limit={limit ?? 20}
            />
         ) : null}
         {contents.category && contents.category.length > 0 && (
            <MemoSearchCategoryGrid
               searchType={searchType!}
               contents={contents.category}
               title="หมวดหมู่"
               keyword={keyword}
            />
         )}
         {contents.subcategory && contents.subcategory.length > 0 && (
            <MemoSearchCategoryGrid
               contents={contents.subcategory}
               title="หมวดหมู่ย่อย"
               searchType={searchType!}
               keyword={keyword}
            />
         )}
         {contents.writers && contents.writers.length > 0 && (
            <MemoSearchWriterView
               contents={contents.writers}
               title="นักเขียน/Creator"
               searchType={searchType!}
               onUpdateFollow={onUpdateFollow!}
               keyword={keyword}
               currentSearchType={SearchPageType.Writer}
            />
         )}
         {contents.publishers && contents.publishers.length > 0 && (
            <MemoSearchWriterView
               contents={contents.publishers}
               title="สำนักพิมพ์"
               searchType={searchType!}
               keyword={keyword}
               onUpdateFollow={onUpdateFollow!}
               currentSearchType={SearchPageType.Publisher}
            />
         )}
         {contents.tags && contents.tags.length > 0 && (
            <MemoSearchTag contents={contents.tags} searchType={searchType!} />
         )}

         {contents.ebooks?.length === 0 &&
         contents.magazines?.length === 0 &&
         contents.audioBooks?.length === 0 &&
         contents.videos?.length === 0 &&
         contents.category?.length === 0 &&
         contents.subcategory?.length === 0 &&
         contents.writers?.length === 0 &&
         contents.publishers?.length === 0 &&
         contents.tags?.length === 0 ? (
            <>{!request.loading && <EmptyDataView minHeight={60} />}</>
         ) : null}
      </>
   );
};
export const MemoSearchAllContent = memo(SearchAllContent);

interface SearchAllContentListProps {
   contentType: ContentType;
   pageId: string;
   keyword: string;
}

const SearchAllContentList: React.FC<SearchAllContentListProps> = ({
   contentType,
   pageId,
   keyword
}) => {
   const dispatch = useDispatch();
   const [content, setContent] = useState<ListContentResponse>();
   const { request, environment } = useSelector(
      (state: HibraryRootState) => ({
         request: state.fetch,
         environment: state.environment
      }),
      shallowEqual
   );

   useEffect(() => {
      fetchContent(false);
   }, []);

   useEffect(() => {
      if (environment.endOfScreen) {
         if (content?.lastEvaluatedKey !== undefined && !request.loading) {
            fetchContent(true);
         }
      }
   }, [environment]);

   const fetchContent = async (appendData: boolean) => {
      try {
         dispatch(action.setProcess(true, pageId));
         const lastEvaluatedKey = appendData ? content?.lastEvaluatedKey : undefined;
         const response = await getSearchAllContentList(contentType, lastEvaluatedKey!, keyword);

         if (appendData) {
            setListModel(response!, appendData);
         } else {
            setContent(response);
         }
         dispatch(action.setProcess(false, pageId));
      } catch (error) {
         dispatch(action.setProcess(false, pageId));
      }
   };

   const setListModel = (data: ListContentResponse, appendData: boolean) => {
      const current = content as ListContentResponse;
      const currentData = data as ListContentResponse;
      const newArray = appendData ? current.contents : [];
      setContent({
         contents: newArray.concat(currentData?.contents ?? []),
         categories: current.categories.concat(currentData?.categories ?? []),
         lastEvaluatedKey: currentData?.lastEvaluatedKey ?? undefined
      });
   };

   return (
      <>
         <h1>{setContentType(contentType)}</h1>
         {content?.contents ? (
            <div className="content-list">
               <Row gutter={[16, 16]} className="list-row">
                  {content.contents?.map((content, index) => (
                     <Col
                        key={`col_${content.contentUID}_${index}`}
                        className={`col-${contentType}`}
                     >
                        <ContentCollectionView content={content} />
                     </Col>
                  ))}
               </Row>
            </div>
         ) : (
            <>{!request.loading && <EmptyDataView minHeight={50} />}</>
         )}
      </>
   );
};
