import { Button, Input, message, Modal } from 'antd';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { EditMemberResponse } from '../../../interface';
import { VerifyOTP } from '../../pages/auth/VerifyOTP';
import { VerifyType } from '../../pages/profile/memberInfo';
import { updateMemberEmail, updateMemberPhoneNumber } from '../../redux/auth/authCrud';
import { actions } from '../../redux/auth/authRedux';

interface verifyProps {
   visible: boolean;
   onClose: () => void;
   verifyType: VerifyType;
   email: string;
}

const VerifyModal = (props: verifyProps) => {
   const dispatch = useDispatch();
   const [loading, setLoading] = useState(false);
   const [isVerifyOTP, setIsVerifyOTP] = useState(false);
   const [responseVerify, setResponseVerify] = useState<EditMemberResponse>();
   const getTitle = () => {
      switch (props.verifyType) {
         case VerifyType.VerifyEmail:
            return 'เปลี่ยนอีเมล';
         default:
            return 'เปลี่ยนเบอร์โทรศัพท์';
      }
   };

   const renderSendEmailVerify = () => {
      const validationSchema = Yup.object().shape({
         email: Yup.string().email('รูปแบบของอีเมลไม่ถูกต้อง').required('กรุณากรอกอีเมล')
      });

      return (
         <Formik
            initialValues={{
               email: ''
            }}
            validationSchema={validationSchema}
            onSubmit={(e) => {
               updateData(e.email);
            }}
         >
            {({ errors, touched, values, handleChange, handleSubmit }) => (
               <>
                  <h1>{getTitle()}</h1>
                  <form
                     onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit();
                     }}
                  >
                     <Input
                        size="large"
                        name="email"
                        placeholder="กรอกอีเมลใหม่"
                        onChange={handleChange}
                     />
                     {errors.email && touched.email ? <label>{errors.email}</label> : null}

                     <Button
                        type="primary"
                        shape="round"
                        htmlType="submit"
                        disabled={!values.email ? true : false}
                        loading={loading}
                     >
                        ยืนยัน
                     </Button>
                  </form>
               </>
            )}
         </Formik>
      );
   };

   const renderSendPhoneNumberVerify = () => {
      const validationSchema = Yup.object().shape({
         phoneNumber: Yup.string()
            .min(10, 'กรุณากรอกเบอร์มือถือให้ถูกต้อง')
            .max(10, 'กรุณากรอกเบอร์มือถือให้ถูกต้อง')
            .matches(/^[0-9]+$/, 'ใช้ตัวอักษรเป็นตัวเลขเท่านั้น')
            .required('กรุณากรอกเบอร์โทรศัพท์')
      });

      return (
         <Formik
            initialValues={{
               phoneNumber: ''
            }}
            validationSchema={validationSchema}
            onSubmit={(e) => {
               updateData(e.phoneNumber);
            }}
         >
            {({ errors, touched, values, handleChange, handleSubmit }) => (
               <>
                  <h1>{getTitle()}</h1>
                  <form
                     onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit();
                     }}
                  >
                     <Input
                        size="large"
                        name="phoneNumber"
                        placeholder="กรุณากรอกเบอร์มือถือใหม่"
                        onChange={handleChange}
                     />
                     {errors.phoneNumber && touched.phoneNumber ? (
                        <label>{errors.phoneNumber}</label>
                     ) : null}

                     <Button
                        type="primary"
                        shape="round"
                        htmlType="submit"
                        disabled={!values.phoneNumber ? true : false}
                        loading={loading}
                     >
                        ยืนยัน
                     </Button>
                  </form>
               </>
            )}
         </Formik>
      );
   };

   const updateData = async (value: string) => {
      switch (props.verifyType) {
         case VerifyType.VerifyEmail: {
            try {
               setLoading(true);
               const data = await updateMemberEmail(value);

               if (data) {
                  setResponseVerify(data);
                  dispatch(actions.updateToken(data.userToken as string));
                  setIsVerifyOTP(true);
                  setLoading(false);
               }
            } catch (error) {
               setLoading(false);
               message.error(JSON.stringify(error));
            }
            break;
         }
         case VerifyType.VerifyPhoneNumber: {
            try {
               setLoading(true);
               const data = await updateMemberPhoneNumber(value);

               if (data) {
                  setResponseVerify(data);
                  dispatch(actions.updateToken(data.userToken as string));
               }
               setIsVerifyOTP(true);
               setLoading(false);
            } catch (error) {
               setLoading(false);
               message.error(JSON.stringify(error));
            }
            break;
         }
         default:
            break;
      }
   };

   return (
      <Modal
         width={500}
         visible={props.visible}
         onCancel={props.onClose}
         title={null}
         footer={null}
      >
         <div className="verify-modal">
            <img src="/images/tk-icon/tk-logo-normal-mode.svg" className="logo" />

            {isVerifyOTP ? (
               <VerifyOTP
                  verifyOtpType={props.verifyType}
                  response={responseVerify!}
                  onBack={() => setIsVerifyOTP(false)}
                  onClose={props.onClose}
               />
            ) : (
               <>
                  {props.verifyType === VerifyType.VerifyEmail
                     ? renderSendEmailVerify()
                     : renderSendPhoneNumberVerify()}
               </>
            )}
         </div>
      </Modal>
   );
};

export default VerifyModal;
