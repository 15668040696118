import { FunctionComponent } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination } from 'swiper';
import { TKCategory, ContentType } from '../../../../interface/home';
import { CategoryCover } from './categoryCover';
import ViewAllButton from '../../../customUI/viewAllButton';
import '../pattern.scss';
import 'swiper/swiper.scss';

SwiperCore.use([Pagination]);

interface CategorySwiperProps {
   categories: TKCategory[];
   contentType: ContentType;
}

const CategorySwiper: FunctionComponent<CategorySwiperProps> = ({
   categories,
   contentType
}) => {
   const breakpoint = {
      '1': {
         slidesPerView: 1.7
      },
      '576.98': {
         slidesPerView: 3.5
      },
      '768.98': {
         slidesPerView: 4.5
      },
      '1024.98': {
         slidesPerView: 5.5
      },
      '1440.98': {
         slidesPerView: 6.5
      }
   };

   return (
      <div className="category-list fadeIn">
         <div className="title-section">
            <div>
               <h2>หมวดหมู่</h2>
            </div>
            {categories.length >= 7 && (
               <ViewAllButton
                  path={`/${contentType}/categories/all`}
                  title="หมวดหมู่"
               />
            )}
         </div>

         <Swiper
            className="segment-swiper"
            breakpoints={breakpoint}
            spaceBetween={8}
            pagination={{
               dynamicBullets: true
            }}
         >
            {categories.map((e, index) => (
               <SwiperSlide key={`banner_${index}`}>
                  <CategoryCover category={e} contentType={e.contentType} />
               </SwiperSlide>
            ))}
         </Swiper>
      </div>
   );
};

export default CategorySwiper;
