import React, { useState } from 'react';
import { Dropdown } from 'antd';

interface VideoQualityProps {
   quality: VideoBitrate;
   url: string;
   setQuality: (quality: VideoBitrate) => void;
}

export enum VideoBitrate {
   Bitrate480 = '480p',
   Bitrate720 = '720p',
   Bitrate1080 = '1080p'
}

const VideoQuality: React.FC<VideoQualityProps> = ({ quality, setQuality }) => {
   const DropdownList = () => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [videoQuality, setVideoQuality] = useState<VideoBitrate[]>([
         VideoBitrate.Bitrate1080,
         VideoBitrate.Bitrate720,
         VideoBitrate.Bitrate480
      ]);

      return (
         <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)', color: '#fff' }}>
            {videoQuality.map((el, index) => (
               <p key={index} onClick={() => setQuality(el)}>
                  {el}
               </p>
            ))}
         </div>
      );
   };
   return (
      <>
         <Dropdown overlay={DropdownList()} placement="topCenter">
            <div>{quality}</div>
         </Dropdown>
      </>
   );
};

export default VideoQuality;
