import React, { useLayoutEffect, useState } from 'react';
import { EmptyDataView } from '../../components/nodata';
import { generateViewAllPath } from '../../redux/utilities';
import {
   HomeList,
   ContentMetaData,
   DisplayType,
   ContentType,
   Banner,
   TKCategory,
   MagazineSetType
} from '../../../interface';
import Pattern1 from './ebook/pattern-1';
import Pattern2 from './ebook/pattern-2';
import Pattern3 from './ebook/pattern-3';
import Pattern4 from './ebook/pattern-4';
import Pattern5 from './ebook/pattern-5';
import Pattern6 from './ebook/pattern-6';
import CategorySwiper from './categoryHome/category';
import VideoPattern1 from './video/videoPattern-1';
import VideoPattern2 from './video/videoPattern-2';
import MagazineSetSection from './magazine/magazineSetSection';
import './pattern.scss';
import './banner.scss';

interface ContentListProps {
   contents: HomeList[];
   contentsType: ContentType;
}

export const ContentList: React.FC<ContentListProps> = ({ contents, contentsType }) => {
   const useWindowSize = () => {
      const [size, setsize] = useState([0, 0]);
      useLayoutEffect(() => {
         const updateSize = () => {
            setsize([window.innerWidth, window.innerHeight]);
         };
         window.addEventListener('resize', updateSize);
         updateSize();
         return () => window.removeEventListener('resize', updateSize);
      }, []);

      return size;
   };
   const [width] = useWindowSize();

   const setLimitContent = () => {
      if (width > 1024.98) return 8;
      else if (width < 1024.98 && width > 768.98) return 6;
      else if (width < 768.98 && width > 576.98) return 8;
      else if (width < 576.98) return 6;
      else return 8;
   };

   const renderContent = (contentType: ContentType, content: HomeList) => {
      if (contentType === ContentType.video) {
         switch (content.displayType) {
            case DisplayType.VideoSlide:
               return (
                  <VideoPattern1
                     contents={content.contents as ContentMetaData[]}
                     title={content.title}
                     subtitle={content.subtitle}
                     segmentType={content.segmentType}
                     path={generateViewAllPath(contentType, content)}
                  />
               );
            case DisplayType.AllCategory:
               return (
                  <CategorySwiper
                     categories={content.contents as TKCategory[]}
                     contentType={contentType}
                  />
               );
            default:
               return (
                  <VideoPattern2
                     contents={content.contents as ContentMetaData[]}
                     title={content.title}
                     subtitle={content.subtitle}
                     segmentType={content.segmentType}
                     path={generateViewAllPath(contentType, content)}
                     limit={8}
                  />
               );
         }
      } else if (
         contentType === ContentType.ebook ||
         contentType === ContentType.audioBook ||
         contentType === ContentType.magazine
      ) {
         switch (content.displayType) {
            case DisplayType.Content:
               return (
                  <Pattern1
                     contents={content.contents as ContentMetaData[]}
                     title={content.title}
                     subtitle={content.subtitle}
                     path={generateViewAllPath(contentType, content)}
                     limit={setLimitContent()}
                  />
               );
            case DisplayType.ContentWithButton:
               return (
                  <Pattern2
                     contents={content.contents as ContentMetaData[]}
                     title={content.title}
                     subtitle={content.subtitle}
                     path={generateViewAllPath(contentType, content)}
                     limit={setLimitContent()}
                  />
               );
            case DisplayType.ContentSlideWithButtonDetail:
               return (
                  <Pattern3
                     contents={content.contents as ContentMetaData[]}
                     title={content.title}
                     subtitle={content.subtitle}
                     path={generateViewAllPath(contentType, content)}
                  />
               );
            case DisplayType.ContentWithDetail:
               return (
                  <Pattern4
                     contents={content.contents as ContentMetaData[]}
                     title={content.title}
                     subtitle={content.subtitle}
                     path={generateViewAllPath(contentType, content)}
                     limit={setLimitContent()}
                  />
               );
            case DisplayType.ContentWithButtonDetail:
               return (
                  <Pattern5
                     contents={content.contents as ContentMetaData[]}
                     title={content.title}
                     subtitle={content.subtitle}
                     path={generateViewAllPath(contentType, content)}
                     limit={setLimitContent()}
                  />
               );
            case DisplayType.Banner:
               return (
                  <Pattern6
                     contents={content.contents as Banner[]}
                     title={content.title}
                     subtitle={content.subtitle}
                     path={generateViewAllPath(contentType, content)}
                  />
               );
            case DisplayType.MagazineSet:
               return (
                  <MagazineSetSection
                     contents={content.contents as MagazineSetType[]}
                     contentType={ContentType.magazine}
                     title={content.title}
                  />
               );
            case DisplayType.NewspaperSet:
               return (
                  <MagazineSetSection
                     contents={content.contents as MagazineSetType[]}
                     contentType={ContentType.magazine}
                     title={content.title}
                  />
               );
            case DisplayType.AllCategory:
               return (
                  <CategorySwiper
                     categories={content.contents as TKCategory[]}
                     contentType={contentType}
                  />
               );
         }
      } else return <EmptyDataView />;
   };

   return (
      <>
         <div className="content-list fadeIn">
            {contents?.map((el, index) => (
               <div key={index}>{renderContent(contentsType, el)}</div>
            ))}
         </div>
      </>
   );
};

export default ContentList;
