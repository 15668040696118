import { Button, Skeleton, Space } from 'antd';
import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { HibraryRootState } from '../../../redux/rootReducer';
import { FollowAll } from '../../../interface/bookShelf';
import { EmptyDataView } from '../../components/nodata';
import { getAllFollow } from '../../redux/bookshelf/request';
import { addFollow, FollowType, unFollow } from '../../redux/follow';
import { action } from '../../redux/request';
import { useHistory } from 'react-router-dom';
import { SearchPageType } from '../set/search';

interface FollowViewProps {
   pageId: string;
}

const FollowView = (props: FollowViewProps) => {
   const dispatch = useDispatch();
   const history = useHistory();
   const [follows, setFollows] = useState<FollowAll[]>();
   const [afterUnFollow, setAfterUnFollow] = useState<string[]>([]);
   const [buttonLoading, setButtonLoading] = useState<number>();
   const [viewContent, setViewContent] = useState(FollowType.Publisher);
   const { request } = useSelector(
      (state: HibraryRootState) => ({
         request: state.fetch
      }),
      shallowEqual
   );

   useEffect(() => {
      fetchFollows();
   }, [viewContent]);

   const fetchFollows = async () => {
      try {
         dispatch(action.setProcess(true, props.pageId));
         const data = await getAllFollow(viewContent);
         setFollows(data?.follow);
         dispatch(action.setProcess(false, props.pageId));
      } catch (error) {
         dispatch(action.setProcess(false, props.pageId));
      }
   };

   const unfollow = async (index: number) => {
      try {
         const current = follows![index];

         setButtonLoading(index);
         if (current.followKey === FollowType.SubCategory) {
            unFollow(current.followKey, current.uid, current.catUID);
         } else {
            unFollow(current.followKey, current.uid);
         }
         setAfterUnFollow([...afterUnFollow, current.uid]);
         setButtonLoading(undefined);
      } catch (error) {
         dispatch(action.setProcess(false, props.pageId));
      }
   };

   const addfollow = async (index: number) => {
      try {
         const current = follows![index];
         setButtonLoading(index);
         if (current.followKey === FollowType.SubCategory) {
            addFollow(current.followKey, current.uid, current.catUID);
         } else {
            addFollow(current.followKey, current.uid);
         }

         const newList = afterUnFollow.filter((e) => e !== current.uid);
         setAfterUnFollow(newList);
         setButtonLoading(undefined);
      } catch (error) {
         dispatch(action.setProcess(false, props.pageId));
      }
   };

   const renderFollowBox = (data: FollowAll, index: number) => {
      const checkBoxClick = () => {
         if (data.followKey === FollowType.Writer) {
            if (data.contentType) {
               history.push(
                  `/search/${data.name}/${SearchPageType.Writer}/${data.contentType}/${data.uid}`
               );
            } else {
               history.push(`/search/${data.name}/${SearchPageType.Writer}/${data.uid}`);
            }
         } else if (data.followKey === FollowType.Publisher) {
            if (data.contentType) {
               history.push(
                  `/search/${data.name}/${SearchPageType.Publisher}/${data.contentType}/${data.uid}`
               );
            } else {
               history.push(`/search/${data.name}/${SearchPageType.Publisher}/${data.uid}`);
            }
         } else {
            history.push(`/search/${data.name}/${SearchPageType.Category}`);
         }
      };

      return (
         <div className="follow-box" key={index}>
            <Space size={10}>
               {viewContent === FollowType.Publisher || viewContent === FollowType.Writer ? (
                  <>{data.image ? <img src={data.image} /> : <Skeleton.Image />}</>
               ) : null}
               <span
                  className="name"
                  onClick={() => {
                     checkBoxClick();
                  }}
               >
                  {data.name}
               </span>
            </Space>

            {afterUnFollow?.includes(data.uid) ? (
               <Button
                  size="middle"
                  shape="round"
                  type="ghost"
                  style={{ width: '112px' }}
                  className="secondary-background-button"
                  loading={buttonLoading === index}
                  onClick={() => addfollow(index)}
               >
                  ติดตาม
               </Button>
            ) : (
               <Button
                  size="middle"
                  shape="round"
                  type="ghost"
                  className="secondary-outline-button"
                  loading={buttonLoading === index}
                  onClick={() => unfollow(index)}
               >
                  กำลังติดตาม
               </Button>
            )}
         </div>
      );
   };

   return (
      <div className="follow-view">
         <div className="segment-container">
            <div className="segment-button">
               <button
                  onClick={() => {
                     setViewContent(FollowType.Publisher);
                  }}
                  className={`mode-button${viewContent === FollowType.Publisher ? '-active' : ''}`}
               >
                  สำนักพิมพ์/Channel
               </button>
               <button
                  onClick={() => {
                     setViewContent(FollowType.Writer);
                  }}
                  className={`mode-button${viewContent === FollowType.Writer ? '-active' : ''}`}
               >
                  นักเขียน/Creator
               </button>
               <button
                  onClick={() => {
                     setViewContent(FollowType.Category);
                  }}
                  className={`mode-button${viewContent === FollowType.Category ? '-active' : ''}`}
               >
                  หมวดหมู่
               </button>
            </div>
         </div>
         {follows && follows.length > 0 && follows ? (
            <div className="follow-list fadeIn">
               {follows?.map((el, index) => renderFollowBox(el, index))}
            </div>
         ) : null}
         {follows && follows.length === 0 && (
            <> {!request.loading && <EmptyDataView minHeight={60} />}</>
         )}
      </div>
   );
};

export default FollowView;
