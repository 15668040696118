import { Empty } from 'antd';
import './index.scss';

interface EmptyDataViewProps {
  description?: string;
  minHeight?: number;
}

export const EmptyDataView = (props: EmptyDataViewProps) => {
  return (
    <div className="nodata-view" style={{ minHeight: `${props.minHeight}vh` }}>
      <Empty
        image={`/images/tk-icon/empty-data.svg`}
        imageStyle={{ transform: 'scale(1.5)', marginBottom: ' 8px' }}
        description={props.description ?? 'ไม่มีรายการ'}
      />
    </div>
  );
};
