import { createGlobalStyle } from 'styled-components';
import { useState, useEffect } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { HibraryRootState } from '../../../redux/rootReducer';
import { RentalModal } from '../../components/modal';
import { useLocation, useHistory } from 'react-router-dom';
import { message, Modal } from 'antd';
import { setCookie } from '../../helpers/cookie';
import { useMemo } from 'react';
import { actions, getUserInfo } from '../../redux/auth/authRedux';
import { ListDevicesResponse } from '../../../interface';
import { AxiosError } from '../../../interface';
import { fetchGuestToken, MappingToken, signOut, statisticLogin } from '../../redux/auth/authCrud';
import { MainLoading } from './mainLoading';
import { FetchStatus } from '../../redux/request';

import qs from 'qs';
import dayjs from 'dayjs';
import axios from 'axios';
import Routes from '../../routes';
import * as environment from '../../redux/environment';
import ManageDeviceModal from '../../components/modal/manageDeviceModal';
import './index.scss';

export const Main = () => {
   const dispatch = useDispatch();
   const location = useLocation();
   const history = useHistory();
   const [GlobalStyle, setGlobalStyle] = useState(createGlobalStyle({}));
   const [manageDevice, setManageDevice] = useState(false);
   const [mainLoading, setMainLoading] = useState(true);
   const [devices, setDevices] = useState<ListDevicesResponse>();

   const { authen, themeState, download } = useSelector(
      (state: HibraryRootState) => ({
         authen: state.auth,
         themeState: state.theme,
         login: state.login,
         download: state.download
      }),
      shallowEqual
   );

   axios.interceptors.response.use(
      (response) => response,
      (error) => {
         if (error.response.data.errorCode === '012') {
            guestToken();
         } else if (error.response.data.errorCode === 'invalid signature') {
            guestToken();
         } else if (error.response.data.errorCode === '4443') {
            setDevices(error.response.data.data);
            showRemoveDeviceConfirm();
         } else if (error.response.data.errorCode === '4449') {
            message.error(error.response.data.message);
         } else return Promise.reject(error);
      }
   );

   useEffect(() => {
      const handleScroll = (el: any) => {
         const target = el.target.scrollingElement as HTMLDivElement;
         const offsetScrollHeight = target.scrollHeight - target.offsetHeight;
         const scrollTop = Math.floor(target.scrollTop);
         if (offsetScrollHeight <= scrollTop + 144) {
            dispatch(environment.action.setEndOfScreen(true));
         } else {
            dispatch(environment.action.setEndOfScreen(false));
         }
      };

      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
   }, []);

   useEffect(() => {
      if (download?.requestStatus === FetchStatus.Complete && download?.readerURL) {
         setCookie(process.env.REACT_APP_READER_TOKEN!, authen.authToken!, 1);
         const urlString = window.location.href;
         setCookie(process.env.REACT_APP_READER_RETURN_URL!, urlString, 1);
         setTimeout(() => {
            window.location.href = download!.readerURL!;
         }, 300);
      }
   }, [download]);

   useEffect(() => {
      const theme = themeState.darkMode ? themeState.darkTheme : themeState.normalTheme;
      setGlobalStyle(
         createGlobalStyle({
            html: {
               '--primary': theme.Primary,
               '--primary-variant': theme.PrimaryVariants,
               '--secondary': theme.Secondary,
               '--background': theme.Background,
               '--surface': theme.Surfaces,
               '--error': theme.Error,
               '--on-primary': theme.OnPrimary,
               '--on-secondary': theme.OnSecondary,
               '--on-background': theme.OnBackground,
               '--on-surface': theme.OnSurfaces,
               '--on-error': theme.OnError,
               '--nav': theme.Nav,
               '--on-primary-variant': theme.OnPrimaryVariants,
               '--disable': theme.Disable,
               '--on-disable': theme.OnDisable,
               '--OnNav': theme.OnNav,
               '--grey1': theme.GREY1,
               '--grey2': theme.GREY2,
               '--grey3': theme.GREY3,
               '--grey4': theme.GREY4,
               '--grey5': theme.GREY5,
               '--grey6': theme.GREY6,
               '--grey7': theme.GREY7,
               '--grey8': theme.GREY8
            }
         })
      );
   }, [themeState]);

   useEffect(() => {
      if (authen.authToken && authen.authToken !== '') statLogin();
   }, [authen.authToken]);

   useEffect(() => {
      if (authen.authToken) {
         const query = qs.parse(location.search, { ignoreQueryPrefix: true });
         const decodeToken = getUserInfo(authen.authToken);
         const isExpire = decodeToken.exp < dayjs().unix();
         const isLogin = authen.isLogin;

         if (!isLogin) {
            if (query.code && !query.device) Login();
            else if (isExpire) guestToken();
            else setMainLoading(false);
         } else if (isLogin) {
            if (isExpire) getSignOut();
            else setMainLoading(false);
         } else setMainLoading(false);
      } else {
         guestToken();
      }
   }, [authen]);

   useEffect(() => {
      const query = qs.parse(location.search, { ignoreQueryPrefix: true });
      const isLogin = false;
      if (authen.isLogin && query.code && isLogin) {
         const newLocation = location.pathname.split('?');
         history.replace(newLocation[0]);
      }
   }, [authen.isLogin]);

   const Login = async () => {
      const query = qs.parse(location.search, { ignoreQueryPrefix: true });

      if (query.code && !query.device) {
         try {
            setMainLoading(true);
            const response = await MappingToken(
               query.code as string,
               query.state as string,
               localStorage.getItem('redirectURL')
            );
            if (response.status) {
               dispatch(actions.login(response.data.data?.userToken ?? ''));
               setMainLoading(false);
            }
         } catch (error) {
            setMainLoading(false);
            const getError = error as AxiosError;
            switch (getError.response.data.errorCode) {
               case '4443':
                  setDevices(getError.response.data.data);
                  showRemoveDeviceConfirm();
                  break;
               default:
                  break;
            }
         }
      }
   };

   const guestToken = async () => {
      try {
         setMainLoading(true);
         const response = await fetchGuestToken();
         if (response) {
            dispatch(actions.login(response.guestToken));
            setMainLoading(false);
         }
      } catch (error) {
         console.log(error);
         setMainLoading(false);
      }
   };

   // const getRefreshToken = async () => {
   //    try {
   //       setMainLoading(true);
   //       const response = await refreshToken();
   //       if (response) {
   //          dispatch(actions.refreshToken(response.userToken));
   //          setMainLoading(false);
   //       }
   //    } catch (error) {
   //       setMainLoading(false);
   //       console.log(error);
   //    }
   // };

   const getSignOut = async () => {
      try {
         setMainLoading(true);
         const response = await signOut();
         if (response) {
            dispatch(actions.logout());
            setMainLoading(false);
            window.location.href = response.logoutURL;
         }
      } catch (error) {
         setMainLoading(false);
         dispatch(actions.logout());
         history.push(`/`);
      }
   };

   const statLogin = async () => {
      try {
         await statisticLogin();
      } catch (error) {
         console.log(error);
      }
   };

   const showRemoveDeviceConfirm = () => {
      Modal.confirm({
         title: 'จำนวนอุปกรณ์ใช้งานเกินกำหนด',
         content: 'คุณต้องจัดการอุปกรณ์หรือไม่?',
         okText: 'จัดการ',
         cancelText: 'ยกเลิก',
         onOk: () => {
            setManageDevice(true);
         }
      });
   };

   const RouteMemo = useMemo(() => {
      return <Routes />;
   }, [authen]);

   return (
      <>
         <GlobalStyle />
         <RentalModal />
         {!mainLoading && authen.authToken && authen.authToken !== '' ? (
            RouteMemo
         ) : (
            <MainLoading absolute={true} />
         )}

         {manageDevice && (
            <ManageDeviceModal
               visible={manageDevice}
               onClose={() => setManageDevice(false)}
               devices={devices}
            />
         )}
      </>
   );
};
