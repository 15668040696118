import { createSlice } from '@reduxjs/toolkit';
import * as StoreModel from '../store/storeModel';
import * as CategoriesModel from './categoriesModel';

const initialCategoriesState: CategoriesModel.CategoriesState = {
   listLoading: false,
   catId: '',
   error: '',
   callType: StoreModel.CallType.list
};

export const categoriesSlice = createSlice({
   name: 'categories',
   initialState: initialCategoriesState,
   reducers: {
      catchError: (state, action) => {
         const payload: CategoriesModel.CatPayload = action.payload;
         if (payload.error) {
            state.error = payload.error.message;
         }
         state.listLoading = false;
      },
      startCall: (state) => {
         state.error = '';
         state.listLoading = true;
      },
      categoriesFetched: (state, action) => {
         const payload: CategoriesModel.CategoriesPayload<CategoriesModel.CategoryHome> =
            action.payload;
         // state as
         state.error = '';
         state.listLoading = false;
         // state.contentTypeState = payload.contentType;

         state.entities = payload.entities;
         switch (payload.licenseType) {
            case StoreModel.LicenseType.free:
               state.listCategoriesFree = state.entities;
               break;
            case StoreModel.LicenseType.private:
               state.listCategoriesPrivate = state.entities;
               break;
            default:
               state.listCategoriesHibrary = state.entities;
               break;
         }
      },
      categoryDetailFetched: (state, action) => {
         const payload: CategoriesModel.CategoriesPayload<CategoriesModel.CategoryHome> =
            action.payload;

         state.error = '';
         state.listLoading = false;
         state.detail = payload.detail;
      }
   }
});
