import FastAverageColor from 'fast-average-color';
import React, { memo, useEffect, useState } from 'react';
import { isIE } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import { MemoSearchResultListView, SearchPageType } from '.';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { action } from '../../../redux/request';
import { Row } from 'antd';
import { EmptyDataView } from '../../../components/nodata';
import { HibraryRootState } from '../../../../redux/rootReducer';
import { actions } from '../../../redux/searchCategory';
import {
    ContentType,
    ListAllCategoryResponse,
    SearchCategoryResponse,
    SearchCategoryType,
    SearchContentSubcategoryType
} from '../../../../interface';
import {
    fetchCategoryIgnoreContentType,
    fetchContentIgnoreContentType,
    getContentListCategory,
    getSearchCategory
} from '../../../redux/store/storeCrud';
import '../../home/pattern.scss';
import Pattern4 from '../../home/ebook/pattern-4';
import VideoPattern1 from '../../home/video/videoPattern-1';
import VideoPattern2 from '../../home/video/videoPattern-2';

interface CategoryViewProps {
    pageId: string;
    keyword: string;
    selectContentType: ContentType;
    searchType: SearchPageType;
}

interface IColor {
    value?: any;
    isLight?: any;
    hex: string;
}

enum SearchCategoryPageType {
    SearchCategorySection = 'searchCategorySection',
    SearchContentSection = 'searchContentSection',
    SearchCategoryContentList = 'searchCategoryContentList',
    SearchSubcategoryList = 'searchSubcategoryList',
    SearchCategoryContentTypeSection = 'searchCategoryContentTypeSection'
}

const CategoryViewSection: React.FC<CategoryViewProps> = ({
    pageId,
    keyword,
    selectContentType,
    searchType
}) => {
    const dispatch = useDispatch();
    const [categoryList, setCategoryList] = useState<SearchCategoryResponse>();

    const { searchCategory, request } = useSelector(
        (state: HibraryRootState) => ({
            searchCategory: state.searchCategory,
            request: state.fetch
        }),
        shallowEqual
    );

    useEffect(() => {
        fetchCategory();
    }, [keyword, selectContentType]);

    const fetchCategory = async () => {
        try {
            dispatch(action.setProcess(true, pageId));
            const response = await getSearchCategory(selectContentType, keyword.trim());
            setCategoryList(response);
            dispatch(action.setProcess(false, pageId));
        } catch (error) {
            dispatch(action.setProcess(false, pageId));
        }
    };

    const renderCategoryContent = () => {
        switch (searchCategory.currentPage) {
            case SearchCategoryPageType.SearchCategorySection: {
                return (
                    <>
                        {categoryList?.category && categoryList.category.length > 0 ? (
                            <MemoSearchCategoryGrid
                                contents={categoryList?.category}
                                title="หมวดหมู่"
                                searchType={searchType}
                                keyword={keyword}
                            />
                        ) : null}
                        {categoryList?.subcategory && categoryList.subcategory.length > 0 ? (
                            <MemoSearchCategoryGrid
                                searchType={searchType}
                                contents={categoryList?.subcategory}
                                title="หมวดหมู่ย่อย"
                                keyword={keyword}
                            />
                        ) : null}
                        {categoryList?.category &&
                        categoryList?.category.length < 1 &&
                        categoryList?.subcategory &&
                        categoryList?.subcategory.length < 1 ? (
                            <>{!request.loading && <EmptyDataView minHeight={70} />}</>
                        ) : null}
                    </>
                );
            }
            case SearchCategoryPageType.SearchContentSection: {
                return (
                    <ContentSectionView
                        currentCategory={searchCategory.currentCategory!}
                        pageId={pageId}
                    />
                );
            }
            case SearchCategoryPageType.SearchSubcategoryList: {
                return (
                    <MemoSubcategoryViewList
                        currentCategory={searchCategory.currentCategory!}
                        pageId={pageId}
                        searchType={searchType}
                        keyword={keyword}
                    />
                );
            }
            case SearchCategoryPageType.SearchCategoryContentList: {
                return (
                    <MemoSearchResultListView
                        currentCategory={searchCategory.currentCategory!}
                        pageId={pageId}
                    />
                );
            }
            case SearchCategoryPageType.SearchCategoryContentTypeSection: {
                return (
                    <MemoContentTypeSection
                        currentCategory={searchCategory.currentCategory!}
                        pageId={pageId}
                    />
                );
            }
        }
    };

    return <div>{renderCategoryContent()}</div>;
};
export const MemoCategorySection = memo(CategoryViewSection);

interface SubCategoryViewListProps {
    pageId: string;
    currentCategory: SearchCategoryType;
    searchType: SearchPageType;
    keyword: string;
}

const SubcategoryViewList: React.FC<SubCategoryViewListProps> = ({
    pageId,
    currentCategory,
    searchType,
    keyword
}) => {
    const dispatch = useDispatch();
    const [subcategoryList, setSubcategoryList] = useState<SearchCategoryType[]>();

    useEffect(() => {
        fetchCategory();
    }, []);

    const fetchCategory = async () => {
        try {
            dispatch(action.setProcess(true, pageId));
            const response = await fetchCategoryIgnoreContentType(currentCategory.categoryUID!);
            setSubcategoryList(response?.subcategory);
            dispatch(action.setProcess(false, pageId));
        } catch (error) {
            dispatch(action.setProcess(false, pageId));
        }
    };
    return (
        <div className="subcategory-list-view">
            <h1>{currentCategory.categoryName ?? currentCategory.subcategoryName}</h1>
            <div className="content-list">
                <Row className="list-row-categories category-list fadeIn">
                    {subcategoryList?.map((el, index: number) => (
                        <div key={`col_${index}`} className="category-col fadeIn">
                            <CategorySearchCover
                                content={el}
                                searchType={searchType}
                                keyword={keyword}
                            />
                        </div>
                    ))}
                </Row>
            </div>
        </div>
    );
};
export const MemoSubcategoryViewList = memo(SubcategoryViewList);

interface ContentSectionProps {
    currentCategory: SearchCategoryType;
    pageId: string;
}

const ContentSectionView: React.FC<ContentSectionProps> = ({ currentCategory, pageId }) => {
    const dispatch = useDispatch();
    const [subcategoryList, setSubcategoryList] = useState<ListAllCategoryResponse>();

    useEffect(() => {
        fetchSubcategory();
    }, []);

    const fetchSubcategory = async () => {
        try {
            dispatch(action.setProcess(true, pageId));
            const response = await getContentListCategory(
                currentCategory.contentType!,
                currentCategory.categoryUID!
            );

            setSubcategoryList(response);
            dispatch(action.setProcess(false, pageId));
        } catch (error) {
            dispatch(action.setProcess(false, pageId));
        }
    };

    return (
        <div>
            {subcategoryList?.contentsByCategory?.map((el) => {
                switch (currentCategory.contentType) {
                    case ContentType.video: {
                        return (
                            <VideoPattern1
                                title={el.subcategoryName}
                                contents={el.contents}
                                path={`/${currentCategory.contentType}/categories/${currentCategory.categoryUID}/subcategories/${el.subcategoryUID}`}
                            />
                        );
                    }
                    default: {
                        return (
                            <Pattern4
                                title={el.subcategoryName}
                                contents={el.contents}
                                path={`/${currentCategory.contentType}/categories/${currentCategory.categoryUID}/subcategories/${el.subcategoryUID}`}
                                limit={8}
                            />
                        );
                    }
                }
            })}
        </div>
    );
};
export const MemoContentSectionView = memo(ContentSectionView);

interface SearchCategoryContentTypeProps {
    currentCategory: SearchCategoryType;
    pageId: string;
}

const ContentTypeSection: React.FC<SearchCategoryContentTypeProps> = ({
    currentCategory,
    pageId
}) => {
    const dispatch = useDispatch();
    const [title, setTitle] = useState<string>();
    const [contentList, setContentList] = useState<SearchContentSubcategoryType>();

    const { request } = useSelector(
        (state: HibraryRootState) => ({
            request: state.fetch
        }),
        shallowEqual
    );

    useEffect(() => {
        fetchContent();
    }, []);

    const fetchContent = async () => {
        try {
            dispatch(action.setProcess(true, pageId));
            const response = await fetchContentIgnoreContentType(
                currentCategory.categoryUID!,
                currentCategory.subcategoryUID!
            );
            setContentList(response?.contentBySubcategory);
            setTitle(response?.title);
            dispatch(action.setProcess(false, pageId));
        } catch (error) {
            dispatch(action.setProcess(false, pageId));
        }
    };

    return (
        <>
            <h1>{title}</h1>
            {contentList && (
                <>
                    {contentList.ebook && contentList.ebook.length > 0 ? (
                        <Pattern4 title="หนังสือ" contents={contentList.ebook} path="/" />
                    ) : null}
                    {contentList.magazine && contentList.magazine.length > 0 ? (
                        <Pattern4 title="นิตยสาร" contents={contentList.magazine} path="/" />
                    ) : null}
                    {contentList.audioBook && contentList.audioBook.length > 0 ? (
                        <Pattern4 title="หนังสือเสียง" contents={contentList.audioBook} path="/" />
                    ) : null}

                    {contentList.video && contentList.video.length > 0 ? (
                        <VideoPattern2
                            title="วิดีโอ"
                            contents={contentList.video}
                            path="/"
                            limit={500}
                        />
                    ) : null}

                    {contentList.ebook.length === 0 &&
                        contentList.magazine.length === 0 &&
                        contentList.audioBook.length === 0 &&
                        contentList.video.length === 0 && (
                            <>{!request.loading && <EmptyDataView minHeight={60} />}</>
                        )}
                </>
            )}
        </>
    );
};
export const MemoContentTypeSection = memo(ContentTypeSection);

interface SearchCategorySlideProps {
    contents: SearchCategoryType[];
    title: string;
    searchType: SearchPageType;
    keyword: string;
}

const CategoryGrid: React.FC<SearchCategorySlideProps> = ({
    contents,
    title,
    searchType,
    keyword
}) => {
    return (
        <div className="category-list fadeIn">
            {contents && (
                <>
                    <div className="title-section">
                        <div>
                            <h2>{title}</h2>
                        </div>
                    </div>
                    <div className="category-grid-container">
                        {contents.map((el) => (
                            <CategorySearchCover
                                content={el}
                                searchType={searchType}
                                keyword={keyword}
                            />
                        ))}
                    </div>
                </>
            )}
        </div>
    );
};
export const MemoSearchCategoryGrid = memo(CategoryGrid);

interface SearchCategoryCoverProps {
    content: SearchCategoryType;
    searchType: SearchPageType;
    keyword: string;
}

const CategorySearchCover: React.FC<SearchCategoryCoverProps> = ({
    content,
    searchType,
    keyword
}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [color, setColor] = useState<IColor>();
    const [bg, setBG] = useState<string>();
    const [fontColor, setFontColor] = useState<string>();
    const [isError, setIsError] = useState<boolean>(false);

    useEffect(() => {
        let loaded = false;
        const img = document.getElementById(content.coverImage) as HTMLImageElement;

        if (typeof img !== 'undefined' && img !== null) {
            const loadHandler = () => {
                if (loaded) {
                    return;
                }
                loaded = true;
                const fac = new FastAverageColor();
                if (img) {
                    const googleProxyURL =
                        'https://images1-focus-opensocial.googleusercontent.com/gadgets/proxy?container=focus&refresh=2592000&url=';

                    const finalScr = googleProxyURL + encodeURIComponent(content.coverImage!);

                    fac.getColorAsync(finalScr)
                        .then((c: any) => {
                            setColor(c);
                        })
                        .catch(() => {
                            setColor({ hex: '#BDBDBD' });
                            setIsError(true);
                        });
                }
            };

            img.addEventListener('load', loadHandler, false);
            return () => {
                img.removeEventListener('load', loadHandler, false);
            };
        }
    }, []);

    useEffect(() => {
        if (color) {
            const bgEle = color;
            const bg =
                bgEle !== undefined
                    ? bgEle &&
                      bgEle.value &&
                      bgEle.value[0] > 230 &&
                      bgEle.value[1] > 230 &&
                      bgEle.value[2] > 230
                        ? 'rgb(230, 230, 230)'
                        : bgEle && bgEle.hex
                    : 'rgb(230, 230, 230)';
            setBG(bg);
            setFontColor((bgEle && bgEle.isLight) || isError ? '#000000' : '#ffffff');

            // setFontColor(
            //     bgEle ? (!bgEle || (bgEle && bgEle.isLight) ? '#000000' : '#ffffff') : '#000000'
            // );
        }
    }, [color, isError]);

    const checkOnClickPage = () => {
        //! -1type
        if (content.contentType) {
            //! -1ย่อย1type
            if (content.subcategoryUID) {
                dispatch(actions.setSearchCategoryContentList(content));
                if (searchType !== SearchPageType.Category) {
                    history.push(`/search/${keyword}/${SearchPageType.Category}`);
                }
            }
            //! -หลายย่อย1type
            else {
                dispatch(actions.setSearchContentSection(content));
                if (searchType !== SearchPageType.Category) {
                    history.push(`/search/${keyword}/${SearchPageType.Category}`);
                }
            }
        }
        //! -หลายtype
        else {
            //! -1ย่อยหลายtype
            if (content.subcategoryUID) {
                dispatch(actions.setSearchCategoryContentTypeSection(content));
                if (searchType !== SearchPageType.Category) {
                    history.push(`/search/${keyword}/${SearchPageType.Category}`);
                }
            }
            //! -หลายย่อยหลายtype
            else {
                dispatch(actions.setSearchSubcategoryList(content));
                if (searchType !== SearchPageType.Category) {
                    history.push(`/search/${keyword}/${SearchPageType.Category}`);
                }
            }
        }
    };

    return (
        <div className="content-collection-pattern-cover" style={{ backgroundColor: `${bg}` }}>
            <p className="category-title" style={{ color: `${fontColor}` }}>
                {content?.categoryName ?? content?.subcategoryName}
            </p>
            <div className={`image-cover`} onClick={checkOnClickPage}>
                <img
                    src={isIE ? content.coverImage : content.coverImageWebp}
                    id={content.coverImage}
                />
            </div>
        </div>
    );
};
