export interface Publisher {
   uid: string;
   name: string;
   profileImage: string;
}

export interface NewestLastEvaluatedKey {
   saleYear: string;
   saleDate: number;
   bookUID: string;
}

export interface SubCatData {
   catName: string;
   catId: number;
   subcats: Subcat[];
   coverImage: string;
}

export interface Subcat {
   subcatName: string;
   subcatId: number;
   coverImage: string;
}

export interface constentBySubCat {
   catName: string;
   subcatName: string;
   contents: Content[];
   contentAmount: number;
   LastEvaluatedKey?: any;
}

export interface Content {
   contentUID: string;
   catId: number;
   subcatId: number;
   creatorUID: string;
   title: string;
   publisherName: string;
   authorName: string;
   catName: string;
   subcatName: string;
   coverImage: string;
   purchasedConcurrent: number;
   orderAmount: number;
   fileType: string;
}

export interface CatalogDetail {
   publicationDate: number;
   status: number;
   fileSize: number;
   pageCount: number;
   description: string;
   creatorUID: string;
   title: string;
   publisherName: string;
   catName: string;
   subcatName: string;
   writerID: number;
   authorName: string;
   coverImage: string;
   samplePath: string;
   fileType: string;
   contentUID: string;
   purchasedConcurrent: number;
   orderAmount: number;
}

export enum SortType {
   NewUpdateDate = 'newUpdateDate',
   OldUpdateDate = 'oldUpdateDate',
   NewPublisherDate = 'newPublisherDate',
   OldPublisherDate = 'oldPublisherDate',
   RentHistory = 'rentHistory',
   Title = 'title',
   WriterName = 'writerName',
   popularPoint = 'popularPoint'
}

export enum isRentAble {
   RentAble = 'RentAble',
   RentUnable = 'RentUnable'
}

export interface QueryParam {
   categoryUID?: string;
   subCategoryUID?: string;
   sortType?: SortType;
   isRentAble?: string;
   lastEvaluatedKey?: string;
}
