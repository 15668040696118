import parse from 'html-react-parser';
import dayjs from 'dayjs';
import { FC, FunctionComponent, useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { HibraryRootState } from '../../../../redux/rootReducer';
import { PlayState } from '../../../redux/player';
import { Dropdown, Space } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import {
   ContentList,
   ContentType,
   RentalStatus,
   SampleChapterType
} from '../../../../interface';
import './index.scss';

interface ChapterListProps {
   contentList?: ContentList[];
   chapterDidClick?: (chapterUID: string, isPlaying: boolean) => void;
   chapterVideoClick?: (track: number) => void;
   maxHeight?: number;
   licenseStatus: RentalStatus;
   licenseUID: string;
   contentType: ContentType;
   sampleChapter?: SampleChapterType;
}

interface sortChapterType {
   text: string;
   sortChapter: SortChapter;
}

enum SortChapter {
   AddRecent = 'addRecent',
   AddOldest = 'addOldest'
}

export const ChapterList: FC<ChapterListProps> = ({
   contentList,
   chapterDidClick,
   chapterVideoClick,
   maxHeight,
   licenseStatus,
   contentType,
   sampleChapter
}) => {
   const [chapters, setChapters] = useState<ContentList[]>();
   const [sortChapters, setSortChapters] = useState<sortChapterType>({
      text: 'วันที่เพิ่ม (เก่าสุด)',
      sortChapter: SortChapter.AddOldest
   });
   const { darkMode } = useSelector(
      (state: HibraryRootState) => ({
         darkMode: state.darkMode
      }),
      shallowEqual
   );

   useEffect(() => {
      setChapters(contentList!);
   }, [contentList]);

   useEffect(() => {
      if (chapters) {
         switch (sortChapters.sortChapter) {
            case SortChapter.AddRecent: {
               if (contentType === ContentType.audioBook) {
                  const sortData = chapters.sort((a, b) => b.index! - a.index!);
                  setChapters([...sortData]);
               } else {
                  const sortData = chapters.sort((a, b) => b.track! - a.track!);
                  setChapters([...sortData]);
               }
               break;
            }
            case SortChapter.AddOldest: {
               if (contentType === ContentType.audioBook) {
                  const sortData = chapters.sort((a, b) => a.index! - b.index!);
                  setChapters([...sortData]);
               } else {
                  const sortData = chapters.sort((a, b) => a.track! - b.track!);
                  setChapters([...sortData]);
               }
               break;
            }
         }
      }
   }, [sortChapters]);

   const sortChapter = () => {
      const sortDropDown = [
         {
            text: 'วันที่เพิ่ม (ล่าสุด)',
            sortChapters: SortChapter.AddRecent
         },
         {
            text: 'วันที่เพิ่ม (เก่าสุด)',
            sortChapters: SortChapter.AddOldest
         }
      ];

      return (
         <div className="drop-down">
            {sortDropDown.map((item, index) => (
               <div
                  key={index}
                  onClick={() =>
                     setSortChapters({
                        text: item.text,
                        sortChapter: item.sortChapters
                     })
                  }
               >
                  {item.text}
               </div>
            ))}
         </div>
      );
   };

   return (
      <div className="chapter-list-container">
         <div className="episode-section">
            <h1>ตอน</h1>
            <Space size={5}>
               <p>{chapters?.length} Ep.</p>
               <div className="sort">
                  <span
                     className="sortTitle"
                     style={
                        darkMode.darkMode
                           ? { color: darkMode.darkTheme.GREY8 }
                           : {}
                     }
                  >
                     Sort
                  </span>
                  <Dropdown
                     overlay={sortChapter}
                     trigger={['click']}
                     arrow={true}
                  >
                     <a
                        className="dropdown"
                        style={
                           darkMode.darkMode
                              ? { color: darkMode.darkTheme.GREY5 }
                              : {}
                        }
                     >
                        {sortChapters.text + ' '}
                        <DownOutlined />
                     </a>
                  </Dropdown>
               </div>
            </Space>
         </div>

         <div className="chapter-line"></div>
         <div
            className="chapter-list"
            style={
               contentType === ContentType.audioBook
                  ? { maxHeight: `${maxHeight! - 195}px` }
                  : { maxHeight: `${maxHeight!}px` }
            }
         >
            {sampleChapter?.fileSample &&
            contentType === ContentType.audioBook ? (
               <ChapterListItem
                  key={sampleChapter?.chapterUID}
                  licenseStatus={RentalStatus.Rented}
                  chapterDidClick={chapterDidClick}
                  contentType={contentType}
                  chapterUID={sampleChapter?.chapterUID}
                  title={sampleChapter?.title}
                  duration={sampleChapter?.duration}
                  contentUpdated={sampleChapter?.contentUpdated}
                  status={1}
               />
            ) : null}
            {chapters?.map((chapterList, index) => {
               return (
                  <ChapterListItem
                     key={index}
                     licenseStatus={licenseStatus}
                     {...chapterList}
                     chapterDidClick={chapterDidClick}
                     chapterVideoClick={chapterVideoClick}
                     contentType={contentType}
                  />
               );
            })}
         </div>
      </div>
   );
};

interface ChapterListItemProp extends ContentList {
   chapterDidClick?: (contentUID: string, isPlaying: boolean) => void;
   chapterVideoClick?: (track: number) => void;
   startTime?: (track: number) => void;
   licenseStatus: RentalStatus;
}

const ChapterListItem: FunctionComponent<ChapterListItemProp> = ({
   title,
   description,
   duration,
   contentUpdated,
   chapterUID,
   licenseStatus,
   track,
   chapterDidClick,
   chapterVideoClick,
   contentType
}) => {
   const { player, darkMode } = useSelector(
      (state: HibraryRootState) => ({
         darkMode: state.darkMode,
         player:
            chapterUID === state.player.chapterUID ? state.player : undefined
      }),
      shallowEqual
   );

   const onChapterPlayDidClick = () => {
      if (chapterDidClick) {
         chapterDidClick(
            chapterUID,
            player?.status === PlayState.Playing ? true : false
         );
      }
   };

   const onChapterVideoClick = () => {
      if (chapterVideoClick) {
         chapterVideoClick(track ?? 0);
      }
   };

   return (
      <div className="chapter-list-item">
         <div className="chapter-list-item-title">
            {contentType === ContentType.audioBook ? (
               <h2>{title}</h2>
            ) : (
               <h2
                  onClick={() => {
                     onChapterVideoClick();
                  }}
                  className="chapter-title-video"
               >
                  {title}
               </h2>
            )}
            {licenseStatus !== RentalStatus.Rented &&
            chapterUID !== 'Sample' ? (
               <img src="/images/tk-icon/lock-btn.svg" />
            ) : null}
         </div>
         <div
            className={darkMode.darkMode ? 'detail  font-color' : 'detail'}
            style={darkMode.darkMode ? { color: '#bdbdbd' } : {}}
         >
            {parse(description ?? '')}
         </div>
         <div className="bottom-section">
            <div className="left">
               {contentUpdated && (
                  <p>{dayjs.unix(contentUpdated!).format('DD-MM-YYYY')}</p>
               )}
            </div>
            {contentType === ContentType.audioBook ? (
               <div className="right">
                  <p className="duration">{duration}</p>
                  <button
                     onClick={() => {
                        onChapterPlayDidClick();
                     }}
                  >
                     {player?.status === PlayState.Playing &&
                     player.chapterUID === chapterUID ? (
                        <img
                           src="/images/player/pause-circle.svg"
                           style={{ transform: 'scale(1.2)' }}
                        />
                     ) : (
                        <img src="/images/player/play-circle.svg" />
                     )}
                  </button>
               </div>
            ) : null}
         </div>
      </div>
   );
};
