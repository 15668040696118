import React, { useEffect, useState } from 'react';
import { HibraryRootState } from '../../../redux/rootReducer';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { encode as base64Encode } from 'base-64';
import { getAllFavorite } from '../../redux/bookshelf/request';

import { FavoriteContentResponse } from '../../../interface/bookShelf';
import { MemoContentCollectionView } from '../../components/contents';
import { ContentMetaData } from '../../../interface';
import { Col, Row } from 'antd';
import { action } from '../../redux/request';
import { EmptyDataView } from '../../components/nodata';

interface WishListProps {
   pageId: string;
}

const WishListContent = (props: WishListProps) => {
   const dispatch = useDispatch();
   const [favoriteContent, setFavoriteContent] = useState<FavoriteContentResponse>();

   const { request } = useSelector(
      (state: HibraryRootState) => ({
         request: state.fetch
      }),
      shallowEqual
   );

   useEffect(() => {
      fetchFavoriteContent(false);
   }, []);

   const fetchFavoriteContent = async (appendData: boolean) => {
      try {
         dispatch(action.setProcess(true, props.pageId));
         const lastEvaluatedKey = appendData ? favoriteContent?.lastEvaluatedKey : undefined;
         const encoded64 = appendData ? base64Encode(JSON.stringify(lastEvaluatedKey)) : undefined;
         const data = await getAllFavorite(encoded64);

         if (appendData) {
            setListModel(data!);
         } else {
            setFavoriteContent(data);
         }

         dispatch(action.setProcess(false, props.pageId));
      } catch (error) {
         dispatch(action.setProcess(false, props.pageId));
      }
   };

   const setListModel = (data: FavoriteContentResponse) => {
      const current = favoriteContent as FavoriteContentResponse;
      const currentData = data as FavoriteContentResponse;
      setFavoriteContent({
         favorite: current.favorite.concat(currentData?.favorite ?? []),
         lastEvaluatedKey: currentData?.lastEvaluatedKey
      });
   };

   const endScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
      const scroll = e.target;
      const destScroll: any = scroll;
      const isEnd = destScroll.scrollHeight - destScroll.offsetHeight === destScroll.scrollTop;
      return isEnd;
   };

   return (
      <div
         className="wishList"
         onScroll={(e) => {
            const end = endScroll(e);
            if (end && favoriteContent?.lastEvaluatedKey) {
               fetchFavoriteContent(true);
            }
         }}
      >
         {favoriteContent ? (
            <Row gutter={[16, 16]} align="bottom">
               {favoriteContent?.favorite.map((el) => (
                  <Col className="col-cover fadeIn">
                     <MemoContentCollectionView
                        content={el as ContentMetaData}
                        licenseType={el.licenseType}
                     />
                  </Col>
               ))}
            </Row>
         ) : null}
         {favoriteContent?.favorite.length === 0 && (
            <> {!request.loading && <EmptyDataView minHeight={70} />}</>
         )}
      </div>
   );
};

export default WishListContent;
