import { useEffect, useLayoutEffect, useState } from 'react';

import { shallowEqual, useSelector } from 'react-redux';
import { RentalStatus } from '../../../../interface';

import Rented from './rented';
import Booking from './booking';
import HistoryLicense from './historyLicense';
import { useHistory } from 'react-router-dom';
import { Loading } from '../../../customUI/loading';

import '../index.scss';
import { HibraryRootState } from '../../../../redux/rootReducer';
import { Helmet } from 'react-helmet-async';
import BookShelfReturnContentModal from '../../../components/modal/bookShelfReturnContentModal';
import { saveDisplayModal } from '../../../redux/bookshelf/request';

interface routerProps {
    rentStatus: RentalStatus;
}

const BookShelfView = (props: routerProps) => {
    const pageId = 'BookShelfPage';
    const history = useHistory();
    const [viewContent, setViewContent] = useState(props.rentStatus);

    const { authState } = useSelector(
        (state: HibraryRootState) => ({
            authState: state.auth
        }),
        shallowEqual
    );

    const useWindowSize = () => {
        const [size, setsize] = useState([0, 0]);
        useLayoutEffect(() => {
            const updateSize = () => {
                setsize([window.innerWidth, window.innerHeight]);
            };
            window.addEventListener('resize', updateSize);
            updateSize();
            return () => window.removeEventListener('resize', updateSize);
        }, []);

        return size;
    };
    const [width] = useWindowSize();

    useEffect(() => {
        setViewContent(props.rentStatus);
    }, [props.rentStatus]);

    useEffect(() => {
        if (authState.isLogin) {
            const isOpenModal = localStorage.getItem('tooltipModal');
            if (isOpenModal !== null) {
                onSaveDisplayModal();
            }
        }
    }, []);

    const onSaveDisplayModal = async () => {
        const response = await saveDisplayModal();
        if (response) localStorage.removeItem('tooltipModal');
    };

    const renderViewPage = () => {
        switch (viewContent) {
            case RentalStatus.WaitingList:
                return <Booking pageId={pageId} />;
            case RentalStatus.Returned:
                return <HistoryLicense pageId={pageId} />;
            default:
                return <Rented />;
        }
    };

    return (
        <>
            <Helmet>
                <title>TK Read | ชั้นหนังสือ</title>
            </Helmet>
            <div className="bookShelf" style={width < 768.98 ? { paddingTop: '32px' } : {}}>
                {width > 768.98 && (
                    <div className="segment-container">
                        <div className="segment-button">
                            <button
                                onClick={() => {
                                    setViewContent(RentalStatus.Rented);
                                    history.replace(`/${RentalStatus.Rented}`);
                                }}
                                className={
                                    authState.isLogin
                                        ? `mode-button${
                                              viewContent === RentalStatus.Rented ? '-active' : ''
                                          }`
                                        : `mode-button-non-login${
                                              viewContent === RentalStatus.Rented ? '-active' : ''
                                          }`
                                }
                            >
                                ยืม
                            </button>
                            {authState.isLogin && (
                                <button
                                    onClick={() => {
                                        setViewContent(RentalStatus.WaitingList);
                                        history.replace(`/${RentalStatus.WaitingList}`);
                                    }}
                                    className={`mode-button${
                                        viewContent === RentalStatus.WaitingList ? '-active' : ''
                                    }`}
                                >
                                    จอง
                                </button>
                            )}
                            <button
                                onClick={() => {
                                    setViewContent(RentalStatus.Returned);
                                    history.replace(`/${RentalStatus.Returned}`);
                                }}
                                className={
                                    authState.isLogin
                                        ? `mode-button${
                                              viewContent === RentalStatus.Returned ? '-active' : ''
                                          }`
                                        : `mode-button-non-login${
                                              viewContent === RentalStatus.Returned ? '-active' : ''
                                          }`
                                }
                            >
                                ประวัติ
                            </button>
                        </div>
                    </div>
                )}
                {renderViewPage()}
                <BookShelfReturnContentModal />
                <Loading id={pageId} />
            </div>
        </>
    );
};
export default BookShelfView;
