import { chkLastEvaluateKey } from './../utilities/index';
import { QueryParam } from '../../../interface/listEbook';
import { SearchPageType } from '../../pages/set/search';
import axios from 'axios';
import {
   ContentType,
   HomeResponse,
   HomeSet,
   LicenseType,
   BannerResponse,
   HomeListResponse,
   ContentDetailResponse,
   CategoryResponse,
   ContentMetaData,
   WatchProgression,
   ListenProgression,
   SetType,
   MagazineSetResponse
} from '../../../interface/home';
import {
   HtiResponse,
   ContestSetListResponse,
   ListContentByCreatorResponse,
   ListContentByWriterResponse,
   ListContentByKeywordResponse,
   SearchResultResponse,
   ListContentResponse,
   ListAllCategoryResponse,
   SearchCategoryResponse,
   SearchWriterResponse,
   SearchPublisherResponse,
   SearchTagType,
   SearchSubcategoryTypeResponse,
   SearchContentSubcategoryResponse,
   SearchStatisticResponse,
   IgnoreContentTypeResponse
} from '../../../interface/request';
import { generateCreatorPath, generateWriterPath, generateKeywordPath } from '../utilities';
import queryString from 'query-string';
const viewAllContentLimit = 32;

export const getStoreHome = async () => {
   const path = `store/home`;
   const {
      data: { data }
   } = await axios.get<HtiResponse<HomeResponse>>(path);
   return data;
};

export const getHomeContent = async (contentType: string) => {
   try {
      const path = `store/home/${contentType}`;
      const {
         data: { data }
      } = await axios.get<HtiResponse<HomeListResponse>>(path);
      return data;
   } catch (error) {
      return Promise.reject(error);
   }
};

export const getBanner = async () => {
   try {
      const path = `store/banner`;
      const data = await axios.get<HtiResponse<BannerResponse>>(path);
      if (data) {
         return data.data.data?.banners;
      }
   } catch (error) {
      return Promise.reject(error);
   }
};

export const fetchContentDetail = async <T = ContentMetaData>(
   contentType: ContentType,
   contentUid: string
) => {
   const path = `store/${contentType}/${contentUid}/detail`;
   const {
      data: { data }
   } = await axios.get<HtiResponse<ContentDetailResponse<T>>>(path);
   return data;
};

export const fetchCategory = async (contentType: string) => {
   try {
      const path = `store/${contentType}/category`;
      const {
         data: { data }
      } = await axios.get<HtiResponse<CategoryResponse>>(path);
      return data;
   } catch (error) {
      return Promise.reject(error);
   }
};

export const fetchMagazineSet = async (setType: SetType) => {
   const path = `store/allMagazineSet/${setType}`;
   const {
      data: { data }
   } = await axios.get<HtiResponse<MagazineSetResponse>>(path);

   return data;
};

export const fetchCategoryIgnoreContentType = async (categoryUID: string) => {
   const path = `store/category/${categoryUID}/subcategory/all/ignoreContentType`;
   const {
      data: { data }
   } = await axios.get<HtiResponse<SearchSubcategoryTypeResponse>>(path);

   return data;
};

export const fetchContentIgnoreContentType = async (
   categoryUID: string,
   subcategoryUID: string
) => {
   const path = `store/category/${categoryUID}/subcategory/${subcategoryUID}/all/ignoreContentType`;
   const {
      data: { data }
   } = await axios.get<HtiResponse<SearchContentSubcategoryResponse>>(path);

   return data;
};

export const fetchWriterIgnoreType = async (writerUID: string) => {
   const path = `store/writerIgnoreType/${writerUID}`;
   const {
      data: { data }
   } = await axios.get<HtiResponse<IgnoreContentTypeResponse>>(path);
   return data;
};

export const fetchPublisherIgnoreType = async (publisherUID: string) => {
   const path = `store/publisherIgnoreType/${publisherUID}`;
   const {
      data: { data }
   } = await axios.get<HtiResponse<IgnoreContentTypeResponse>>(path);
   return data;
};

interface GetChapterStreamUrlResponse {
   chapterPath?: string;
   url?: string;
}
export const getChapterStreamUrl = async (
   contentType: ContentType,
   contentUid: string,
   chapterUID?: string
) => {
   const path = `license/steamPath/${contentType}/${contentUid}${
      chapterUID ? `?chapterUID=${chapterUID}` : ''
   }`;
   const {
      data: { data }
   } = await axios.get<HtiResponse<GetChapterStreamUrlResponse>>(path);
   return data;
};

export const checkPathVideo = async (path: string) => {
   const response = await axios.get(path);
   return response;
};

export const addVideoView = async (contentUID: string) => {
   try {
      const path = `store/addVideoView?contentUID=${contentUID}`;
      const data = await axios.get(path);
      return data;
   } catch (error) {
      return Promise.reject(error);
   }
};

export const saveWatchProgress = async (
   contentUID: string,
   licenseUID: string,
   licenseType: LicenseType,
   watchProgress: WatchProgression
) => {
   const path = `license/watching/${ContentType.video}/${contentUID}/${licenseUID}/${licenseType}`;
   const data = await axios.post<HtiResponse>(path, { watchProgress });

   return data;
};

export const saveListenProgress = async (
   contentUID: string,
   licenseUID: string,
   licenseType: LicenseType,
   listenProgress: ListenProgression
) => {
   const path = `license/listening/${ContentType.audioBook}/${contentUID}/${licenseUID}/${licenseType}`;
   const data = await axios.post<HtiResponse>(path, { listenProgress });

   return data;
};

export const getEbookListByCreator = async (
   creatorUID: string,
   lastEvaluatedKey?: any,
   licenseType?: LicenseType,
   contentType?: ContentType,
   prefix?: string,
   isPublic = true
) => {
   const qString: any = chkLastEvaluateKey(lastEvaluatedKey);
   qString['contentType'] = contentType;

   // https://api-elibrary-prod.hytexts.com/public/tho/store/free/creators/c31d1b02-8847-4a83-b79e-1a9ecf6af3d5?contentType=ebook
   //public/hti/store/creators/449e09b8-869c-4269-9f54-a3fa1db8dafd?contentTyp
   const path = generateCreatorPath(
      `store`,
      'creators',
      creatorUID,
      prefix,
      licenseType,
      contentType,
      isPublic
   );

   const {
      data: { data }
   } = await axios.get<HtiResponse<ListContentByCreatorResponse>>(
      queryString.stringifyUrl({
         url: path,
         query: qString
      })
   );
   return data;
};

export const getEbookListByWriter = async (
   writerName: string,
   lastEvaluatedKey?: any,
   licenseType?: LicenseType,
   contentType?: ContentType,
   prefix?: string,
   isPublic = true
) => {
   const qString: any = chkLastEvaluateKey(lastEvaluatedKey);
   qString['contentType'] = contentType;

   const path = generateWriterPath(
      `store`,
      'authors',
      writerName,
      prefix,
      licenseType,
      contentType,
      isPublic
   );

   const {
      data: { data }
   } = await axios.get<HtiResponse<ListContentByWriterResponse>>(
      queryString.stringifyUrl({
         url: path,
         query: qString
      })
   );
   return data;
};

export const getEbookListByKeyword = async (
   keyword: string,
   lastEvaluatedKey?: any,
   licenseType?: LicenseType,
   contentType?: ContentType,
   prefix?: string,
   isPublic = true
) => {
   const qString: any = chkLastEvaluateKey(lastEvaluatedKey);
   qString['contentType'] = contentType;

   const path = generateKeywordPath(
      `store`,
      'tags',
      keyword,
      prefix,
      licenseType,
      contentType,
      isPublic
   );
   const {
      data: { data }
   } = await axios.get<HtiResponse<ListContentByKeywordResponse>>(
      queryString.stringifyUrl({
         url: path,
         query: qString
      })
   );
   return data;
};

export function rentEbook(
   uid?: string,
   contentType?: string,
   rentalStatus?: string,
   licenseType?: string
) {
   if (rentalStatus === 'Rent') {
      const path = `license`;
      const qString: any = {
         contentUID: uid,
         contentType: contentType,
         licenseType: licenseType ? licenseType : 'Rent'
      };
      return axios.post(path, qString);
   } else if (rentalStatus === 'WaitingList') {
      const path = `licenses/booking`;
      const qString: any = {
         licenseType: licenseType ? licenseType : 'Rent'
      };
      return axios.post(path, qString);
   } else if (rentalStatus === 'WaitingListed') {
      const path = `/licenses/${uid}/waiting`;
      const qString: any = {
         licenseType: licenseType ? licenseType : 'Rent'
      };
      return axios.delete(path, qString);
   }
}
export function returnEbook(
   uid?: string, //licenseUID
   rentalStatus?: string,
   licenseType?: string
) {
   if (
      rentalStatus === 'Rented' ||
      (rentalStatus === undefined && licenseType) //bookshelf
   ) {
      const path = `/licenses/${uid}/return`;
      const qString: any = {
         licenseType: licenseType ? licenseType : 'Rent'
      };
      return axios.patch(path, qString);
   }
}

export interface ListSetResponse {
   sets: HomeSet[];
}

export const getListSets = async (setType: string, prefix?: string, isLogin = false) => {
   const path = `${!isLogin ? `/public/${prefix}` : ``}/sets`;
   const qString: any = {
      setType: setType
   };

   const {
      data: { data }
   } = await axios.get<HtiResponse<ListSetResponse>>(
      queryString.stringifyUrl({
         url: path,
         query: qString
      })
   );
   return data;
};

export const getListContentSets = async (
   uid: string,
   setType: string,
   lastEvaluatedKey?: any,
   prefix?: string,
   isLogin = false
) => {
   const path = `${!isLogin ? `/public/${prefix}` : ``}/sets/${uid}`;
   const qString: any = chkLastEvaluateKey(lastEvaluatedKey);
   qString['setType'] = setType;
   const {
      data: { data }
   } = await axios.get<HtiResponse<ContestSetListResponse>>(
      queryString.stringifyUrl({
         url: path,
         query: qString
      })
   );
   return data;
};

export function getContactList() {
   return axios.get(`/application/contactList`);
}

export function getIsPublicRegister(prefix: string) {
   return axios.get(`/public/${prefix}/theme`);
}

export const getContentList = async (
   segmentType: string,
   contentType: string,
   filter?: QueryParam
) => {
   const {
      data: { data }
   } = await axios.get<HtiResponse<ListContentResponse>>(`store/${segmentType}/${contentType}`, {
      params: {
         categoryUID: filter?.categoryUID,
         subcategoryUID: filter?.subCategoryUID,
         sortType: filter?.sortType,
         isRentAble: filter?.isRentAble
      }
   });

   return data;
};

export const getContentListRelate = async (
   contentType: string,
   contentUID: string,
   filter?: QueryParam
) => {
   const {
      data: { data }
   } = await axios.get<HtiResponse<ListContentResponse>>(
      `store/${contentType}/${contentUID}/related`,
      {
         params: {
            categoryUID: filter?.categoryUID,
            subcategoryUID: filter?.subCategoryUID,
            sortType: filter?.sortType,
            isRentAble: filter?.isRentAble
         }
      }
   );

   return data;
};

export const getContentListContent = async (
   contentType: string,
   contentListUID: string,
   fromBanner: number,
   filter?: QueryParam
) => {
   const {
      data: { data }
   } = await axios.get<HtiResponse<ListContentResponse>>(
      `store/contentList/${contentType}/${contentListUID}`,
      {
         params: {
            categoryUID: filter?.categoryUID,
            subcategoryUID: filter?.subCategoryUID,
            sortType: filter?.sortType,
            isRentAble: filter?.isRentAble,
            fromBanner: fromBanner
         }
      }
   );
   return data;
};

export const getContentListCategory = async (contentType: string, categoryUID: string) => {
   const {
      data: { data }
   } = await axios.get<HtiResponse<ListAllCategoryResponse>>(
      `store/${contentType}/category/${categoryUID}/content`
   );
   return data;
};

export const getContentListSubCategory = async (
   contentType: ContentType,
   categoryUID: string,
   subcategoryUID: string,
   lastEvaluatedKey: any,
   filter?: QueryParam
) => {
   if (lastEvaluatedKey) {
      const {
         data: { data }
      } = await axios.get<HtiResponse<ListContentResponse>>(`store/all/${contentType}`, {
         params: {
            lastEvaluatedKey: lastEvaluatedKey,
            categoryUID: categoryUID,
            subcategoryUID: subcategoryUID,
            sortType: filter?.sortType,
            isRentAble: filter?.isRentAble,
            limit: viewAllContentLimit
         }
      });
      return data;
   } else {
      const {
         data: { data }
      } = await axios.get<HtiResponse<ListContentResponse>>(`store/all/${contentType}`, {
         params: {
            categoryUID: categoryUID,
            subcategoryUID: subcategoryUID,
            sortType: filter?.sortType,
            isRentAble: filter?.isRentAble,
            limit: viewAllContentLimit
         }
      });
      return data;
   }
};

export const getContentListMagazineSet = async (
   setUID: string,
   lastEvaluatedKey: any,
   filter?: QueryParam
) => {
   console.log(setUID);

   if (lastEvaluatedKey) {
      const {
         data: { data }
      } = await axios.get<HtiResponse<ListContentResponse>>(`store/magazineSet/${setUID}`, {
         params: {
            lastEvaluatedKey: lastEvaluatedKey,
            categoryUID: filter?.categoryUID,
            subcategoryUID: filter?.subCategoryUID,
            sortType: filter?.sortType,
            isRentAble: filter?.isRentAble,
            limit: viewAllContentLimit
         }
      });
      return data;
   } else {
      const {
         data: { data }
      } = await axios.get<HtiResponse<ListContentResponse>>(`store/magazineSet/${setUID}`, {
         params: {
            categoryUID: filter?.categoryUID,
            subcategoryUID: filter?.subCategoryUID,
            sortType: filter?.sortType,
            isRentAble: filter?.isRentAble,
            limit: viewAllContentLimit
         }
      });
      return data;
   }
};

export const getAllContent = async (
   contentType: ContentType,
   lastEvaluatedKey: any,
   filter?: QueryParam
) => {
   if (lastEvaluatedKey) {
      const {
         data: { data }
      } = await axios.get<HtiResponse<ListContentResponse>>(`store/all/${contentType}`, {
         params: {
            lastEvaluatedKey: lastEvaluatedKey,
            categoryUID: filter?.categoryUID,
            subcategoryUID: filter?.subCategoryUID,
            sortType: filter?.sortType,
            isRentAble: filter?.isRentAble,
            limit: viewAllContentLimit
         }
      });
      return data;
   } else {
      const {
         data: { data }
      } = await axios.get<HtiResponse<ListContentResponse>>(`store/all/${contentType}`, {
         params: {
            categoryUID: filter?.categoryUID,
            subcategoryUID: filter?.subCategoryUID,
            sortType: filter?.sortType,
            isRentAble: filter?.isRentAble,
            limit: viewAllContentLimit
         }
      });
      return data;
   }
};

export const getContentListWriter = async (
   contentType: ContentType,
   lastEvaluatedKey: string,
   writerUID: string,
   filter?: QueryParam
) => {
   const path = `store/${contentType}/creator/content`;
   if (lastEvaluatedKey) {
      const {
         data: { data }
      } = await axios.get<HtiResponse<ListContentResponse>>(path, {
         params: {
            writerUID: writerUID,
            lastEvaluatedKey: lastEvaluatedKey,
            categoryUID: filter?.categoryUID,
            subcategoryUID: filter?.subCategoryUID,
            sortType: filter?.sortType,
            isRentAble: filter?.isRentAble,
            limit: viewAllContentLimit
         }
      });
      return data;
   } else {
      const {
         data: { data }
      } = await axios.get<HtiResponse<ListContentResponse>>(path, {
         params: {
            writerUID: writerUID,
            categoryUID: filter?.categoryUID,
            subcategoryUID: filter?.subCategoryUID,
            sortType: filter?.sortType,
            isRentAble: filter?.isRentAble,
            limit: viewAllContentLimit
         }
      });
      return data;
   }
};

export const getContentListPublisher = async (
   contentType: ContentType,
   lastEvaluatedKey: string,
   publisherUID: string,
   filter?: QueryParam
) => {
   const path = `store/${contentType}/creator/content`;
   if (lastEvaluatedKey) {
      const {
         data: { data }
      } = await axios.get<HtiResponse<ListContentResponse>>(path, {
         params: {
            publisherUID: publisherUID,
            lastEvaluatedKey: lastEvaluatedKey,
            categoryUID: filter?.categoryUID,
            subcategoryUID: filter?.subCategoryUID,
            sortType: filter?.sortType,
            isRentAble: filter?.isRentAble,
            limit: viewAllContentLimit
         }
      });
      return data;
   } else {
      const {
         data: { data }
      } = await axios.get<HtiResponse<ListContentResponse>>(path, {
         params: {
            publisherUID: publisherUID,
            categoryUID: filter?.categoryUID,
            subcategoryUID: filter?.subCategoryUID,
            sortType: filter?.sortType,
            isRentAble: filter?.isRentAble,
            limit: viewAllContentLimit
         }
      });
      return data;
   }
};

export const getSearchResult = async (
   searchFilter: ContentType,
   searchType: SearchPageType,
   keyword: string
) => {
   const path = `search/${searchType}/${searchFilter}`;
   const {
      data: { data }
   } = await axios.get<HtiResponse<SearchResultResponse>>(path, {
      params: {
         keyword: keyword,
         limit: 20
      }
   });

   return data;
};

export const getSearchAllContentList = async (
   contentType: ContentType,
   lastEvaluatedKey: string,
   keyword: string
) => {
   const path = `store/search/content/${contentType}`;
   if (lastEvaluatedKey) {
      const {
         data: { data }
      } = await axios.get<HtiResponse<ListContentResponse>>(path, {
         params: {
            limit: 32,
            lastEvaluatedKey: lastEvaluatedKey,
            keyword: keyword
         }
      });
      return data;
   } else {
      const {
         data: { data }
      } = await axios.get<HtiResponse<ListContentResponse>>(path, {
         params: { limit: 32, keyword: keyword }
      });
      return data;
   }
};

export const getSearchCategory = async (searchFilter: ContentType, keyword: string) => {
   const path = `search/${SearchPageType.Category}/${searchFilter}`;
   const {
      data: { data }
   } = await axios.get<HtiResponse<SearchCategoryResponse>>(path, {
      params: {
         keyword: keyword
      }
   });

   return data;
};

export const getSearchWriter = async (searchFilter: ContentType, keyword: string) => {
   const path = `search/${SearchPageType.Writer}/${searchFilter}`;
   const {
      data: { data }
   } = await axios.get<HtiResponse<SearchWriterResponse>>(path, {
      params: {
         keyword: keyword
      }
   });

   return data;
};

export const getSearchPublisher = async (searchFilter: ContentType, keyword: string) => {
   const path = `search/${SearchPageType.Publisher}/${searchFilter}`;
   const {
      data: { data }
   } = await axios.get<HtiResponse<SearchPublisherResponse>>(path, {
      params: {
         keyword: keyword
      }
   });

   return data;
};

export const getSearchTag = async (searchFilter: ContentType, keyword: string) => {
   const path = `search/${SearchPageType.Tag}/${searchFilter}`;
   const {
      data: { data }
   } = await axios.get<HtiResponse<SearchTagType>>(path, {
      params: {
         keyword: keyword
      }
   });

   return data;
};

export const getSearchStatistic = async () => {
   const path = `search/statistics`;
   const {
      data: { data }
   } = await axios.get<HtiResponse<SearchStatisticResponse>>(path);

   return data;
};
