import axios from 'axios';
import { OtpPassword, OtpRegister } from '../../../interface/authen';
import {
   HtiResponse,
   LoginResponse,
   LoginRedirect,
   MappingTokenResponse,
   OnboardResponse,
   LogoutResponse,
   MemberData,
   NotificationResponse,
   NotificationType,
   EditMemberResponse
} from '../../../interface/request';
import { ClientLocation } from '../../../interface/client';
export const LOGIN_URL = `auth/sign-in`;
export const LOGOUT_URL = `auth/sign-out`;
export const REGISTER_URL = '/api/auth/register';
export const REQUEST_PASSWORD_URL = '/signin/forgotPassword';
export const VERIFY_OTP_URL = '/signin/verifyPasswordOTP';
export const RESET_PASSWORD_URL = '/signin/changePasswordOTP';
export const CHANGE_EMAIL_URL = '/member/email/requestotp';

export interface LoginRequest {
   userID: string;
   password: string;
   notificationToken?: string;
}

export const deleteAccount = async (password: string) => {
   const path = `member/delete`;
   const { data } = await axios.delete<HtiResponse>(path, {
      data: { password: password }
   });
   return data;
};

export const fetchGuestToken = async () => {
   const path = `application/onBoarding`;
   const {
      data: { data }
   } = await axios.get<HtiResponse<OnboardResponse>>(path);
   return data;
};

export const refreshToken = async () => {
   const path = `auth/refreshToken`;
   const {
      data: { data }
   } = await axios.get<HtiResponse<MappingTokenResponse>>(path);
   return data;
};

export const signIn = async (redirectURL: string | null) => {
   try {
      const data = await axios.get<HtiResponse<LoginRedirect>>(
         LOGIN_URL + `?redirectURL=${redirectURL}`
      );
      return data;
   } catch (error) {
      return Promise.reject(error);
   }
};

export const signInSSO = async (username: string, IDCode: string, appid: string) => {
   try {
      const {
         data: { data }
      } = await axios.post<HtiResponse<LoginRedirect>>(
         `auth/sign-in-sso`,
         {
            userName: username.toLowerCase(),
            IDcode: IDCode
         },
         {
            params: {
               appid: appid
            }
         }
      );
      return data;
   } catch (error) {
      return Promise.reject(error);
   }
};

export const MappingToken = async (code: string, state: string, redirectURL: string | null) => {
   try {
      const data = await axios.post<HtiResponse<MappingTokenResponse>>(LOGIN_URL, {
         code: code,
         state: state,
         redirectURL: redirectURL
      });
      return data;
   } catch (error) {
      return Promise.reject(error);
   }
};

export const statisticLogin = async () => {
   const data = await axios.post(`application/addLogin`);

   return data;
};

export const signOut = async () => {
   const {
      data: { data }
   } = await axios.get<HtiResponse<LogoutResponse>>(LOGOUT_URL);
   return data;
};

export const getNotification = async (lastEvaluatedKey: string) => {
   if (lastEvaluatedKey) {
      const {
         data: { data }
      } = await axios.get<HtiResponse<NotificationResponse>>(`member/notificationAll`, {
         params: { lastEvaluatedKey: lastEvaluatedKey, limit: 5 }
      });
      return data;
   } else {
      const {
         data: { data }
      } = await axios.get<HtiResponse<NotificationResponse>>(`member/notificationAll`, {
         params: { limit: 6 }
      });

      return data;
   }
};

export const deleteNotification = async (
   isAll: boolean,
   notificationUID?: string,
   notificationType?: NotificationType
) => {
   const data = await axios.delete<HtiResponse>('member/notificationDelete', {
      data: {
         notificationUID: notificationUID,
         notificationType: notificationType,
         isAll: isAll
      }
   });
   return data;
};

export const readNotification = async (
   notificationType: NotificationType,
   notificationUID: string
) => {
   const bodyReq = {
      notificationType: notificationType,
      notificationUID: notificationUID
   };
   const data = await axios.post<HtiResponse>(`member/notificationRead`, bodyReq);

   return data;
};

export const resetPassword = async (
   newPassword: string,
   uid: string,
   otp: string,
   refCode: string
) => {
   const {
      data: { status }
   } = await axios.patch<HtiResponse>(RESET_PASSWORD_URL, {
      password: newPassword,
      verification: { uid: uid, referenceCode: refCode, otp: otp }
   });
   return status;
};

export const verifyRegister = async (
   prefix?: string,
   userID?: string,
   geoLocation?: ClientLocation
) => {
   const path = `${`/public/${prefix}/register/request`}`;
   let payload = {
      userID: userID
   };

   if (geoLocation) payload = await Object.assign(payload, { geoLocation });
   console.log(geoLocation);

   const {
      data: { data }
   } = await axios.post<HtiResponse<OtpRegister>>(path, payload);
   return data;
};

export const registerForm = async (
   prefix?: string,
   userID?: string,
   name?: string,
   password?: string,
   surname?: string,
   otp?: string,
   staffID?: string,
   latitude?: number,
   logitude?: number
) => {
   const path = `${`/public/${prefix}/register`}`;

   const {
      data: { data }
   } = await axios.post<HtiResponse<LoginResponse>>(path, {
      userID: userID,
      name: name,
      password: password,
      surname: surname,
      otp: otp,
      staffID: staffID,
      latitude: latitude,
      logitude: logitude
   });
   return data;
};
export const verifyOTPregister = async (
   prefix?: string,
   email?: string,
   refCode?: string,
   otp?: string
) => {
   const path = `${`/public/${prefix}/register/verify`}`;
   const {
      data: { data }
   } = await axios.post<HtiResponse<LoginResponse>>(path, {
      email: email,
      referenceCode: refCode,
      otp: otp
   });
   return data;
};

export const verifyOTP = async (uid: string, otp: string, refCode: string) => {
   const {
      data: { data }
   } = await axios.post<HtiResponse<LoginResponse>>(VERIFY_OTP_URL, {
      uid: uid,
      referenceCode: refCode,
      otp: otp
   });
   return data;
};
export const verifyOTPChangeEmail = async (uid?: string, otp?: string, refCode?: string) => {
   const path = `${`/member/email/verifyotp`}`;
   const {
      data: { data }
   } = await axios.post<HtiResponse<LoginResponse>>(path, {
      uid: uid,
      referenceCode: refCode,
      otp: otp
   });
   return data;
};

export const requestPassword = async (email: string) => {
   const {
      data: { data }
   } = await axios.post<HtiResponse<OtpPassword>>(REQUEST_PASSWORD_URL, {
      userID: email
   });
   return data;
};
export const requestChangeEmail = async (email: string) => {
   const {
      data: { data }
   } = await axios.post<HtiResponse<OtpPassword>>(CHANGE_EMAIL_URL, {
      email: email
   });
   return data;
};

export const requestChangePassword = async (email: string) => {
   const {
      data: { data }
   } = await axios.post<HtiResponse<OtpPassword>>(REQUEST_PASSWORD_URL, {
      userID: email
   });
   return data;
};

export function logout() {
   return axios.patch(LOGOUT_URL);
}

export const getMemberData = async () => {
   const {
      data: { data }
   } = await axios.get<HtiResponse<MemberData>>(`member/profiles`);
   return data;
};

export const updateMemberData = async (memberData: MemberData) => {
   const bodyReq = {
      firstName: memberData.firstName,
      lastName: memberData.lastName,
      birthDate: memberData.birthDate,
      laserNumber: memberData.laserNumber,
      referenceName: memberData.refName,
      referenceEmail: memberData.refEmail,
      referenceTel: memberData.refPhoneNumber
   };
   const { data } = await axios.patch<HtiResponse<EditMemberResponse>>(`member/profiles`, bodyReq);

   return data;
};

export const updateMemberPassword = async (oldPassword: string, newPassword: string) => {
   const bodyReq = {
      oldPassword: oldPassword,
      newPassword: newPassword
   };
   const { data } = await axios.post<HtiResponse<EditMemberResponse>>(`member/password`, bodyReq);

   return data;
};

export const updateMemberPhoneNumber = async (phoneNumber: string) => {
   const bodyReq = {
      mobile: phoneNumber
   };

   const {
      data: { data }
   } = await axios.post<HtiResponse<EditMemberResponse>>(`member/mobile`, bodyReq);

   return data;
};

export const validateUpdatePhoneNumber = async (mobileToken: string, otp: string) => {
   const bodyReq = {
      mobileToken: mobileToken,
      otp: otp
   };
   const {
      data: { data }
   } = await axios.post<HtiResponse<EditMemberResponse>>(`member/mobile/validate`, bodyReq);

   return data;
};

export const updateMemberEmail = async (email: string) => {
   const bodyReq = {
      email: email
   };

   const {
      data: { data }
   } = await axios.post<HtiResponse<EditMemberResponse>>(`member/email`, bodyReq);

   return data;
};

export const validateUpdateEmail = async (emailToken: string, otp: string) => {
   const bodyReq = {
      emailToken: emailToken,
      otp: otp
   };
   const {
      data: { data }
   } = await axios.post<HtiResponse<EditMemberResponse>>(`member/email/validate`, bodyReq);

   return data;
};
