import {
  SplashLoadingAction,
  SplashLoadingActionType
} from '../redux/splashLoading';

export const removeSplash = (dispatch: any) => {
  dispatch(animateHideLoading());
  setTimeout(() => {
    dispatch(hideSplash());
  }, 500);
};
export const showSplash = (): SplashLoadingAction => {
  return {
    type: SplashLoadingActionType.Show
  };
};

export const animateHideLoading = (): SplashLoadingAction => {
  return {
    type: SplashLoadingActionType.AnimateHide
  };
};

export const hideSplash = (): SplashLoadingAction => {
  return {
    type: SplashLoadingActionType.Hide
  };
};
