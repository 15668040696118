export enum PlayState {
   Init,
   Playing,
   Pause,
   Stop
}
export interface PlayerState {
   chapterUID?: string;
   contentUID?: string;
   status: PlayState;
   playlist?: string;
   title?: string;
   description?: string;
   currentTime?: number;
}

export interface PlayerState {
   contentUID?: string;
   status: PlayState;
   playlist?: string;
}
const initialData: PlayerState = {
   status: PlayState.Init
};

export interface PlayerAction {
   payload?: PlayerActionPayload;
   type: PlayerActionType;
}

export enum PlayerActionType {
   Play = 'PlayerActionType[Play]',
   Pause = 'PlayerActionType[Pause]',
   Stop = 'PlayerActionType[Stop]',
   Init = 'PlayerActionType[Init]',
   SetupPlayer = 'PlayerActionType[SetupPlayer]',
   ChangeTrack = 'PlayerActionType[ChangeTrack]',
   UpdateCurrentTime = 'PlayerActionType[UpdateCurrentTime]',
   SetStartPlaylist = 'PlayerActionType[SetStartPlaylist]'
}

export interface PlayerActionPayload {
   playlist?: string;
   contentUID?: string;
   title?: string;
   description?: string;
   currentTime?: number;
   chapterUID?: string;
}

export const reducer = (state = initialData, action: PlayerAction): PlayerState => {
   switch (action.type) {
      case PlayerActionType.Play: {
         if (state.playlist) {
            return { ...state, status: PlayState.Playing };
         } else {
            return {
               ...state,
               status: PlayState.Playing,
               playlist: action.payload?.playlist
            };
         }
      }
      case PlayerActionType.ChangeTrack: {
         return {
            ...state,
            currentTime: action.payload?.currentTime,
            playlist: action.payload?.playlist
         };
      }
      case PlayerActionType.Pause: {
         return { ...state, status: PlayState.Pause };
      }
      case PlayerActionType.Stop: {
         return { ...state, status: PlayState.Stop, currentTime: 0 };
      }
      case PlayerActionType.Init: {
         return { status: PlayState.Init };
      }
      case PlayerActionType.UpdateCurrentTime: {
         return { ...state, currentTime: action.payload?.currentTime };
      }
      case PlayerActionType.SetStartPlaylist: {
         return { ...state, playlist: action.payload?.playlist };
      }
      case PlayerActionType.SetupPlayer: {
         return {
            ...state,
            contentUID: action.payload?.contentUID,
            title: action.payload?.title,
            description: action.payload?.description,
            currentTime: action.payload?.currentTime,
            chapterUID: action.payload?.chapterUID,
            playlist: action.payload?.playlist
         };
      }
      default:
         return state;
   }
};

const setupPlayer = (
   contentUID: string,
   chapterUID: string,
   title: string,
   currentTime: number,
   playlist?: string
): PlayerAction => {
   return {
      type: PlayerActionType.SetupPlayer,
      payload: {
         chapterUID,
         contentUID,
         title,
         currentTime,
         playlist
      }
   };
};
const setPlay = (playlist?: string): PlayerAction => {
   return {
      type: PlayerActionType.Play,
      payload: {
         playlist
      }
   };
};
const setPause = (): PlayerAction => {
   return {
      type: PlayerActionType.Pause
   };
};
const setStop = (): PlayerAction => {
   return {
      type: PlayerActionType.Stop
   };
};
const updateCurrentTime = (currentTime: number): PlayerAction => {
   return {
      type: PlayerActionType.UpdateCurrentTime,
      payload: { currentTime }
   };
};
const setStartPlaylist = (playlist: string): PlayerAction => {
   return {
      type: PlayerActionType.SetStartPlaylist,
      payload: { playlist }
   };
};

const setChangeTrack = (playlist?: string, currentTime = 0): PlayerAction => {
   return {
      type: PlayerActionType.ChangeTrack,
      payload: {
         playlist,
         currentTime
      }
   };
};
export const action = {
   setupPlayer,
   setPlay,
   setPause,
   setStop,
   updateCurrentTime,
   setChangeTrack,
   setStartPlaylist
};
