import React from 'react';
import { ManageDevice } from '../devices';

const Device = () => {
   return (
      <div className="device">
         <div className="title-memberInfo">
            <h1>จัดการอุปกรณ์</h1>
         </div>

         <ManageDevice />
      </div>
   );
};

export default Device;
