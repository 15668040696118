import { useState, memo, useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Loading } from '../../../customUI/loading';
import { ContentMetaData, ContentType } from '../../../../interface/home';
import { Row, Col, Tag, Menu, Dropdown } from 'antd';
import { EmptyDataView } from '../../../components/nodata';
import { setContentType } from '../../../redux/utilities';
import { ContentCollectionView } from '../../../components/contents';
import { HibraryRootState } from '../../../../redux/rootReducer';
import SearchWriterMainPage from './searchWriter';
import { MemoSearchTag } from './searchTags';
import { MemoCategorySection } from './searchCategory';
import { action } from '../../../redux/request';
import { getContentListSubCategory, getSearchStatistic } from '../../../redux/store/storeCrud';
import { SearchBox } from '../../base/NavBar';
import { MenuStyle } from './style';
import { actions } from '../../../redux/searchCategory';
import { BreadcrumbLink, BreadCrumbView } from '../../../components/breadcrumb';
import {
   ListContentResponse,
   SearchCategoryType,
   SearchStatisticResponse
} from '../../../../interface';

import SVG from 'react-inlinesvg';
import SearchAllView, { SearchAllPageType } from './searchAll';
import SearchTitle from './searchTitle';

import '../index.scss';
import '../../home/pattern.scss';

interface SearchResultProps {
   keyword?: string;
   currentSearch?: SearchPageType;
   writerUID?: string;
   publisherUID?: string;
   contentType?: ContentType;
   searchAllType?: SearchAllPageType;
}

export enum SearchPageType {
   All = 'all',
   Title = 'title',
   Writer = 'writer',
   Publisher = 'publisher',
   Category = 'category',
   Tag = 'tag'
}

export const SearchResult: React.FC<SearchResultProps> = ({
   keyword,
   currentSearch,
   publisherUID,
   writerUID,
   contentType,
   searchAllType
}) => {
   const pageId = 'SearchResult';
   const dispatch = useDispatch();
   const location = useLocation();
   const history = useHistory();
   const [breadCrumbs, setBreadCrumbs] = useState<BreadcrumbLink[]>();
   const [searchStat, setSearchStat] = useState<SearchStatisticResponse>();
   const [searchType, setSearchType] = useState(currentSearch ?? SearchPageType.All);
   const [selectContentType, setSelectContentType] = useState(ContentType.allContent);

   useEffect(() => {
      if (!keyword) {
         fetchSearchStat();
      }
      setBreadCrumbs([
         {
            title: `ค้นหา ${keyword ?? ''}`,
            path: `/search/${keyword}/${searchType}`
         }
      ]);
   }, [location]);

   useEffect(() => {
      if (searchType !== SearchPageType.Category) {
         dispatch(actions.setSearchCategorySection());
      }
   }, [searchType]);

   useEffect(() => {
      if (currentSearch) {
         setSearchType(currentSearch);
      }
   }, [currentSearch]);

   const breadcrumbs = useMemo(() => {
      return <BreadCrumbView addLinks={breadCrumbs} />;
   }, [breadCrumbs]);

   const fetchSearchStat = async () => {
      try {
         dispatch(action.setProcess(true, pageId));
         const response = await getSearchStatistic();
         setSearchStat(response);
         dispatch(action.setProcess(false, pageId));
      } catch (error) {
         dispatch(action.setProcess(false, pageId));
      }
   };

   const renderContent = () => {
      switch (searchType) {
         case SearchPageType.All:
            return (
               <SearchAllView
                  pageId={pageId}
                  keyword={keyword!}
                  selectContentType={selectContentType}
                  searchType={searchType}
                  contentType={contentType ?? ContentType.ebook}
                  searchAllTypeProps={searchAllType ?? SearchAllPageType.SearchAllSection}
               />
            );
         case SearchPageType.Title:
            return (
               <SearchTitle
                  pageId={pageId}
                  keyword={keyword!}
                  selectContentType={selectContentType}
                  searchType={searchType}
               />
            );
         case SearchPageType.Writer:
            return (
               <SearchWriterMainPage
                  title="นักเขียน/Creator"
                  pageId={pageId}
                  keyword={keyword!}
                  selectContentType={selectContentType}
                  searchType={searchType}
                  writerUID={writerUID}
                  contentType={contentType!}
               />
            );
         case SearchPageType.Publisher:
            return (
               <SearchWriterMainPage
                  title="สำนักพิมพ์"
                  pageId={pageId}
                  keyword={keyword!}
                  selectContentType={selectContentType}
                  searchType={searchType}
                  publisherUID={publisherUID}
                  contentType={contentType!}
               />
            );
         case SearchPageType.Tag:
            return (
               <MemoSearchTag
                  searchType={searchType}
                  pageId={pageId}
                  keyword={keyword!}
                  selectContentType={selectContentType}
               />
            );
         case SearchPageType.Category:
            return (
               <MemoCategorySection
                  searchType={searchType}
                  pageId={pageId}
                  keyword={keyword!}
                  selectContentType={selectContentType}
               />
            );
      }
   };

   const renderSearchStatistic = () => {
      return (
         <div className="tag-container">
            <h1 className="search-title">กำลังเป็นที่นิยม</h1>
            <div className="tag-flex">
               {searchStat && (
                  <>
                     {searchStat.keywords.map((el, index) => (
                        <Tag
                           key={index}
                           className="search-tag"
                           onClick={() => {
                              history.push(`/search/${el}/${SearchPageType.All}`);
                           }}
                        >
                           {el}
                        </Tag>
                     ))}
                  </>
               )}
            </div>
         </div>
      );
   };

   return (
      <>
         {breadcrumbs}
         <div className="store view-all-content">
            <Helmet>
               <title>TK Read | ผลการค้นหา {keyword ?? ''}</title>
            </Helmet>

            <div className="search-bar">
               <SearchBox />
            </div>

            {keyword ? (
               <>
                  <h3 style={{ marginTop: '0' }}>ผลการค้นหาของคุณสำหรับ: {keyword}</h3>
                  <SegmentButton
                     keyword={keyword}
                     selectContentType={selectContentType}
                     searchType={searchType}
                     onSelectContentType={(e) => setSelectContentType(e)}
                     onSearchType={(e) => setSearchType(e)}
                  />
                  {renderContent()}
               </>
            ) : (
               <div className="fadeIn">{renderSearchStatistic()}</div>
            )}

            <Loading id={pageId} />
         </div>
      </>
   );
};

interface SearchResultListViewProps {
   contents?: ContentMetaData[];
   contentType?: ContentType;
   pageId?: string;
   currentCategory?: SearchCategoryType;
   title?: string;
}

type ResponseModel = ListContentResponse;

export const SearchResultListView = (props: SearchResultListViewProps) => {
   const dispatch = useDispatch();

   const [contentList, setContentList] = useState<ResponseModel>();
   const { request, environment } = useSelector(
      (state: HibraryRootState) => ({
         request: state.fetch,
         environment: state.environment
      }),
      shallowEqual
   );

   useEffect(() => {
      if (environment.endOfScreen && !props.contents) {
         if (contentList?.lastEvaluatedKey !== undefined && !request.loading) {
            fetchContentBySubcategory(true);
         }
      }
   }, [environment]);

   useEffect(() => {
      if (!props.contents) {
         fetchContentBySubcategory(false);
      }
   }, [props.currentCategory]);

   const fetchContentBySubcategory = async (append: boolean) => {
      try {
         if (props.currentCategory) {
            dispatch(action.setProcess(true, props.pageId));
            const lastEvaluatedKey = append ? contentList?.lastEvaluatedKey : undefined;
            const response = await getContentListSubCategory(
               props.currentCategory.contentType!,
               props.currentCategory.categoryUID!,
               props.currentCategory.subcategoryUID!,
               lastEvaluatedKey
            );
            setContentList(response as ListContentResponse);
            dispatch(action.setProcess(false, props.pageId));
         }
      } catch (error) {
         dispatch(action.setProcess(false, props.pageId));
      }
   };

   return (
      <>
         {props.contents && (
            <>
               <h1>{props.title ?? setContentType(props.contentType ?? ContentType.allContent)}</h1>
               {props.contents!.length > 0 ? (
                  <div className="content-list">
                     <Row gutter={[16, 16]} className="list-row">
                        {props.contents?.map((content, index: number) => (
                           <Col
                              key={`col_${content.contentUID}_${index}`}
                              className={`col-${props.contentType}`}
                           >
                              <ContentCollectionView content={content} />
                           </Col>
                        ))}
                     </Row>
                  </div>
               ) : (
                  <div className="content-list flex-center ">
                     <>{!request.loading && <EmptyDataView minHeight={50} />}</>
                  </div>
               )}
            </>
         )}

         {contentList && (
            <>
               <h1>
                  {props.contentType
                     ? setContentType(props.contentType)
                     : props.currentCategory?.subcategoryName}
               </h1>
               {contentList.contents.length > 0 ? (
                  <div className="content-list">
                     <Row gutter={[16, 16]} className="list-row">
                        {contentList.contents.map((content, index: number) => (
                           <Col
                              key={`col_${content.contentUID}_${index}`}
                              className={`col-${
                                 props.contentType ?? props.currentCategory?.contentType
                              }`}
                           >
                              <ContentCollectionView content={content} />
                           </Col>
                        ))}
                     </Row>
                  </div>
               ) : (
                  <div className="content-list flex-center ">
                     <>{!request.loading && <EmptyDataView minHeight={50} />}</>
                  </div>
               )}
            </>
         )}
      </>
   );
};
export const MemoSearchResultListView = memo(SearchResultListView);

interface SegmentButtonProps {
   keyword: string;
   selectContentType: ContentType;
   searchType: SearchPageType;
   onSelectContentType: (contentType: ContentType) => void;
   onSearchType: (contentType: SearchPageType) => void;
}

export const SegmentButton: React.FC<SegmentButtonProps> = ({
   selectContentType,
   searchType,
   onSelectContentType,
   keyword
}) => {
   const history = useHistory();

   const buttonContentType = [
      ContentType.allContent,
      ContentType.ebook,
      ContentType.magazine,
      ContentType.audioBook,
      ContentType.video
   ];

   const buttonSearchType = [
      SearchPageType.All,
      SearchPageType.Title,
      SearchPageType.Writer,
      SearchPageType.Publisher,
      SearchPageType.Category,
      SearchPageType.Tag
   ];

   const setButtonTitle = (searchType: SearchPageType) => {
      switch (searchType) {
         case SearchPageType.Writer:
            return `นักเขียน`;
         case SearchPageType.Title:
            return `ชื่อเรื่อง`;
         case SearchPageType.Publisher:
            return `สำนักพิมพ์`;
         case SearchPageType.Category:
            return `หมวดหมู่`;
         case SearchPageType.Tag:
            return `แท็ก`;
         default:
            return `ทั้งหมด`;
      }
   };

   const selectType = (
      <Menu>
         {buttonContentType.map((el, index) => (
            <Menu.Item>
               <MenuStyle
                  key={index}
                  onClick={() => {
                     onSelectContentType(el);
                  }}
               >
                  {setContentType(el)}
                  {selectContentType === el && (
                     <SVG src="/images/follow/checked.svg" width="15" height="15" />
                  )}
               </MenuStyle>
            </Menu.Item>
         ))}
      </Menu>
   );

   return (
      <div>
         <div className="segment-container">
            <div className="segment-button-left">
               {buttonSearchType.map((el) => (
                  <button
                     key={el}
                     onClick={() => {
                        history.push(`/search/${keyword}/${el}`);
                     }}
                     className={`mode-button-searchType${searchType === el ? '-active' : ''}`}
                  >
                     {setButtonTitle(el)}
                  </button>
               ))}
            </div>

            <Dropdown overlay={selectType} placement="bottomRight" trigger={['click']}>
               <SVG
                  src="/images/tk-icon/search-filter-icon.svg"
                  width="20"
                  height="20"
                  style={{ cursor: 'pointer' }}
               />
            </Dropdown>
         </div>
      </div>
   );
};
