import axios from 'axios';
import { ContentType } from '../../../interface';
import { HtiResponse } from '../../../interface/request';

export const fetchAutocomplete = async (keyword: string) => {
   const url = `search/autoComplete?keyword=${keyword.trim().toLowerCase()}`;
   const {
      data: { data }
   } = await axios.get<HtiResponse<AutoCompleteResult>>(url);
   return data;
};

export const saveSearchAutoComplete = async (keyword: string) => {
   const {
      data: { status }
   } = await axios.post<HtiResponse<saveSearchAutoCompleteResult>>(
      `search/statistics?keyword=${keyword}`
   );

   return status;
};

export const saveSearchAutoCompletePublic = async (keyword: string, clientPrefix: string) => {
   const {
      data: { status }
   } = await axios.post<HtiResponse<saveSearchAutoCompleteResult>>(
      `/public/${clientPrefix}/search?keyword=${keyword}`
   );

   return status;
};

export type AutoCompleteKey =
   | KeywordResult[]
   | WriterResult[]
   | PublisherResult[]
   | TitleResult[]
   | CategoryResult[];

export interface AutoCompleteResult {
   writers: WriterResult[];
   publishers: PublisherResult[];
   keywords: KeywordResult[];
   titles: TitleResult[];
   categories: CategoryResult[];
}

export interface saveSearchAutoCompleteResult {
   status: boolean;
}

export interface KeywordResult {
   contentType: ContentType;
   keyword: string;
   searchType: SearchType;
}

export interface WriterResult {
   writerUID: string;
   keyword: string;
   contentType: ContentType;
   searchType: SearchType;
}

export interface PublisherResult {
   creatorUID: string;
   keyword: string;
   contentType: ContentType;
   searchType: SearchType;
}

export interface TitleResult {
   contentUID: string;
   keyword: string;
   contentType: ContentType;
   searchType: SearchType;
}

export interface CategoryResult {
   categoryUID: string;
   contentType: ContentType;
   keyword: string;
   searchType: SearchType;
}

export enum SearchType {
   TITLE = 'TITLE',
   PUBLISHER = 'PUBLISHER',
   AUTHOR = 'AUTHOR',
   KEYWORD = 'KEYWORD',
   CATEGORY = 'CATEGORY',
   SUBCATEGORY = 'SUBCATEGORY',
   ISBN = 'ISBN',
   EISBN = 'EISBN',
   PUBLICATIONDATE = 'PUBLICATIONDATE'
}
