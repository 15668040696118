/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react';
import { useLang } from './Metronici18n';
import { IntlProvider } from 'react-intl';
import thMessages from './messages/th';
import enMessages from './messages/en';

const allMessages = {
   th: thMessages,
   en: enMessages
};

export function I18nProvider({ children }) {
   const locale = useLang();
   const messages = allMessages[locale];

   return (
      <IntlProvider locale={locale} messages={messages}>
         {children}
      </IntlProvider>
   );
}
