import { Row, Col, Space } from 'antd';
import dayjs from 'dayjs';
import { shallowEqual, useSelector } from 'react-redux';
import { HibraryRootState } from '../../../redux/rootReducer';

export const Footer = () => {
   const privacyLink = `/privacyPolicy`;
   const termsLink = `/termsAndCondition`;

   const { darkMode } = useSelector(
      (state: HibraryRootState) => ({
         darkMode: state.darkMode
      }),
      shallowEqual
   );

   const socialIcon = [
      {
         src: '/images/tk-icon/facebook-icon.svg',
         link: 'https://www.facebook.com/tkparkclub'
      },
      {
         src: '/images/tk-icon/twitter-icon.svg',
         link: 'https://twitter.com/TKpark_TH'
      },
      {
         src: '/images/tk-icon/line-icon.png',
         link: 'https://lin.ee/mcSA5cW'
      },
      {
         src: '/images/tk-icon/youtube-icon.svg',
         link: 'https://www.youtube.com/tkparkchannel'
      },
      {
         src: '/images/tk-icon/instragram-icon.svg',
         link: 'https://www.instagram.com/tkpark_th/'
      }
   ];

   return (
      <div className="footer" style={darkMode.darkMode ? { background: '#1c1c1c' } : {}}>
         <div
            className="topFooter top-footer-row"
            style={darkMode.darkMode ? { background: '#1c1c1c' } : {}}
         >
            <div>
               <img src="/images/tk-icon/tk-logo-white.svg" />
            </div>
            <div>
               <p className="companyName" id="companyNameRef">
                  สถาบันอุทยานการเรียนรู้
               </p>
               <p>สังกัดสำนักงานบริหาร และพัฒนาองค์ความรู้ (องค์การมหาชน)</p>
               <div>
                  <Space size={32}>
                     <a href="https://www.tkpark.or.th/tha/page/story">เกี่ยวกับเรา</a>
                     <a href="https://www.tkpark.or.th/tha/faq">คำถามที่พบบ่อย</a>
                     <a href="https://www.tkpark.or.th/tha/page/contact_us">ติดต่อเรา</a>
                  </Space>
               </div>
            </div>
         </div>
         <div className="bottomFooter">
            <Row className="inner-bottom-footer">
               <Col className="bottomFooter-col">
                  <Space size={10}>
                     {socialIcon.map((el, index) => (
                        <a href={el.link} key={index}>
                           <img src={el.src} />
                        </a>
                     ))}
                  </Space>
               </Col>
               <Col className="bottomFooter-col">
                  <Space>
                     <span>Copyright ©{dayjs().get('year')} All Right Reserved.</span>
                     <span> | </span>
                     <span>version {process.env.REACT_APP_VERSION}</span>
                  </Space>
               </Col>
               <Col className="bottomFooter-col">
                  <Space size={16}>
                     <a href={termsLink}>เงื่อนไขและข้อตกลง</a>
                     <a href={privacyLink}>นโยบายคุ้มครองข้อมูลส่วนบุคคล</a>
                  </Space>
               </Col>
            </Row>
         </div>
      </div>
   );
};

export default Footer;
