import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ContentType } from '../../../../interface';
import { bookShelfContent } from '../../../../interface/bookShelf';
import { MemoReadingNowCover } from './contentCover';

interface ReadingNowProps {
    contents: bookShelfContent[];
    onReturnContent?: (licenseUID: string, contentType: ContentType) => void;
    addReadingNow?: (license: bookShelfContent, contentType: ContentType) => void;
    loadingUID: string;
}

const ReadingNow: React.FC<ReadingNowProps> = ({
    contents,
    onReturnContent,
    addReadingNow,
    loadingUID
}) => {
    const selectProgression = (el: bookShelfContent) => {
        switch (el.contentType) {
            case ContentType.video:
                if (el.watchProgress) {
                    return Math.floor(el.watchProgress.lastProgress * 100);
                }
                break;
            case ContentType.audioBook:
                if (el.listenPercent) {
                    return Math.floor(el.listenPercent!);
                }
                break;
            default:
                if (el.locator && el.locator.locations) {
                    return Math.floor(el.locator.locations.totalProgression * 100);
                }
        }
    };

    return (
        <div className="reading-now">
            <Swiper
                slidesPerView={'auto'}
                spaceBetween={16}
                freeMode={true}
                pagination={{
                    dynamicBullets: true
                }}
            >
                {contents.map((el) => (
                    <SwiperSlide>
                        <MemoReadingNowCover
                            keyUID={el.licenseUID}
                            content={el}
                            onReturnContent={onReturnContent}
                            totalProgression={selectProgression(el)}
                            addReadingNow={addReadingNow}
                            loadingUID={loadingUID}
                        />
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
};

export default ReadingNow;
