import { message } from 'antd';
import { osVersion, osName, mobileModel, browserName } from 'react-device-detect';
import { HtiErrorResponse } from '../interface';
import { createBrowserHistory } from 'history';

import { publicIpv4 } from 'public-ip';
import J from '../../package.json';
import axios from 'axios';

export const ip = publicIpv4();
let requestMiddleware: any;
let responseMiddleware: any;
const history = createBrowserHistory();

export const setupAxiosByToken = (
   authToken?: string,
   BASE_URL: string = process.env.REACT_APP_API_ENDPOINT!
) => {
   const requestInterceptors = async (config: any) => {
      if (config.url && !config.url.match(/^(https|http)/)) {
         config.url = BASE_URL + config.url;
      }
      const headers = setUpHeader();
      config.headers = headers;
      if (authToken && authToken !== '') {
         config.headers.Authorization = `Bearer ${authToken}`;
      } else {
         config.headers.Authorization = `Bearer ${localStorage.getItem(`guest`)}`;
      }
      return config;
   };

   const responseInterceptors = (response: any) => {
      return response;
   };

   if (requestMiddleware !== undefined) {
      axios.interceptors.request.eject(requestMiddleware);
   }

   if (responseMiddleware !== undefined) {
      axios.interceptors.response.eject(responseMiddleware);
   }

   requestMiddleware = axios.interceptors.request.use(requestInterceptors, (err) => {
      return Promise.reject(err);
   });

   responseMiddleware = axios.interceptors.response.use(responseInterceptors, (err) => {
      if (err && err.response && err.response.data) {
         if (err.response.config) {
            errorHandler(err.response.data);
         }
      }
      return Promise.reject(err);
   });
};

export const setupAxios = (
   store: any,
   BASE_URL: string = process.env.REACT_APP_API_ENDPOINT ?? ''
) => {
   const { auth } = store.getState();
   const userToken = auth && auth.authToken ? auth.authToken : undefined;
   setupAxiosByToken(userToken, BASE_URL);
};

const setUpHeader = () => {
   return {
      appversion: J.version,
      deviceuid: 'TKP.' + localStorage.getItem('deviceuid'),
      devicemodel: mobileModel === 'none' ? browserName : mobileModel,
      devicename: osName,
      osversion: osVersion,
      appname: 'TKP_WEB',
      appid: 'TKP.WEB',
      os: osName,
      'x-accept-version': process.env.REACT_APP_API_VERSION
   };
};

const errorHandler = (error: HtiErrorResponse) => {
   if (!error.status) {
      switch (error.errorCode) {
         case '012':
         case '009':
         case '004':
         case '063':
         case '905':
         case '911':
         case '912':
         case '915':
         case '926':
         case '6004':
         case '929':
         case '4449':
         case '8001':
         case 'invalid signature': {
            break;
         }
         case undefined: {
            message.error('พบความผิดพลาดของระบบ');
            break;
         }
         default: {
            if (!['/bookshelf', '/profile'].includes(history.location.pathname)) {
               message.error(error.message);
            }
            break;
         }
      }
   }
};
