import React from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import { ContentType, RentalStatus } from '../../../interface';

export const StoreTab = () => {
   const intl = useIntl();
   const history = useHistory();
   const location = useLocation();

   const tabData = [
      {
         title: intl.formatMessage({
            id: 'TAB.BOOK'
         }),
         path: '/'
      },
      {
         title: intl.formatMessage({
            id: 'TAB.MAGAZINE'
         }),
         path: '/magazine'
      },
      {
         title: intl.formatMessage({
            id: 'TAB.AUDIOBOOK'
         }),
         path: '/audioBook'
      },
      {
         title: intl.formatMessage({
            id: 'TAB.VIDEO'
         }),
         path: '/video'
      }
   ];

   return (
      <>
         {tabData.map((e) => {
            return (
               <div
                  key={e.path}
                  onClick={() => history.push(e.path)}
                  className={
                     location.pathname === e.path ? 'btn-active' : 'btn'
                  }
               >
                  {e.title}
               </div>
            );
         })}
      </>
   );
};

export const CategoryTab = () => {
   const intl = useIntl();
   const history = useHistory();
   const location = useLocation();

   const tabData = [
      {
         title: intl.formatMessage({
            id: 'TAB.BOOK'
         }),
         path: `/${ContentType.ebook}/categories/all`
      },
      {
         title: intl.formatMessage({
            id: 'TAB.MAGAZINE'
         }),
         path: `/${ContentType.magazine}/categories/all`
      },
      {
         title: intl.formatMessage({
            id: 'TAB.AUDIOBOOK'
         }),
         path: `/${ContentType.audioBook}/categories/all`
      },
      {
         title: intl.formatMessage({
            id: 'TAB.VIDEO'
         }),
         path: `/${ContentType.video}/categories/all`
      }
   ];

   return (
      <>
         {tabData.map((e) => {
            return (
               <div
                  key={e.path}
                  onClick={() => history.push(e.path)}
                  className={
                     location.pathname === e.path ? 'btn-active' : 'btn'
                  }
               >
                  {e.title}
               </div>
            );
         })}
      </>
   );
};

export const BookShelfTab = () => {
   const history = useHistory();
   const location = useLocation();

   const bookShelfMenu = [
      {
         title: `ยืม`,
         path: `/${RentalStatus.Rented}`
      },
      {
         title: `จอง`,
         path: `/${RentalStatus.WaitingList}`
      },
      {
         title: `ประวัติ`,
         path: `/${RentalStatus.Returned}`
      }
   ];

   return (
      <>
         {bookShelfMenu.map((e) => (
            <div
               key={e.path}
               onClick={() => history.push(e.path)}
               className={location.pathname === e.path ? 'btn-active' : 'btn'}
            >
               {e.title}
            </div>
         ))}
      </>
   );
};

export const BookShelfTabWithoutLogin = () => {
   const history = useHistory();
   const location = useLocation();

   const bookShelfMenu = [
      {
         title: `ยืม`,
         path: `/${RentalStatus.Rented}`
      },
      {
         title: `ประวัติ`,
         path: `/${RentalStatus.Returned}`
      }
   ];

   return (
      <>
         {bookShelfMenu.map((e) => (
            <div
               key={e.path}
               onClick={() => history.push(e.path)}
               className={location.pathname === e.path ? 'btn-active' : 'btn'}
            >
               {e.title}
            </div>
         ))}
      </>
   );
};
