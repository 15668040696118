import dayjs from 'dayjs';
import { useState, memo, useEffect } from 'react';
import { Device } from '../../../interface/device';
import { getDeviceList, removeDeviceList } from '../../redux/device';
import { useDispatch } from 'react-redux';
import { Divider, message, Modal } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { ComponentLoading } from '../../customUI/loading';
import { action } from '../../redux/request';
import './index.scss';

type RemoveDeviceCb = (index: number, DeviceName: string) => void;

interface ManageDeviceProps {
    devices?: Device[];
    memberUID?: string;
}

export const ManageDevice: React.FC<ManageDeviceProps> = ({ devices, memberUID }) => {
    const pageId = 'ManageDevice';
    const dispatch = useDispatch();
    const [memberID, setMemberID] = useState<string>();
    const [deviceList, setDeviceList] = useState<Device[]>(devices!);
    const [deleteDeviceUiD, setDeleteDeviceUiD] = useState<string>();

    const fetchData = async () => {
        try {
            dispatch(action.setProcess(true, pageId));
            const data = await getDeviceList();
            data!.devices = data!.devices.sort((a, b) => b.regisDate - a.regisDate);
            setMemberID(data?.memberID);
            setDeviceList(data?.devices ?? []);
            dispatch(action.setProcess(false, pageId));
        } catch (error) {
            dispatch(action.setProcess(false, pageId));
        }
    };

    const removeDevice = async (listIndex: number) => {
        const getDevice = deviceList![listIndex];
        setDeleteDeviceUiD(getDevice?.deviceuid);
        try {
            if (memberUID) {
                await removeDeviceList(getDevice!.deviceToken, memberUID!);
            } else {
                await removeDeviceList(getDevice!.deviceToken, memberID!);
            }
            const newDeviceList = deviceList!.filter((el, index) => listIndex !== index);
            setDeviceList(newDeviceList);
            setDeleteDeviceUiD(undefined);
            message.success('ลบอุปกรณ์เรียบร้อยแล้ว');
        } catch (error) {
            message.success(JSON.stringify(error));
            setDeleteDeviceUiD(undefined);
        }
    };

    const onRemoveDevice = (index: number, deviceName: string) => {
        Modal.confirm({
            title: `ยืนยันการลบ`,
            content: `คุณต้องการลบอุปกรณ์ ${deviceName}`,
            okText: 'ยืนยัน',
            cancelText: 'ยกเลิก',
            onOk: () => {
                removeDevice(index);
            }
        });
    };
    useEffect(() => {
        if (deviceList === undefined) {
            fetchData();
        }
    }, []);

    useEffect(() => {
        console.log('render <ManageDevice/>');
    });

    return (
        <div className="device-list">
            {deviceList?.map((el, index) => {
                return (
                    <MemoDeviceItem
                        key={index}
                        device={el}
                        onRemoveDevice={onRemoveDevice}
                        index={index}
                        isProcessing={el.deviceuid === deleteDeviceUiD ? true : false}
                    />
                );
            })}
            <ComponentLoading id={pageId} />
        </div>
    );
};

interface DeviceItemProps {
    onRemoveDevice: RemoveDeviceCb;
    device: Device;
    index: number;
    isProcessing: boolean;
}

const DeviceItem = (props: DeviceItemProps) => {
    useEffect(() => {
        console.log('<DeviceItem/>');
    });
    return (
        <>
            <div className="device-item fadeIn">
                <div className="device-information">
                    <h3>{props.device.displayName}</h3>
                    <p>
                        {props.device.isDevice
                            ? 'อุปกรณ์นี้'
                            : `ลงชื่อเข้าใช้งานล่าสุด : ${dayjs
                                  .unix(props.device.regisDate)
                                  .format('DD/MM/YYYY HH:mm')}`}
                    </p>
                </div>
                <div>
                    {props.device.isDevice ? null : (
                        <button
                            onClick={() => {
                                if (!props.isProcessing) {
                                    props.onRemoveDevice(
                                        props.index,
                                        `${props.device.devicemodel} ${props.device.os}  ${props.device.osversion}`
                                    );
                                }
                            }}
                        >
                            {!props.isProcessing ? (
                                <img src="/images/tk-icon/trash-icon.svg" alt="trash" />
                            ) : (
                                <LoadingOutlined style={{ fontSize: 24 }} />
                            )}
                        </button>
                    )}
                </div>
            </div>
            <Divider className="divider" />
        </>
    );
};
export const MemoDeviceItem = memo(DeviceItem);
