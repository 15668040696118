import Cookies from 'js-cookie';
import dayjs from 'dayjs';

export const setCookie = (name: string, value: string, expired: number) => {
  Cookies.set(name, value, {
    expires: dayjs().add(expired, 'd').unix(),
    path: '/',
    domain: `.${process.env.REACT_APP_DOMAIN ?? 'hibrary.me'}`
  });
};
export const eraseCookie = (name: string) => {
  Cookies.remove(name, { path: '/', domain: `.${process.env.REACT_APP_DOMAIN ?? 'hibrary.me'}`});
};

export const getCookie = (name: string) => {
  return Cookies.get(name);
};

export const getJSONCookie = (name: string) => {
  return Cookies.getJSON(name);
};
