import axios from 'axios';
import { ContentType, HtiResponse } from '../../../interface';

export interface GetFollowResponse {
   writer: Writer;
   publisher: Publisher;
   category: Category;
   subcategory: Subcategory;
}

interface Subcategory {
   name: string;
   subcatUID: string;
   followStatus: boolean;
   followKey: FollowType;
}

interface Category {
   name: string;
   catUID: string;
   followStatus: boolean;
   followKey: FollowType;
}

interface Publisher {
   name: string;
   publisherUID: string;
   followStatus: boolean;
   followKey: FollowType;
}

interface Writer {
   name: string;
   writerUID: string;
   followStatus: boolean;
   followKey: FollowType;
}

export enum FollowType {
   Category = 'category',
   SubCategory = 'subcategory',
   Publisher = 'publisher',
   Writer = 'writer'
}
export const getFollow = async (contentUID: string, contentType: ContentType) => {
   const path = `license/follow/${contentType}/${contentUID}`;
   const {
      data: { data }
   } = await axios.get<HtiResponse<GetFollowResponse>>(path);
   return data;
};

export const addFollow = async (followType: FollowType, uid: string, subUID?: string) => {
   const path = `license/follow`;
   const followPayload = {};
   switch (followType) {
      case FollowType.SubCategory:
         Object.assign(followPayload, { catUID: uid, subcatUID: subUID });
         break;
      case FollowType.Category:
         Object.assign(followPayload, { catUID: uid });
         break;
      case FollowType.Publisher:
         Object.assign(followPayload, { publisherUID: uid });
         break;
      case FollowType.Writer:
         Object.assign(followPayload, { writerUID: uid });
         break;
   }
   const {
      data: { data }
   } = await axios.post<HtiResponse<any>>(path, followPayload);
   return data;
};

export const unFollow = async (followType: FollowType, uid: string, subUID?: string) => {
   const path = `license/follow`;
   const followPayload = {};
   switch (followType) {
      case FollowType.SubCategory:
         Object.assign(followPayload, { catUID: uid, subcatUID: subUID });
         break;
      case FollowType.Category:
         Object.assign(followPayload, { catUID: uid });
         break;
      case FollowType.Publisher:
         Object.assign(followPayload, { publisherUID: uid });
         break;
      case FollowType.Writer:
         Object.assign(followPayload, { writerUID: uid });
         break;
   }
   const {
      data: { data }
   } = await axios.delete<HtiResponse<any>>(path, { data: followPayload });
   return data;
};
