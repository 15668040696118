import { useState } from 'react';
import * as model from '../redux/store/storeModel';
import { ContentMetaData } from '../../interface';
import { rentEbook } from '../redux/store/storeCrud';
import { Button, Modal } from 'antd';

export interface SampleBtnParam {
   content: ContentMetaData;
}

const SampleBtn = (props: SampleBtnParam) => {
   const content = props.content;
   const [sampleStatus, setSampleStatus] = useState<boolean>(content.licenseSampleStatus);
   const [loading, setLoading] = useState(false);

   const SampleBtnDidClick = (content: ContentMetaData) => {
      Modal.confirm({
         title: 'คุณต้องการดาวน์โหลดตัวอย่างหรือไม่',
         content: content.title,
         okText: 'ยืม',
         cancelText: 'ยกเลิก',
         onOk: () => {
            SampleAction(content);
         }
      });
   };

   const SampleAction = (content: ContentMetaData) => {
      rentEbook(
         content.contentUID,
         content.contentType,
         model.RentalStatus.Rent,
         model.LicenseType.sample
      )
         ?.then((response: any) => {
            setLoading(true);
            if (response.data && response.data.status === true) {
               const modal = Modal.success({});
               modal.update({
                  title: 'เพิ่มสื่อตัวอย่างเรียบร้อยแล้ว',
                  okText: 'ตกลง'
               });
               setLoading(false);
               setSampleStatus(true);
               setTimeout(() => {
                  modal.destroy();
               }, 3000);
            }
         })
         .catch((err: any) => {
            if (err) {
               Modal.info({
                  title: err.response && err.response.data && err.response.data.message,
                  okText: 'ตกลง'
               });
            }
         });
   };

   return (
      <>
         <Button
            type="primary"
            shape="round"
            onClick={() => {
               SampleBtnDidClick(content);
               // if (authen.isLogin) {
               // } else {
               //   dispatch(showLogin(true));
               // }
            }}
            className="grey7-background-button"
            style={{ fontWeight: '700' }}
            disabled={sampleStatus}
            loading={loading}
         >
            ตัวอย่าง
         </Button>
      </>
   );
};

export default SampleBtn;
