import { Button, Space } from 'antd';
import React from 'react';
import { getDynamicLinkPath } from '../../redux/utilities';

const OpenAppTab = () => {
   return (
      <div className="open-app-tab">
         <Space size={16}>
            <img src="/images/tk-icon/app-tk-read-icon.png" />

            <Space size={0} direction="vertical">
               <span className="header">TK Read</span>
               <span className="expand-text">เปิดอ่านในแอป "TK Read"</span>
            </Space>
         </Space>

         <Button
            type="primary"
            shape="round"
            href={getDynamicLinkPath(window.location.href)}
         >
            เปิด
         </Button>
      </div>
   );
};

export default OpenAppTab;
