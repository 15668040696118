import { Button, Skeleton, Space } from 'antd';
import React, { memo, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { MemoSearchResultListView, SearchPageType } from '.';
import {
   ContentType,
   IgnoreContentTypeResponse,
   ListContentWriterResponse,
   SearchPublisherType,
   SearchWriterType
} from '../../../../interface';
import { HibraryRootState } from '../../../../redux/rootReducer';
import { EmptyDataView } from '../../../components/nodata';
import { showConfirmLogin } from '../../../customUI/RentButton';
import { addFollow, FollowType, unFollow } from '../../../redux/follow';
import { action } from '../../../redux/request';
import {
   getSearchPublisher,
   getSearchWriter,
   fetchWriterIgnoreType,
   fetchPublisherIgnoreType,
   getContentListWriter,
   getContentListPublisher
} from '../../../redux/store/storeCrud';
import Pattern4 from '../../home/ebook/pattern-4';
import VideoPattern2 from '../../home/video/videoPattern-2';
import '../../profile/index.scss';

interface SearchWriterProps {
   pageId?: string;
   contents?: SearchWriterType[] | SearchPublisherType[];
   searchType: SearchPageType;
   currentSearchType?: SearchPageType;
   selectContentType?: ContentType;
   keyword: string;
   title: string;
   publisherUID?: string;
   writerUID?: string;
   contentType?: ContentType;
   onUpdateFollow?: (index: number, followType: SearchPageType) => void;
   key?: string;
}

const SearchWriterMainPage: React.FC<SearchWriterProps> = ({
   pageId,
   searchType,
   selectContentType,
   keyword,
   title,
   publisherUID,
   writerUID,
   contentType
}) => {
   const renderWritePage = () => {
      if (writerUID) {
         if (contentType) {
            return (
               <MemoContentListView
                  pageId={pageId!}
                  contentType={contentType}
                  writerUID={writerUID}
               />
            );
         } else {
            return (
               <MemoContentSectionView
                  pageId={pageId!}
                  searchType={searchType}
                  writerUID={writerUID}
               />
            );
         }
      } else if (publisherUID) {
         if (contentType) {
            return (
               <MemoContentListView
                  pageId={pageId!}
                  contentType={contentType}
                  publisherUID={publisherUID}
               />
            );
         } else {
            return (
               <MemoContentSectionView
                  pageId={pageId!}
                  searchType={searchType}
                  publisherUID={publisherUID}
               />
            );
         }
      } else if (searchType === SearchPageType.Writer) {
         return (
            <MemoSearchWriterView
               title={title}
               pageId={pageId}
               keyword={keyword!}
               selectContentType={selectContentType}
               searchType={searchType}
               contentType={contentType}
               key={`writer`}
            />
         );
      } else {
         return (
            <MemoSearchWriterView
               title={title}
               pageId={pageId}
               keyword={keyword!}
               selectContentType={selectContentType}
               searchType={searchType}
               contentType={contentType}
               key={`publisher`}
            />
         );
      }
   };

   return <div>{renderWritePage()}</div>;
};

export default SearchWriterMainPage;

const SearchWriterView: React.FC<SearchWriterProps> = ({
   pageId,
   contents,
   searchType,
   selectContentType,
   keyword,
   title,
   onUpdateFollow,
   currentSearchType,
   key
}) => {
   const dispatch = useDispatch();
   const history = useHistory();
   const [followList, setFollowList] = useState<SearchWriterType[] | SearchPublisherType[]>();
   const [afterUnFollow, setAfterUnFollow] = useState<string[]>([]);
   const [buttonLoading, setButtonLoading] = useState<number>();

   const { request, authState } = useSelector(
      (state: HibraryRootState) => ({
         request: state.fetch,
         authState: state.auth
      }),
      shallowEqual
   );

   useEffect(() => {
      if (searchType === SearchPageType.Writer) {
         fetchWriter();
      } else if (searchType === SearchPageType.Publisher) {
         fetchPublisher();
      }
   }, [searchType, selectContentType]);

   const fetchWriter = async () => {
      try {
         dispatch(action.setProcess(true, pageId));
         const response = await getSearchWriter(selectContentType!, keyword?.trim());
         setFollowList((response?.writers as SearchWriterType[]) ?? []);
         dispatch(action.setProcess(false, pageId));
      } catch (error) {
         dispatch(action.setProcess(false, pageId));
      }
   };

   const fetchPublisher = async () => {
      try {
         dispatch(action.setProcess(true, pageId));
         const response = await getSearchPublisher(selectContentType!, keyword?.trim());
         setFollowList((response?.publishers as SearchPublisherType[]) ?? []);
         dispatch(action.setProcess(false, pageId));
      } catch (error) {
         dispatch(action.setProcess(false, pageId));
      }
   };

   const onUnFollow = async (index: number) => {
      try {
         if (searchType === SearchPageType.Writer || currentSearchType === SearchPageType.Writer) {
            if (contents && onUpdateFollow) {
               const current = contents![index] as SearchWriterType;
               setButtonLoading(index);
               await unFollow(FollowType.Writer, current.writerUID);
               setAfterUnFollow([...afterUnFollow, current.writerUID]);
            } else {
               const current = followList![index] as SearchWriterType;
               setButtonLoading(index);
               await unFollow(FollowType.Writer, current.writerUID);
               setAfterUnFollow([...afterUnFollow, current.writerUID]);
            }
         } else {
            if (contents && onUpdateFollow) {
               const current = contents![index] as SearchPublisherType;
               setButtonLoading(index);
               await unFollow(FollowType.Publisher, current.publisherUID);
               setAfterUnFollow([...afterUnFollow, current.publisherUID]);
            } else {
               const current = followList![index] as SearchPublisherType;
               setButtonLoading(index);
               await unFollow(FollowType.Writer, current.publisherUID);
               setAfterUnFollow([...afterUnFollow, current.publisherUID]);
            }
         }

         setButtonLoading(undefined);
      } catch (error) {
         setButtonLoading(undefined);
      }
   };

   const onAddFollow = async (index: number) => {
      try {
         if (searchType === SearchPageType.Writer || currentSearchType === SearchPageType.Writer) {
            if (contents && onUpdateFollow) {
               const current = contents![index] as SearchWriterType;
               setButtonLoading(index);
               await addFollow(FollowType.Writer, current.writerUID);
               setAfterUnFollow([...afterUnFollow, current.writerUID]);
               const newList = afterUnFollow.filter((e) => e !== current.writerUID);
               setAfterUnFollow(newList);
               onUpdateFollow(index, SearchPageType.Writer);
            } else {
               const current = followList![index] as SearchWriterType;
               setButtonLoading(index);
               await addFollow(FollowType.Writer, current.writerUID);
               setAfterUnFollow([...afterUnFollow, current.writerUID]);
               const newList = afterUnFollow.filter((e) => e !== current.writerUID);
               const newFollowList = JSON.parse(JSON.stringify(followList));
               newFollowList[index] = {
                  ...newFollowList[index],
                  followStatus: !followList![index].followStatus
               };
               setFollowList(newFollowList);
               setAfterUnFollow(newList);
            }
         } else {
            if (contents && onUpdateFollow) {
               const current = contents![index] as SearchPublisherType;
               setButtonLoading(index);
               await addFollow(FollowType.Publisher, current.publisherUID);
               setAfterUnFollow([...afterUnFollow, current.publisherUID]);
               const newList = afterUnFollow.filter((e) => e !== current.publisherUID);
               onUpdateFollow(index, SearchPageType.Publisher);
               setAfterUnFollow(newList);
            } else {
               const current = followList![index] as SearchPublisherType;
               setButtonLoading(index);
               await addFollow(FollowType.Publisher, current.publisherUID);
               setAfterUnFollow([...afterUnFollow, current.publisherUID]);
               const newList = afterUnFollow.filter((e) => e !== current.publisherUID);
               const newFollowList = JSON.parse(JSON.stringify(followList));
               newFollowList[index] = {
                  ...newFollowList[index],
                  followStatus: !followList![index].followStatus
               };
               setFollowList(newFollowList);
               setAfterUnFollow(newList);
            }
         }

         setButtonLoading(undefined);
      } catch (error) {
         setButtonLoading(undefined);
      }
   };

   const renderFollowBox = (data: SearchWriterType | SearchPublisherType, index: number) => {
      const publisher = data as SearchPublisherType;
      const writer = data as SearchWriterType;

      const checkBoxClick = () => {
         if (searchType === SearchPageType.Writer) {
            if (writer.contentType) {
               history.push(
                  `/search/${keyword}/${SearchPageType.Writer}/${writer.contentType}/${writer.writerUID}`
               );
            } else {
               history.push(`/search/${keyword}/${SearchPageType.Writer}/${writer.writerUID}`);
            }
         } else if (searchType === SearchPageType.Publisher) {
            if (publisher.contentType) {
               history.push(
                  `/search/${keyword}/${SearchPageType.Publisher}/${publisher.contentType}/${publisher.publisherUID}`
               );
            } else {
               history.push(
                  `/search/${keyword}/${SearchPageType.Publisher}/${publisher.publisherUID}`
               );
            }
         } else {
            if (writer.writerUID) {
               if (writer.contentType) {
                  history.push(
                     `/search/${keyword}/${SearchPageType.Writer}/${writer.contentType}/${writer.writerUID}`
                  );
               } else {
                  history.push(`/search/${keyword}/${SearchPageType.Writer}/${writer.writerUID}`);
               }
            } else if (publisher.publisherUID) {
               if (publisher.contentType) {
                  history.push(
                     `/search/${keyword}/${SearchPageType.Publisher}/${publisher.contentType}/${publisher.publisherUID}`
                  );
               } else {
                  history.push(
                     `/search/${keyword}/${SearchPageType.Publisher}/${publisher.publisherUID}`
                  );
               }
            }
         }
      };

      return (
         <div className="follow-box" key={index}>
            {writer.writerUID ? (
               <>
                  <Space size={10}>
                     {writer.image ? <img src={writer.image} alt="cover" /> : <Skeleton.Image />}
                     <span
                        className="name"
                        onClick={() => {
                           setFollowList(undefined);
                           checkBoxClick();
                        }}
                     >
                        {writer.writerName}
                     </span>
                  </Space>
                  {!writer.followStatus || afterUnFollow?.includes(writer.writerUID) ? (
                     <Button
                        size="middle"
                        shape="round"
                        type="ghost"
                        style={{ width: '112px' }}
                        className="secondary-background-button"
                        loading={buttonLoading === index}
                        onClick={() => {
                           authState.isLogin ? onAddFollow(index) : showConfirmLogin();
                        }}
                     >
                        ติดตาม
                     </Button>
                  ) : (
                     <Button
                        size="middle"
                        shape="round"
                        type="ghost"
                        className="secondary-outline-button"
                        loading={buttonLoading === index}
                        onClick={() => {
                           authState.isLogin ? onUnFollow(index) : showConfirmLogin();
                        }}
                     >
                        กำลังติดตาม
                     </Button>
                  )}
               </>
            ) : (
               <>
                  <Space size={10}>
                     {publisher.publisherImage ? (
                        <img src={publisher.publisherImage} alt="cover" />
                     ) : (
                        <Skeleton.Image />
                     )}
                     <span
                        className="name"
                        onClick={() => {
                           setFollowList(undefined);
                           checkBoxClick();
                        }}
                     >
                        {publisher.publisherName}
                     </span>
                  </Space>
                  {!publisher.followStatus || afterUnFollow?.includes(publisher.publisherUID) ? (
                     <Button
                        size="middle"
                        shape="round"
                        type="ghost"
                        style={{ width: '112px' }}
                        className="secondary-background-button"
                        loading={buttonLoading === index}
                        onClick={() => {
                           authState.isLogin ? onAddFollow(index) : showConfirmLogin();
                        }}
                     >
                        ติดตาม
                     </Button>
                  ) : (
                     <Button
                        size="middle"
                        shape="round"
                        type="ghost"
                        className="secondary-outline-button"
                        loading={buttonLoading === index}
                        onClick={() => {
                           authState.isLogin ? onUnFollow(index) : showConfirmLogin();
                        }}
                     >
                        กำลังติดตาม
                     </Button>
                  )}
               </>
            )}
         </div>
      );
   };

   return (
      <div className="follow-view" key={key}>
         <div
            className="title-section margin-bottom-10"
            style={searchType !== SearchPageType.All ? { justifyContent: 'center' } : {}}
         >
            <h2>{title}</h2>
         </div>

         <div className="search-writer-list fadeIn">
            {contents ? (
               <>{contents.map((el, index) => renderFollowBox(el, index))}</>
            ) : (
               <>{followList?.map((el, index) => renderFollowBox(el, index))}</>
            )}
         </div>

         {followList && followList.length < 1 && (
            <>{!request.loading && <EmptyDataView minHeight={60} />}</>
         )}
      </div>
   );
};
export const MemoSearchWriterView = memo(SearchWriterView);

interface SearchWriterSectionProps {
   pageId: string;
   searchType: SearchPageType;
   publisherUID?: string;
   writerUID?: string;
}

const SearchWriterSection: React.FC<SearchWriterSectionProps> = ({
   pageId,
   searchType,
   publisherUID,
   writerUID
}) => {
   const dispatch = useDispatch();
   const [contentList, setContentList] = useState<IgnoreContentTypeResponse>();

   const { request } = useSelector(
      (state: HibraryRootState) => ({
         request: state.fetch
      }),
      shallowEqual
   );

   useEffect(() => {
      if (writerUID) {
         fetchSearchWriter();
      } else {
         fetchSearchPublisher();
      }
   }, []);

   const fetchSearchWriter = async () => {
      try {
         dispatch(action.setProcess(true, pageId));
         const response = await fetchWriterIgnoreType(writerUID!);
         setContentList(response);
         dispatch(action.setProcess(false, pageId));
      } catch (error) {
         dispatch(action.setProcess(false, pageId));
      }
   };

   const fetchSearchPublisher = async () => {
      try {
         dispatch(action.setProcess(true, pageId));
         const response = await fetchPublisherIgnoreType(publisherUID!);
         setContentList(response);
         dispatch(action.setProcess(false, pageId));
      } catch (error) {
         dispatch(action.setProcess(false, pageId));
      }
   };

   const getLink = (contentType: ContentType) => {
      switch (searchType) {
         case SearchPageType.Publisher: {
            return `/${contentType}/publisher/${writerUID}`;
         }
         default: {
            return `/${contentType}/writer/${publisherUID}`;
         }
      }
   };

   return (
      <>
         <h1>{contentList?.title ?? ''}</h1>
         {contentList?.contents?.ebook && (
            <Pattern4
               title="หนังสือ"
               contents={contentList?.contents.ebook}
               path={getLink(ContentType.ebook)}
            />
         )}

         {contentList?.contents?.magazine && (
            <Pattern4
               title="นิตยสาร"
               contents={contentList?.contents.magazine}
               path={getLink(ContentType.magazine)}
            />
         )}

         {contentList?.contents?.audioBook && (
            <Pattern4
               title="หนังสือเสียง"
               contents={contentList?.contents.audioBook}
               path={getLink(ContentType.audioBook)}
            />
         )}

         {contentList?.contents?.video ? (
            <VideoPattern2
               title="วิดีโอ"
               contents={contentList?.contents.video}
               path={getLink(ContentType.video)}
               limit={500}
            />
         ) : null}

         {!contentList?.contents?.ebook &&
            !contentList?.contents?.magazine &&
            !contentList?.contents?.audioBook &&
            !contentList?.contents?.video && (
               <>{!request.loading && <EmptyDataView minHeight={50} />}</>
            )}
      </>
   );
};
const MemoContentSectionView = memo(SearchWriterSection);

interface SearchWriterListViewProps {
   writerUID?: string;
   publisherUID?: string;
   pageId: string;
   contentType: ContentType;
}

const SearchWriterContentList: React.FC<SearchWriterListViewProps> = ({
   writerUID,
   publisherUID,
   pageId,
   contentType
}) => {
   const dispatch = useDispatch();
   const location = useLocation();
   const [contentList, setContentList] = useState<ListContentWriterResponse>();
   const [title, setTitle] = useState<string>();

   const { environment, request } = useSelector(
      (state: HibraryRootState) => ({
         environment: state.environment,
         request: state.fetch
      }),
      shallowEqual
   );

   useEffect(() => {
      if (publisherUID) {
         fetchListContentByPublisher(false);
      } else {
         fetchListContentByWriter(false);
      }
   }, [location]);

   useEffect(() => {
      if (environment.endOfScreen) {
         if (contentList?.lastEvaluatedKey !== undefined && !request.loading) {
            if (publisherUID) {
               fetchListContentByPublisher(true);
            } else {
               fetchListContentByWriter(true);
            }
         }
      }
   }, [environment]);

   const fetchListContentByWriter = async (appendData: boolean) => {
      try {
         dispatch(action.setProcess(true, pageId));
         const lastEvaluatedKey = appendData ? contentList?.lastEvaluatedKey : undefined;

         const data = await getContentListWriter(contentType, lastEvaluatedKey!, writerUID!);

         if (appendData) {
            setListModel(data as ListContentWriterResponse, appendData);
         } else {
            setContentList(data as ListContentWriterResponse);
            if (data) {
               setTitle(data.contents[0].writerName[0]);
            }
         }
         dispatch(action.setProcess(false, pageId));
      } catch (error) {
         dispatch(action.setProcess(false, pageId));
      }
   };

   const fetchListContentByPublisher = async (appendData: boolean) => {
      try {
         dispatch(action.setProcess(true, pageId));
         const lastEvaluatedKey = appendData ? contentList?.lastEvaluatedKey : undefined;

         const data = await getContentListPublisher(contentType, lastEvaluatedKey!, publisherUID!);

         if (appendData) {
            setListModel(data as ListContentWriterResponse, appendData);
         } else {
            setContentList(data as ListContentWriterResponse);
            if (data) {
               setTitle(data.contents[0].publisherName);
            }
         }
         dispatch(action.setProcess(false, pageId));
      } catch (error) {
         dispatch(action.setProcess(false, pageId));
      }
   };

   const setListModel = (data: ListContentWriterResponse, appendData: boolean) => {
      const current = contentList as ListContentWriterResponse;
      const currentData = data as ListContentWriterResponse;
      const newArray = appendData ? current.contents : [];

      setContentList({
         contents: newArray.concat(currentData?.contents ?? []),
         lastEvaluatedKey: currentData?.lastEvaluatedKey
      });
   };

   return (
      <>
         {contentList && (
            <MemoSearchResultListView
               contents={contentList?.contents}
               title={title}
               contentType={contentType}
            />
         )}

         {!contentList && <>{!request.loading && <EmptyDataView />}</>}
      </>
   );
};
const MemoContentListView = memo(SearchWriterContentList);
