import { Banner, HomeList } from '../../../interface/home';

export interface HomeContent {
   contents?: HomeList[];
}

export interface HomeState {
   banners?: Banner[];
   ebooks?: HomeContent;
   magazines?: HomeContent;
   audioBooks?: HomeContent;
   videos?: HomeContent;
   isReset?: boolean;
   title?: string;
}

const initialState: HomeState = {
   isReset: false,
   banners: [],
   ebooks: { contents: undefined },
   magazines: { contents: undefined },
   audioBooks: { contents: undefined },
   videos: { contents: undefined },
   title: undefined
};

export enum HomeActionType {
   SetBanner = 'SetBanner[Banner]',
   ResetData = 'ResetData[ResetData]',
   setEbook = 'setEbook[Ebook]',
   setMagazine = 'setEbook[Magazine]',
   setAudioBook = 'setEbook[Audiobook]',
   setVideo = 'setEbook[Video]',
   SetTitle = 'SetTitle[Title]',
   setMainLoading = 'setMainLoading[loading]'
}

export interface HomeAction {
   type: HomeActionType;
   payload?: HomeState;
}

export const reducer = (state = initialState, action: HomeAction): HomeState => {
   switch (action.type) {
      case HomeActionType.SetBanner: {
         return {
            ...state,
            isReset: false,
            banners: action.payload?.banners ?? []
         };
      }
      case HomeActionType.setEbook: {
         return {
            ...state,
            ebooks: action.payload?.ebooks
         };
      }
      case HomeActionType.setMagazine: {
         return {
            ...state,
            magazines: action.payload?.magazines
         };
      }
      case HomeActionType.setAudioBook: {
         return {
            ...state,
            audioBooks: action.payload?.audioBooks
         };
      }
      case HomeActionType.setVideo: {
         return {
            ...state,
            videos: action.payload?.videos
         };
      }

      case HomeActionType.SetTitle: {
         return {
            ...state,
            title: action.payload?.title
         };
      }

      // case HomeActionType.ResetData:
      //   return {
      //     ...state,
      //     homeContent: {
      //       contents: undefined,
      //       categories: []
      //     }
      //   };

      default:
         return state;
   }
};

const setBanner = (banners: Banner[]): HomeAction => {
   return {
      type: HomeActionType.SetBanner,
      payload: { banners }
   };
};

const resetHomeContent = (): HomeAction => {
   return {
      type: HomeActionType.ResetData
   };
};

const setEbook = (content: HomeList[]): HomeAction => {
   return {
      type: HomeActionType.setEbook,
      payload: {
         ebooks: {
            contents: content
         }
      }
   };
};

const setMagazine = (content: HomeList[]): HomeAction => {
   return {
      type: HomeActionType.setMagazine,
      payload: {
         magazines: {
            contents: content
         }
      }
   };
};

const setAudiobook = (content: HomeList[]): HomeAction => {
   return {
      type: HomeActionType.setAudioBook,
      payload: {
         audioBooks: {
            contents: content
         }
      }
   };
};

const setVideo = (content: HomeList[]): HomeAction => {
   return {
      type: HomeActionType.setVideo,
      payload: {
         videos: {
            contents: content
         }
      }
   };
};

const setTitle = (title: string): HomeAction => {
   return {
      type: HomeActionType.SetTitle,
      payload: { title: title }
   };
};

export const action = {
   resetHomeContent,
   setBanner,
   setTitle,
   setEbook,
   setMagazine,
   setAudiobook,
   setVideo
};
