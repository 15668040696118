import { FC, useEffect, useState } from 'react';
import { BreadCrumbView } from '../../components/breadcrumb';
import { Loading } from '../../customUI/loading';
import { Modal, Space } from 'antd';
import { MemberProfileType } from '../base/menuTab';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import SVG from 'react-inlinesvg';
import MemberInfo from './memberInfo';
import Device from './device';
import WishListContent from './wishList';
import FollowView from './followView';
import NotificationPage from './notificationPage';
import DeleteAccountModal from '../../components/modal/deleteAccountModal';
import './index.scss';

interface Params {
   memberProfileType: MemberProfileType;
}

const MemberProfile: FC<Params> = ({ memberProfileType }) => {
   const pageId = 'ProfilePage';
   const history = useHistory();

   const [selectMenu, setSelectMenu] = useState(memberProfileType);
   const [deleteAccountModal, setDeleteAccountModal] = useState(false);

   useEffect(() => {
      setSelectMenu(memberProfileType);
   }, [memberProfileType]);

   const showConfirmSignOut = () => {
      Modal.confirm({
         title: 'ต้องการลงชื่อออกจากระบบ',
         okText: 'ออกจากระบบ',
         cancelText: 'ยกเลิก',
         onOk: () => {
            history.push('/logout');
         }
      });
   };

   const renderMenu = () => {
      const menu = [
         {
            title: 'ข้อมูลส่วนตัว',
            icon: '/images/tk-icon/memberInfo-icon.svg',
            key: MemberProfileType.MemberData
         },
         {
            title: 'Wishlist',
            icon: '/images/tk-icon/notification-icon.svg',
            key: MemberProfileType.WishList
         },
         {
            title: 'การติดตาม',
            icon: '/images/tk-icon/follow-icon.svg',
            key: MemberProfileType.Follow
         },
         {
            title: 'จัดการอุปกรณ์',
            icon: '/images/tk-icon/device-icon.svg',
            key: MemberProfileType.ManageDevice
         },
         {
            title: `ลบบัญชี`,
            icon: `/images/tk-icon/delete-account-icon.svg`,
            key: MemberProfileType.DeleteAccount
         },
         {
            title: 'ออกจากระบบ',
            icon: '/images/tk-icon/signOut-icon.svg',
            key: MemberProfileType.Logout
         }
      ];

      return (
         <>
            {menu.map((e) => {
               if (e.key === MemberProfileType.DeleteAccount) {
                  return (
                     <Space
                        size={16}
                        key={e.key}
                        align="center"
                        onClick={() => setDeleteAccountModal(true)}
                        className={selectMenu === e.key ? 'menu menu-active' : 'menu'}
                     >
                        <SVG src={`${e.icon}`} />
                        <span>{e.title}</span>
                     </Space>
                  );
               } else if (e.key === MemberProfileType.Logout) {
                  return (
                     <Space
                        size={16}
                        key={e.key}
                        align="center"
                        onClick={() => showConfirmSignOut()}
                        className={selectMenu === e.key ? 'menu menu-active' : 'menu'}
                     >
                        <SVG src={`${e.icon}`} />
                        <span>{e.title}</span>
                     </Space>
                  );
               } else {
                  return (
                     <Space
                        size={16}
                        key={e.key}
                        align="center"
                        onClick={() => history.push(`/memberProfile/${e.key}`)}
                        className={selectMenu === e.key ? 'menu menu-active' : 'menu'}
                     >
                        <SVG src={`${e.icon}`} />
                        <span>{e.title}</span>
                     </Space>
                  );
               }
            })}
         </>
      );
   };

   const setHelmet = () => {
      switch (selectMenu) {
         case MemberProfileType.MemberData:
            return `ข้อมูลส่วนตัว`;
         case MemberProfileType.WishList:
            return `Wishlist`;
         case MemberProfileType.Follow:
            return `การติดตาม`;
         case MemberProfileType.ManageDevice:
            return `จัดการอุปกรณ์`;
         case MemberProfileType.Notification:
            return `การแจ้งเตือน`;
      }
   };

   const renderContent = () => {
      switch (selectMenu) {
         case MemberProfileType.MemberData:
            return <MemberInfo pageId={pageId} />;
         case MemberProfileType.WishList:
            return <WishListContent pageId={pageId} />;
         case MemberProfileType.Follow:
            return <FollowView pageId={pageId} />;
         case MemberProfileType.ManageDevice:
            return <Device />;
         case MemberProfileType.Notification:
            return <NotificationPage pageId={pageId} />;
      }
   };

   return (
      <>
         <Helmet>
            <title>TK Read | {setHelmet()}</title>
         </Helmet>
         <BreadCrumbView />
         <div className="main-page">
            <div className="left-side">{renderMenu()}</div>
            <div className="right-side">
               {renderContent()}
               <Loading id={pageId} absolute={true} />
            </div>
         </div>
         <DeleteAccountModal
            visible={deleteAccountModal}
            onCancel={() => setDeleteAccountModal(false)}
         />
      </>
   );
};
export default MemberProfile;
