import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { ClientInfo } from '../../../interface/client';
import * as theme from '../theme';
import dayjs from 'dayjs';

export interface ClientInfoState extends ClientInfo {
   expireDate: number;
}

const initialState: ClientInfoState = {
   clientName: '',
   logoImages: '',
   companyPrefix: '',
   isPrivateLibrary: false,
   isPublicRegister: true,
   registerDomain: [],
   contactList: undefined,
   theme: theme.normalTheme,
   displayName: '',
   expireDate: dayjs().add(15, 'm').unix()
};

export enum ClientInfoActionType {
   SetClient = 'SetClient[Client]'
}
export interface ClientInfoAction {
   type: ClientInfoActionType;
   payload: ClientInfo;
}
export const reducer = persistReducer(
   {
      storage,
      key: 'hibrary-clientInfo'
   },
   (state: ClientInfoState = initialState, action: ClientInfoAction): ClientInfoState => {
      switch (action.type) {
         case ClientInfoActionType.SetClient:
            return { ...action.payload, expireDate: dayjs().add(15, 'm').unix() };
         default:
            return state;
      }
   }
);
