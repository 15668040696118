import { Alert, Button, Input, Modal } from "antd";
import { useFormik } from "formik";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { deleteAccount } from "../../redux/auth/authCrud";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";

import * as Yup from "yup";

interface DeleteAccountModalProps {
  visible: boolean;
  onCancel: () => void;
}

interface FormPayload {
  password?: string;
}

enum inputType {
  password = "password",
  text = "text",
}

const DeleteAccountModal = (props: DeleteAccountModalProps) => {
  const history = useHistory();
  const [input, setInput] = useState<inputType>(inputType.password);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  const schema = Yup.object().shape({
    password: Yup.string().required("กรุณากรอกรหัสผ่าน"),
  });

  const formik = useFormik({
    initialValues: {
      password: "",
    } as FormPayload,
    validationSchema: schema,
    onSubmit: (value) => {
      onDeleteAccount(value.password!);
    },
  });

  const onDeleteAccount = async (password: string) => {
    try {
      setButtonLoading(true);
      const response = await deleteAccount(password);
      if (response.status) {
        setButtonLoading(false);
        props.onCancel();
        history.push("/logout");
      }
    } catch (error) {
      setButtonLoading(false);
    }
  };

  return (
    <Modal
      title={null}
      footer={null}
      visible={props.visible}
      onCancel={props.onCancel}
      className="delete-account-modal"
    >
      <h2>ยืนยันการลบบัญชี</h2>
      <p className="text">กรอกรหัสผ่านเพื่อยืนยันการลบบัญชี</p>
      <div>
        <label>รหัสผ่าน</label>
        <Input
          id="password"
          name="password"
          autoComplete="new-password"
          type={input}
          value={formik.values.password}
          onChange={formik.handleChange}
          placeholder="กรอกรหัสผ่าน"
          suffix={
            input === inputType.text ? (
              <EyeInvisibleOutlined
                onClick={() => setInput(inputType.password)}
              />
            ) : (
              <EyeOutlined onClick={() => setInput(inputType.text)} />
            )
          }
        />
      </div>

      {formik.errors.password && formik.touched.password && (
        <Alert
          message={formik.errors.password}
          type="error"
          showIcon
          style={{ marginBottom: "1rem" }}
        />
      )}

      <div className="button-contain">
        <Button
          shape="round"
          size="large"
          className="secondary-outline-button"
          onClick={() => {
            props.onCancel();
            formik.resetForm();
          }}
        >
          ยกเลิก
        </Button>
        <Button
          shape="round"
          size="large"
          type="primary"
          danger
          onClick={() => formik.handleSubmit()}
          loading={buttonLoading}
        >
          ลบบัญชี
        </Button>
      </div>
    </Modal>
  );
};

export default DeleteAccountModal;
