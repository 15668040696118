import { Col, Row, Slider } from 'antd';
import { useRef, useState, useEffect, FC } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { HibraryRootState } from '../../../../redux/rootReducer';
import { PlayState, action } from '../../../redux/player';
import { ContentList } from '../../../../interface';
import Hls from 'hls.js';
import SVG from 'react-inlinesvg';
import './index.scss';

interface AudioPlayerProps {
   autoPlaying: boolean;
   playDidChange: (playState: PlayState) => void;
   goBack: () => void;
   goNext: () => void;
   sample: string;
   currentEpisode: ContentList;
}

export const AudioPlayerView: FC<AudioPlayerProps> = ({
   playDidChange,
   goBack,
   goNext,
   sample,
   currentEpisode
}) => {
   const audioPlayerRef = useRef(null);
   const [audioPlayer, setAudioPlayer] = useState<any>();
   const [duration, setDuration] = useState(0);
   const [speedRate, setSpeedRate] = useState(0);

   const dispatch = useDispatch();
   const { player } = useSelector(
      (state: HibraryRootState) => ({
         player: state.player
      }),
      shallowEqual
   );

   useEffect(() => {
      console.log('<PodcastPlayerView/>');
   });

   useEffect(() => {
      if (audioPlayer && player.playlist) {
         switch (player.status) {
            case PlayState.Playing:
               audioPlayer.play();
               break;
            case PlayState.Pause:
               audioPlayer.pause();
               break;
            case PlayState.Stop:
               audioPlayer.pause();
               break;
            default:
               break;
         }
      }
   }, [player]);

   useEffect(() => {
      setAudioPlayer(audioPlayerRef.current);
   }, [audioPlayerRef]);

   useEffect(() => {
      if (audioPlayer) {
         audioPlayer.onloadeddata = () => {
            setDuration(audioPlayer.duration);
         };
         audioPlayer.ontimeupdate = () => {
            dispatch(action.updateCurrentTime(audioPlayer.currentTime));
         };
         //  audioPlayer.onprogress = () => {};
         //  audioPlayer.onwaiting = () => {};
      }
   }, [audioPlayer]);

   useEffect(() => {
      if (audioPlayer)
         audioPlayer.playbackRate = speedRatePreset[speedRate].rate;
   }, [speedRate]);

   useEffect(() => {
      if (player.currentTime! > duration - 1) goNext();
   }, [player.currentTime]);

   useEffect(() => {
      if (speedRate > 0) setSpeedRate(0);
      const timer = setTimeout(() => {
         if (currentEpisode) {
            if (
               currentEpisode.progression &&
               player.chapterUID &&
               audioPlayer
            ) {
               dispatch(
                  action.updateCurrentTime(
                     (currentEpisode.progression?.lastProgress *
                        currentEpisode.mediaLength!) /
                        1000
                  )
               );
               audioPlayer.currentTime =
                  (currentEpisode.progression?.lastProgress *
                     currentEpisode.mediaLength!) /
                  1000;
            }
         }
      }, 800);
      return () => clearTimeout(timer);
   }, [player.chapterUID]);

   const prevDidClick = () => {
      goBack();
   };

   const nextDidClick = () => {
      goNext();
   };

   const speedRateBtnAction = () => {
      if (speedRate < speedRatePreset.length - 1) {
         setSpeedRate(speedRate + 1);
      } else {
         setSpeedRate(0);
      }
   };

   const sliderOnChange = (value: number) => {
      audioPlayer.currentTime = value;
   };

   return (
      <div className="audio-player">
         <div className="play-button-section">
            <h2 style={{ minHeight: '22px' }}>{player.title ?? ' '}</h2>
            <button
               className="player-button background-button play-rate"
               onClick={speedRateBtnAction}
               disabled={false}
               style={{ color: 'black' }}
            >
               {speedRatePreset[speedRate].title}
            </button>
         </div>

         <Row align="middle" justify="space-around">
            <Col span={3}>
               <label className="time">
                  {dateConvert(player.currentTime ?? 0)}
               </label>
            </Col>
            <Col span={16}>
               <Slider
                  className="player-slider"
                  defaultValue={0}
                  value={player.currentTime}
                  tooltipVisible={false}
                  max={duration}
                  min={0}
                  onChange={sliderOnChange}
               />
            </Col>
            <Col span={3}>
               <label className="time">
                  -{dateConvert(duration - (player.currentTime ?? 0))}
               </label>
            </Col>
         </Row>

         <div className="play-button-section">
            <button
               className="player-button"
               disabled={false}
               onClick={() => {
                  nextDidClick();
               }}
            >
               <SVG src="/images/tk-icon/prev-btn.svg" />
            </button>
            <button
               className="player-button"
               style={{ transform: 'scale(1.2)' }}
               onClick={() => {
                  dispatch(action.updateCurrentTime(player.currentTime! - 15));
                  audioPlayer.currentTime = player.currentTime! - 15;
               }}
            >
               <SVG src="/images/tk-icon/decrease-current-time.svg" />
            </button>
            <button
               className="player-button"
               onClick={() => {
                  if (audioPlayer.paused) {
                     playDidChange(PlayState.Playing);
                  } else {
                     playDidChange(PlayState.Pause);
                  }
               }}
               disabled={player.status === PlayState.Init ? false : false}
            >
               <SVG
                  src={
                     player.status === PlayState.Playing
                        ? '/images/player/pause.svg'
                        : '/images/player/play.svg'
                  }
               />
            </button>
            <button
               className="player-button"
               onClick={() => {
                  dispatch(action.updateCurrentTime(player.currentTime! + 15));
                  audioPlayer.currentTime = player.currentTime! + 15;
               }}
            >
               <SVG
                  src="/images/tk-icon/increase-current-time.svg"
                  style={{ transform: 'scale(1.2)' }}
               />
            </button>
            <button
               className="player-button"
               disabled={false}
               onClick={() => {
                  prevDidClick();
               }}
            >
               <SVG src="/images/tk-icon/next-btn.svg" />
            </button>
         </div>

         {player.chapterUID !== 'Sample' ? (
            <audio ref={audioPlayerRef}>
               {player.playlist && player.chapterUID !== '' ? (
                  <HLSSource
                     type="audio/mpeg"
                     source={audioPlayer}
                     src={player.playlist}
                  />
               ) : null}
            </audio>
         ) : (
            <audio ref={audioPlayerRef} src={sample} />
         )}
      </div>
   );
};

const HLSSource = (props: any) => {
   useEffect(() => {
      const hls = new Hls();
      const { src, source } = props;
      if (Hls.isSupported()) {
         hls.loadSource(src);
         hls.attachMedia(source);
      }
   }, [props.source, props.src]);
   return (
      <source src={props.src} type={props.type || 'application/x-mpegURL'} />
   );
};

const speedRatePreset = [
   { title: '1X', rate: 1 },
   { title: '1.5X', rate: 1.5 },
   { title: '2X', rate: 2 },
   { title: '3X', rate: 3 },
   { title: '4X', rate: 4 }
];
const dateConvert = (second: number) => {
   return new Date(second * 1000)
      .toISOString()
      .substr(11, 8)
      .replace(/^00:/, '');
};
