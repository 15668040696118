import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { MemoSearchResultListView, SearchPageType } from '.';
import { ContentMetaData, ContentType, SearchResultResponse } from '../../../../interface';
import { HibraryRootState } from '../../../../redux/rootReducer';
import { EmptyDataView } from '../../../components/nodata';
import { action } from '../../../redux/request';
import { getSearchResult } from '../../../redux/store/storeCrud';
import { MemoSearchAllContent } from './searchAll';

interface SearchTitleProps {
   pageId: string;
   keyword: string;
   selectContentType: ContentType;
   searchType: SearchPageType;
}

const SearchTitle: React.FC<SearchTitleProps> = ({
   pageId,
   keyword,
   searchType,
   selectContentType
}) => {
   const dispatch = useDispatch();

   const [resultList, setResultList] = useState<SearchResultResponse<ContentMetaData[]>>();

   const { request } = useSelector(
      (state: HibraryRootState) => ({
         request: state.fetch
      }),
      shallowEqual
   );

   useEffect(() => {
      fetchContentByTitle();
   }, [searchType, selectContentType]);

   const fetchContentByTitle = async () => {
      try {
         dispatch(action.setProcess(true, pageId));
         const response = await getSearchResult(selectContentType, searchType, keyword.trim());
         setResultList(response);
         dispatch(action.setProcess(false, pageId));
      } catch (error) {
         dispatch(action.setProcess(false, pageId));
      }
   };

   const renderContent = () => {
      switch (selectContentType) {
         case ContentType.allContent:
            return <MemoSearchAllContent contents={resultList!} keyword={keyword} />;
         case ContentType.ebook:
            return (
               <MemoSearchResultListView
                  contents={resultList!.ebooks}
                  contentType={selectContentType}
               />
            );
         case ContentType.audioBook:
            return (
               <MemoSearchResultListView
                  contents={resultList!.audioBooks}
                  contentType={selectContentType}
               />
            );
         case ContentType.magazine:
            return (
               <MemoSearchResultListView
                  contents={resultList!.magazines}
                  contentType={selectContentType}
               />
            );
         case ContentType.video:
            return (
               <MemoSearchResultListView
                  contents={resultList!.videos}
                  contentType={selectContentType}
               />
            );
      }
   };
   return (
      <div>
         {resultList && (
            <>
               <>{renderContent()}</>
               {resultList.ebooks?.length === 0 &&
                  resultList.audioBooks?.length === 0 &&
                  resultList.magazines?.length === 0 &&
                  resultList.videos?.length === 0 && (
                     <>{!request.loading && <EmptyDataView minHeight={60} />}</>
                  )}
            </>
         )}
      </div>
   );
};

export default SearchTitle;
