import { memo, useState } from 'react';
import { Button, Col, Dropdown, Progress, Tooltip } from 'antd';

import { ContentType, LicenseType, RentalStatus } from '../../../../interface';
import { bookShelfContent } from '../../../../interface/bookShelf';
import { Link, useHistory } from 'react-router-dom';
import { changeTimeStampToText } from '../util';

import Lottie from 'lottie-react';
import SVG from 'react-inlinesvg';

import tkLoading from '../../../assets/lotties/tak-loading2.json';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { HibraryRootState } from '../../../../redux/rootReducer';
import { ConfirmModal } from '../content';
import { FetchStatus } from '../../../redux/request';
import { fulfillContent } from '../../../redux/download';
import { rentConfirm } from '../../../redux/rental/action';
import { isAndroid, isIOS, isTablet, osVersion } from 'react-device-detect';
import { convertMonth, getDynamicLinkPath, setContentType } from '../../../redux/utilities';
import {
    getContentDetailRoute,
    getDetailRoute,
    getPublisherContentRoute,
    getWriterContentRoute
} from '../../../routes';
import dayjs from 'dayjs';

interface coverProps {
    content: bookShelfContent;
    keyUID: string;
    totalProgression?: number;
    addReadingNow?: (license: bookShelfContent, contentType: ContentType) => void;
    onReturnContent?: (licenseUID: string, contentType: ContentType) => void;
    loadingUID: string;
}

interface historyProps {
    content: bookShelfContent;
    keyUID: string;
    onReturnContent?: (licenseUID: string) => void;
}

export enum ButtonType {
    Rent = 'rent',
    Cancel = 'cancel'
}

const ContentCover = (props: coverProps) => {
    const [confirmDialog, setConfirmDialog] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();

    const { download } = useSelector(
        (state: HibraryRootState) => ({
            authen: state.auth,
            download:
                state.download.licenseUID === props.content.licenseUID ? state.download : undefined
        }),
        shallowEqual
    );

    const menu = (
        <div className="drop-down-bookShelf">
            <div
                key="return"
                onClick={() => {
                    setConfirmDialog(true);
                }}
                className="bookShelf-dropdown"
            >
                คืน{setContentType(props.content.contentType)}
            </div>
        </div>
    );

    const onOpenContent = (licenseType: LicenseType) => {
        const version = Math.floor(parseFloat(osVersion));

        if (isIOS && !isTablet && version >= 13 && licenseType !== LicenseType.Sample) {
            window.location.href = getDynamicLinkPath(window.location.href);
        } else if (isAndroid && !isTablet && version >= 8 && licenseType !== LicenseType.Sample) {
            window.location.href = getDynamicLinkPath(window.location.href);
        } else if (isTablet && licenseType !== LicenseType.Sample) {
            if ((isIOS && version >= 13) || (isAndroid && version >= 8)) {
                window.location.href = getDynamicLinkPath(window.location.href);
            } else {
                onLoadContent();
            }
        } else {
            onLoadContent();
        }
    };

    const onLoadContent = () => {
        switch (props.content.contentType) {
            case ContentType.video:
            case ContentType.audioBook:
                history.push(`/${props.content.contentType}/${props.content.contentUID}`);
                if (props.addReadingNow) {
                    props.addReadingNow(props.content, props.content.contentType);
                }
                break;
            case ContentType.ebook:
            case ContentType.magazine:
                if (download?.requestStatus !== FetchStatus.Process) {
                    dispatch(
                        fulfillContent(
                            props.content.contentUID,
                            props.content.licenseUID,
                            props.content.contentType,
                            props.content.licenseType
                        )
                    );
                    if (props.addReadingNow) {
                        props.addReadingNow(props.content, props.content.contentType);
                    }
                }
                break;
            default:
                break;
        }
    };

    return (
        <>
            <Col
                key={props.keyUID}
                className={
                    props.content.contentType == ContentType.video
                        ? `cover-col-video fadeIn`
                        : `cover-col fadeIn`
                }
            >
                <div className="content-collection-bookshelf">
                    {props.totalProgression && props.content.licenseType !== LicenseType.Sample ? (
                        <Progress
                            percent={props.totalProgression}
                            showInfo={false}
                            strokeLinecap="square"
                            strokeWidth={5}
                            className="progress-bar"
                        />
                    ) : null}

                    {download?.requestStatus === FetchStatus.Process ||
                    props.loadingUID === props.content.licenseUID ? (
                        <Lottie animationData={tkLoading} className="read-loading" />
                    ) : null}

                    {props.content.licenseType === LicenseType.Sample && (
                        <SVG src="/images/tk-icon/sample-tag.svg" className="sample-tag" />
                    )}

                    {props.content.contentType === ContentType.audioBook && (
                        <SVG
                            src="/images/tk-icon/audiobook-icon.svg"
                            className="audiobook-icon"
                            width="25"
                            height="25"
                        />
                    )}

                    {props.content.contentType === ContentType.video && (
                        <p className="total-time">{props.content.duration}</p>
                    )}

                    <Dropdown overlay={menu} trigger={['click']} placement="bottomCenter" arrow>
                        <SVG
                            src="/images/tk-icon/bookshelf-button.svg"
                            width={24}
                            height={24}
                            className="dropdown-return-content__icon"
                        />
                    </Dropdown>

                    <img
                        src={props.content.coverImage}
                        alt="Banner"
                        style={
                            download?.requestStatus === FetchStatus.Process ||
                            props.loadingUID === props.content.licenseUID
                                ? { filter: 'brightness(0.4)' }
                                : {}
                        }
                        onClick={() => {
                            onOpenContent(props.content.licenseType ?? LicenseType.Rent);
                        }}
                    />
                </div>
                <div className="reading-percent__text">
                    <span className="progress__text">
                        {props.totalProgression && props.content.licenseType !== LicenseType.Sample
                            ? props.totalProgression > 100
                                ? 100 + '%'
                                : props.totalProgression + '%'
                            : null}
                    </span>
                    <span className="expire-date__text">
                        {changeTimeStampToText(props.content.expireDate!)}
                    </span>
                </div>
            </Col>
            {confirmDialog && (
                <ConfirmModal
                    setConfirmDialog={setConfirmDialog}
                    data={props.content}
                    nextFunction={props.onReturnContent!}
                />
            )}
        </>
    );
};

const HistoryLicenseCover = (props: historyProps) => {
    const [confirmDialog, setConfirmDialog] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const buddhistEra = require('dayjs/plugin/buddhistEra');
    dayjs.extend(buddhistEra);

    const { request, darkMode } = useSelector(
        (state: HibraryRootState) => ({
            request: state.fetch.id === props.content.licenseUID ? state.fetch : undefined,
            darkMode: state.darkMode
        }),
        shallowEqual
    );

    return (
        <>
            <div className="flex-container fadeIn" key={props.keyUID}>
                <div className="content-collection-history">
                    {props.content.licenseType === LicenseType.Sample && (
                        <SVG src="/images/tk-icon/sample-tag.svg" className="sample-tag" />
                    )}
                    {props.content.contentType === ContentType.audioBook && (
                        <SVG
                            src="/images/tk-icon/audiobook-icon.svg"
                            className="audiobook-icon"
                            width="25"
                            height="25"
                        />
                    )}
                    <img src={props.content.coverImage} />
                </div>
                <div className="detail-book">
                    <div>
                        {props.content.contentType === ContentType.magazine &&
                        props.content.displayTitle ? (
                            <div style={{ marginBottom: '8px' }}>
                                <Tooltip title={<span>{props.content.displayTitle.title}</span>}>
                                    <Link
                                        to={getDetailRoute(
                                            props.content.contentUID,
                                            props.content.contentType
                                        )}
                                        className="book-title-bookShelf"
                                    >
                                        {props.content.displayTitle.title}
                                    </Link>
                                </Tooltip>
                                <Tooltip title={<span>{props.content.displayTitle.subtitle}</span>}>
                                    <Link
                                        to={getDetailRoute(
                                            props.content.contentUID,
                                            props.content.contentType
                                        )}
                                        className="name"
                                        style={darkMode.darkMode ? { color: '#bdbdbd' } : {}}
                                    >
                                        {props.content.displayTitle.subtitle}
                                    </Link>
                                </Tooltip>
                            </div>
                        ) : (
                            <div style={{ marginBottom: '8px' }}>
                                <Tooltip title={<span>{props.content.title}</span>}>
                                    <Link
                                        to={getDetailRoute(
                                            props.content.contentUID,
                                            props.content.contentType
                                        )}
                                        className="book-title-bookShelf"
                                    >
                                        {props.content.title}
                                    </Link>
                                </Tooltip>
                                <Tooltip
                                    title={
                                        <span>
                                            {props.content.contentType === ContentType.video
                                                ? props.content.publisherName!
                                                : props.content.writerName!}
                                        </span>
                                    }
                                >
                                    <Link
                                        to={
                                            props.content.contentType === ContentType.video
                                                ? getPublisherContentRoute(
                                                      props.content.publisherUID!,
                                                      props.content.contentType
                                                  )
                                                : getWriterContentRoute(
                                                      props.content.contentUID,
                                                      props.content.contentType
                                                  )
                                        }
                                        className="name"
                                        style={darkMode.darkMode ? { color: '#bdbdbd' } : {}}
                                    >
                                        {props.content.contentType === ContentType.video
                                            ? props.content.publisherName!
                                            : props.content.writerName!}
                                    </Link>
                                </Tooltip>
                            </div>
                        )}
                        <div>
                            <label className="header-date">วันที่ยืม</label>
                            <p className="date">
                                {convertMonth(
                                    dayjs.unix(props.content.startDate!).format('DD/MM/BBBB HH:mm')
                                )}
                            </p>
                        </div>

                        {props.content.licenseType !== LicenseType.Sample && (
                            <div>
                                <label className="header-date">วันที่คืน</label>
                                <p className="date">
                                    {convertMonth(
                                        dayjs
                                            .unix(props.content.expireDate!)
                                            .format('DD/MM/BBBB HH:mm')
                                    )}
                                </p>
                            </div>
                        )}
                    </div>

                    {props.content.licenseStatus === RentalStatus.Rented && (
                        <Button
                            type="primary"
                            shape="round"
                            size="middle"
                            className="primary-background-button"
                            loading={request?.loading ? true : false}
                            onClick={() => {
                                setConfirmDialog(true);
                            }}
                        >
                            คืน
                        </Button>
                    )}
                </div>
            </div>

            {confirmDialog && (
                <ConfirmModal
                    setConfirmDialog={setConfirmDialog}
                    data={props.content}
                    nextFunction={props.onReturnContent!}
                />
            )}
        </>
    );
};

const BookingLicenseCover = (props: historyProps) => {
    const [confirmDialog, setConfirmDialog] = useState(false);
    const [buttonType, setButtonType] = useState<ButtonType>(ButtonType.Rent);
    const dispatch = useDispatch();
    const { request, darkMode } = useSelector(
        (state: HibraryRootState) => ({
            request: state.fetch.id === props.content.licenseUID ? state.fetch : undefined,
            darkMode: state.darkMode
        }),
        shallowEqual
    );

    const renderButton = (licenseStatus: RentalStatus) => {
        if (licenseStatus === RentalStatus.WaitingListed) {
            return (
                <>
                    <Button
                        type="primary"
                        shape="round"
                        size="middle"
                        className="secondary-outline-button"
                        loading={request?.loading}
                        onClick={() => {
                            setConfirmDialog(true);
                        }}
                    >
                        ยกเลิกจอง
                    </Button>
                </>
            );
        } else {
            return (
                <div style={{ display: 'flex' }}>
                    <Button
                        type="primary"
                        shape="round"
                        size="middle"
                        className="secondary-outline-button"
                        loading={
                            request?.loading && buttonType === ButtonType.Cancel ? true : false
                        }
                        style={{ width: '60%', padding: '4px', marginRight: '5px' }}
                        onClick={() => {
                            setConfirmDialog(true);
                            setButtonType(ButtonType.Cancel);
                        }}
                    >
                        ยกเลิกจอง
                    </Button>

                    <Button
                        type="primary"
                        shape="round"
                        size="middle"
                        className="primary-background-button"
                        loading={request?.loading && buttonType === ButtonType.Rent ? true : false}
                        style={{ width: '40%' }}
                        onClick={() => {
                            dispatch(rentConfirm(props.content, RentalStatus.Rent));
                            setButtonType(ButtonType.Rent);
                        }}
                    >
                        ยืม
                    </Button>
                </div>
            );
        }
    };

    return (
        <>
            <div className="flex-container fadeIn" key={props.keyUID}>
                <div className="content-collection-history">
                    {props.content.contentType === ContentType.audioBook && (
                        <SVG
                            src="/images/tk-icon/audiobook-icon.svg"
                            className="audiobook-icon"
                            width="25"
                            height="25"
                        />
                    )}
                    <img src={props.content.coverImage} />
                </div>
                <div className="detail-book">
                    <div
                        style={{
                            width: '100%',
                            height: '80%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between'
                        }}
                    >
                        {props.content.contentType === ContentType.audioBook &&
                        props.content.displayTitle ? (
                            <div>
                                <Tooltip title={<span>{props.content.displayTitle.title}</span>}>
                                    <Link
                                        to={getContentDetailRoute(
                                            props.content.contentUID,
                                            props.content.contentType
                                        )}
                                    >
                                        <h4 className="book-title-bookShelf">
                                            {props.content.displayTitle.title}
                                        </h4>
                                    </Link>
                                </Tooltip>
                                <Tooltip title={<span>{props.content.displayTitle.subtitle}</span>}>
                                    <p
                                        className="name"
                                        style={darkMode.darkMode ? { color: '#BDBDBD' } : {}}
                                    >
                                        {props.content.displayTitle.subtitle}
                                    </p>
                                </Tooltip>
                            </div>
                        ) : (
                            <div>
                                <Tooltip title={<span>{props.content.title}</span>}>
                                    <Link
                                        to={getDetailRoute(
                                            props.content.contentUID,
                                            props.content.contentType
                                        )}
                                    >
                                        <h4 className="book-title-bookShelf">
                                            {props.content.title}
                                        </h4>
                                    </Link>
                                </Tooltip>
                                <Tooltip
                                    title={
                                        <span>
                                            {props.content.contentType === ContentType.video
                                                ? props.content.publisherName
                                                : props.content.writerName}
                                        </span>
                                    }
                                >
                                    <Link
                                        to={
                                            props.content.contentType === ContentType.video
                                                ? getPublisherContentRoute(
                                                      props.content.publisherUID!,
                                                      props.content.contentType
                                                  )
                                                : getWriterContentRoute(
                                                      props.content.writerUID![0],
                                                      props.content.contentType
                                                  )
                                        }
                                    >
                                        <p
                                            className="name"
                                            style={darkMode.darkMode ? { color: '#BDBDBD' } : {}}
                                        >
                                            {props.content.contentType === ContentType.video
                                                ? props.content.publisherName
                                                : props.content.writerName}
                                        </p>
                                    </Link>
                                </Tooltip>
                            </div>
                        )}

                        {props.content.licenseStatus === RentalStatus.WaitingListed &&
                        props.content.bookingMemberStatus === 2 ? (
                            <div>
                                <label className="header-queue">รอคิว</label>
                                <p className="queue">{props.content.bookingQueue + ' คิว'}</p>
                            </div>
                        ) : (
                            <div>
                                <label
                                    className="header-queue"
                                    style={{ fontSize: '12px', fontWeight: '700' }}
                                >
                                    กรุณายืม{setContentType(props.content.contentType)}
                                    ภายใน
                                </label>
                                <p className="date">
                                    {convertMonth(
                                        dayjs
                                            .unix(props.content.expireDate!)
                                            .format('DD/MM/BBBB HH:mm')
                                    )}
                                </p>
                            </div>
                        )}

                        {props.content.forecastQueueTH && (
                            <div style={{ marginBottom: '0.5rem' }}>
                                <label
                                    className="header-queue"
                                    style={{
                                        fontSize: '0.75rem',
                                        fontWeight: '600',
                                        color: `${darkMode.normalTheme.GREY4}`
                                    }}
                                >
                                    {`${props.content.forecastQueueTH}`}
                                </label>
                            </div>
                        )}
                    </div>

                    {renderButton(props.content.licenseStatus!)}
                </div>
            </div>

            {confirmDialog && (
                <ConfirmModal
                    setConfirmDialog={setConfirmDialog}
                    data={props.content}
                    nextFunction={props.onReturnContent!}
                    buttonType={buttonType}
                />
            )}
        </>
    );
};

const ReadingNowCover = (props: coverProps) => {
    const [confirmDialog, setConfirmDialog] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const { download } = useSelector(
        (state: HibraryRootState) => ({
            download:
                state.download.licenseUID === props.content.licenseUID ? state.download : undefined
        }),
        shallowEqual
    );
    const menu = (
        <div className="drop-down-bookShelf">
            <div
                key="return"
                onClick={() => {
                    setConfirmDialog(true);
                }}
                className="bookShelf-dropdown"
            >
                คืน{setContentType(props.content.contentType)}
            </div>
        </div>
    );

    const onOpenContent = (licenseType: LicenseType) => {
        const version = Math.floor(parseFloat(osVersion));

        if (isIOS && !isTablet && version >= 13 && licenseType !== LicenseType.Sample) {
            window.location.href = getDynamicLinkPath(window.location.href);
        } else if (isAndroid && !isTablet && version >= 8 && licenseType !== LicenseType.Sample) {
            window.location.href = getDynamicLinkPath(window.location.href);
        } else if (isTablet && licenseType !== LicenseType.Sample) {
            if ((isIOS && version >= 13) || (isAndroid && version >= 8)) {
                window.location.href = getDynamicLinkPath(window.location.href);
            } else {
                onLoadContent();
            }
        } else {
            onLoadContent();
        }
    };

    const onLoadContent = () => {
        switch (props.content.contentType) {
            case ContentType.video:
            case ContentType.audioBook:
                history.push(`/${props.content.contentType}/${props.content.contentUID}`);
                if (props.addReadingNow) {
                    props.addReadingNow(props.content, props.content.contentType);
                }
                break;
            case ContentType.ebook:
            case ContentType.magazine:
                if (download?.requestStatus !== FetchStatus.Process) {
                    dispatch(
                        fulfillContent(
                            props.content.contentUID,
                            props.content.licenseUID,
                            props.content.contentType,
                            props.content.licenseType
                        )
                    );
                    if (props.addReadingNow) {
                        props.addReadingNow(props.content, props.content.contentType);
                    }
                }
                break;
            default:
                break;
        }
    };

    return (
        <>
            <div
                key={props.content.licenseUID}
                className={
                    props.content.contentType == ContentType.video
                        ? `cover-col-video-reading`
                        : `cover-col-reading`
                }
            >
                <div className="content-collection-bookshelf">
                    {props.totalProgression && props.content.licenseType !== LicenseType.Sample ? (
                        <Progress
                            percent={props.totalProgression}
                            showInfo={false}
                            strokeLinecap="square"
                            strokeWidth={5}
                            className="progress-bar"
                        />
                    ) : null}

                    {download?.requestStatus === FetchStatus.Process ? (
                        <Lottie animationData={tkLoading} className="read-loading" />
                    ) : null}
                    {props.content.licenseType === LicenseType.Sample && (
                        <SVG src="/images/tk-icon/sample-tag.svg" className="sample-tag" />
                    )}
                    {props.content.contentType === ContentType.audioBook && (
                        <SVG
                            src="/images/tk-icon/audiobook-icon.svg"
                            className="audiobook-icon"
                            width="25"
                            height="25"
                        />
                    )}
                    <Dropdown overlay={menu} trigger={['click']} placement="bottomCenter" arrow>
                        <SVG
                            src="/images/tk-icon/bookshelf-button.svg"
                            width={24}
                            height={24}
                            className="dropdown-return-content__icon"
                        />
                    </Dropdown>
                    <img
                        src={props.content.coverImage}
                        style={
                            download?.requestStatus === FetchStatus.Process
                                ? { filter: 'brightness(0.4)' }
                                : {}
                        }
                        onClick={() => {
                            onOpenContent(props.content.licenseType ?? LicenseType.Rent);
                        }}
                    />
                </div>
                <div className="reading-percent__text">
                    <span className="progress-text">
                        {props.totalProgression && props.content.licenseType !== LicenseType.Sample
                            ? props.totalProgression > 100
                                ? 100 + '%'
                                : props.totalProgression + '%'
                            : null}
                    </span>
                    <span className="expire-date__text">
                        {changeTimeStampToText(props.content.expireDate!)}
                    </span>
                </div>
            </div>
            {confirmDialog && (
                <ConfirmModal
                    setConfirmDialog={setConfirmDialog}
                    data={props.content}
                    nextFunction={props.onReturnContent!}
                />
            )}
        </>
    );
};

export const MemoContentCover = memo(ContentCover);
export const MemoHistoryLicenseCover = memo(HistoryLicenseCover);
export const MemoBookingLicenseCover = memo(BookingLicenseCover);
export const MemoReadingNowCover = memo(ReadingNowCover);
