/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';
import ReactDOM from 'react-dom';
import App from './app/App';
import { setupAxios } from './request';
import reportWebVitals from './reportWebVitals';
import store, { persistor } from './redux/store';
import { MetronicI18nProvider } from './app/helpers/i18n';
import { HelmetProvider } from 'react-helmet-async';
import './_index.scss';
import { PersistGate } from 'redux-persist/integration/react';

const API_URL = process.env.REACT_APP_API_ENDPOINT;
const { PUBLIC_URL } = process.env;

window.onload = () => {
   setupAxios(store, API_URL ?? '');
   ReactDOM.render(
      <PersistGate loading={null} persistor={persistor}>
         <MetronicI18nProvider>
            <HelmetProvider>
               <App store={store} persistor={persistor} basename={PUBLIC_URL} />
            </HelmetProvider>
         </MetronicI18nProvider>
      </PersistGate>,
      document.getElementById('root')
   );
};

if (process.env.REACT_APP_ENV === 'production') {
   console.log = () => {};
   console.info = () => {};
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
