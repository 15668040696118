import axios from 'axios';
import { genRanHex } from '../utilities';
import {
    bookshelfResponse,
    GenerateContentResponse,
    GetDownloadUrlResponse,
    HtiResponse,
    License,
    LicenseType,
    ReadingTimeResponse
} from '../../../interface';
import {
    bookingResponse,
    bookShelfResponse,
    FavoriteContentResponse,
    FollowAllResponse,
    historyLicenseResponse
} from '../../../interface/bookShelf';

export const getReadingTime = async () => {
    const {
        data: { data }
    } = await axios.get<HtiResponse<ReadingTimeResponse>>('/statistics');
    return data;
};

export const getLicense = async () => {
    const {
        data: { data }
    } = await axios.get<HtiResponse<bookshelfResponse>>('license/contentBookShelf');

    return data;
};

export const getReaderContentUrl = async (clientUID: string, license: License) => {
    const {
        data: { data }
    } = await axios.post<HtiResponse<GetDownloadUrlResponse>>(
        `/licenses/${license.contentUID}/download`,
        {
            licenseType: license.licenseType,
            licenseUID: license.licenseUID,
            clientUID: clientUID
        }
    );
    return data;
};

export const generateContent = async (
    clientUID: string,
    license: License,
    requestURL: string,
    clientKey: string,
    encryptKey: string = genRanHex(16)
) => {
    const url =
        license.licenseType === LicenseType.Sample
            ? `${requestURL}?licenseUID=${license.licenseUID}`
            : requestURL;
    const payload =
        license.licenseType === LicenseType.Sample
            ? undefined
            : {
                  fileType: license.fileType,
                  encryptKey: encryptKey,
                  clientKey,
                  contentUID: license.contentUID,
                  licenseType: license.licenseType,
                  licenseUID: license.licenseUID,
                  clientUID
              };
    const {
        data: { data }
    } = await axios.post<HtiResponse<GenerateContentResponse>>(url, payload);
    return data;
};

export const getLicenseContent = async () => {
    const path = 'license/contentBookShelf';
    const {
        data: { data }
    } = await axios.get<HtiResponse<bookShelfResponse>>(path);

    return data;
};

export const getBookingContent = async () => {
    const path = 'license/bookingBookShelf';
    const data = await axios.get<HtiResponse<bookingResponse>>(path);

    return data;
};

export const getHistoryLicense = async (lastStatus: number, lastEvaluatedKey?: string) => {
    const path = 'license/history';
    if (lastEvaluatedKey) {
        const data = await axios.get<HtiResponse<historyLicenseResponse>>(path, {
            params: { lastEvaluatedKey: lastEvaluatedKey, limit: 20, lastStatus: lastStatus }
        });

        return data;
    } else {
        const data = await axios.get<HtiResponse<historyLicenseResponse>>(path, {
            params: { limit: 20, lastStatus: lastStatus }
        });

        return data;
    }
};

export const getAllFavorite = async (lastEvaluatedKey?: string) => {
    const path = 'license/favorite';
    if (lastEvaluatedKey) {
        const {
            data: { data }
        } = await axios.get<HtiResponse<FavoriteContentResponse>>(path, {
            params: { lastEvaluatedKey: lastEvaluatedKey, limit: 28 }
        });

        return data;
    } else {
        const {
            data: { data }
        } = await axios.get<HtiResponse<FavoriteContentResponse>>(path, { params: { limit: 28 } });

        return data;
    }
};

export const getAllFollow = async (followedKey: any) => {
    const path = `license/follow/${followedKey}`;

    const {
        data: { data }
    } = await axios.get<HtiResponse<FollowAllResponse>>(path, {
        params: { followedKey: followedKey }
    });

    return data;
};

export const saveDisplayModal = async () => {
    try {
        const data = await axios.post('license/popup');
        return data;
    } catch (error) {
        return Promise.reject(error);
    }
};

export const resetDisplayModal = async () => {
    try {
        const data = await axios.post('license/popup?isShowPopup=false');
        return data;
    } catch (error) {
        return Promise.reject(error);
    }
};
