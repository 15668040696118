import { combineReducers } from 'redux';
import * as auth from '../app/redux/auth/authRedux';
import * as rental from '../app/redux/rental';
import { categoriesSlice } from '../app/redux/categories/categoriesSlice';
import { ebooksSlice } from '../app/redux/store/storeSlice';
import * as splashLoading from '../app/redux/splashLoading';
import * as theme from '../app/redux/theme';
import * as clientInfo from '../app/redux/clientInfo';
import * as home from '../app/redux/home';
import * as login from '../app/redux/login';
import { HomeState } from '../app/redux/home';
import { LoginState } from '../app/redux/login';
import * as Fetch from '../app/redux/request';
import * as environment from '../app/redux/environment';
import * as player from '../app/redux/player';
import * as videoPlayer from '../app/redux/videoPlayer';
import * as wishlist from '../app/redux/wishlist';
import * as download from '../app/redux/download';
import * as searchCategory from '../app/redux/searchCategory';
import * as searchWriter from '../app/redux/searchWriter';
import * as darkMode from '../app/redux/theme';
import * as bookShelf from '../app/redux/bookshelf/bookShelfRedux';

export interface HibraryRootState {
    auth: auth.UserAuthentication;
    categories: any;
    ebooks: any;
    rental: rental.RentalState;
    splashLoading: splashLoading.SplashLoadingState;
    theme: theme.ThemeState;
    darkMode: darkMode.ThemeState;
    clientInfo: clientInfo.ClientInfoState;
    home: HomeState;
    login: LoginState;
    fetch: Fetch.FetchState;
    environment: environment.EnvState;
    player: player.PlayerState;
    videoPlayer: videoPlayer.VideoPlayerState;
    wishlist: wishlist.WishListState;
    download: download.DownloadContentState;
    searchCategory: searchCategory.SearchCategoryState;
    searchWriter: searchWriter.SearchWriterState;
    bookShelf: bookShelf.BookShelfState;
}

export const rootReducer = combineReducers({
    auth: auth.reducer,
    darkMode: darkMode.reducer,
    categories: categoriesSlice.reducer,
    ebooks: ebooksSlice.reducer,
    rental: rental.reducer,
    player: player.reducer,
    videoPlayer: videoPlayer.reducer,
    splashLoading: splashLoading.reducer,
    theme: theme.reducer,
    clientInfo: clientInfo.reducer,
    home: home.reducer,
    login: login.reducer,
    fetch: Fetch.reducer,
    environment: environment.reducer,
    wishlist: wishlist.reducer,
    download: download.reducer,
    searchCategory: searchCategory.reducer,
    searchWriter: searchWriter.reducer,
    bookShelf: bookShelf.reducer
});
