import './index.scss';

const Policy = () => {
   return (
      <div className="privacyPolicy">
         <div className="contentCol">
            <div>
               <h1 className="titleHeader">คำประกาศเกี่ยวกับความเป็นส่วนตัว (Privacy Notice)</h1>
               <h1 className="titleHeader marginBottom">
                  ของผู้ใช้บริการของสถาบันอุทยานการเรียนรู้
               </h1>
            </div>
            <div>
               <ul className="textIndent">
                  <li>
                     <span>
                        สำนักงานบริหารและพัฒนาองค์ความรู้ (องค์การมหาชน) โดย สถาบันอุทยานการเรียนรู้
                     </span>{' '}
                     ซึ่งต่อไปในประกาศนี้เรียกว่า
                     <span> “TK Park” </span>
                     ตระหนักและให้ความสำคัญกับการคุ้มครองข้อมูลส่วนบุคคลของผู้ใช้บริการของสถาบันอุทยานการเรียนรู้
                     และถือปฏิบัติอย่างเคร่งครัด
                     ในเรื่องการเคารพสิทธิความเป็นส่วนตัวของผู้ใช้บริการเป็นสำคัญ
                  </li>
                  <li>
                     คำประกาศเกี่ยวกับความเป็นส่วนตัว (“ประกาศ”)
                     ฉบับนี้จึงถูกจัดทำขึ้นเพื่อให้ท่านในฐานะผู้ใช้บริการของสถาบันได้ทราบรายละเอียดของวัตถุประสงค์ของการเก็บรวบรวม
                     ใช้ หรือเปิดเผย (รวมเรียกว่า “ประมวลผล”) ข้อมูลส่วนบุคคล รวมทั้งสิทธิต่าง ๆ
                     ของท่านภายใต้พระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. ๒๕๖๒
                  </li>
                  <li>
                     ทั้งนี้ การประมวลผลข้อมูลส่วนบุคคลของท่านตามวัตถุประสงค์ในประกาศนี้
                     สถาบันดำเนินการในฐานะผู้ควบคุมข้อมูลส่วนบุคคล (Data Controller) ซึ่งหมายความว่า
                     สถาบันเป็นผู้มีอำนาจหน้าที่ตัดสินใจเกี่ยวกับการเก็บรวบรวม ใช้
                     หรือเปิดเผยข้อมูลส่วนบุคคล คำนิยาม
                  </li>
               </ul>
            </div>
            <div>
               <strong>คำนิยาม</strong>
               <ul className="noDot textIndent">
                  <li>
                     <b>“ข้อมูลส่วนบุคคล”</b> หมายความถึง
                     ข้อมูลเกี่ยวกับบุคคลซึ่งทำให้สามารถระบุตัวบุคคลนั้นได้ไม่ว่าทางตรงหรือทางอ้อม
                     แต่ไม่รวมถึงข้อมูลของผู้ถึงแก่กรรมโดยเฉพาะ
                  </li>
                  <li>
                     <b>“ข้อมูลส่วนบุคคล”</b> หมายความถึง
                     ข้อมูลเกี่ยวกับบุคคลซึ่งทำให้สามารถระบุตัวบุคคลนั้นได้ไม่ว่าทางตรงหรือทางอ้อม
                     แต่ไม่รวมถึงข้อมูลของผู้ถึงแก่กรรมโดยเฉพาะ
                  </li>
                  <li>
                     <b>“สำนักงาน”</b> หมายความถึง สำนักงานบริหารและพัฒนาองค์ความรู้ (องค์การมหาชน)
                  </li>
                  <li>
                     <b>“สถาบัน”</b> หมายความถึง สำนักงานบริหารและพัฒนาองค์ความรู้ (องค์การมหาชน)
                     โดย สถาบันอุทยานการเรียนรู้
                  </li>
                  <li>
                     <b>“ผู้ปฏิบัติงาน”</b> หมายความถึง
                     เจ้าหน้าที่และผู้ปฏิบัติงานของสำนักงานตามมาตรา ๓๔
                     แห่งพระราชกฤษฎีกาจัดตั้งสำนักงานบริหารและพัฒนาองค์ความรู้ (องค์การมหาชน) พ.ศ.
                     ๒๕๖๑
                  </li>
                  <li>
                     <b>“ผู้ใช้บริการของสถาบัน”</b> ได้แก่ <b>“สมาชิก”</b> หมายความว่า
                     ประชาชนทั่วไปที่สมัครเป็นสมาชิกของสถาบันอุทยานการเรียนรู้และศูนย์การเรียนรู้เครือข่ายของสถาบันอุทยานการเรียนรู้และเครือข่ายของสถาบัน
                     ประกอบด้วย สมาชิกรายปี (Member) และ สมาชิกออนไลน์ (e-Member)
                  </li>
                  <li>
                     <b>สมาชิกรายปี (Member)</b> เป็นการสมัครสมาชิก
                     เพื่อใช้บริการพื้นที่อุทยานการเรียนรู้ต้นแบบและบริการของสถาบันอุทยานการเรียนหรือพื้นที่บริการ
                     ณ เครือข่ายของสถาบัน มีสิทธิในการยืมทรัพยากรต่าง ๆ เข้าร่วมกิจกรรม
                     ตลอดจนเข้าถึงและใช้งานบริการต่าง ๆ เช่น Digital read
                     ของสถาบันอุทยานการเรียนรู้หรือเครือข่ายของสถาบัน มีอายุสมาชิกเป็นรายปี
                  </li>
                  <li>
                     <b>สมาชิกออนไลน์ (eMember)</b>{' '}
                     เป็นการสมัครเพื่อใช้บริการออนไลน์ของสถาบันอุทยานการเรียนรู้
                     มีสิทธิในการเข้าถึงและใช้งาน Digital read
                     ของสถาบันอุทยานการเรียนรู้หรือเครือข่ายของสถาบัน มีอายุตลอดชีพ
                     เว้นแต่สมาชิกจะดำเนินการขอยกเลิกการเป็นสมาชิก
                  </li>
                  <li>
                     <b>“สมาชิกรายวัน” หรือ “visitor”</b> หมายความว่า
                     ประชาชนทั่วไปที่สมัครเพื่อเข้าใช้บริการ ณ
                     พื้นที่ส่วนบริการอุทยานการเรียนรู้ต้นแบบและบริการ ของสถาบันอุทยานการเรียนรู้
                     หรือ พื้นที่บริการ ณ เครือข่ายของสถาบัน มีอายุ 1 วัน
                     เว้นแต่สมาชิกจะดำเนินการขอยกเลิกการใช้งาน โดยยังมิได้สมัครเป็นสมาชิก
                  </li>
                  <li>
                     <b>“ผู้ลงทะเบียนเข้าร่วมกิจกรรม”</b> หมายความว่า สมาชิก สมาชิกรายวัน
                     หรือประชาชนทั่วไปที่ลงทะเบียน เพื่อประสงค์ในการเข้าร่วมกิจกรรมต่างๆ ที่จัดโดย
                     สถาบันอุทยานการเรียนรู้หรือเครือข่ายของสถาบัน
                  </li>
                  <li>
                     <b>“วิทยากร”</b> หมายความถึง
                     ผู้ที่ทำหน้าถ่ายทอดความรู้และประสบการณ์ให้แก่ผู้เข้าอบรมในหลักสูตรต่างๆที่จัดโดยสถาบันหรือเครือข่ายของสถาบัน
                  </li>
                  <li>
                     <b>“ผู้ใช้งานเว็บไซต์”</b> หมายความถึง
                     ผู้ที่เข้าใช้งานเว็บไซต์ของสถาบันอุทยานการเรียนรู้ อาทิ tkpark.or.th ,
                     library.tkpark.or.th , vr.tkpark.or.th , member.tkpark.or.th ,
                     read.tkpark.or.th
                  </li>
                  <li>
                     <b>“ผู้มีส่วนเกี่ยวข้องอื่นๆ”</b> หมายความถึง
                     ผู้ที่มีส่วนเกี่ยวข้องกับการดำเนินการของสถาบันอุทยานการเรียนรู้ ที่นอกเหนือจาก
                     ผู้ใช้บริการ
                  </li>
               </ul>
            </div>
            <div>
               <ul className="textIndent">
                  สถาบันดำเนินการเกี่ยวกับการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของท่าน ดังนี้
               </ul>
               <ul>
                  <li className="redHightLight">๑. ฐานกฎหมายในการประมวลผลข้อมูลส่วนบุคคล</li>
                  <li>๑.๑ สถาบันเก็บรวบรวมข้อมูลส่วนบุคคลของท่าน ภายใต้ฐานกฎหมายดังต่อไปนี้</li>
                  <li>
                     <p style={{ marginBottom: '1rem' }}>
                        ๑.๑.๑
                        ความจำเป็นเพื่อการปฏิบัติหน้าที่ในการดำเนินภารกิจสาธารณะหรือการใช้อำนาจรัฐที่ได้มอบให้แก่สถาบัน
                        ทั้งนี้ เป็นไปตามพระราชกฤษฎีกาจัดตั้งสำนักงานบริหารและพัฒนาองค์ความรู้
                        (องค์การมหาชน) พ.ศ. ๒๕๖๑ มาตรา ๗ เพื่อให้บรรลุวัตถุประสงค์ตามมาตรา ๖
                        ให้สํานักงานมีอํานาจหน้าที่หลักดังต่อไปนี้
                     </p>
                     <ul className=" textIndent">
                        <li>
                           ๑. ดำเนินการจัดให้มีระบบการเผยแพร่ความรู้
                           และการเรียนรู้เพื่อการสร้างสรรค์ทางปัญญาไม่ว่าโดยผ่านสิ่งพิมพ์
                           สื่อดิจิทัล
                           หรือสื่อเทคโนโลยีอื่นใดอันทันสมัยซึ่งสามารถดึงดูดความสนใจการเรียนรู้ได้
                        </li>
                        <li>
                           ๒. ประสานงานหรือสนับสนุนให้มีการจัดทำแหล่งเรียนรู้ต้นแบบ
                           ทั้งในรูปแบบของอุทยานการเรียนรู้ พิพิธภัณฑ์การเรียนรู้ ห้องสมุด การแสดง
                           หรือการจัดนิทรรศการ
                           เพื่อส่งเสริมการสร้างสรรค์ทางความคิดในทุกรูปแบบที่ทันสมัย
                           โดยร่วมมือกับหน่วยงานอื่นไม่ว่าหน่วยงานของรัฐหรือภาคเอกชนในกิจการที่เกี่ยวกับการบริการหรือถ่ายทอดความรู้แก่สังคม
                        </li>
                        <li>๓. ให้คำปรึกษาหรือบริการทางวิชาการภายในขอบวัตถุประสงค์ตามมาตรา ๖</li>
                        <li>
                           ๔.
                           จัดสรรหรือให้การสนับสนุนทางการเงินแก่กิจกรรมที่มีวัตถุประสงค์เพื่อการเพิ่มและพัฒนาการเรียนรู้และสร้างสรรค์ภูมิปัญญา
                        </li>
                        <li>๕. ปฏิบัติงานหรือดำเนินการอื่นใดตามที่คณะรัฐมนตรีมอบหมาย</li>
                     </ul>
                  </li>
                  <li>
                     ๑.๑.๒ ความจำเป็นเพื่อประโยชน์โดยชอบด้วยกฎหมายของสถาบันหรือของบุคคลอื่น
                     โดยประโยชน์ดังกล่าวมีความสำคัญไม่น้อยไปกว่าสิทธิขั้นพื้นฐานในข้อมูลส่วนบุคคลของท่าน
                     ทั้งนี้ เพื่อการสมัครสมาชิก การสมัครเข้าใช้บริการชั่วคราว
                     การรับสมัครและคัดเลือกผู้เข้าอบรม
                     การติดต่อประสานงานและแจ้งข้อมูลข่าวสารให้แก่ผู้ใช้บริการ
                     การเข้าถึงข้อมูลองค์ความรู้ กิจกรรมการให้รางวัล/กิจกรรมของสถาบัน
                     รวมถึงการบริหารจัดการการฝึกอบรมหรือกิจกรรมภายในของสถาบัน
                     การประกอบธุรกิจ/การทำธุรกรรมกับคู่ค้าหรือลูกค้า
                  </li>
                  <li>
                     ๑.๑.๓
                     ความจำเป็นเพื่อการปฏิบัติหน้าที่ตามสัญญาซึ่งท่านเป็นคู่สัญญาหรือเพื่อดำเนินการตามคำขอของท่านก่อนการเข้าทำสัญญา
                     เช่น เพื่อการให้แก่บริการสมาชิก
                     เพื่อการเบิกจ่ายค่าลงทะเบียนฝึกอบรมและค่าตอบแทนวิทยากร เป็นต้น
                  </li>
                  <li>
                     ๑.๑.๔ ได้รับความยินยอมที่สมบูรณ์จากท่าน
                     ในการประมวลผลข้อมูลส่วนบุคคลที่จำเป็นต้องได้รับความยินยอมของท่าน เช่น
                     ข้อมูลการแพ้อาหาร หรือการจัดทำสื่อโฆษณาหรือประชาสัมพันธ์ของสถาบัน เป็นต้น
                  </li>
                  <li className="redHightLight">๒.วัตถุประสงค์ในการเก็บรวบรวมข้อมูล</li>
                  <li>
                     สถาบันดำเนินการเก็บรวบรวมข้อมูลส่วนบุคคลของท่านเพื่อวัตถุประสงค์ ดังต่อไปนี้
                  </li>
                  <li>
                     ๒.๑ เพื่อการสมัครสมาชิกของสถาบันหรือเครือข่ายของสถาบัน
                     ซึ่งผู้สมัครดำเนินการสมัครตามช่องทางที่จัดไว้
                  </li>
                  <li>
                     ๒.๒
                     เพื่อประกอบการรับสมัครและพิจารณาคัดเลือกผู้เข้าร่วมกิจกรรมที่จัดโดยสถาบันหรือเครือข่ายของสถาบัน
                     ซึ่งผู้สมัครดำเนินการลงทะเบียนหรือส่งใบสมัครมาตามช่องทางที่จัดไว้
                  </li>
                  <li>
                     ๒.๓ เพื่อประกาศรายชื่อผู้เข้าร่วมกิจกรรมที่จัดโดยสถาบันและเครือข่ายของสถาบัน
                     และเผยแพร่ให้แก่ผู้เข้าร่วมกิจกรรม
                  </li>
                  <li>๒.๔ เพื่อจัดทำรายชื่อและประวัติย่อของวิทยากร สำหรับเผยแพร่ประชาสัมพันธ์</li>
                  <li>๒.๕ เพื่อประมวลผลและจัดทำรายงานสถิติของสถาบัน</li>
                  <li>
                     ๒.๖
                     เพื่อจัดทำภาพนิ่งและวีดีโอที่เกี่ยวข้องกับการจัดกิจกรรมของสถาบันเพื่อให้เรียนรู้ย้อนหลัง
                  </li>
                  <li>
                     ๒.๗ เพื่อเบิกจ่ายค่าลงทะเบียนการเข้าร่วมกิจกรรมของสถาบัน
                     และเบิกจ่ายค่าตอบแทนวิทยากร
                  </li>
                  <li>๒.๘ เพื่อการติดต่อประสานงานและแจ้งข้อมูลข่าวสาร</li>
                  <li>
                     ๒.๙ เพื่อจัดทำสื่อโฆษณาประชาสัมพันธ์ของสถาบัน
                     ซึ่งมีผู้เข้าร่วมกิจกรรมหรือวิทยากรปรากฎเป็นส่วนใดส่วนหนึ่งของสื่อ
                  </li>
                  <li>๒.๑๐ เพื่อการบริหารจัดการกิจกรรมของสถาบัน</li>
                  <li>๒.๑๑ เพื่อการรักษาความปลอดภัยของอาคารสถานที่</li>
                  <li>
                     ๒.๑๒ เพื่อวิเคราะห์และประมวลผล พัฒนาการบริการชองสถาบัน
                     ส่งเสริมการอ่านการเรียนรู้
                  </li>
                  <li>๒.๑๓ เพื่อการจัดเก็บข้อมูลจราจรคอมพิวเตอร์ในฐานะผู้ให้บริการ</li>
                  <li>๒.๑๔ เพื่อการเข้าถึงองค์ความรู้ของสถาบัน</li>
                  <li>
                     ๒.๑๕ เพื่อเป็นข้อมูลการใช้วิเคราะห์การเข้าใช้งาน การเก็บสถิติ
                     และการนำเสนอบริการให้ตรงกับความต้องการของผู้ใช้บริการ
                  </li>
                  <li className="redHightLight">๓. ข้อมูลส่วนบุคคลที่สถาบันเก็บรวบรวม</li>
                  <li>
                     เพื่อวัตถุประสงค์ตามที่ได้แจ้งในข้อ ๒.
                     สถาบันเก็บรวบรวมข้อมูลส่วนบุคคลของท่านดังรายการต่อไปนี้
                  </li>
                  <li>
                     <p>๓.๑ แหล่งข้อมูลและรายการข้อมูลส่วนบุคคลที่เก็บรวบรวม มีดังนี้</p>
                     <table>
                        <thead>
                           <th>แหล่ง/วิธีการเก็บรวบรวม</th>
                           <th>รายการข้อมูลส่วนบุคคล</th>
                        </thead>
                        <tbody>
                           <tr>
                              <td>
                                 ๑. ข้อมูลที่เก็บโดยตรงผ่านการกรอกข้อมูลในแอปพลิเคชัน MyTK , TK Read
                                 และสมัครสมาชิกผ่านช่องทางต่าง ๆ เช่น เว็บไซต์ ทางเว็บไซต์
                                 tkpark.or.th , member.tkpark.or.th library.tkpark.or.th ,
                                 tkpark.2ebook.com , tkpark.overdrive.com ,
                                 การลงทะเบียนเข้าร่วมกิจกรรม
                                 หรือการที่ท่านส่งข้อมูลส่วนบุคคลของท่านให้แก่สถาบันโดยตรง
                              </td>
                              <td>
                                 ชื่อ นามสกุล วัน/เดือน/ปีเกิด อายุ รูปถ่าย ที่อยู่ที่ติดต่อได้
                                 เบอร์โทรศัพท์ เบอร์โทรศัพท์มือถือ อีเมล เลขบัตรประจำตัวประชาชน
                                 เลขหนังสือเดินทาง ข้อมูลบุคคลอ้างอิงชื่อ นามสกุล เบอร์โทรศัพท์
                                 เบอร์โทรศัพท์มือถือ อีเมล ข้อมูลหน่วยงาน
                              </td>
                           </tr>
                           <tr>
                              <td>
                                 ๒. ข้อมูลที่เก็บโดยตรงผ่านลงทะเบียนเข้าร่วมกิจกรรมผ่านช่องทางต่าง ๆ
                                 เช่น google form
                              </td>
                              <td>
                                 ชื่อ นามสกุล ชื่อเล่น ข้อมูลบุคคลอ้างอิง ที่อยู่ที่ติดต่อได้
                                 เบอร์โทรศัพท์ เบอร์โทรศัพท์มือถือ อีเมล ข้อมูลหน่วยงาน
                                 ระดับการศึกษา เพศ อาชีพ
                              </td>
                           </tr>
                           <tr>
                              <td>๓. ข้อมูลที่เก็บรวบรวมระหว่างการใช้บริการ</td>
                              <td>
                                 ชื่อ-นามสกุล เลขบัตรประจำตัวประชาชน
                                 รายการธุรกรรมทางเงินหรือบริการใด ๆ
                                 ข้อมูลเกี่ยวกับการชำระเงินไปยังและจากท่าน
                                 วันที่และ/หรือเวลาที่ชำระเงิน ยอดชำระเงิน ธนาคาร
                                 ข้อมูลเกี่ยวกับการขอเงินคืน ยอดเงินคืน วันที่และสถานที่
                                 หมายเลขการทำรายการ ข้อมูลยอดเงินประกันหนังสือ
                                 ข้อมูลยอดเงินค่าบริการอื่นๆ (eMomey) และข้อมูลทางการเงินอื่นๆ
                                 รายการธุรกรรมการยืม-คืนหนังสือ รายการค่าปรับล่าช้า
                                 รายการค่าปรับหนังสือหายและค่าดำเนินการกรณีหนังสือหาย
                                 รายการสมัครเข้าร่วมกิจกรรม รายการยกเลิกการเข้าร่วมกิจกรรม
                                 รายการเข้าร่วมกิจกรรม วันที่และสถานที่การเช้าใช้บริการในพื้นที่
                              </td>
                           </tr>
                           <tr>
                              <td>
                                 ๔.
                                 ข้อมูลที่เก็บโดยการใช้เทคโนโลยีตรวจจับหรือติดตามพฤติกรรมการใช้งานเว็บไซต์
                                 tkpark.or.th , library.tkpark.or.th ของท่าน
                              </td>
                              <td>
                                 เว็บไซต์คุกกี้ ข้อมูลการจราจรทางคอมพิวเตอร์ ข้อมูลการใช้
                                 Application, Device ID, Browsing history
                              </td>
                           </tr>
                           <tr>
                              <td>๕. ข้อมูลที่เก็บรวบรวมระหว่างการเข้าร่วมกิจกรรม</td>
                              <td>
                                 ภาพถ่าย วีดีโอกิจกรรม
                                 ประวัติส่วนตัวที่เก็บเพิ่มเติมเพื่อสำรวจความพึงพอใจในการเข้าร่วมกิจกรรมของสถาบัน
                                 เป็นต้น
                              </td>
                           </tr>
                        </tbody>
                     </table>
                  </li>
                  <li>
                     <p>๓.๒ จุดประสงค์การใช้งานข้อมูลส่วนบุคคล</p>
                     <table>
                        <thead>
                           <th>จุดประสงค์ในการใช้ข้อมูล</th>
                           <th>รายการข้อมูลส่วนบุคคลที่ใช้</th>
                        </thead>
                        <tbody>
                           <tr>
                              <td>๑. เพื่อยืนยันตัวตนในการสมัครในการเข้ารับบริการจากสถาบัน</td>
                              <td>
                                 ชื่อ-นามสกุล เลขบัตรประจำตัวประชาชน
                                 เบอร์โทรศัพท์หรือเบอร์โทรศัพท์มือถือ อีเมล ประเภทสมาชิก
                                 วันและเดือนที่เข้าร่วม/วันที่สมัคร
                                 ระยะเวลาการเป็นสมาชิก/วันที่สิ้นสุดการเป็นสมาชิก
                                 และข้อมูลการเป็นผู้ใช้บริการของสถาบัน/สมาชิกอื่นๆ
                              </td>
                           </tr>
                           <tr>
                              <td>
                                 ๒. เพื่อยืนยันตัวตนในการทำธุรกรรมทางการเงินของสมาชิก
                                 ผู้ใช้บริการของสถาบัน และผู้เข้าร่วมกิจกรรม
                              </td>
                              <td>
                                 ชื่อ-นามสกุล เลขบัตรประจำตัวประชาชน ที่อยู่ที่ติดต่อได้
                                 เบอร์โทรศัพท์ เบอร์โทรศัพท์มือถือ ข้อมูลยอดเงินประกันหนังสือ
                                 ข้อมูลยอดเงินค่าบริการอื่นๆ (eMomey) และข้อมูลทางการเงินอื่นๆ
                                 รายการธุรกรรมทางเงินหรือบริการใด ๆ
                                 ข้อมูลเกี่ยวกับการชำระเงินไปยังและจากท่าน
                                 วันที่และ/หรือเวลาที่ชำระเงิน ยอดชำระเงิน ธนาคาร
                                 ข้อมูลเกี่ยวกับการ65yขอเงินคืน ยอดเงินคืน วันที่และสถานที่
                                 หมายเลขการทำรายการ
                              </td>
                           </tr>
                           <tr>
                              <td>๓. เพื่อยืนยันตัวตนในการทำธุรกรรมการยืม-คืนหนังสือ ของสมาชิก</td>
                              <td>
                                 ชื่อ-นามสกุล เลขบัตรประจำตัวประชาชน ที่อยู่ที่ติดต่อได้
                                 เบอร์โทรศัพท์ เบอร์โทรศัพท์มือถือ อีเมล
                                 รายการธุรกรรมการยืม-คืนหนังสือ ข้อมูลการจอง วันนัดหมายรับบริการ
                                 ที่อยู่/วันที่และเวลาในการรับหรือจัดส่ง ข้อความตอบรับของผู้รับ
                              </td>
                           </tr>
                           <tr>
                              <td>
                                 ๔. เพื่อยืนยันตัวตนในการรับบริการของสมาชิกและผู้ใช้บริการของสถาบัน
                              </td>
                              <td>
                                 ชื่อ-นามสกุล เลขบัตรประจำตัวประชาชน ที่อยู่ที่ติดต่อได้
                                 เบอร์โทรศัพท์ เบอร์โทรศัพท์มือถือ
                              </td>
                           </tr>
                           <tr>
                              <td>๕.เพื่อรับสมัครและพิจารณาคัดเลือกผู้เข้าร่วมกิจกรรม</td>
                              <td>
                                 ชื่อ นามสกุล ชื่อเล่น ข้อมูลบุคคลอ้างอิง ที่อยู่ที่ติดต่อได้
                                 เบอร์โทรศัพท์ เบอร์โทรศัพท์มือถือ อีเมล ข้อมูลหน่วยงาน
                                 ระดับการศึกษา เพศ (กรณีจัดอบรม/กิจกรรม ที่ต้องแบ่งกลุ่มหรือห้องพัก)
                                 อาชีพ (กรณีอบรมเฉพาะทาง)
                              </td>
                           </tr>
                           <tr>
                              <td>
                                 ๖.
                                 เพื่อประกาศรายชื่อผู้เข้าร่วมกิจกรรมที่จัดโดยสถาบันและเครือข่ายของสถาบัน
                              </td>
                              <td>ชื่อ-นามสกุล อีเมล</td>
                           </tr>
                           <tr>
                              <td>
                                 ๗.เพื่อจัดทำรายชื่อและประวัติย่อของวิทยากร
                                 สำหรับเผยแพร่ประชาสัมพันธ์
                              </td>
                              <td>ชื่อ-นามสกุล ประวัติย่อ ตำแหน่ง สังกัด ความเชี่ยวชาญ ภาพถ่าย</td>
                           </tr>
                           <tr>
                              <td>๘. เพื่อประมวลผลและจัดทำรายงานสถิติของสถาบัน</td>
                              <td>
                                 ชื่อ-นามสกุล เลขบัตรประจำตัวประชาชน ที่อยู่ เบอร์โทรศัพท์
                                 เบอร์โทรศัพท์มือถือ อีเมล รายการธุรกรรมทางการเงิน
                                 รายการธุรกรรมการยืม-คืนหนังสือ ข้อมูลการจอง วันนัดหมายรับบริการ
                                 ที่อยู่/วันที่ วันที่เวลาและสถานที่การเช้าใช้บริการในพื้นที่
                                 วันที่เวลาและสถานที่ในการลงทะเบียนและเข้าร่วมกิจกรรม
                                 รายการประวัติสืบค้นข้อมูลทรัพยากร รายการประวัติการจองทรัพยากร
                                 ประเภทการเป็นสมาชิก
                                 ระยะเวลาการเป็นสมาชิก/วันที่สิ้นสุดการเป็นสมาชิก
                                 ประวัติการเป็นสมาชิก
                              </td>
                           </tr>
                           <tr>
                              <td>๙. เพื่อเผยแพร่องค์ความรู้จากการจัดกิจกรรม</td>
                              <td>คลิปวีดีโอที่เกี่ยวข้องกับการจัดกิจกรรม</td>
                           </tr>
                           <tr>
                              <td>
                                 ๑๐.จัดทำสื่อโฆษณาประชาสัมพันธ์
                                 ซึ่งมีผู้เข้าร่วมกิจกรรมหรือวิทยากรปรากฎเป็นส่วนใดส่วนหนึ่งของสื่อ
                                 เพื่อเผยแพร่ในช่องทางประชาสัมพันธ์ต่างๆของสถาบัน
                              </td>
                              <td>
                                 คลิปวีดีโอกิจกรรมที่เกี่ยวข้องกับการจัดกิจกรรมภาพถ่าย
                                 เทปบันทึกเสียง
                              </td>
                           </tr>
                           <tr>
                              <td>
                                 ๑๑. เพื่อจ่ายค่าตอบแทนวิทยากร คืนเงินประกันสิทธิ์ผู้เข้าร่วมกิจกรรม
                                 และคืนเงินประกันการบริการเช่าใช้พื้นที่
                              </td>
                              <td>ชื่อ สกุล ชื่อหน่วยงาน (กรณีงานพื้นที่) เลขที่บัญชีธนาคาร</td>
                           </tr>
                           <tr>
                              <td>
                                 ๑๒. ติดต่อประสานงานและแจ้งข้อมูลข่าวสารต่างๆ
                                 ให้แก่ผู้ลงทะเบียนเข้าร่วมกิจกรรม
                              </td>
                              <td>ไอดีไลน์ ที่อยู่ เบอร์โทรศัพท์ อีเมล ข้อมูลผู้ประสานงาน</td>
                           </tr>
                           <tr>
                              <td>๑๓. บริหารจัดการการจัดกิจกรรมของสถาบัน</td>
                              <td>
                                 ข้อมูลส่วนบุคคลซึ่งมีความจำเป็นต่อการดำเนินงานตามกฎหมายของสถาบัน
                              </td>
                           </tr>
                        </tbody>
                     </table>
                  </li>
                  <li>๔. การเปิดเผยข้อมูลส่วนบุคคลของท่าน</li>
                  <li>
                     สถาบันอาจเปิดเผยข้อมูลส่วนบุคคลของท่านต่อประเภทของบุคคลและช่องทางดังต่อไปนี้
                  </li>
                  <li>
                     ๔.๑ หน่วยงานต้นสังกัดของท่าน เช่น
                     กรณีหน่วยงานร้องขอเป็นหนังสือมายังสถาบันขอให้จัดส่งรายงานข้อมูลผู้ใช้บริการซึ่งเป็นบุคลากรในสังกัดของหน่วยงาน
                     เป็นต้น
                  </li>
                  <li>
                     ๔.๒ เจ้าหน้าที่ของรัฐ หน่วยงานที่มีอำนาจหน้าที่
                     หรือบุคคลอื่นเพื่อการดำเนินการตามที่กฎหมายกำหนด คำสั่งของผู้มีอำนาจ
                     คำสั่งหรือหมายศาล เป็นต้น
                  </li>
                  <li>
                     ๔.๓ หน่วยงานเครือข่าย คู่สัญญา
                     ผู้ให้บริการหรือบุคคลผู้เกี่ยวข้องหรือมีความจำเป็นในการให้บริการของสถาบันซึ่งมีความเกี่ยวข้องกับข้อมูลของท่าน
                     เช่น ผู้ให้บริการระบบฐานข้อมูล ผู้จัดส่งเอกสารหรือผู้พัฒนาเว็บไซต์
                     ผู้ให้บริการในการจัดเก็บสภิติการใช้งานระบบ เป็นต้น
                  </li>
                  <li>
                     ๔.๔ ประกาศต่อสาธารณะ ในกรณีเช่น การประกาศรายชื่อผู้ใช้บริการ รายชื่อวิทยากร
                     คลิปวีดีโอกิจกรรมที่เกี่ยวข้องกับการจัดกิจกรรมสื่อโฆษณาประชาสัมพันธ์ซึ่งมีผู้ใช้บริการหรือวิทยากรปรากฏเป็นส่วนใดส่วนหนึ่งของสื่อ
                     ผ่านช่องทางเว็บไซต์ของสถาบัน tkpark.or.th และประกาศผ่านสื่อ Social Media เช่น
                     Facebook ของสถาบัน เป็นต้น
                     <br /> <br />
                     ทั้งนี้กรณีที่การเปิดเผยข้อมูลส่วนบุคคลของท่านจำเป็นต้องได้รับความยินยอมจากท่านก่อน
                     สถาบันจะดำเนินการขอความยินยอมตามข้อกำหนดและเงื่อนไขของกฎหมายที่เกี่ยวข้อง
                  </li>
                  <li>๕. สิทธิตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. ๒๕๖๒ ของท่าน</li>
                  <li>
                     พระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. ๒๕๖๒
                     มีวัตถุประสงค์เพื่อให้ข้อมูลส่วนบุคคลของท่านอยู่ในความควบคุมของท่านได้มากขึ้น
                     โดยท่านสามารถใช้สิทธิตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. ๒๕๖๒
                     เมื่อบทบัญญัติในส่วนที่เกี่ยวกับสิทธิของเจ้าของข้อมูลส่วนบุคคลมีผลใช้บังคับ
                     ซึ่งมีรายละเอียดดังต่อไปนี้
                  </li>
                  <li>
                     ๕.๑ สิทธิในการเข้าถึง รับสำเนาข้อมูลส่วนบุคคลของท่าน
                     รวมถึงขอให้เปิดเผยที่มาของข้อมูลส่วนบุคคลของท่านที่สถาบันเก็บรวบรวมโดยไม่ได้รับความยินยอมจากท่าน
                     เว้นแต่กรณีที่สถาบันมีสิทธิปฏิเสธคำขอของท่านตามกฎหมายหรือคำสั่งศาลและกรณีที่การขอเข้าถึงและรับสำเนาของท่านจะส่งผลกระทบที่อาจก่อให้เกิดความเสียหายต่อสิทธิและเสรีภาพของบุคคลอื่น
                  </li>
                  <li>
                     ๕.๒ สิทธิในการขอแก้ไขข้อมูลส่วนบุคคลของท่านที่ไม่ถูกต้องหรือไม่ครบถ้วน
                     เพื่อให้มีความถูกต้อง เป็นปัจจุบัน สมบูรณ์ และไม่ก่อให้เกิดความเข้าใจผิด
                  </li>
                  <li>
                     <p style={{ marginBottom: '1rem' }}>
                        ๕.๓
                        สิทธิในการขอให้องค์กรระงับการใช้ข้อมูลส่วนบุคคลของท่านในกรณีหนึ่งกรณีใดดังต่อไปนี้
                     </p>
                     <ul className="textIndent">
                        <li>
                           ๕.๓.๑
                           เมื่ออยู่ในช่วงเวลาที่สถาบันทำการตรวจสอบตามคำร้องขอของท่านให้แก้ไขข้อมูลส่วนบุคคลของท่านให้ถูกต้อง
                           สมบูรณ์และเป็นปัจจุบัน
                        </li>
                        <li>
                           ๕.๓.๒ ข้อมูลส่วนบุคคลของท่านถูกเก็บรวบรวม
                           ใช้หรือเปิดเผยโดยมิชอบด้วยกฎหมาย
                        </li>
                        <li>
                           ๕.๓.๓
                           เมื่อข้อมูลส่วนบุคคลของท่านหมดความจำเป็นในการเก็บรักษาไว้ตามวัตถุประสงค์ที่สถาบันได้แจ้งแก่ท่านในการเก็บรวบรวม
                           แต่ท่านประสงค์ให้สถาบันเก็บรักษาข้อมูลนั้นต่อไปเพื่อประกอบการใช้สิทธิตามกฎหมายของท่าน
                        </li>
                        <li>
                           ๕.๓.๔
                           เมื่ออยู่ในช่วงเวลาที่สถาบันกำลังพิสูจน์ให้ท่านเห็นถึงเหตุอันชอบด้วยกฎหมายในการเก็บรวบรวมข้อมูลส่วนบุคคลของท่าน
                           หรือตรวจสอบความจำเป็นในการเก็บรวบรวม ใช้
                           หรือเปิดเผยข้อมูลส่วนบุคคลของท่านเพื่อประโยชน์สาธารณะ
                           อันเนื่องมาจากการที่ท่านได้ใช้สิทธิคัดค้านการเก็บรวบรวม ใช้
                           หรือเปิดเผยข้อมูลส่วนบุคคลของท่าน
                        </li>
                     </ul>
                  </li>
                  <li>
                     ๕.๔ สิทธิในการคัดค้านการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของท่าน
                     เว้นแต่กรณีที่สถาบันมีเหตุในการปฏิเสธคำขอของท่านโดยชอบด้วยกฎหมาย (เช่น
                     สถาบันสามารถแสดงให้เห็นว่าการเก็บรวบรวม ใช้
                     หรือเปิดเผยข้อมูลส่วนบุคคลของท่านมีเหตุอันชอบด้วยกฎหมายยิ่งกว่า
                     หรือเพื่อการก่อตั้งสิทธิเรียกร้องตามกฎหมาย
                     การปฏิบัติตามหรือการใช้สิทธิเรียกร้องทางกฎหมาย
                     หรือเพื่อประโยชน์สาธารณะตามภารกิจของสถาบัน)
                  </li>
                  <li className="redHightLight">๖. ระยะเวลาในการเก็บรักษาข้อมูลส่วนบุคคล</li>
                  <li>สถาบันเก็บรักษาข้อมูลส่วนบุคคลของท่านเป็นระยะเวลาดังต่อไปนี้</li>
                  <li>
                     ๖.๑ สำหรับข้อมูลสมาชิกรายปี เก็บรักษาไว้เท่าที่กฏหมายกำหนด
                     เพื่อในการตรวจสอบธรุกรรมทางการเงินและบัญชีกับสถาบัน
                  </li>
                  <li>
                     ๖.๒ สำหรับข้อมูลสมาชิกออนไลน์
                     เก็บรักษาตลอดชีพเพื่ออำนวยความสะดวกแก่สมาชิกในการเข้าใช้บริการ
                     จนกว่าได้รับแจ้งจากสมาชิกออนไลน์แจ้งยกเลิกการเป็นสมาชิก สถาบันจะทำการลบ
                     ทำลายข้อมูลส่วนบุคคลของท่าน ภายใน ๓๐ วัน
                     นับจากวันที่ท่านสิ้นสุดความสัมพันธ์กับสถาบัน
                  </li>
                  <li>
                     ๖.๓ สำหรับข้อมูลสมาชิกรายวัน เก็บรักษาไว้เท่าที่กฏหมายกำหนด
                     เพื่อในการตรวจสอบธรุกรรมทางการเงินและบัญชีกับสถาบัน
                  </li>
                  <li>
                     ๖.๔ สำหรับข้อมูลผู้เข้าอบรม เก็บรักษาไว้ ๒ ปี
                     นับจากวันที่ท่านสิ้นสุดความสัมพันธ์กับสถาบัน
                  </li>
                  <li>
                     ๖.๕ สำหรับข้อมูลวิทยากร เก็บรักษาไว้ ๕ ปี
                     นับจากวันที่ท่านสิ้นสุดความสัมพันธ์กับสถาบัน
                  </li>
                  <li>
                     ๖.๖ สำหรับข้อมูลผู้ใช้งานเว็บไซต์ของสถาบัน เก็บรักษาไว้ ๓ ปี
                     นับแต่วันที่สถาบันได้รับข้อมูลส่วนบุคคล
                  </li>
                  <li>
                     ๖.๗ สำหรับข้อมูลผู้มีส่วนเกี่ยวข้องอื่น ๆ เก็บรักษาไว้ ๓ ปี
                     นับแต่วันที่สถาบันได้รับข้อมูลส่วนบุคคล เมื่อพ้นระยะเวลาดังกล่าวแล้ว
                     สถาบันจะทำการลบ
                     ทำลายข้อมูลส่วนบุคคลของท่านเมื่อหมดความจำเป็นในการใช้ข้อมูลส่วนบุคคลตามวัตถุประสงค์ที่ได้แจ้งแก่ท่าน
                     อย่างไรก็ตาม
                     ในกรณีที่สถาบันมีความจำเป็นในการเก็บรักษาข้อมูลบางส่วนเพื่อประโยชน์ของท่าน เช่น
                     การจัดทำใบประกาศณีบัตรของท่านเพื่อเป็นหลักฐานการเข้าร่วมกิจกรรมของท่าน
                     หรือในกรณีที่ท่านได้ใช้สิทธิหรือมีข้อพิพาทหรือคดีความเกี่ยวกับการเข้าร่วมกิจกรรม
                     การเป็นวิทยากร หรือการใช้บริการของท่าน
                     สถาบันขอสงวนสิทธิในการเก็บรักษาข้อมูลนั้นต่อไปจนกว่าภารกิจดังกล่าวจะมีข้อกำหนดให้ยกเลิก
                     หรือข้อพิพาทนั้นจะได้มีคำสั่งหรือคำพิพากษาถึงที่สุดแล้ว
                  </li>
                  <li className="redHightLight">๗. นโยบายการใช้คุกกี้</li>
                  <li>
                     สถาบันเก็บรวบรวมและใช้คุกกี้รวมถึงเทคโนโลยีอื่นในลักษณะเดียวกันในเว็บไซต์ที่อยู่ภายใต้ความดูแลของสถาบัน
                     ได้แก่ tkpark.or.th library.or.th หรือบนอุปกรณ์ของท่าน ทั้งนี้
                     เพื่อการดำเนินการด้านความปลอดภัยในการให้บริการของสถาบัน
                     และเพื่อให้ท่านซึ่งเป็นผู้ใช้งานได้รับความสะดวกและประสบการณ์ที่ดีในการใช้งานบริการของสถาบัน
                     และข้อมูลเหล่านี้จะถูกนำไปเพื่อปรับปรุงเว็บไซต์ของสถาบันให้ตรงกับความต้องการของท่านมากยิ่งขึ้น
                     โดยท่านสามารถตั้งค่าหรือลบการใช้งานคุกกี้ได้ด้วยตนเองจากการตั้งค่าในเว็บเบราว์เซอร์
                     (Web Browser) ของท่าน
                  </li>
                  <li className="redHightLight">๘. การรักษาความมั่นคงปลอดภัยข้อมูลส่วนบุคคล</li>
                  <li>
                     สถาบันและสำนักงานมีมาตรการในการรักษาความมั่นคงปลอดภัยข้อมูลส่วนบุคคลของท่านอย่างเหมาะสม
                     ทั้งในเชิงเทคนิคและการบริหารจัดการ เพื่อป้องกันมิให้ข้อมูลสูญหาย
                     หรือมีการเข้าถึง ทำลาย ใช้ เปลี่ยนแปลง แก้ไข
                     หรือเปิดเผยข้อมูลส่วนบุคคลโดยไม่ได้รับอนุญาต
                     ซึ่งสอดคล้องกับนโยบายและแนวปฏิบัติด้านความมั่นคงปลอดภัยสารสนเทศ (Information
                     Security Policy) ของสำนักงาน
                  </li>
                  <li>
                     นอกจากนี้ สำนักงานได้กำหนดให้มีนโยบายการคุ้มครองข้อมูลส่วนบุคคล (Privacy
                     Policy) ขึ้นโดยประกาศให้ทราบกันโดยทั่วทั้งองค์กร
                     พร้อมแนวทางปฏิบัติเพื่อให้เกิดความมั่นคงปลอดภัยในการเก็บรวบรวม ใช้
                     และเปิดเผยข้อมูลส่วนบุคคล โดยธำรงไว้ซึ่งความเป็นความลับ (Confidentiality)
                     ความถูกต้องครบถ้วน (Integrity) และสภาพพร้อมใช้งาน (Availability)
                     ของข้อมูลส่วนบุคคล
                     โดยได้จัดให้มีการทบทวนนโยบายดังกล่าวรวมถึงประกาศนี้ในระยะเวลาตามที่เหมาะสม
                  </li>
                  <li className="redHightLight">
                     ๙. การมีส่วนร่วมของเจ้าของข้อมูลส่วนบุคคลและผู้แทน
                  </li>
                  <li>
                     <p>
                        สถาบันอาจเปิดเผยข้อมูลส่วนบุคคลของท่านเมื่อได้รับคำร้องขอจากท่าน
                        ผู้สืบสิทธิ์ ทายาท ผู้แทนโดยชอบธรรม
                        หรือผู้อนุบาลหรือผู้พิทักษ์ตามกฎหมายของท่าน โดยส่งคำร้องขอผ่าน อีเมล :
                        info@tkpark.or.th
                     </p>
                     <p>
                        ในกรณีที่ท่าน ผู้สืบสิทธิ์ ทายาท ผู้แทนโดยชอบธรรม
                        หรือผู้อนุบาลหรือผู้พิทักษ์ตามกฎหมายมีการคัดค้านการจัดเก็บ ความถูกต้อง
                        หรือการกระทำใด ๆ เช่น การแจ้งดำเนินการปรับปรุงแก้ไขข้อมูลส่วนบุคคล
                        สถาบันจะดำเนินการบันทึกหลักฐานคำคัดค้านดังกล่าวไว้เป็นหลักฐานด้วย
                     </p>
                     <p>
                        ทั้งนี้ สถาบันอาจปฏิเสธสิทธิตามวรรคสองได้ในกรณีที่มีกฎหมายกำหนด
                        หรือในกรณีที่ข้อมูลส่วนบุคคลของท่านถูกทำให้ไม่ปรากฏชื่อหรือสิ่งบอกลักษณะอันสามารถระบุตัวท่านได้
                     </p>
                  </li>
                  <li className="redHightLight">
                     ๑๐. การให้บริการโดยบุคคลที่สามหรือผู้ให้บริการช่วง
                  </li>
                  <li>
                     <p>
                        ในกรณีที่มีความจำเป็น สถาบันอาจมีการมอบหมายหรือจัดซื้อจัดจ้างบุคคลที่สาม
                        (ผู้ประมวลผลข้อมูลส่วนบุคคล)
                        ให้ทำการประมวลผลข้อมูลส่วนบุคคลแทนหรือในนามของสถาบัน
                        ซึ่งบุคคลที่สามดังกล่าวอาจเสนอบริการในลักษณะต่าง ๆ เช่น การเป็นผู้ดูแล
                        (Hosting) รับงานบริการช่วง (Outsourcing) หรือเป็นผู้ให้บริการคลาวด์ (Cloud
                        computing service/provider) หรือเป็นงานในลักษณะการจ้างทำของในรูปแบบอื่น
                     </p>
                     <p>
                        การมอบหมายให้บุคคลที่สามทำการประมวลผลข้อมูลส่วนบุคคลในฐานะผู้ประมวลผลข้อมูลส่วนบุคคลนั้น
                        สถาบันจะจัดให้มีข้อตกลงระบุสิทธิและหน้าที่ของสถาบันในฐานะผู้ควบคุมข้อมูลส่วนบุคคลและของบุคคลที่สถาบันมอบหมายในฐานะผู้ประมวลผลข้อมูลส่วนบุคคล
                        ซึ่งรวมถึงกำหนดรายละเอียดประเภทข้อมูลส่วนบุคคลที่สถาบันมอบหมายให้ประมวลผล
                        รวมถึงวัตถุประสงค์ ขอบเขตในการประมวลผลข้อมูลส่วนบุคคลและข้อตกลงอื่น ๆ
                        ที่เกี่ยวข้อง
                        ซึ่งผู้ประมวลผลข้อมูลส่วนบุคคลมีหน้าที่ประมวลผลข้อมูลส่วนบุคคลตามขอบเขตที่ระบุในข้อตกลงและตามคำสั่งของสถาบันเท่านั้นโดยไม่สามารถประมวลผลเพื่อวัตถุประสงค์อื่นได้
                     </p>
                     <p>
                        ในกรณีที่ผู้ประมวลผลข้อมูลส่วนบุคคลมีการมอบหมายผู้ให้บริการช่วง
                        (ผู้ประมวลผลช่วง)
                        เพื่อทำการประมวลผลข้อมูลส่วนบุคคลแทนหรือในนามของผู้ประมวลผลข้อมูลส่วนบุคคล
                        ดังนี้
                        สถาบันจะกำกับให้ผู้ประมวลผลข้อมูลส่วนบุคคลจัดให้มีเอกสารข้อตกลงระหว่างผู้ประมวลผลข้อมูลส่วนบุคคลกับผู้ประมวลผลช่วง
                        ในรูปแบบและมาตรฐานที่ไม่ต่ำกว่าข้อตกลงระหว่างสถาบันกับผู้ประมวลผลข้อมูลส่วนบุคคล
                     </p>
                  </li>
                  <li className="redHightLight">๑๑. การเชื่อมต่อเว็บไซต์หรือบริการภายนอก</li>
                  <li>
                     เว็บไซต์ของสถาบันอาจมีการเชื่อมต่อไปยังเว็บไซต์หรือบริการของบุคคลที่สาม
                     ซึ่งเว็บไซต์หรือบริการดังกล่าวอาจมีการประกาศนโยบายการคุ้มครองข้อมูลส่วนบุคคลหรือประกาศเกี่ยวกับความเป็นส่วนตัวที่มีเนื้อหาสาระแตกต่างจากประกาศนี้
                     สถาบันขอแนะนำให้ท่านศึกษานโยบายการคุ้มครองข้อมูลส่วนบุคคลหรือประกาศเกี่ยวกับความเป็นส่วนตัวของเว็บไซต์หรือบริการนั้น
                     ๆ เพื่อทราบในรายละเอียดก่อนการเข้าใช้งาน ทั้งนี้
                     สถาบันไม่มีความเกี่ยวข้องและไม่มีอำนาจควบคุมถึงมาตรการคุ้มครองข้อมูลส่วนบุคคลของเว็บไซต์หรือบริการดังกล่าวและไม่สามารถรับผิดชอบต่อเนื้อหา
                     นโยบาย ความเสียหาย หรือการกระทำอันเกิดจากเว็บไซต์หรือบริการของบุคคลที่สาม
                  </li>
                  <li className="redHightLight">๑๒. การเข้าถึงข้อมูลส่วนบุคคล</li>
                  <li>
                     สถาบันได้กำหนดให้ผู้ปฏิบัติงาน
                     เจ้าหน้าที่และบุคคลเฉพาะผู้ที่มีอำนาจหน้าที่เกี่ยวข้องในการจัดเก็บรวบรวม ใช้
                     และเปิดเผยข้อมูลส่วนบุคคลของกิจกรรมการประมวลผลนี้เท่านั้นที่จะสามารถเข้าถึงข้อมูลส่วนบุคคลของท่านได้
                     โดยสถาบันจะดำเนินการให้เจ้าหน้าที่และบุคคลดังกล่าวปฏิบัติตามประกาศนี้อย่างเคร่งครัด
                  </li>
                  <li className="redHightLight">
                     ๑๓. การเปลี่ยนแปลงแก้ไขประกาศเกี่ยวกับความเป็นส่วนตัว
                  </li>
                  <li>
                     <p>
                        สถาบันอาจพิจารณาปรับปรุง แก้ไขหรือเปลี่ยนแปลงประกาศนี้ตามที่เห็นสมควร
                        และจะทำการแจ้งให้ท่านทราบผ่านช่องทางเว็บไซต์ tkpark.or.th
                        โดยมีวันที่ของเวอร์ชั่นล่าสุดกำกับไว้ อย่างไรก็ดี
                        สถาบันขอแนะนำให้ท่านโปรดตรวจสอบเพื่อรับทราบประกาศฉบับใหม่อย่างสม่ำเสมอ
                        โดยเฉพาะก่อนที่ท่านจะทำการเปิดเผยข้อมูลส่วนบุคคล
                     </p>
                     <p>
                        การเข้าใช้งานผลิตภัณฑ์หรือบริการภายใต้กิจกรรมการประมวลผลนี้ของท่าน
                        ถือเป็นการรับทราบตามข้อตกลงในประกาศนี้ ทั้งนี้
                        โปรดหยุดการใช้งานหากท่านไม่เห็นด้วยกับข้อตกลงในประกาศฉบับนี้
                        หากท่านยังคงใช้งานต่อไปภายหลังจากที่ประกาศนี้มีการแก้ไขและนำขึ้นประกาศในช่องทางข้างต้นแล้ว
                        ถือว่าท่านได้รับทราบการเปลี่ยนแปลงดังกล่าวแล้ว
                     </p>
                  </li>
                  <li className="redHightLight">๑๔. การติดต่อกับ TK Park</li>
                  <li>
                     <p>
                        หากคุณต้องการที่จะติดต่อสถาบัน เกี่ยวกับนโยบายคุ้มครองข้อมูลส่วนบุคคลของเรา
                        โปรดติดต่อเราผ่านทาง
                     </p>
                     <p className="redHightLight">สถาบันอุทยานการเรียนรู้</p>
                     <p className="redHightLight">
                        สังกัดสำนักงานบริหาร และพัฒนาองค์ความรู้ (องค์การมหาชน)
                     </p>
                     <p>ที่อยู่: เลขที่ 999/9 อาคารสำนักงานเซ็นทรัลเวิลด์</p>
                     <p>ชั้น 17 ถนนพระราม 1 แขวงปทุมวัน เขตปทุมวัน กรุงเทพฯ 10330</p>
                     <p>เบอร์โทรศัพท์: +66 2 264 5963-5</p>
                     <p>เบอร์แฟกซ์: +66 2 264 5966</p>
                     <p>อีเมล : info@tkpark.or.th</p>
                  </li>
                  <li>* วันที่ปรับปรุงข้อมูล 1 มิถุนายน 2565</li>
               </ul>
            </div>
         </div>
      </div>
   );
};

export default Policy;
