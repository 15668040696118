export enum SearchWriterPageType {
   SearchWriterMainVew = 'searchWriterMainPage',
   SearchWriterBoxView = 'searchWriterBoxView',
   SearchWriterContentView = 'searchWriterContentView'
}

export enum SearchWriterActionType {
   SetSearchWriterMainView = 'setSearchWriterMainView[SearchWriterMainView]',
   SetSearchWriterBoxView = 'setSearchWriterBoxView[SearchWriterBoxView]',
   SetSearchWriterContentView = 'setSearchWriterContentView[SearchWriterContentView]'
}

export interface SearchWriterState {
   currentPage: SearchWriterPageType;
   uid?: string;
}

export interface SearchWriterAction {
   type: SearchWriterActionType;
   payload?: any;
}

const initialData: SearchWriterState = {
   currentPage: SearchWriterPageType.SearchWriterBoxView
};

export const reducer = (state = initialData, action: SearchWriterAction): SearchWriterState => {
   switch (action.type) {
      case SearchWriterActionType.SetSearchWriterBoxView: {
         return {
            currentPage: SearchWriterPageType.SearchWriterBoxView
         };
      }
      case SearchWriterActionType.SetSearchWriterContentView: {
         return {
            currentPage: SearchWriterPageType.SearchWriterContentView,
            uid: action.payload.uid
         };
      }
      case SearchWriterActionType.SetSearchWriterMainView: {
         return { currentPage: SearchWriterPageType.SearchWriterMainVew };
      }
      default: {
         return state;
      }
   }
};

const setSearchWriterBox = (): SearchWriterAction => {
   return {
      type: SearchWriterActionType.SetSearchWriterBoxView
   };
};

const setSearchWriterMain = (): SearchWriterAction => {
   return {
      type: SearchWriterActionType.SetSearchWriterMainView
   };
};

const setSearchWriterContent = (uid: string): SearchWriterAction => {
   return {
      type: SearchWriterActionType.SetSearchWriterContentView,
      payload: {
         uid
      }
   };
};

export const actionWriter = {
   setSearchWriterBox,
   setSearchWriterMain,
   setSearchWriterContent
};
