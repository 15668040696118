import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);

export const changeTimeStampToText = (timestamp: number) => {
   let timeString = '';

   if (timestamp) {
      const day = Math.floor((timestamp - dayjs().unix()) / 86400);
      const hour = Math.floor(((timestamp - dayjs().unix()) / 3600) % 24);
      const minus = Math.floor(((timestamp - dayjs().unix()) / 86400) * 24 * 60) % 60;

      if (day > 0) {
         timeString += day + ' วัน ';
      }
      if (hour > 0) {
         timeString += hour + ' ชั่วโมง ';
      }
      if (minus > 0 && day <= 0) {
         timeString += minus + ' นาที';
      }
   }

   return timeString;
};
