import { createSlice } from '@reduxjs/toolkit';
import * as model from './storeModel';

const initialEbooksState = {
   listLoading: false,
   actionsLoading: false,
   entities: {},
   detail: {},
   listHomeHibrary: {},
   listHomeFree: {},
   listHomePrivate: {},
   listHomeContent: {},
   listAll: {},
   listSearch: {},
   listContents: [],
   error: '',
   lastEvaluatedKey: undefined,
   prefixState: ''
};
export const callTypes = {
   list: 'list',
   detail: 'detail',
   action: 'action'
};

export const ebooksSlice = createSlice({
   name: 'ebooks',
   initialState: initialEbooksState,
   reducers: {
      catchError: (state, action) => {
         if (action.payload.error.response) {
            state.error =
               action.payload.error.response &&
               action.payload.error.response.data &&
               action.payload.error.response.data.errorCode;
         }
         if (action.payload.callType === callTypes.list) {
            state.listLoading = false;
         } else {
            state.actionsLoading = false;
         }
      },
      startCall: (state, action) => {
         const { callType, lastEvaluatedKey } = action.payload;
         state.error = '';
         if (callType === callTypes.list) {
            if (lastEvaluatedKey === undefined || Object.keys(lastEvaluatedKey).length === 0) {
               state.listLoading = true;
            } else {
               state.listLoading = false;
            }
         } else if (callType === callTypes.detail) {
            state.listLoading = true;
         } else {
            state.actionsLoading = true;
         }
      },
      detailFetched: (state, action) => {
         const { entities } = action.payload;
         state.error = '';
         state.detail = entities;
         state.listLoading = false;
      },
      ebooksFetched: (state, action) => {
         const { entities, lastEvaluatedKey, isSearch, listSearch } = action.payload;
         state.error = '';

         const newData = entities.ebooks ? entities.ebooks : entities.contents;
         if (isSearch) {
            state.listSearch = listSearch;
            state.listContents = newData;
         } else {
            if (lastEvaluatedKey === undefined || Object.keys(lastEvaluatedKey).length === 0) {
               state.entities = entities;
               state.listContents = newData;
            } else {
               state.listContents = state.listContents.concat(newData);
            }
            state.lastEvaluatedKey = entities.lastEvaluatedKey;
         }
         state.listLoading = false;
      },
      storeHomeFetched: (state, action) => {
         const { entities, prefixState, contentType } = action.payload;
         state.error = '';
         state.listLoading = false;
         state.prefixState = prefixState;
         state.entities = entities;
         if (contentType) {
            state.listAll = state.entities;
         } else {
            switch (action.payload.licenseType) {
               case model.LicenseType.free:
                  state.listHomeFree = state.entities;
                  break;
               case model.LicenseType.private:
                  state.listHomePrivate = state.entities;
                  break;
               default:
                  state.listHomeHibrary = state.entities;
                  break;
            }
         }
      },
      ebooksReset: (state) => {
         state.error = '';
         state.entities = {};
         state.listLoading = true;
      },
      storeHomeReset: (state) => {
         state.error = '';
         state.entities = {};
         state.listHomeHibrary = {};
         state.listHomeFree = {};
         state.listHomePrivate = {};
         state.listHomeContent = {};
         state.listLoading = true;
      }
   }
});
