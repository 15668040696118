import { useMemo } from 'react';
import { NavBar } from '../pages/base/NavBar';
import { Switch, Route } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';
import { message } from 'antd';
import { HibraryRootState } from '../../redux/rootReducer';
import { ContentType, HomeSetType, LicenseType } from '../../interface';
import { SearchPageType } from '../pages/set/search';

import BasePage from '../pages/base/BasePage';
import Footer from '../pages/base/Footer';
import MenuTab from '../pages/base/menuTab';
// import LoginSSO from '../pages/auth/sso/LoginSSO';

message.config({
   top: 50,
   duration: 2,
   maxCount: 1
});
const Routes = () => {
   const { authState } = useSelector(
      (state: HibraryRootState) => ({
         authState: state.auth,
         ebooksState: state.ebooks,
         clientInfo: state.clientInfo
      }),
      shallowEqual
   );

   const basePageMemoAuth = useMemo(() => {
      return <BasePage />;
   }, [authState]);

   return (
      <>
         <Switch>
            {/* <Route path="/signinsso" exact render={() => <LoginSSO />} /> */}
            <Route path="/">
               <NavBar />
               <>{basePageMemoAuth}</>
               <Footer />
               <MenuTab />
            </Route>
         </Switch>
      </>
   );
};

export const getContentDetailRoute = (
   contentUID: string,
   contentType: ContentType = ContentType.ebook
) => {
   return `/${contentType}/${contentUID}`;
};

export const getAllContentRoute = (contentType: ContentType = ContentType.ebook) => {
   return `/${contentType}/all`;
};

export const getListCategoryRoute = (
   licenseType: LicenseType = LicenseType.Rent,
   contentType: ContentType = ContentType.ebook
) => {
   return `/${licenseType.toLowerCase()}/categories/${contentType}/all`;
};

export const getListSubInCategoryRoute = (
   catId: number | string,
   licenseType: LicenseType = LicenseType.Rent,
   contentType: ContentType = ContentType.ebook
) => {
   return `/${licenseType.toLowerCase()}/categories/${catId}/${contentType}/list`;
};

export const getSetContentRoute = (
   setType: HomeSetType,
   licenseType: LicenseType = LicenseType.Rent
) => {
   return `/${licenseType.toLowerCase()}/sets/${setType.toLowerCase()}`;
};

export const getSetContentListRoute = (
   setUID: string,
   setType: HomeSetType,
   licenseType: LicenseType = LicenseType.Rent
) => {
   return `/${licenseType.toLowerCase()}/sets/${setType.toLowerCase()}/${setUID}`;
};

export const getListSubCategoryRoute = (
   catId: number | string,
   subcatId: number | string,
   contentType: ContentType = ContentType.ebook
) => {
   return `/${contentType}/categories/${catId}/subcategories/${subcatId}`;
};

export const getListSetRoute = (
   setUID: string,
   setType: HomeSetType,
   licenseType: LicenseType = LicenseType.Rent
) => {
   return `/${licenseType.toLowerCase()}/sets/${setType.toLowerCase()}/${setUID}`;
};

export const getSearchWriterRoute = (keyword: string) => {
   return `/search/${keyword}/${SearchPageType.Writer}`;
};

export const getSearchCategoryRoute = (keyword: string) => {
   return `/search/${keyword}/${SearchPageType.Category}`;
};

export const getSearchKeywordRoute = (keyword: string) => {
   return `/search/${keyword}/${SearchPageType.Tag}`;
};

export const getSearchPublisherRoute = (keyword: string) => {
   return `/search/${keyword}/${SearchPageType.Publisher}`;
};

export const getDetailRoute = (contentUID: string, contentType: ContentType) => {
   return `/${contentType}/${contentUID}`;
};

export const getSearchRoute = (
   keyword: string
   // type: SearchType = SearchType.TITLE
) => {
   return `/search/${keyword.trim()}`;
};

export const getWriterContentRoute = (
   writerUID: string,
   contentType: ContentType = ContentType.ebook
) => {
   return `/${contentType}/writer/${writerUID}`;
};

export const getPublisherContentRoute = (
   publisherUID: string,
   contentType: ContentType = ContentType.ebook
) => {
   return `/${contentType}/publisher/${publisherUID}`;
};

export const getCategoryRoute = (catUID: string, contentType: ContentType = ContentType.ebook) => {
   return `/${contentType}/categories/${catUID}/list`;
};

export const getSubCategoryRoute = (
   catUID: string,
   subCatUID: string,
   contentType: ContentType = ContentType.ebook
) => {
   return `/${contentType}/categories/${catUID}/subcategories/${subCatUID}`;
};

export const convertSetType = (setType: string): HomeSetType => {
   switch (setType) {
      case 'magazine':
         return HomeSetType.Magazine;
      case 'newspaper':
         return HomeSetType.Newspaper;
      default:
         return HomeSetType.eBook;
   }
};
export default Routes;
