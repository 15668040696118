/* eslint-disable @typescript-eslint/no-unused-vars */
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { reduxBatch } from '@manaflair/redux-batch';
import { persistStore } from 'redux-persist';
import { rootReducer } from './rootReducer';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';

const logger = createLogger({});
const middleware = [
   ...getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false
      // thunk: true,
   }),
   thunk
];

const store = configureStore({
   reducer: rootReducer,
   middleware,
   devTools: true,
   enhancers: [reduxBatch]
});

/**
 * @see https://github.com/rt2zz/redux-persist#store-config-callback
 * @see https://github.com/rt2zz/redux-persist#persistor-object
 */
export const persistor = persistStore(store);

export default store;
