import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { Main } from './pages/main';
import { I18nProvider } from './helpers/i18n';

export default function App({
   store,
   persistor,
   basename
}: {
   store: any;
   persistor: any;
   basename: any;
}) {
   return (
      <Provider store={store}>
         <PersistGate persistor={persistor}>
            <BrowserRouter basename={basename}>
               <I18nProvider>
                  <Main />
               </I18nProvider>
            </BrowserRouter>
         </PersistGate>
      </Provider>
   );
}
