import { ContentMetaData } from '../../../interface/home';
import { AddLicenseResponse } from '../../../interface/rental';
import { RentalStatus } from '../store/storeModel';
import { HtiResponse } from '../../../interface/request';
import { bookShelfContent } from '../../../interface/bookShelf';

export enum RequestStage {
   Init,
   ConFirm,
   Process,
   Complete,
   Error
}

const initialData: RentalState = {
   showConfirmModal: false,
   id: '',
   stage: RequestStage.Init
};

export interface RentalState {
   stage: RequestStage;
   showConfirmModal: boolean;
   error?: any;
   rentalStatus?: RentalStatus;
   id: string;
   content?: ContentMetaData | bookShelfContent;
   license?: AddLicenseResponse;
   onConfirm?: (content: ContentMetaData | bookShelfContent) => void;
}

export enum RentalActionType {
   Confirm = 'RentalAction[Confirm]',
   Process = 'RentalAction[Process]',
   Complete = 'RentalAction[Complete]',
   Error = 'RentalAction[Error]',
   Cancel = 'RentalAction[Cancel]',
   Reset = 'RentalAction[Reset]'
}

interface RentalActionPayLoad {
   rentalStatus?: RentalStatus;
   content?: ContentMetaData | bookShelfContent;
   response?: HtiResponse | AddLicenseResponse;
}
export interface RentalAction {
   payload?: RentalActionPayLoad;
   type: RentalActionType;
}
export const reducer = (state = initialData, action: RentalAction): RentalState => {
   switch (action.type) {
      case RentalActionType.Confirm: {
         const payload = action.payload;
         return {
            id: payload!.content!.contentUID,
            stage: RequestStage.ConFirm,
            showConfirmModal: true,
            license: undefined,
            content: payload?.content,
            error: undefined,
            rentalStatus: payload!.rentalStatus
         };
      }
      case RentalActionType.Process: {
         return {
            ...state,
            showConfirmModal: true,
            stage: RequestStage.Process,
            license: undefined,
            error: undefined
         };
      }
      case RentalActionType.Complete: {
         const payload = action.payload?.response as AddLicenseResponse;
         return {
            ...state,
            showConfirmModal: true,
            stage: RequestStage.Complete,
            license: payload ?? {},
            error: undefined,
            id: state.content!.contentUID
         };
      }

      case RentalActionType.Error: {
         const payload = action.payload?.response as HtiResponse;
         return {
            ...state,
            showConfirmModal: true,
            license: undefined,
            stage: RequestStage.Error,
            error: payload,
            rentalStatus: state.content?.licenseStatus
         };
      }
      case RentalActionType.Cancel: {
         return {
            ...initialData
         };
      }
      case RentalActionType.Reset: {
         return {
            ...initialData
         };
      }

      default:
         return state;
   }
};
