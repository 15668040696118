import axios from 'axios';
import { HtiResponse, ListDevicesResponse } from '../../../interface/request';

export const getDeviceList = async () => {
   const {
      data: { data }
   } = await axios.get<HtiResponse<ListDevicesResponse>>('member/device');
   return data;
};

export const removeDeviceList = async (deviceToken: string, memberID: string) => {
   const data = await axios.delete<HtiResponse>('auth/devices', {
      data: { memberID: memberID, deviceToken: deviceToken }
   });
   return data;
};
