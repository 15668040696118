import React, { FC, useEffect, useState } from 'react';
import { Button, Modal } from 'antd';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { ContentType, LicenseType, ContentMetaData, RentalStatus } from '../../../interface/home';
import { HibraryRootState } from '../../../redux/rootReducer';
import './index.scss';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { rentConfirm, rentEbook } from '../../redux/rental/action';
import { RequestStage } from '../../redux/rental';
import { bookShelfContent } from '../../../interface/bookShelf';
import { fulfillContent } from '../../redux/download';
import { FetchStatus } from '../../redux/request';
import { useHistory, useLocation } from 'react-router-dom';
import { signIn } from '../../redux/auth/authCrud';
import { isAndroid, isIOS, isIPad13, isTablet, osVersion } from 'react-device-detect';
import { getDynamicLinkPath } from '../../redux/utilities';

interface RentBtnProps {
    content: ContentMetaData | bookShelfContent;
    contentType: ContentType;
    licenseType: LicenseType;
    disabled?: boolean;
    style?: React.CSSProperties;
    status: RentalStatus;
    size: SizeType;
    className?: string;
    customBtn?: any;
    onClick?: (status: RentalStatus) => void;
    title?: string;
    licenseUID?: string;
    onStatus?: (status: RentalStatus) => void;
    triggerStatus?: () => void;
}

export enum ButtonSize {
    Large = 'large',
    Medium = 'medium',
    Small = 'small'
}

export const RentBtn: FC<RentBtnProps> = ({ ...props }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();
    const [btnStatus, setBtnStatus] = useState(props.status);
    const [disableBtn, setDisableBtn] = useState(false);

    const { rental, authen, download } = useSelector(
        (state: HibraryRootState) => ({
            rental:
                state.rental.id === props.licenseUID || state.rental.id === props.content.contentUID
                    ? state.rental
                    : undefined,
            authen: state.auth,
            download:
                state.download.contentUID === props.content.contentUID ? state.download : undefined
        }),
        shallowEqual
    );

    const setTitle = (contentType: ContentType) => {
        switch (contentType) {
            case ContentType.ebook:
                return `อ่าน`;
            case ContentType.video:
                return `ดู`;
            case ContentType.audioBook:
                return `ฟัง`;
            default:
                return `อ่าน`;
        }
    };

    const RentalStatusClass: {
        [x: string]: { title: string; className: string };
    } = {
        Rent: {
            title: 'ยืม',
            className: 'primary-background-button'
        },
        Rented: {
            title: setTitle(props.contentType),
            className: 'primary-outline-button'
        },
        WaitingList: {
            title: 'จอง',
            className: 'secondary-background-button'
        },
        WaitingListed: {
            title: 'ยกเลิกจอง',
            className: 'secondary-outline-button'
        },
        Returned: {
            title: 'คืน',
            className: 'primary-background-button'
        },
        ReturnAble: {
            title: '',
            className: 'returnAble'
        },
        Block: {
            title: 'งดจอง',
            className: 'disabled-button'
        }
    };

    useEffect(() => {
        setBtnStatus(props.status);
    }, [props.status]);

    useEffect(() => {
        if (rental) {
            rental.onConfirm = (content) => {
                if (rental.rentalStatus) {
                    content.licenseStatus = rental.rentalStatus;
                }
                if (rental.rentalStatus === RentalStatus.Rented && props.licenseUID) {
                    dispatch(rentEbook(content, props.contentType, props.licenseType));
                } else {
                    dispatch(rentEbook(content, props.contentType, props.licenseType));
                }
            };
            if ([RequestStage.Error].includes(rental.stage)) {
                setBtnStatus(rental.rentalStatus ?? RentalStatus.Rent);
            }
            if ([RequestStage.Complete].includes(rental.stage)) {
                setBtnStatus(getNextRentalStatus(rental.rentalStatus ?? RentalStatus.Rent));
            }
        }
    }, [rental]);

    useEffect(() => {
        if (
            rental?.stage === RequestStage.Process ||
            props.content.licenseStatus === RentalStatus.Block
        ) {
            setDisableBtn(true);
        } else {
            setDisableBtn(false);
        }
    }, [btnStatus]);

    const getBtnTitle = (status: RentalStatus) => {
        if (props.title) {
            return props.title;
        }
        if (status === RentalStatus.WaitingListed) {
            return `${RentalStatusClass[btnStatus].title}`;
        } else if (status === RentalStatus.Rented && props.licenseUID) {
            return `คืนสื่อ`;
        } else {
            return RentalStatusClass[btnStatus]
                ? RentalStatusClass[btnStatus].title
                : 'ไม่พบข้อมูล';
        }
    };

    const onOpenContent = () => {
        const version = Math.floor(parseFloat(osVersion));

        if (isIOS && !isTablet && version >= 13) {
            window.location.href = getDynamicLinkPath(window.location.href);
        } else if (isAndroid && !isTablet && version >= 8) {
            window.location.href = getDynamicLinkPath(window.location.href);
        } else if (isTablet) {
            if ((isIOS && version >= 13) || (isAndroid && version >= 8)) {
                window.location.href = getDynamicLinkPath(window.location.href);
            } else {
                onLoadContent();
            }
        } else {
            onLoadContent();
        }
    };

    const onLoadContent = () => {
        switch (props.contentType) {
            case ContentType.video:
            case ContentType.audioBook: {
                const gotoPath = `/${props.contentType}/${props.content.contentUID}`;
                if (gotoPath !== location.pathname) {
                    history.push(`/${props.contentType}/${props.content.contentUID}`);
                }
                break;
            }
            case ContentType.ebook:
            case ContentType.magazine: {
                if (download?.requestStatus !== FetchStatus.Process) {
                    dispatch(
                        fulfillContent(
                            props.content.contentUID,
                            undefined,
                            props.content.contentType,
                            props.content.licenseType as LicenseType
                        )
                    );
                }
                break;
            }
            default:
                break;
        }
    };

    return (
        <>
            {props.customBtn ? (
                <div
                    onClick={() => {
                        if (!authen.isLogin) {
                            showConfirmLogin();
                        } else {
                            dispatch(rentConfirm(props.content, btnStatus));
                        }
                    }}
                >
                    {props.customBtn}
                </div>
            ) : (
                <Button
                    loading={
                        rental?.stage === RequestStage.Process ||
                        download?.requestStatus === FetchStatus.Process
                            ? true
                            : false
                    }
                    type="primary"
                    shape="round"
                    onClick={() => {
                        if (!authen.isLogin) {
                            showConfirmLogin();
                        } else {
                            if (btnStatus === RentalStatus.Rented) {
                                onOpenContent();
                            } else {
                                dispatch(rentConfirm(props.content, btnStatus));
                            }
                        }
                    }}
                    className={`${
                        btnStatus !== undefined ? RentalStatusClass[btnStatus].className : ''
                    } ${props.className}`}
                    disabled={props.disabled !== undefined ? props.disabled : disableBtn}
                    style={props.style}
                >
                    {rental?.stage === RequestStage.Process ? 'ดำเนินการ' : getBtnTitle(btnStatus)}
                </Button>
            )}
        </>
    );
};

export const redirectURL = () => {
    localStorage.setItem('redirectURL', window.location.href);
};

export const checkPath = () => {
    const path = localStorage.getItem('redirectURL')?.split('?');
    if (path && path?.length >= 1) {
        return path[0];
    } else {
        return localStorage.getItem('redirectURL');
    }
};

export const getLoginPath = async () => {
    try {
        const response = await signIn(checkPath());

        if (response.data.data) {
            localStorage.setItem('redirectURL', response.data.data.redirectURL);
            window.location.href = response.data.data?.authorizeURL;
        }
    } catch (error) {
        console.log(error);
    }
};

export const showConfirmLogin = () => {
    Modal.confirm({
        title: 'กรุณาเข้าสู่ระบบ',
        content: 'คุณต้องการเข้าสู่ระบบหรือไม่?',
        okText: 'เข้าสู่ระบบ',
        cancelText: 'ยกเลิก',
        onOk: () => {
            redirectURL();
            getLoginPath();
        }
    });
};

export const getNextRentalStatus = (status: RentalStatus) => {
    switch (status) {
        case RentalStatus.Rent:
            return RentalStatus.Rented;
        case RentalStatus.Rented:
            return RentalStatus.Rent;
        case RentalStatus.WaitingList:
            return RentalStatus.WaitingListed;
        case RentalStatus.WaitingListed:
            return RentalStatus.WaitingList;
        default:
            return RentalStatus.Rent;
    }
};
