import { useEffect } from 'react';
import { useFormik } from 'formik';
import { Button, message } from 'antd';
import { useState } from 'react';
import { AxiosError, EditMemberResponse } from '../../../interface/request';
import { VerifyType } from '../profile/memberInfo';
import { actions } from '../../redux/auth/authRedux';
import { useDispatch } from 'react-redux';
import {
   validateUpdateEmail,
   validateUpdatePhoneNumber
} from '../../redux/auth/authCrud';
import jwtDecode from 'jwt-decode';
import OtpInput from 'react-otp-input';
import dayjs from 'dayjs';

interface VerifyOTPProps {
   verifyOtpType: VerifyType;
   response: EditMemberResponse;
   onBack: () => void;
   onClose: () => void;
}

interface DecodeToken {
   code: string;
   expire: number;
   mobile: string;
   ref: string;
}

export const VerifyOTP = (props: VerifyOTPProps) => {
   const dispatch = useDispatch();
   let decodeToken: DecodeToken;
   const regexInput = RegExp(/^[0-9]+$/i);
   const initialValues = {
      OTP: ''
   };
   const [otpCode, setOtpCode] = useState('');
   const [timeLeft, setTimeLeft] = useState(0);

   useEffect(() => {
      if (props.response.emailToken && !timeLeft) {
         decodeToken = jwtDecode(props.response.emailToken);
         const startTime = (decodeToken.expire ?? 0) - dayjs().unix();
         setTimeLeft(startTime);
      } else if (props.response.mobileToken && !timeLeft) {
         decodeToken = jwtDecode(props.response.mobileToken);
         const startTime = (decodeToken.expire ?? 0) - dayjs().unix();
         setTimeLeft(startTime);
      }
      const interval = setInterval(() => {
         const timeLeft = (decodeToken!.expire ?? 0) - dayjs().unix();
         if (timeLeft >= 0) {
            setTimeLeft(timeLeft);
         }
      }, 1000);
      return () => clearInterval(interval);
   }, []);

   const formik = useFormik({
      initialValues,
      onSubmit: async (values, { setStatus, setSubmitting }) => {
         switch (props.verifyOtpType) {
            case VerifyType.VerifyEmail: {
               try {
                  const result = await validateUpdateEmail(
                     props.response.emailToken!,
                     otpCode
                  );
                  setSubmitting(false);
                  props.onClose();
                  dispatch(actions.updateToken(result?.userToken as string));
                  message.success('อัพเดทอีเมลเรียบร้อยแล้ว');
               } catch (error) {
                  const getError = error as AxiosError;
                  setStatus(getError.response.data.message);
                  message.error(getError.response.data.message);
                  setOtpCode('');
               }
               break;
            }
            case VerifyType.VerifyPhoneNumber: {
               try {
                  const result = await validateUpdatePhoneNumber(
                     props.response.mobileToken!,
                     otpCode
                  );
                  setSubmitting(false);
                  props.onClose();
                  dispatch(actions.updateToken(result?.userToken as string));
                  message.success('อัพเดทเบอร์มือถือเรียบร้อยแล้ว');
               } catch (error) {
                  const getError = error as AxiosError;
                  setStatus(getError.response.data.message);
                  message.error(getError.response.data.message);
                  setOtpCode('');
               }

               break;
            }

            default:
               break;
         }
      }
   });

   const onOTPChange = (e: any) => {
      const value = e;
      if (value && value.length <= 6 && regexInput.test(value)) {
         setOtpCode(value);
      } else if (value === '') {
         setOtpCode(value);
      }
   };

   return (
      <div className="verify-otp">
         <h3 className="center">Verify OTP</h3>
         <form onSubmit={formik.handleSubmit}>
            <p className="under-text center">
               Reference Code : {props.response.ref}
            </p>
            <div>
               <div>
                  <OtpInput
                     value={otpCode}
                     onChange={onOTPChange}
                     numInputs={6}
                     isInputNum
                     inputStyle={{
                        margin: '16px 3px',
                        fontSize: '36px',
                        width: '60px',
                        textAlign: 'center',
                        height: '70px',
                        backgroundColor: '#F2F2F2',
                        color: '#1d252c',
                        borderRadius: '8px',
                        border: 'none',
                        outline: 'none'
                     }}
                  />
               </div>
            </div>
            <p className="expire-text center">
               {' หมดเวลาภายใน '}
               {timeLeft < 60 ? '' : Math.floor(timeLeft / 60) + ' นาที '}
               {timeLeft % 60} วินาที
            </p>
            <Button
               loading={formik.isSubmitting}
               htmlType="submit"
               disabled={
                  formik.isSubmitting || otpCode.length < 6 ? true : false
               }
               type="primary"
               style={{ marginBottom: '16px' }}
            >
               {formik.isSubmitting ? 'รอสักครู่' : 'ยืนยัน'}
            </Button>
            <p className="under-text center" onClick={props.onBack}>
               กลับ
            </p>
         </form>
      </div>
   );
};
