/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useMemo, useState, FC } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { HibraryRootState } from '../../../redux/rootReducer';
import { Col, Row } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { getBanner, getHomeContent } from '../../redux/store/storeCrud';
import { action, HomeContent } from '../../redux/home';
import { Loading } from '../../customUI/loading';
import { EmptyDataView } from '../../components/nodata';
import { Category, ContentType, SubCategory } from '../../../interface/home';

import * as requestContent from '../../redux/request';
import Banner from './Banner';
import ContentList from '../home/patternHome';

import './index.scss';

interface StoreHomePageParam {
   contentType: ContentType;
}

export const StoreHomePage: React.FC<StoreHomePageParam> = ({ contentType }) => {
   const dispatch = useDispatch();
   const location = useLocation();
   const pageId = 'StoreHomeList';
   const { home, rental } = useSelector(
      (state: HibraryRootState) => ({
         home: state.home,
         rental: state.rental
      }),
      shallowEqual
   );
   const [homeContents, setHomeContents] = useState<HomeContent>({
      contents: []
   });

   useEffect(() => {
      fetchContent();
   }, [location]);

   // useEffect(() => {
   // console.log(rental);
   //    if (rental.id && rental.id !== '') {
   //       fetchUpdateContent();
   //    }
   // }, [rental]);

   const fetchUpdateContent = async () => {
      try {
         const response = await getHomeContent(contentType ?? ContentType.ebook);
         if (response) {
            setHomeContents({
               ...homeContents,
               contents: response?.home ?? []
            });
         }
         dispatch(requestContent.action.setProcess(false, pageId));
      } catch (error) {
         dispatch(requestContent.action.setProcess(false, pageId));
      }
   };

   const fetchContent = async () => {
      try {
         dispatch(requestContent.action.setProcess(true, pageId));
         const [banners, response] = await Promise.all([
            getBanner(),
            getHomeContent(contentType ?? ContentType.ebook)
         ]);

         if (banners && response) {
            if (response.home.length === 0) {
               dispatch(action.setBanner(banners ?? []));
               setHomeContents({
                  contents: undefined
               });
            } else {
               dispatch(action.setBanner(banners ?? []));
               setHomeContents({
                  contents: response?.home ?? []
               });
            }
         }
         dispatch(requestContent.action.setProcess(false, pageId));
      } catch (error) {
         setHomeContents({
            contents: undefined
         });

         dispatch(requestContent.action.setProcess(false, pageId));
      }
   };

   //  const updateList = (index: number) => {
   //     const findContentIndex = homeContents.contents![index].contents.findIndex(
   //        (el) => (el as ContentMetaData).contentUID === rental.id
   //     );
   //     const newContent = JSON.parse(JSON.stringify(homeContents.contents));

   //     newContent[index].contents[findContentIndex] = {
   //        ...newContent[index].contents[findContentIndex],
   //        licenseUID: rental.license?.licenseUID
   //     };

   //     setHomeContents({
   //        ...homeContents,
   //        contents: newContent
   //     });
   //  };

   const MainHome = useMemo(() => {
      return <StoreHomeList homeContent={homeContents} contentType={contentType} />;
   }, [homeContents]);

   const MainBanner = useMemo(() => {
      return home.banners && <Banner banners={home.banners} />;
   }, [home]);

   return (
      <div className="store">
         <Helmet>
            <title>TK Read | พื้นที่แห่งการเรียนรู้ของทุกคน</title>
         </Helmet>
         {home.banners && home.banners.length > 0 && MainBanner}
         {MainHome}
         <Loading id={pageId} />
      </div>
   );
};

interface StoreHomeListProps {
   homeContent: HomeContent;
   contentType: ContentType;
}
const StoreHomeList: FC<StoreHomeListProps> = ({ homeContent, contentType }) => {
   useEffect(() => {
      console.info('render <StoreHomeList/>', homeContent);
   });

   const { request, home } = useSelector(
      (state: HibraryRootState) => ({
         request: state.fetch,
         home: state.home
      }),
      shallowEqual
   );

   return (
      <>
         {homeContent.contents ? (
            <ContentList contents={homeContent.contents} contentsType={contentType} />
         ) : (
            <>
               {!request.loading && (
                  <EmptyDataView minHeight={home.banners && home.banners.length > 0 ? 50 : 70} />
               )}
            </>
         )}
      </>
   );
};

export interface SegmentHeaderParam {
   left?: string;
   right?: boolean;
   path?: string;
   params?: Category | SubCategory;
}
export const SegmentHeader = (props: SegmentHeaderParam) => {
   const intl = useIntl();
   return (
      <Row key={`col_`} gutter={[0, 0]} className="segmentHeader">
         <Col span={20}>{props.left}</Col>
         <Col span={4}>
            {props.right ? (
               <Link
                  to={{
                     pathname: props.path ?? '',
                     state: props.params
                  }}
               >
                  {`${intl.formatMessage({
                     id: 'VIEWALL'
                  })} >`}
               </Link>
            ) : null}
         </Col>
      </Row>
   );
};

// switch (contentType) {
//   case ContentType.ebook:
//     return (
//       <StoreHomeList homeContent={home.ebooks!} contentType={contentType} />
//     );
//   case ContentType.magazine:
//     return (
//       <StoreHomeList
//         homeContent={home.magazines!}
//         contentType={contentType}
//       />
//     );
//   case ContentType.audioBook:
//     return (
//       <StoreHomeList
//         homeContent={home.audioBooks!}
//         contentType={contentType}
//       />
//     );
//   case ContentType.video:
//     return (
//       <StoreHomeList homeContent={home.videos!} contentType={contentType} />
//     );
// }

// switch (contentType) {
//   case ContentType.ebook: {
//     dispatch(action.setEbook(response.home, categoryResponse.category));
//     break;
//   }
//   case ContentType.magazine: {
//     dispatch(
//       action.setMagazine(response.home, categoryResponse.category)
//     );
//     break;
//   }
//   case ContentType.audioBook: {
//     dispatch(
//       action.setAudiobook(response.home, categoryResponse.category)
//     );
//     break;
//   }
//   case ContentType.video: {
//     dispatch(action.setVideo(response.home, categoryResponse.category));
//     break;
//   }
// }
