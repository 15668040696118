import { Button } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { action } from '../../redux/home';

interface buttonProps {
  path: string;
  title: string;
}

const ViewAllButton = (props: buttonProps) => {
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <Button
      shape="round"
      size="middle"
      className="secondary-outline-button"
      onClick={() => {
        dispatch(action.setTitle(props.title));
        history.push(props.path);
      }}
    >
      ดูทั้งหมด
    </Button>
  );
};

export default ViewAllButton;
