const initialData: EnvState = {
   endOfScreen: false
};
export interface EnvState {
   endOfScreen: boolean;
}
export enum EnvActionType {
   SetEndOffScreen = 'Env[SetEndOffScreen]'
}
export interface EnvAction<T = any> {
   payload?: T;
   type: EnvActionType;
}
export const reducer = (state = initialData, action: EnvAction): EnvState => {
   switch (action.type) {
      case EnvActionType.SetEndOffScreen:
         if (action.payload) {
            return {
               ...state,
               endOfScreen: action.payload
            };
         } else {
            return state;
         }

      default:
         return state;
   }
};
const setEndOfScreen = (isEndOfScreen: boolean): EnvAction<boolean> => {
   return {
      type: EnvActionType.SetEndOffScreen,
      payload: isEndOfScreen
   };
};

export const action = { setEndOfScreen };
