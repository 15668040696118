import { useState } from "react";
import { Button, Input, message, Modal, Space, Tooltip } from "antd";
import { Formik } from "formik";
import * as Yup from "yup";
import { updateMemberPassword } from "../../redux/auth/authCrud";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { actions } from "../../redux/auth/authRedux";
import { useDispatch } from "react-redux";

interface ModalProps {
  visible: boolean;
  onClose: () => void;
}

const ChangePasswordModal = (props: ModalProps) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [seePW, setSeePW] = useState(false);
  const [seeNewPW, setSeeNewPW] = useState(false);

  const validateSchema = Yup.object().shape({
    oldPassword: Yup.string()
      .min(8, "อย่างน้อย 8 ตัวอักษร")
      .max(20, "ไม่เกิน 20 ตัวอักษร")
      .matches(
        /[A-Za-z0-9@$!%*?&_.]/,
        "ใช้ตัวอักษรภาษาอังกฤษหรือตัวเลขเท่านั้น"
      ),
    newPassword: Yup.string()
      .min(8, "อย่างน้อย 8 ตัวอักษร")
      .max(20, "ไม่เกิน 20 ตัวอักษร")
      .matches(
        /[A-Za-z0-9@$!%*?&_.]/,
        "ใช้ตัวอักษรภาษาอังกฤษหรือตัวเลขเท่านั้น"
      ),
  });

  const changePassword = async (oldPassword: string, newPassword: string) => {
    try {
      setLoading(true);
      if (oldPassword === newPassword) {
        setLoading(false);
        return message.warning("รหัสผ่านใหม่ต้องไม่ซ้ำกับรหัสผ่านเก่า");
      }
      const data = await updateMemberPassword(oldPassword, newPassword);
      setLoading(false);
      dispatch(actions.updateToken(data.data?.userToken as string));
      message.success("อัพเดทรหัสผ่านเรียบร้อยแล้ว");
      props.onClose();
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <Modal
      width={500}
      visible={props.visible}
      onCancel={props.onClose}
      footer={null}
    >
      <div className="change-password-modal">
        <img
          src="/images/tk-icon/tk-logo-normal-mode.svg"
          className="logo"
          alt="logo"
        />
        <h1>เปลี่ยนรหัสผ่าน</h1>
        <Formik
          initialValues={{
            oldPassword: "",
            newPassword: "",
          }}
          validationSchema={validateSchema}
          onSubmit={(values) => {
            changePassword(values.oldPassword, values.newPassword);
          }}
        >
          {({ errors, touched, values, handleChange, handleSubmit }) => (
            <>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                <Space size={16} direction="vertical">
                  <div>
                    <p className="title-input">รหัสผ่าน</p>
                    <Input
                      size="large"
                      id="oldPassword"
                      placeholder="กรอกรหัสผ่าน"
                      onChange={handleChange}
                      defaultValue={values.oldPassword}
                      type={seePW ? `text` : "password"}
                      suffix={
                        <Tooltip
                          placement="bottom"
                          overlay={
                            <span>
                              {seePW ? "ซ่อนรหัสผ่าน" : " แสดงรหัสผ่าน"}
                            </span>
                          }
                        >
                          {seePW ? (
                            <EyeInvisibleOutlined
                              onClick={() => setSeePW(false)}
                            />
                          ) : (
                            <EyeOutlined onClick={() => setSeePW(true)} />
                          )}
                        </Tooltip>
                      }
                    />

                    {errors.oldPassword && touched.oldPassword ? (
                      <label>{errors.oldPassword}</label>
                    ) : null}
                  </div>
                  <div>
                    <p className="title-input">รหัสผ่านใหม่</p>
                    <Input
                      size="large"
                      id="newPassword"
                      placeholder="กรอกรหัสผ่านใหม่"
                      type={seeNewPW ? "text" : "password"}
                      onChange={handleChange}
                      defaultValue={values.newPassword}
                      suffix={
                        <Tooltip
                          placement="bottom"
                          overlay={
                            <span>
                              {seeNewPW ? "ซ่อนรหัสผ่าน" : " แสดงรหัสผ่าน"}
                            </span>
                          }
                        >
                          {seeNewPW ? (
                            <EyeInvisibleOutlined
                              onClick={() => setSeeNewPW(false)}
                            />
                          ) : (
                            <EyeOutlined onClick={() => setSeeNewPW(true)} />
                          )}
                        </Tooltip>
                      }
                    />
                    {errors.newPassword && touched.newPassword ? (
                      <label>{errors.newPassword}</label>
                    ) : null}
                  </div>
                </Space>
                <Button
                  type="primary"
                  shape="round"
                  htmlType="submit"
                  loading={loading ? true : false}
                  disabled={
                    values.newPassword && values.oldPassword ? false : true
                  }
                >
                  เปลี่ยนรหัสผ่าน
                </Button>
              </form>
            </>
          )}
        </Formik>
      </div>
    </Modal>
  );
};
export default ChangePasswordModal;
