import { useEffect, useState } from 'react';
import { Input, DatePicker, message } from 'antd';
import { Formik } from 'formik';
import { MemberData } from '../../../interface';
import { useDispatch } from 'react-redux';
import { action } from '../../redux/request';
import { getMemberData, updateMemberData } from '../../redux/auth/authCrud';
import { actions } from '../../redux/auth/authRedux';
import moment from 'moment';
import * as Yup from 'yup';
import ChangePasswordModal from '../../components/modal/changePasswordModal';
import VerifyModal from '../../components/modal/verifyModal';
import './index.scss';

interface MemberProps {
   pageId: string;
}

export enum VerifyType {
   VerifyPhoneNumber = 'VerifyPhoneNumber',
   VerifyEmail = 'VerifyEmail'
}

const MemberInfo = (props: MemberProps) => {
   const dateFormat = 'DD/MM/YYYY';
   const dispatch = useDispatch();
   const [memberData, setMemberData] = useState<MemberData>();
   const [changePasswordModal, setChangePasswordModal] = useState(false);
   const [isVerify, setIsVerify] = useState(false);
   // eslint-disable-next-line @typescript-eslint/no-unused-vars
   const [verifyType, setVerifyType] = useState(VerifyType.VerifyPhoneNumber);

   useEffect(() => {
      fetchMemberData();
   }, []);

   const fetchMemberData = async () => {
      try {
         dispatch(action.setProcess(true, props.pageId));
         const data = await getMemberData();
         setMemberData(data);
         dispatch(action.setProcess(false, props.pageId));
      } catch (error) {
         dispatch(action.setProcess(false, props.pageId));
      }
   };

   const updateMember = async (value: MemberData) => {
      try {
         message.loading('กำลังอัพเดทข้อมูล');
         const data = await updateMemberData(value);
         setMemberData(value);
         dispatch(actions.updateToken(data.data?.userToken as string));
         message.success('อัพเดทข้อมูลเรียบร้อย');
      } catch (error) {
         message.error(JSON.stringify(error));
      }
   };

   const validationSchema = Yup.object().shape({
      firstName: Yup.string().required('กรุณากรอกชื่อ'),
      lastName: Yup.string().required('กรุณากรอกนามสกุุล'),
      laserNumber: Yup.string().required('กรุณากรอกรหัสหลังบัตรประชาชน'),
      refName: Yup.string(),
      refPhoneNumber: Yup.string(),
      refEmail: Yup.string().email('รูปแบบของอีเมลไม่ถูกต้อง')
   });

   return (
      <>
         {memberData ? (
            <div className="member-info fadeIn">
               <Formik
                  initialValues={{
                     userName: memberData?.userName,
                     firstName: memberData?.firstName,
                     lastName: memberData?.lastName,
                     birthDate: memberData?.birthDate,
                     phoneNumber: memberData?.phoneNumber,
                     email: memberData?.email,
                     nationalID: memberData?.nationalID,
                     memberStatus: memberData?.memberStatus,
                     refName: memberData?.refName,
                     refEmail: memberData?.refEmail,
                     refPhoneNumber: memberData?.refPhoneNumber,
                     laserNumber: memberData?.laserNumber || ''
                  }}
                  validationSchema={validationSchema}
                  onSubmit={(data: MemberData) => {
                     updateMember(data);
                  }}
                  validator={() => ({})}
               >
                  {({ errors, touched, values, handleChange, handleSubmit, setFieldValue }) => (
                     <>
                        <form
                           onSubmit={(e) => {
                              e.preventDefault();
                              handleSubmit();
                           }}
                        >
                           <div className="title-memberInfo">
                              <h1>ข้อมูลส่วนตัว</h1>
                              {/* <Button
                      type="primary"
                      shape="round"
                      htmlType="submit"
                      className="secondary-background-button"
                      disabled={
                        values.laserNumber !== '' ||
                        memberData?.firstName !== values['firstName'] ||
                        memberData?.lastName !== values['lastName'] ||
                        memberData.refName !== values['refName'] ||
                        memberData.birthDate !== values['birthDate'] ||
                        memberData?.refPhoneNumber !==
                          values['refPhoneNumber'] ||
                        memberData?.refEmail !== values['refEmail']
                          ? false
                          : true
                      }
                    >
                      บันทึก
                    </Button> */}
                           </div>
                           <div className="form-container">
                              <div className="form-col">
                                 <p>ชื่อผู้ใช้งาน</p>

                                 <Input
                                    size="large"
                                    name="username"
                                    defaultValue={values.userName}
                                    onChange={handleChange}
                                    disabled
                                 />
                              </div>

                              <div className="form-col">
                                 <p>ชื่อ</p>
                                 <Input
                                    size="large"
                                    name="firstName"
                                    defaultValue={values.firstName}
                                    onChange={handleChange}
                                    disabled
                                 />
                                 {errors.firstName && touched.firstName ? (
                                    <label>{errors.firstName}</label>
                                 ) : null}
                              </div>

                              <div className="form-col">
                                 <p>นามสกุล</p>
                                 <Input
                                    size="large"
                                    name="lastName"
                                    defaultValue={values.lastName}
                                    onChange={handleChange}
                                    disabled
                                 />
                                 {errors.lastName && touched.lastName ? (
                                    <label>{errors.lastName}</label>
                                 ) : null}
                              </div>

                              <div className="form-col">
                                 <p>เลขบัตรประจำตัวประชาชน</p>
                                 <Input
                                    size="large"
                                    name="nationalID"
                                    defaultValue={values.nationalID}
                                    onChange={handleChange}
                                    disabled
                                 />
                              </div>

                              <div className="form-col">
                                 <p>วัน/เดือน/ปีเกิด</p>
                                 <DatePicker
                                    id="birthDate"
                                    name="birthDate"
                                    defaultValue={moment.unix(values.birthDate!)}
                                    onChange={(e) => {
                                       setFieldValue(
                                          'birthDate',
                                          parseInt(moment(e).format('x')) / 1000
                                       );
                                    }}
                                    format={dateFormat}
                                    className="date-picker"
                                    size="large"
                                    allowClear={false}
                                    disabled
                                 />
                              </div>

                              <div className="form-col">
                                 <div className="flex">
                                    <p>เบอร์มือถือ</p>
                                    {/* <p
                          className="change-password-button"
                          onClick={() => {
                            setIsVerify(true);
                            setVerifyType(VerifyType.VerifyPhoneNumber);
                          }}
                        >
                          เปลี่ยนเบอร์มือถือ
                        </p> */}
                                 </div>
                                 <Input
                                    size="large"
                                    name="phoneNumber"
                                    type="number"
                                    defaultValue={values.phoneNumber}
                                    onChange={handleChange}
                                    disabled
                                 />
                              </div>

                              <div className="form-col">
                                 <div className="flex">
                                    <p>อีเมล</p>
                                    {/* <p
                          className="change-password-button"
                          onClick={() => {
                            setIsVerify(true);
                            setVerifyType(VerifyType.VerifyEmail);
                          }}
                        >
                          เปลี่ยนอีเมล
                        </p> */}
                                 </div>
                                 <Input
                                    size="large"
                                    name="email"
                                    defaultValue={values.email}
                                    onChange={handleChange}
                                    disabled
                                 />
                              </div>

                              <div className="form-col">
                                 <div className="flex">
                                    <p>รหัสผ่าน</p>
                                    <p
                                       className="change-password-button"
                                       onClick={() => setChangePasswordModal(true)}
                                    >
                                       เปลี่ยนรหัสผ่าน
                                    </p>
                                 </div>
                                 <Input
                                    size="large"
                                    name="password"
                                    type="password"
                                    defaultValue={values.phoneNumber}
                                    onChange={handleChange}
                                    disabled
                                 />
                              </div>

                              {/* {memberData.memberStatus === MemberStatus.onGround && (
                      <div className="form-col">
                        <p>รหัสหลังบัตรประชาชน</p>
                        <Input
                          size="large"
                          name="laserNumber"
                          defaultValue={values.laserNumber}
                          onChange={handleChange}
                        />
                      </div>
                    )} */}
                           </div>

                           {memberData.refName && memberData.refPhoneNumber && memberData.refEmail && (
                              <>
                                 <div className="title-memberInfo" style={{ marginTop: '32px' }}>
                                    <h1>ข้อมูลบุคคลอ้างอิง</h1>
                                 </div>
                                 <div className="form-container">
                                    <div className="form-col">
                                       <p>ชื่อนาม-สกุล</p>
                                       <Input
                                          size="large"
                                          name="refName"
                                          defaultValue={values.refName}
                                          onChange={handleChange}
                                          disabled
                                       />
                                       {errors.email && touched.email ? (
                                          <label>{errors.refEmail}</label>
                                       ) : null}
                                    </div>

                                    <div className="form-col">
                                       <p>เบอร์มือถือ</p>
                                       <Input
                                          size="large"
                                          name="refPhoneNumber"
                                          defaultValue={values.refPhoneNumber}
                                          onChange={handleChange}
                                          disabled
                                       />
                                    </div>

                                    <div className="form-col">
                                       <p>อีเมล</p>
                                       <Input
                                          size="large"
                                          name="refEmail"
                                          defaultValue={values.refEmail}
                                          onChange={handleChange}
                                          disabled
                                       />
                                    </div>
                                 </div>
                              </>
                           )}
                        </form>
                        {changePasswordModal && (
                           <ChangePasswordModal
                              visible={changePasswordModal}
                              onClose={() => setChangePasswordModal(false)}
                           />
                        )}
                        {isVerify && (
                           <VerifyModal
                              visible={isVerify}
                              onClose={() => setIsVerify(false)}
                              verifyType={verifyType}
                              email={memberData.email}
                           />
                        )}
                     </>
                  )}
               </Formik>
            </div>
         ) : null}
      </>
   );
};
export default MemberInfo;
