import { Row } from 'antd';
import { useEffect, useState } from 'react';
import { action } from '../../../redux/request';
import { showErrorModal } from '../../../components/error';
import { rentReset, requestFromServer } from '../../../redux/rental/action';
import { MemoContentCover } from './contentCover';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { EmptyDataView } from '../../../components/nodata';
import { HibraryRootState } from '../../../../redux/rootReducer';
import {
    getLicenseContent,
    resetDisplayModal,
    saveDisplayModal
} from '../../../redux/bookshelf/request';
import {
    bookShelfContent,
    bookShelfResponse,
    ContentStatistic
} from '../../../../interface/bookShelf';
import { ContentType, RentalStatus } from '../../../../interface';

import ReadingNow from './readingNow';
import dayjs from 'dayjs';
import { bookShelfActions } from '../../../redux/bookshelf/bookShelfRedux';

const Rented = () => {
    const dispatch = useDispatch();
    const readingNowKey = 'readingNow-TKP';
    const [licenseUID, setLicenseUID] = useState<string>();
    const [contents, setContents] = useState<bookShelfResponse>();

    useEffect(() => {
        // resetDisplayModal();
    }, []);

    const { request, darkMode, authState, bookShelfState } = useSelector(
        (state: HibraryRootState) => ({
            request: state.fetch,
            darkMode: state.darkMode,
            authState: state.auth,
            bookShelfState: state.bookShelf
        }),
        shallowEqual
    );

    const getReadingNow = () => {
        try {
            const data = JSON.parse(
                localStorage.getItem(readingNowKey) ?? ''
            ) as bookShelfContent[];
            return data;
        } catch (error) {
            return [];
        }
    };

    const [readingNowList, setReadingNowList] = useState<bookShelfContent[]>(getReadingNow() ?? []);

    useEffect(() => {
        fetchLicenseContent();
    }, []);

    useEffect(() => {
        if (contents) {
            updateReadingNow();
            setReadingNowList(getReadingNow());
        }
    }, [contents]);

    const fetchLicenseContent = async () => {
        try {
            dispatch(action.setProcess(true, 'BookShelfPage'));
            const data = await getLicenseContent();
            if (data) {
                setContents(data);
                if (data.isAlertPopupTip) checkOpenModal();
            }
        } finally {
            dispatch(action.setProcess(false, 'BookShelfPage'));
        }
    };

    const checkOpenModal = async () => {
        const currentChecked: boolean = bookShelfState.displayModalChecked ?? false;
        const currentDate = dayjs().unix();
        const storedDate = dayjs.unix(bookShelfState.modalDate ?? currentDate);
        const timeDifferenceInMonth = dayjs().diff(storedDate, 'month');
        const timeDifferenceInDay = dayjs().diff(storedDate, 'day');
        // const timeDifferenceInMinutes = dayjs().diff(storedDate, 'minute');

        if (bookShelfState.modalDate) {
            if (authState.isLogin) {
                if (
                    (currentChecked === true && timeDifferenceInMonth > 6) ||
                    (currentChecked === false && timeDifferenceInDay >= 1)
                ) {
                    dispatch(
                        bookShelfActions.initialBookShelf({
                            tooltipModal: true,
                            modalDate: currentDate,
                            displayModalChecked: false
                        })
                    );
                } else if (currentChecked === true && timeDifferenceInMonth < 6) {
                    await saveDisplayModal();
                    dispatch(bookShelfActions.clearBookShelfState());
                }
            } else {
                if (
                    (currentChecked === true && timeDifferenceInMonth > 6) ||
                    (currentChecked === false && timeDifferenceInDay >= 1)
                ) {
                    dispatch(
                        bookShelfActions.initialBookShelf({
                            tooltipModal: true,
                            modalDate: currentDate,
                            displayModalChecked: false
                        })
                    );
                }
            }
        } else {
            dispatch(
                bookShelfActions.initialBookShelf({
                    tooltipModal: true,
                    modalDate: currentDate,
                    displayModalChecked: false
                })
            );
        }
    };

    const onReturnContent = (licenseUID: string, contentType: ContentType, fetch = true) => {
        switch (contentType) {
            case ContentType.ebook: {
                const getIndex = contents?.ebook.content!.findIndex(
                    (el) => el.licenseUID === licenseUID
                );
                const license = contents?.ebook.content[getIndex!];
                if (fetch) {
                    fetchReturnContent(license!);
                    removeReadingNow(license!.licenseUID);
                } else {
                    removeLicense(license!.licenseUID, license!.contentType);
                    removeReadingNow(license!.licenseUID);
                }
                break;
            }
            case ContentType.magazine: {
                const getIndex = contents?.magazine.content!.findIndex(
                    (el) => el.licenseUID === licenseUID
                );
                const license = contents?.magazine.content[getIndex!];
                if (fetch) {
                    fetchReturnContent(license!);
                    removeReadingNow(license!.licenseUID);
                } else {
                    removeLicense(license!.licenseUID, license!.contentType);
                    removeReadingNow(license!.licenseUID);
                }
                break;
            }
            case ContentType.audioBook: {
                const getIndex = contents?.audioBook.content!.findIndex(
                    (el) => el.licenseUID === licenseUID
                );
                const license = contents?.audioBook.content[getIndex!];
                if (fetch) {
                    fetchReturnContent(license!);
                    removeReadingNow(license!.licenseUID);
                } else {
                    removeLicense(license!.licenseUID, license!.contentType);
                    removeReadingNow(license!.licenseUID);
                }
                break;
            }
            case ContentType.video: {
                const getIndex = contents?.video.content!.findIndex(
                    (el) => el.licenseUID === licenseUID
                );
                const license = contents?.video.content[getIndex!];
                if (fetch) {
                    fetchReturnContent(license!);
                    removeReadingNow(license!.licenseUID);
                } else {
                    removeLicense(license!.licenseUID, license!.contentType);
                    removeReadingNow(license!.licenseUID);
                }
                break;
            }
        }
    };

    const fetchReturnContent = async (license: bookShelfContent) => {
        try {
            dispatch(action.setProcess(true, license.licenseUID));
            setLicenseUID(license.licenseUID);
            await requestFromServer(
                license.contentUID,
                RentalStatus.Rented,
                license.contentType,
                license.licenseType,
                license.licenseUID
            );
            dispatch(rentReset());
            removeLicense(license.licenseUID, license.contentType);
            setLicenseUID(undefined);
            dispatch(action.setProcess(false, license.licenseUID));
        } catch (error) {
            showErrorModal(error as any);
            dispatch(action.setProcess(false, license.licenseUID));
        }
    };

    const removeLicense = (licenseUID: string, contentType: ContentType) => {
        switch (contentType) {
            case ContentType.ebook: {
                const getIndex = contents?.ebook.content.findIndex(
                    (el) => el.licenseUID === licenseUID
                );
                if (getIndex! >= 0) {
                    contents?.ebook.content.splice(getIndex!, 1);
                }
                setContents({ ...contents! });
                break;
            }
            case ContentType.magazine: {
                const getIndex = contents?.magazine.content.findIndex(
                    (el) => el.licenseUID === licenseUID
                );
                if (getIndex! >= 0) {
                    contents?.magazine.content.splice(getIndex!, 1);
                }
                setContents({ ...contents! });
                break;
            }
            case ContentType.audioBook: {
                const getIndex = contents?.audioBook.content.findIndex(
                    (el) => el.licenseUID === licenseUID
                );
                if (getIndex! >= 0) {
                    contents?.audioBook.content.splice(getIndex!, 1);
                }
                setContents({ ...contents! });
                break;
            }
            case ContentType.video: {
                const getIndex = contents?.video.content.findIndex(
                    (el) => el.licenseUID === licenseUID
                );
                if (getIndex! >= 0) {
                    contents?.video.content.splice(getIndex!, 1);
                }
                setContents({ ...contents! });
                break;
            }
        }
    };

    const addReadingNow = (license: bookShelfContent, contentType: ContentType) => {
        const newReading = addReadingNowToLocalStorage(license, readingNowList!);
        const newArray: bookShelfContent[] = [];
        setReadingNowList(newArray.concat(newReading));

        switch (contentType) {
            case ContentType.ebook: {
                const getIndex = contents?.ebook.content.findIndex(
                    (el) => el.licenseUID === license.licenseUID
                );
                if (getIndex! >= 0) {
                    const getLicense = contents?.ebook.content[getIndex!];
                    getLicense!.readingDate = dayjs().unix();
                    setContents({ ...contents! });
                }
            }
        }
    };

    const addReadingNowToLocalStorage = (
        readingData: bookShelfContent,
        rawReadingData: bookShelfContent[]
    ) => {
        const index = rawReadingData.findIndex((el) => el.licenseUID === readingData.licenseUID);
        if (index < 0) {
            readingData.readingDate = dayjs().unix();
            rawReadingData.push(readingData);
        } else {
            rawReadingData[index].readingDate = dayjs().unix();
        }
        const sortData = rawReadingData.sort((a, b) => b.readingDate! - a.readingDate!);
        setReadingNow(sortData);
        return sortData;
    };

    const setReadingNow = (readingData: bookShelfContent[]) => {
        localStorage.setItem(readingNowKey, JSON.stringify(readingData));
    };

    const removeReadingNow = (licenseUID: string) => {
        const getIndex = readingNowList!.findIndex((el) => el.licenseUID === licenseUID);
        const license = readingNowList![getIndex];
        const newReading = removeReadingNowFromLocalStorage(license, readingNowList ?? []);
        const newArray: bookShelfContent[] = [];
        setReadingNowList(newArray.concat(newReading));
    };

    const selectProgression = (el: bookShelfContent) => {
        switch (el.contentType) {
            case ContentType.video:
                if (el.watchProgress) return Math.floor(el.watchProgress.lastProgress * 100);
                break;
            case ContentType.audioBook:
                if (el.listenPercent) {
                    return Math.floor(el.listenPercent!);
                }
                break;
            default:
                if (el.locator && el.locator.locations && el.locator.locations.totalProgression) {
                    return Math.floor(el.locator.locations.totalProgression * 100);
                }
        }
    };

    const removeReadingNowFromLocalStorage = (
        readingData: bookShelfContent,
        rawReadingData: bookShelfContent[]
    ) => {
        if (readingData && readingData.licenseUID) {
            const index = rawReadingData.findIndex(
                (el) => el.licenseUID === readingData.licenseUID
            );

            if (index >= 0) {
                rawReadingData.splice(index, 1);
                const sortData = rawReadingData.sort((a, b) => a.readingDate! - b.readingDate!);
                setReadingNow(sortData);
                return sortData;
            }
        }
        return rawReadingData;
    };

    const updateReadingNow = () => {
        const contentData = contents;
        const readNowData = readingNowList;

        readNowData.map((el, currentIndex) => {
            switch (el.contentType) {
                case ContentType.video: {
                    const index = contentData!.video.content.findIndex(
                        (e) => e.licenseUID === el.licenseUID
                    );
                    if (index < 0) {
                        removeReadingNow(el.licenseUID);
                    } else {
                        if (contentData?.video.content[index].watchProgress) {
                            readNowData[currentIndex].watchProgress =
                                contentData?.video.content[index].watchProgress;
                            setReadingNow(readNowData);
                        }
                    }
                    break;
                }
                case ContentType.ebook: {
                    const index = contentData!.ebook.content.findIndex(
                        (e) => e.licenseUID === el.licenseUID
                    );

                    if (index < 0) {
                        removeReadingNow(el.licenseUID);
                    } else {
                        if (contentData?.ebook.content[index].locator) {
                            readNowData[currentIndex].locator =
                                contentData?.ebook.content[index].locator;
                            setReadingNow(readNowData);
                        }
                    }
                    break;
                }
                case ContentType.magazine: {
                    const index = contentData!.magazine.content.findIndex(
                        (e) => e.licenseUID === el.licenseUID
                    );
                    if (index < 0) {
                        removeReadingNow(el.licenseUID);
                    } else {
                        if (contentData?.magazine.content[index].locator) {
                            readNowData[currentIndex].locator =
                                contentData?.magazine.content[index].locator;
                            setReadingNow(readNowData);
                        }
                    }
                    break;
                }
                case ContentType.audioBook: {
                    const index = contentData!.audioBook.content.findIndex(
                        (e) => e.licenseUID === el.licenseUID
                    );
                    if (index < 0) {
                        removeReadingNow(el.licenseUID);
                    } else {
                        if (contentData?.audioBook.content[index].listenPercent) {
                            readNowData[currentIndex].listenPercent =
                                contentData?.audioBook.content[index].listenPercent;
                            setReadingNow(readNowData);
                        }
                    }
                    break;
                }
            }
        });
    };

    const convertTime = (timeReading: number) => {
        let timeText = '';
        const hours = Math.floor(timeReading / 60);
        const minute = Math.floor((timeReading / 60) % 60);

        if (hours / 60 > 1) {
            timeText += Math.floor(hours / 60) + ' ชั่วโมง ';
        }
        if (minute > 0) {
            timeText += minute + ' นาที';
        }

        return timeText;
    };

    const renderContentSegment = (props: ContentStatistic, title: string) => {
        return (
            <div className="bookShelf-container fadeIn">
                <div className="header-section">
                    <h2>{title}</h2>
                    {authState.isLogin && (
                        <p style={darkMode.darkMode ? { color: darkMode.darkTheme.GREY5 } : {}}>
                            เวลาในการอ่าน {convertTime(props.totalTime)}
                        </p>
                    )}
                </div>
                <Row gutter={[16, 16]} className="rented-container">
                    {props.content?.map((el) => (
                        <MemoContentCover
                            keyUID={el.licenseUID}
                            content={el}
                            totalProgression={selectProgression(el)}
                            addReadingNow={addReadingNow}
                            onReturnContent={onReturnContent}
                            loadingUID={licenseUID!}
                        />
                    ))}
                </Row>
            </div>
        );
    };

    const renderReadingNow = () => {
        return (
            <div className="bookShelf-container">
                <div className="header-section">
                    <h2>ล่าสุด</h2>
                </div>
                <ReadingNow
                    contents={readingNowList}
                    onReturnContent={onReturnContent}
                    addReadingNow={addReadingNow}
                    loadingUID={licenseUID!}
                />
            </div>
        );
    };

    return (
        <>
            {process.env.REACT_APP_ENV === 'development' && (
                <button onClick={async () => await resetDisplayModal()}>Reset Popup</button>
            )}
            {readingNowList.length > 0 && renderReadingNow()}

            {contents && contents.ebook.content.length > 0
                ? renderContentSegment(contents.ebook, 'อีบุ๊ก')
                : null}
            {contents && contents.magazine.content.length > 0
                ? renderContentSegment(contents.magazine!, 'นิตยสาร/หนังสือพิมพ์')
                : null}
            {contents && contents.audioBook.content.length > 0
                ? renderContentSegment(contents.audioBook, 'หนังสือเสียง')
                : null}
            {contents && contents.video.content.length > 0
                ? renderContentSegment(contents.video, 'วีดีโอ')
                : null}

            {contents === undefined ||
            (contents?.ebook.content.length === 0 &&
                contents?.magazine.content.length === 0 &&
                contents?.audioBook.content.length === 0 &&
                contents?.video.content.length === 0) ? (
                <>
                    {!request.loading && <EmptyDataView minHeight={50} description="ไม่มีรายการ" />}
                </>
            ) : null}
        </>
    );
};

export default Rented;
