import axios, { AxiosError } from 'axios';
import { ContentType, HtiResponse, LicenseType } from '../../../interface';
import { FetchStatus } from '../request';
import { genRanHex } from '../utilities';

interface DownloadContentResponse {
   fileType: string;
   requestURL: string;
}

export interface DownloadContentState {
   requestStatus: FetchStatus;
   contentUID?: string;
   licenseUID?: string;
   contentType?: ContentType;
   licenseType?: LicenseType;
   readerURL?: string;
   response?: HtiResponse;
}

const initialData: DownloadContentState = {
   requestStatus: FetchStatus.Init
};

export interface DownloadContentAction {
   payload?: DownloadContentActionPayload;
   type: DownloadContentActionType;
}

export enum DownloadContentActionType {
   CreateDownload = 'CreateDownload[DownloadContent]',
   CheckLicense = 'CheckLicense[DownloadContent]',
   Complete = 'CompleteGenerateContent[DownloadContent]',
   Error = 'Error[DownloadContent]',
   Reset = 'Reset[DownloadContent]'
}

export interface DownloadContentActionPayload {
   contentUID?: string;
   licenseUID?: string;
   contentType?: ContentType;
   licenseType?: LicenseType;
   readerURL?: string;
   response?: HtiResponse;
}

export const reducer = (
   state = initialData,
   action: DownloadContentAction
): DownloadContentState => {
   switch (action.type) {
      case DownloadContentActionType.CheckLicense: {
         return {
            ...state,
            requestStatus: FetchStatus.Process,
            contentUID: action.payload?.contentUID,
            licenseType: action.payload?.licenseType,
            licenseUID: action.payload?.licenseUID,
            contentType: action.payload?.contentType
         };
      }
      case DownloadContentActionType.CreateDownload: {
         return {
            ...state,
            requestStatus: FetchStatus.Process
         };
      }
      case DownloadContentActionType.Complete: {
         return {
            ...state,
            requestStatus: FetchStatus.Complete,
            readerURL: action.payload?.readerURL
         };
      }
      case DownloadContentActionType.Error: {
         return {
            ...state,
            requestStatus: FetchStatus.Error,
            response: action.payload?.response
         };
      }
      default:
         return state;
   }
};

export const fulfillContent =
   (
      contentUID: string,
      licenseUID?: string,
      contentType: ContentType = ContentType.ebook,
      licenseType: LicenseType = LicenseType.Rent
   ) =>
   async (dispatch: (arg0: DownloadContentAction) => void) => {
      dispatch(setCheckLicense(contentUID, licenseUID, contentType, licenseType));
      try {
         const response = await getDownloadContent(
            contentUID,
            licenseUID,
            contentType,
            licenseType
         );
         dispatch(setCreateDownload());
         if (licenseType === LicenseType.Sample) {
            dispatch(setComplete(response?.requestURL ?? ''));
         } else {
            const download = await createEncryptContent(
               response!.requestURL,
               contentUID,
               response!.fileType
            );
            dispatch(setComplete(download?.readerURL ?? ''));
         }
      } catch (error) {
         const getError = error as AxiosError<HtiResponse>;
         dispatch(setError(getError.response?.data));
      }
   };

const setCheckLicense = (
   contentUID: string,
   licenseUID?: string,
   contentType: ContentType = ContentType.ebook,
   licenseType: LicenseType = LicenseType.Rent
): DownloadContentAction => {
   return {
      type: DownloadContentActionType.CheckLicense,
      payload: {
         contentUID,
         licenseUID,
         contentType,
         licenseType
      }
   };
};
const setCreateDownload = (): DownloadContentAction => {
   return {
      type: DownloadContentActionType.CreateDownload
   };
};

const setError = (response?: HtiResponse): DownloadContentAction => {
   return {
      type: DownloadContentActionType.Error,
      payload: { response: response }
   };
};

const setComplete = (readerURL: string): DownloadContentAction => {
   return {
      type: DownloadContentActionType.Complete,
      payload: { readerURL }
   };
};
const getDownloadContent = async (
   contentUID: string,
   licenseUID?: string,
   contentType: ContentType = ContentType.ebook,
   licenseType: LicenseType = LicenseType.Rent
) => {
   const path = `license/${contentType}/${contentUID}/download`;
   const {
      data: { data }
   } = await axios.post<HtiResponse<DownloadContentResponse>>(path, {
      licenseType,
      licenseUID
   });
   return data;
};
interface EncryptContentResponse {
   fulfillmentURL?: string;
   licensePassword?: LicensePassword;
   readerURL?: string;
}
interface LicensePassword {
   salt: string;
   algorithm: string;
}
const createEncryptContent = async (
   encryptionUrl: string,
   contentUID: string,
   fileType: string,
   encryptKey: string = genRanHex(32)
) => {
   const {
      data: { data }
   } = await axios.post<HtiResponse<EncryptContentResponse>>(encryptionUrl, {
      contentUID,
      encryptKey,
      fileType
   });
   return data;
};
export const action = { fulfillContent };
