import { SearchCategoryType } from '../../../interface';

export enum SearchCategoryPageType {
   SearchCategorySection = 'searchCategorySection',
   SearchContentSection = 'searchContentSection',
   SearchCategoryContentList = 'searchCategoryContentList',
   SearchSubcategoryList = 'searchSubcategoryList',
   SearchCategoryContentTypeSection = 'searchCategoryContentTypeSection'
}

export enum SearchCategoryActionType {
   SearchCategorySection = 'SearchCategoryActionType[SearchCategorySection]',
   SearchContentSection = 'SearchCategoryActionType[SearchContentSection]',
   SearchCategoryContentList = 'SearchCategoryActionType[SearchCategoryContentList]',
   SearchSubcategoryList = 'SearchCategoryActionType[SearchSubcategoryList]',
   SearchCategoryContentTypeSection = 'SearchCategoryActionType[searchCategoryContentTypeSection]'
}

export interface SearchCategoryState {
   currentPage: SearchCategoryPageType;
   currentCategory?: SearchCategoryType;
}

export interface SearchCategoryAction {
   type: SearchCategoryActionType;
   payload?: SearchCategoryType;
}

const initialData: SearchCategoryState = {
   currentPage: SearchCategoryPageType.SearchCategorySection
};

export const reducer = (state = initialData, action: SearchCategoryAction): SearchCategoryState => {
   switch (action.type) {
      case SearchCategoryActionType.SearchCategorySection: {
         return { currentPage: SearchCategoryPageType.SearchCategorySection };
      }
      case SearchCategoryActionType.SearchContentSection: {
         return {
            currentPage: SearchCategoryPageType.SearchContentSection,
            currentCategory: action.payload
         };
      }
      case SearchCategoryActionType.SearchCategoryContentList: {
         return {
            currentPage: SearchCategoryPageType.SearchCategoryContentList,
            currentCategory: action.payload
         };
      }
      case SearchCategoryActionType.SearchSubcategoryList: {
         return {
            currentPage: SearchCategoryPageType.SearchSubcategoryList,
            currentCategory: action.payload
         };
      }
      case SearchCategoryActionType.SearchCategoryContentTypeSection: {
         return {
            currentPage: SearchCategoryPageType.SearchCategoryContentTypeSection,
            currentCategory: action.payload
         };
      }
      default: {
         return state;
      }
   }
};

const setSearchCategorySection = (): SearchCategoryAction => {
   return {
      type: SearchCategoryActionType.SearchCategorySection
   };
};

const setSearchContentSection = (currentCategory: SearchCategoryType): SearchCategoryAction => {
   return {
      type: SearchCategoryActionType.SearchContentSection,
      payload: currentCategory
   };
};

const setSearchCategoryContentList = (
   currentCategory: SearchCategoryType
): SearchCategoryAction => {
   return {
      type: SearchCategoryActionType.SearchCategoryContentList,
      payload: currentCategory
   };
};
const setSearchSubcategoryList = (currentCategory: SearchCategoryType): SearchCategoryAction => {
   return {
      type: SearchCategoryActionType.SearchSubcategoryList,
      payload: currentCategory
   };
};
const setSearchCategoryContentTypeSection = (
   currentCategory: SearchCategoryType
): SearchCategoryAction => {
   return {
      type: SearchCategoryActionType.SearchCategoryContentTypeSection,
      payload: currentCategory
   };
};

export const actions = {
   setSearchCategorySection,
   setSearchContentSection,
   setSearchCategoryContentList,
   setSearchSubcategoryList,
   setSearchCategoryContentTypeSection
};
