import { Col, Drawer, Modal, Row, Space } from 'antd';
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { ContentType, RentalStatus } from '../../../interface';
import { signIn, signOut } from '../../redux/auth/authCrud';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { actions } from '../../redux/auth/authRedux';
import { HibraryRootState } from '../../../redux/rootReducer';
import { setDarkTheme } from '../../redux/theme/action';
import SVG from 'react-inlinesvg';
import DeleteAccountModal from '../../components/modal/deleteAccountModal';

export enum MemberProfileType {
   MemberData = 'memberData',
   WishList = 'wishList',
   Follow = 'follow',
   ManageDevice = 'manageDevice',
   Logout = 'logout',
   Notification = 'notification',
   DeleteAccount = 'deleteAccount'
}

const MenuTab = () => {
   const dispatch = useDispatch();
   const history = useHistory();
   const [drawer, setDrawer] = useState<boolean>(false);
   const [deleteModal, setDeleteModal] = useState<boolean>(false);

   const { authState, darkMode, theme } = useSelector(
      (state: HibraryRootState) => ({
         authState: state.auth,
         darkMode: state.darkMode,
         theme: state.theme
      }),
      shallowEqual
   );

   const redirectURL = () => {
      localStorage.setItem('redirectURL', window.location.href);
   };

   const checkPath = () => {
      const path = localStorage.getItem('redirectURL')?.split('?');
      if (path && path?.length >= 1) {
         return path[0];
      } else {
         return localStorage.getItem('redirectURL');
      }
   };

   const getLoginPath = async () => {
      try {
         const response = await signIn(checkPath());

         if (response.data.data) {
            localStorage.setItem('redirectURL', response.data.data.redirectURL);
            window.location.href = response.data.data?.authorizeURL;
         }
      } catch (error) {
         console.log(error);
      }
   };

   const renderDrawer = () => {
      const menu = [
         {
            title: 'ข้อมูลส่วนตัว',
            icon: '/images/tk-icon/memberInfo-icon.svg',
            path: `/memberProfile/${MemberProfileType.MemberData}`,
            key: 1
         },

         {
            title: 'Wishlist',
            icon: '/images/tk-icon/notification-icon.svg',
            path: `/memberProfile/${MemberProfileType.WishList}`,
            key: 2
         },
         {
            title: 'การติดตาม',
            icon: '/images/tk-icon/follow-icon.svg',
            path: `/memberProfile/${MemberProfileType.Follow}`,
            key: 3
         },
         {
            title: 'จัดการอุปกรณ์',
            icon: '/images/tk-icon/device-icon.svg',
            path: `/memberProfile/${MemberProfileType.ManageDevice}`,
            key: 4
         },
         {
            title: `${darkMode.darkMode ? 'ปิดใช้งานโหมดกลางคืน' : 'เปิดใช้งานโหมดกลางคืน'}`,
            icon: `/images/tk-icon/Vector.svg`,
            key: 5
         },
         {
            title: `การแจ้งเดือน`,
            icon: `/images/tk-icon/notification-icon.svg`,
            path: `/memberProfile/${MemberProfileType.Notification}`,
            key: 6
         },
         {
            title: `ลบบัญชี`,
            icon: `/images/tk-icon/delete-account-icon.svg`,
            key: 7
         },
         {
            title: 'ออกจากระบบ',
            icon: '/images/tk-icon/signOut-icon.svg',
            key: 8
         }
      ];

      const showConfirmSignOut = () => {
         Modal.confirm({
            title: 'ต้องการลงชื่อออกจากระบบ',
            okText: 'ออกจากระบบ',
            cancelText: 'ยกเลิก',
            onOk: () => {
               SignOut();
            }
         });
      };

      const SignOut = async () => {
         try {
            const response = await signOut();
            if (response) {
               dispatch(actions.logout());
               window.location.href = response.logoutURL;
            }
         } catch (error) {
            console.log(error);
         }
      };

      const darkModeEnabled = () => {
         if (!theme.darkMode) {
            dispatch(setDarkTheme(true));
         } else {
            dispatch(setDarkTheme(false));
         }
      };

      const checkDrawerClick = (key: number, path: string) => {
         if (key === 5) {
            darkModeEnabled();
         } else if (key === 7) {
            setDeleteModal(true);
         } else if (key === 8) {
            showConfirmSignOut();
            setDrawer(false);
         } else {
            history.push(`${path}`);
            setDrawer(false);
         }
      };

      return (
         <Drawer
            title={<h2 style={{ marginBottom: '5px' }}>เมนู</h2>}
            placement="left"
            onClose={() => setDrawer(false)}
            visible={drawer}
            width={250}
            closable={false}
         >
            <Space size={0} direction="vertical">
               {authState.isLogin ? (
                  <>
                     {menu.map((e) => {
                        return (
                           <div
                              key={e.key}
                              onClick={() => checkDrawerClick(e.key, e.path!)}
                              className="menu"
                           >
                              <SVG src={`${e.icon}`} width="15" height="auto" />
                              <span>{e.title}</span>
                           </div>
                        );
                     })}
                  </>
               ) : (
                  <>
                     <div className="menu">
                        {/* <SVG src=`` width="15" height="auto" /> */}
                        <span
                           onClick={() => {
                              redirectURL();
                              getLoginPath();
                           }}
                        >
                           เข้าสู่ระบบ
                        </span>
                     </div>
                  </>
               )}
            </Space>
         </Drawer>
      );
   };

   return (
      <>
         <div className="menu-tab-mobile">
            <Row className="menu-tab-container">
               <Col span={4}>
                  <Link to={`/${RentalStatus.Rented}`} className="book-shelf">
                     <Space direction="vertical" size={3}>
                        <SVG src="/images/tk-icon/book-shelf-icon.svg" />
                        <span>ชั้นหนังสือ</span>
                     </Space>
                  </Link>
               </Col>

               <Col span={4}>
                  <Link to={`/${ContentType.ebook}/categories/all`} className="book-shelf">
                     <Space direction="vertical" size={3}>
                        <SVG src="/images/tk-icon/category-icon.svg" />
                        <span>หมวดหมู่</span>
                     </Space>
                  </Link>
               </Col>

               <Col span={4}>
                  <Link to={`/`} className="book-shelf">
                     <Space direction="vertical" size={3}>
                        <SVG src="/images/tk-icon/home-icon.svg" />
                        <span>ห้องสมุด</span>
                     </Space>
                  </Link>
               </Col>

               <Col span={4}>
                  <Link to="/search" className="book-shelf">
                     <Space direction="vertical" size={3}>
                        <SVG src="/images/tk-icon/search-icon.svg" />
                        <span>ค้นหา</span>
                     </Space>
                  </Link>
               </Col>

               <Col span={4} className="book-shelf">
                  <Space direction="vertical" size={3}>
                     <SVG
                        src="/images/tk-icon/menu-icon.svg"
                        onClick={() => setDrawer(true)}
                        style={{ transform: 'translateY(5px)' }}
                     />
                     <span>เมนู</span>
                  </Space>
               </Col>
            </Row>
         </div>
         {renderDrawer()}
         <DeleteAccountModal visible={deleteModal} onCancel={() => setDeleteModal(false)} />
      </>
   );
};

export default MenuTab;
