import { useEffect, useState } from 'react';
import {
   bookShelfContent,
   historyLicenseResponse
} from '../../../../interface/bookShelf';
import { HibraryRootState } from '../../../../redux/rootReducer';
import { action } from '../../../redux/request';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { removeSplash } from '../../../helpers/removeSplash';
import { getHistoryLicense } from '../../../redux/bookshelf/request';
import { RentalStatus } from '../../../../interface';
import { rentReset, requestFromServer } from '../../../redux/rental/action';
import { showErrorModal } from '../../../components/error';
import { MemoHistoryLicenseCover } from './contentCover';
import { EmptyDataView } from '../../../components/nodata';

interface HistoryLicenseProps {
   pageId: string;
}

const HistoryLicense = (props: HistoryLicenseProps) => {
   const dispatch = useDispatch();
   const [historyContent, setHistoryContent] =
      useState<historyLicenseResponse>();

   const { environment, request } = useSelector(
      (state: HibraryRootState) => ({
         environment: state.environment,
         request: state.fetch
      }),
      shallowEqual
   );

   useEffect(() => {
      fetchHistoryContent(false);
   }, []);

   useEffect(() => {
      if (environment.endOfScreen && !request.loading) {
         if (historyContent?.lastEvaluatedKey !== undefined) {
            fetchHistoryContent(true);
         }
      }
   }, [environment]);

   const fetchHistoryContent = async (appendData: boolean) => {
      try {
         dispatch(action.setProcess(true, props.pageId));
         const lastEvaluatedKey = appendData
            ? historyContent?.lastEvaluatedKey
            : undefined;

         const lastStatus = historyContent?.lastStatus ?? 1;

         const { data } = await getHistoryLicense(lastStatus, lastEvaluatedKey);

         if (appendData) {
            setListModel(data.data!);
         } else {
            setHistoryContent(data.data);
         }

         dispatch(removeSplash);
         dispatch(action.setProcess(false, props.pageId));
      } catch (error) {
         dispatch(action.setProcess(false, props.pageId));
         dispatch(removeSplash);
      }
   };

   const setListModel = (data: historyLicenseResponse) => {
      const current = historyContent as historyLicenseResponse;
      const currentData = data as historyLicenseResponse;
      if (currentData) {
         setHistoryContent({
            history: current.history.concat(currentData?.history ?? []),
            lastEvaluatedKey: currentData.lastEvaluatedKey!,
            lastStatus: currentData.lastStatus
         });
      }
   };

   const onReturnContent = (licenseUID: string, fetch = true) => {
      const getIndex = historyContent?.history.findIndex(
         (el) => el.licenseUID === licenseUID
      );

      const license = historyContent?.history[getIndex!];
      if (fetch) {
         fetchReturnContent(license!, getIndex!);
      } else {
         removeLicense(getIndex!);
      }
   };

   const fetchReturnContent = async (
      license: bookShelfContent,
      index: number
   ) => {
      try {
         dispatch(action.setProcess(true, license.licenseUID));
         await requestFromServer(
            license.contentUID,
            RentalStatus.Rented,
            license.contentType,
            license.licenseType,
            license.licenseUID
         );
         dispatch(rentReset());
         removeLicense(index);
         dispatch(action.setProcess(false, license.licenseUID));
      } catch (error) {
         showErrorModal(error as any);
         dispatch(action.setProcess(false, license.licenseUID));
      }
   };

   const removeLicense = (index: number) => {
      const newHistoryContent = JSON.parse(JSON.stringify(historyContent));

      newHistoryContent.history[index] = {
         ...newHistoryContent.history[index],
         licenseStatus: RentalStatus.Rent
      };

      setHistoryContent(newHistoryContent);
   };

   return (
      <div className="bookShelf-container fadeIn">
         {historyContent && historyContent.history.length > 0 && (
            <div className="history-license-container">
               {historyContent?.history.map((el) => (
                  <MemoHistoryLicenseCover
                     keyUID={el.licenseUID}
                     content={el}
                     onReturnContent={onReturnContent}
                  />
               ))}
            </div>
         )}
         {historyContent && historyContent?.history.length < 1 && (
            <EmptyDataView minHeight={50} description="ไม่มีรายการ" />
         )}
      </div>
   );
};

export default HistoryLicense;
