import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { HibraryRootState } from '../../../../redux/rootReducer';
import { showErrorModal } from '../../../components/error';
import { EmptyDataView } from '../../../components/nodata';
import { getBookingContent } from '../../../redux/bookshelf/request';
import { rentReset, requestFromServer } from '../../../redux/rental/action';
import { action } from '../../../redux/request';
import { RentalStatus } from '../../../redux/store/storeModel';
import { MemoBookingLicenseCover } from './contentCover';
import {
   bookingResponse,
   bookShelfContent
} from '../../../../interface/bookShelf';

interface BookingProps {
   pageId: string;
}

const Booking = (props: BookingProps) => {
   const dispatch = useDispatch();
   const [bookingContent, setBookingContent] = useState<bookingResponse>();
   const { environment, request, rental } = useSelector(
      (state: HibraryRootState) => ({
         environment: state.environment,
         request: state.fetch,
         rental: state.rental
      }),
      shallowEqual
   );

   useEffect(() => {
      fetchBookingContent();
   }, []);

   useEffect(() => {
      if (rental.stage === 3) {
         fetchBookingContent();
      }
   }, [rental]);

   useEffect(() => {
      if (environment.endOfScreen) {
         if (bookingContent?.lastEvaluatedKey !== undefined) {
            fetchBookingContent();
         }
      }
   }, [environment]);

   const fetchBookingContent = async () => {
      try {
         dispatch(action.setProcess(true, props.pageId));
         const data = await getBookingContent();

         if (data) {
            setBookingContent(data.data.data);
         }
         dispatch(action.setProcess(false, props.pageId));
      } catch (error) {
         dispatch(action.setProcess(false, props.pageId));
      }
   };

   const fetchReturnContent = async (license: bookShelfContent) => {
      try {
         dispatch(action.setProcess(true, license.licenseUID));
         await requestFromServer(
            license.contentUID,
            RentalStatus.WaitingListed,
            license.contentType,
            license.licenseType,
            license.licenseUID
         );
         dispatch(rentReset());
         removeLicense(license.licenseUID);
         dispatch(action.setProcess(false, license.licenseUID));
      } catch (error) {
         showErrorModal(error as any);
         dispatch(action.setProcess(false, license.licenseUID));
      }
   };

   const onCancelBooking = (licenseUID: string, fetch = true) => {
      const getIndex = bookingContent?.booking.findIndex(
         (el) => el.licenseUID === licenseUID
      );
      const license = bookingContent?.booking[getIndex!];
      if (fetch) {
         fetchReturnContent(license!);
      } else {
         removeLicense(license!.licenseUID);
      }
   };

   const removeLicense = (licenseUID: string) => {
      const getIndex = bookingContent?.booking.findIndex(
         (el) => el.licenseUID === licenseUID
      );
      if (getIndex! >= 0) {
         bookingContent?.booking.splice(getIndex!, 1);
      }
      setBookingContent({ ...bookingContent! });
   };

   return (
      <>
         {bookingContent?.booking?.length === 0 || !bookingContent?.booking ? (
            <>
               {!request.loading && (
                  <EmptyDataView minHeight={50} description="ไม่มีรายการ" />
               )}
            </>
         ) : (
            <div className="bookShelf-container fadeIn">
               <div className="history-license-container">
                  {bookingContent?.booking?.map((el) => (
                     <MemoBookingLicenseCover
                        keyUID={el.licenseUID}
                        content={el}
                        onReturnContent={onCancelBooking}
                     />
                  ))}
               </div>
            </div>
         )}
      </>
   );
};

export default Booking;
