import { FC } from 'react';
import { Link } from 'react-router-dom';
import {
   ContentType,
   Contributor,
   ContributorRole,
   ContributorValue
} from '../../../../interface';
import { getWriterContentRoute } from '../../../routes';

import '../pattern.scss';

interface ContributorProps {
   title: ContributorRole;
   contributor: Contributor[];
   contentType: ContentType;
}

const ContributorView: FC<ContributorProps> = (props) => {
   const writerName = props.contributor.filter((e) => e.role === props.title);

   const setTitle = (role: ContributorRole) => {
      switch (role) {
         case ContributorRole.Author:
            return ContributorValue.Author;
         case ContributorRole.COAuthor:
            return ContributorValue.COAuthor;
         case ContributorRole.CoNarrator:
            return ContributorValue.CoNarrator;
         case ContributorRole.Created:
            return ContributorValue.Created;
         case ContributorRole.Editor:
            return ContributorValue.Editor;
         case ContributorRole.Illustrator:
            return ContributorValue.Illustrator;
         case ContributorRole.Narrator:
            return ContributorValue.Narrator;
         case ContributorRole.Translator:
            return ContributorValue.Translator;
         case ContributorRole.AudioNarrator:
            return ContributorValue.AudioNarrator;
         case ContributorRole.MixEngineer:
            return ContributorValue.MixEngineer;
         case ContributorRole.MotionAndAnimation:
            return ContributorValue.MotionAndAnimation;
      }
   };

   return (
      <>
         <div className="contributor">
            <span className="name-title">
               {props.title !== ContributorRole.Author &&
                  setTitle(props.title) + ' : '}
            </span>
            {writerName.map((el, index) => (
               <>
                  {props.title !== ContributorRole.Author && (
                     <>
                        <Link
                           to={getWriterContentRoute(
                              el.writerUID,
                              props.contentType
                           )}
                           key={index}
                           className="writer-name"
                        >
                           {el.writerName}
                        </Link>

                        {index < writerName.length - 1 ? ' , ' : null}
                     </>
                  )}
               </>
            ))}
         </div>
      </>
   );
};

export default ContributorView;
