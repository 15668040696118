export interface ClientInfo {
  clientName: string;
  logoImages: string;
  companyPrefix: string;
  isPrivateLibrary: boolean;
  isPublicRegister: boolean;
  registerDomain?: string[];
  contactList?: ContactList;
  theme?: Themes;
  displayName: string;
  isPublicLibrary?: boolean;
  storeType?: string;
  storeStatus?: StoreStatus;
  showClientLogo?: boolean;
  isAnonymous?: boolean;
  geoLocation?: limitArea[];
  requireEmployeeNumber?: boolean;
  registerByPhoneNumber?: boolean;
}

export enum StoreStatus {
  Trial = 'trial',
  Activate = 'activate'
}
export interface limitArea {
  distance: number;
  latitude: number;
  longitude: number;
  name: string;
}
export interface ClientLocation {
  latitude: number;
  longitude: number;
}
export interface Themes {
  Nav: string;
  Surfaces: string;
  OnError: string;
  OnBackground: string;
  OnPrimaryVariants: string;
  OnPrimary: string;
  Primary: string;
  PrimaryVariants: string;
  OnSurfaces: string;
  Disable: string;
  OnDisable: string;
  Secondary: string;
  Background: string;
  OnNav: string;
  Error: string;
  OnSecondary: string;
  GREY7: string;
  GREY8: string;
  GREY5: string;
  GREY6: string;
  GREY3: string;
  GREY4: string;
  GREY1: string;
  GREY2: string;
}

export interface ContactList {
  website?: string;
  address?: string;
  phoneNumber: string;
  mobilePhone: string;
  LineID?: string;
  companyName: string;
  Twitter?: string;
  Instagram?: string;
  Facebook?: string;
  email: string;
  YouTube?: string;
}
export interface newThemes {
  Nav: string;
  Surfaces: string;
  OnError: string;
  OnBackground: string;
  OnPrimaryVariants: string;
  OnPrimary: string;
  Primary: string;
  PrimaryVariants: string;
  OnSurfaces: string;
  Disable: string;
  OnDisable: string;
  Secondary: string;
  Background: string;
  OnNav: string;
  Error: string;
  OnSecondary: string;
  GREY7: string;
  GREY8: string;
  GREY5: string;
  GREY6: string;
  GREY3: string;
  GREY4: string;
  GREY1: string;
  GREY2: string;
}
