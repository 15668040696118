import SwiperCore, { Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { action } from '../../redux/home';
import {
   getCategoryRoute,
   getContentDetailRoute,
   getListSubCategoryRoute
} from '../../routes';
import { useHistory } from 'react-router-dom';
import { Banner, BannerType } from '../../../interface/home';

import 'swiper/swiper.scss';
import './index.scss';

import { useDispatch } from 'react-redux';

SwiperCore.use([Autoplay, Pagination]);

interface BannerList {
   banners: Banner[];
}

const BannerView = (props: BannerList) => {
   const history = useHistory();
   const dispatch = useDispatch();
   const SwiperBanner = {
      1: {
         slidesPerView: 1
      },
      768.98: {
         slidesPerView: 2.4
      },
      1200.98: {
         slidesPerView: 2.7
      },
      1920.98: {
         slidesPerView: 3
      }
   };

   const checkBannerType = (bannerType: BannerType, banner: Banner) => {
      switch (bannerType) {
         case BannerType.Category: {
            return history.push(
               getCategoryRoute(banner.value!, banner.contentType)
            );
         }
         case BannerType.ContentDetail: {
            return history.push(
               getContentDetailRoute(banner.value!, banner.contentType)
            );
         }
         case BannerType.Subcategory: {
            return history.push(
               getListSubCategoryRoute(
                  banner.value!,
                  banner.subValue!,
                  banner.contentType
               )
            );
         }
         case BannerType.Url: {
            return (window.location.href = banner.value!);
         }
      }
   };

   return (
      <div className="fadeIn">
         <Swiper
            breakpoints={SwiperBanner}
            spaceBetween={10}
            centeredSlides={true}
            loop={true}
            pagination={{
               dynamicBullets: true
            }}
            autoplay={{ delay: 3000, disableOnInteraction: false }}
         >
            {props.banners?.map((e, index) => (
               <>
                  {e.image.desktop && (
                     <SwiperSlide key={`banner_${index}`}>
                        <div className="cover-banner">
                           <img
                              src={`${e.image.desktop}`}
                              onClick={() => {
                                 checkBannerType(e.type, e);
                                 if (e.title !== '')
                                    dispatch(action.setTitle(e.title));
                              }}
                           />
                        </div>
                     </SwiperSlide>
                  )}
                  {e.image.desktop && props.banners.length <= 1 && (
                     <SwiperSlide key={`banner_${index}`}>
                        <div className="cover-banner">
                           <img
                              src={`${e.image.desktop}`}
                              onClick={() => {
                                 checkBannerType(e.type, e);
                                 if (e.title !== '')
                                    dispatch(action.setTitle(e.title));
                              }}
                           />
                        </div>
                     </SwiperSlide>
                  )}
               </>
            ))}
         </Swiper>
      </div>
   );
};

export default BannerView;
