import { ContentByCategory, ContentType } from '.';
import { Themes } from './client';
import { Device } from './device';
import { Content, ContentMetaData } from './home';
import { HistoryContent, License } from './rental';

export interface HtiResponse<T = any> {
   status: boolean;
   data?: T;
   errorCode?: string;
   message?: string;
}

export interface Onboard {
   title: string;
   body: string;
   type: string;
   url: string;
}

export interface OnboardResponse {
   guestToken: string;
   onBoards: Onboard[];
   themes: Themes;
}

export interface LoginResponse {
   userToken: string;
   client: Client;
   firebaseToken?: string;
}

export interface EditMemberResponse {
   userToken: string;
   emailToken?: string;
   mobileToken?: string;
   ref?: string;
   themes: Themes;
}

export interface LoginRedirect {
   authorizeURL: string;
   redirectURL: string;
   state: string;
}

export enum MemberStatus {
   online = 'online',
   onGround = 'onground'
}

export enum NotificationType {
   waitingList = 'WaitingList',
   url = 'URL'
}

export interface MemberData {
   birthDate?: number;
   email: string;
   firstName: string;
   lastName: string;
   memberStatus: MemberStatus;
   nationalID: string;
   phoneNumber: string;
   userName: string;
   refName?: string;
   refEmail?: string;
   refPhoneNumber?: string;
   laserNumber?: string;
}

export interface MemberResponse {
   data: MemberData;
}

export interface LogoutResponse {
   logoutURL: string;
   redirectURL: string;
}

export interface MappingTokenResponse {
   userToken: string;
   themes: Themes;
}

export interface Notification {
   contentUID: string;
   notificationUID: string;
   read: boolean;
   sortKey: string;
   memberID: string;
   timeStamp: number;
   contentType: string;
   title: string;
   body: string;
   type: NotificationType;
   coverImage: string;
   coverImageWebp: string;
   timeExpire: string;
}

export interface NotificationResponse {
   notification: Notification[];
   lastEvaluatedKey?: string;
}

interface Client {
   themes: Themes;
}
export interface ContestSetListResponse {
   contents: Content[];
   title?: string;
   lastEvaluatedKey?: ContestSetListLastEvaluatedKey;
}

interface ContestSetListLastEvaluatedKey {
   lastPosition: number;
}

export interface ListDevicesResponse {
   memberID: string;
   devices: Device[];
}

export interface ListContentByCreatorResponse {
   ebooks: Content[];
   contents: Content[];
   videos?: Content[];
   podcasts?: Content[];
   title?: string;
   lastEvaluatedKey: ListContentByCreatorLastEvaluatedKey;
}

export interface ListContentByWriterResponse {
   ebooks: Content[];
   contents: Content[];
   videos?: Content[];
   podcasts?: Content[];
   lastEvaluatedKey: ListContentByWriterEvaluatedKey;
   title?: string;
}

export interface ListContentByKeywordResponse {
   ebooks: Content[];
   contents: Content[];
   videos?: Content[];
   podcasts?: Content[];
   lastEvaluatedKey: ListContentByKeywordEvaluatedKey;
   title?: string;
}

interface ListContentByCreatorLastEvaluatedKey {
   contentUID: string;
   clientUID: string;
   sortKeyCreator: string;
}

interface ListContentByWriterEvaluatedKey {
   contentUID: string;
   clientUID: string;
   sortKeyCreator: string;
}

interface ListContentByKeywordEvaluatedKey {
   contentUID: string;
   clientUID: string;
   sortKeyCreator: string;
}

export interface LicenseResponse {
   licenses: License[];
}

export interface ReadingTimeResponse {
   totalTime: number;
}

export interface GenerateContentResponse {
   readerURL: string;
}
export interface AxiosError<T = HtiErrorResponse> {
   response: AxiosDataError<T>;
}

export interface AxiosDataError<T = HtiErrorResponse> {
   data: T;
}

export interface HtiErrorResponse<T = any> {
   message: string;
   errorCode: string;
   status: boolean;
   data?: T;
}

export interface HistoryListResponse {
   ebooks: HistoryContent[];
   lastEvaluatedKey?: HistoryListLastEvaluatedKey;
}

interface HistoryListLastEvaluatedKey {
   memberUID: string;
   sortKey: string;
   clientUID: string;
   registerDate: number;
}

export interface QueyListResponse {
   ebooks: Content[];
   lastEvaluatedKey?: { [x: string]: any };
}

//new
export interface bookshelfLicense {
   content: ContentMetaData[];
   totalTime: number;
}

export interface bookshelfResponse {
   length: number;
   ebook: bookshelfLicense[];
   audioBook: bookshelfLicense[];
   magazine: bookshelfLicense[];
   video: bookshelfLicense[];
}

export interface category {
   categoryName: string;
   categoryUID: string;
   subcategory: subcategory[];
}

export interface subcategory {
   subcategoryName: string;
   subcategoryUID: string;
}

export interface ListContentResponse {
   contents: ContentMetaData[];
   categories: category[];
   title?: string;
   subTitle?: string;
   lastEvaluatedKey?: string;
}

export interface ListAllCategoryResponse {
   contentsByCategory: ContentByCategory[];
   title?: string;
}

export interface ListAllSubcategoryResponse {
   contentBySubcategory: ContentMetaData[];
   lastEvaluatedKey?: string;
   title?: string;
}

export interface ListContentWriterResponse {
   contents: ContentMetaData[];
   lastEvaluatedKey?: string;
   title?: string;
}

export interface SearchResultResponse<T = any> {
   audioBooks?: T;
   ebooks?: T;
   magazines?: T;
   videos?: T;
   publishers?: SearchPublisherType[];
   category?: SearchCategoryType[];
   subcategory?: SearchCategoryType[];
   tags?: string[];
   writers?: SearchWriterType[];
}

export interface SearchContentSubcategoryResponse {
   contentBySubcategory: SearchContentSubcategoryType;
   title: string;
}

export interface IgnoreContentTypeResponse {
   contents: SearchContentSubcategoryType;
   title: string;
}

export interface SearchContentSubcategoryType {
   audioBook: ContentMetaData[];
   ebook: ContentMetaData[];
   magazine: ContentMetaData[];
   video: ContentMetaData[];
}

export interface SearchCategoryResponse {
   category: SearchCategoryType[];
   subcategory: SearchCategoryType[];
}

export interface SearchWriterResponse {
   writers: SearchWriterType[];
}

export interface SearchPublisherResponse {
   publishers: SearchPublisherType[];
}

export interface SearchTagType {
   tags: string[];
}

export interface SearchCategoryType {
   categoryUID?: string;
   categoryName?: string;
   subcategoryName?: string;
   subcategoryUID?: string;
   coverImage: string;
   coverImageWebp: string;
   status: number;
   contentType?: ContentType;
   libraryValue?: libraryValueType;
}

export interface SearchWriterType {
   followStatus: boolean;
   writerName: string;
   writerUID: string;
   image: string;
   imageWebp: string;
   contentType?: ContentType;
}

export interface SearchPublisherType {
   followStatus: boolean;
   publisherName: string;
   publisherUID: string;
   publisherImage: string;
   contentType?: ContentType;
}

export interface SearchSubcategoryTypeResponse {
   subcategory: SearchCategoryType[];
}

export interface libraryValueType {
   ebook: number;
   video: number;
   audioBook: number;
   magazine: number;
}

export interface SearchStatisticResponse {
   keywords: string[];
}
