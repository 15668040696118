import { LicenseType, ContentType, SegmentType, HomeList } from '../../../interface/home';
import { QueryParam } from '../../../interface/listEbook';
import { RentalStatus } from '../store/storeModel';
import { osName } from 'react-device-detect';
import { VideoBitrate } from '../../pages/home/video/videoQuality';

export const generatePath = (
   route: string,
   url?: string,
   prefix?: string,
   licenseType?: LicenseType,
   contentType?: ContentType,
   isPublic = true
) => {
   let path = '';
   if (isPublic) {
      path = `/public`;
   }

   if (prefix && isPublic) {
      if (path === '/') {
         path += `${prefix}`;
      } else {
         path += `/${prefix}`;
      }
   }
   path += `/${route}`;

   if (licenseType) {
      if (licenseType !== LicenseType.Rent) {
         path += `/${licenseType!.toLowerCase()}`;
      } else if (!isPublic) {
         path += `/${licenseType!.toLowerCase()}`;
      }
   }
   if (url) {
      path += `/${url}`;
   }
   if (contentType) {
      path += `?contentType=${contentType}`;
   }

   return path;
};

export const generateCreatorPath = (
   route: string,
   model?: string,
   creatorUID?: string,
   prefix?: string,
   licenseType?: LicenseType,
   contentType?: ContentType,
   isPublic = true
) => {
   let path = '';
   if (isPublic) {
      path = `/public`;
      if (prefix) {
         path += `/${prefix}`;
      }
   }
   path += `/${route}`;
   if (licenseType && licenseType !== LicenseType.Rent) {
      path += `/${licenseType.toLowerCase()}`;
   }
   if (model) {
      path += `/${model}`;
   }
   if (creatorUID) {
      path += `/${creatorUID}`;
   }
   if (contentType) {
      path += `?contentType=${contentType}`;
   }

   return path;
};

export const generateWriterPath = (
   route: string,
   model?: string,
   writerName?: string,
   prefix?: string,
   licenseType?: LicenseType,
   contentType?: ContentType,
   isPublic = true
) => {
   let path = '';
   if (isPublic) {
      path = `/public`;
      if (prefix) {
         path += `/${prefix}`;
      }
   }

   path += `/${route}`;
   if (licenseType && licenseType !== LicenseType.Rent) {
      path += `/${licenseType.toLowerCase()}`;
   }
   if (model) {
      path += `/${model}`;
   }
   if (writerName) {
      path += `?writerName=${writerName}`;
   }
   if (contentType) {
      path += `&contentType=${contentType}`;
   }

   return path;
};

export const generateKeywordPath = (
   route: string,
   model?: string,
   keyword?: string,
   prefix?: string,
   licenseType?: LicenseType,
   contentType?: ContentType,
   isPublic = true
) => {
   let path = '';
   if (isPublic) {
      path = `/public`;
      if (prefix) {
         path += `/${prefix}`;
      }
   }

   path += `/${route}`;
   if (licenseType && licenseType !== LicenseType.Rent) {
      path += `/${licenseType.toLowerCase()}`;
   }
   if (model) {
      path += `/${model}`;
   }
   if (keyword) {
      path += `?tag=${keyword}`;
   }
   if (contentType) {
      path += `&contentType=${contentType}`;
   }

   return path;
};

export const generateCategoryPath = (
   route: string,
   queryPram?: string,
   prefix?: string,
   licenseType?: LicenseType,
   contentType?: ContentType,
   isPublic = true
) => {
   let path = '';
   if (isPublic) {
      path = `/public`;
   }
   if (prefix && isPublic) {
      path += `/${prefix}`;
   }
   path += `/${route}`;
   if (licenseType && licenseType !== LicenseType.Rent) {
      path += `/${licenseType.toLowerCase()}`;
   }
   if (queryPram) {
      path += `/${queryPram}`;
   }
   if (contentType) {
      path += `?contentType=${contentType}`;
   }
   return path;
};

export const chkLastEvaluateKey = (lastEvaluate?: any) => {
   if (lastEvaluate !== undefined && JSON.stringify(lastEvaluate) !== JSON.stringify({})) {
      return { lastEvaluatedKey: btoa(JSON.stringify(lastEvaluate)) };
   }
   return {};
};

export const genRanHex = (size: number) =>
   [...Array(size)].map(() => Math.floor(Math.random() * 16).toString(16)).join('');

export const getContentType = (contentType: string) => {
   switch (contentType) {
      case ContentType.audioBook:
         return ContentType.audioBook;
      case ContentType.magazine:
         return ContentType.magazine;
      case ContentType.video:
         return ContentType.video;
      default:
         return ContentType.ebook;
   }
};

export const generateViewAllPath = (contentType: ContentType, content: HomeList) => {
   switch (content.segmentType) {
      case SegmentType.Recommend:
      case SegmentType.Popular:
      case SegmentType.NewContent:
      case SegmentType.AllContent:
         return `/${contentType}/${content.segmentType}/all`;
      case SegmentType.ContentList:
         return `/${contentType}/${content.segmentType}/${0}/${content.uid}`;
      case SegmentType.Category:
         return `/${contentType}/categories/${content.categoryUID}/list`;
      case SegmentType.Subcategory:
         return `/${contentType}/categories/${content.categoryUID}/subcategories/${content.subcategoryUID}`;
      case SegmentType.AllCategories:
         return `/${contentType}/categories/all`;
      default:
         return `/`;
   }
};

export const generateFilterPath = (param: QueryParam) => {
   let path = '?';

   if (param.categoryUID) {
      path += `categoryUID=${param.categoryUID}`;
   }
   if (param.subCategoryUID && path.includes('categoryUID=')) {
      path += `&subcategoryUID=${param.subCategoryUID}`;
   }

   return path;
};

export const setContentType = (contentType: ContentType) => {
   switch (contentType) {
      case ContentType.allContent:
         return 'ทั้งหมด';
      case ContentType.audioBook:
         return 'หนังสือเสียง';
      case ContentType.ebook:
         return 'หนังสือ';
      case ContentType.magazine:
         return 'นิตยสาร';
      case ContentType.video:
         return 'วิดีโอ';
      default:
         return '';
   }
};

export const setButtonText = (status: RentalStatus | LicenseType, contentType: ContentType) => {
   switch (status) {
      case RentalStatus.Rent:
         return `ยืม`;
      case RentalStatus.Rented:
         return `คืน${setContentType(contentType)}`;
      case RentalStatus.WaitingList:
         return `จอง`;
      case RentalStatus.WaitingListed:
         return `ยกเลิกจอง`;
      case RentalStatus.Block:
         return `งดจอง`;
      default:
         return `คืน${setContentType(contentType)}`;
   }
};

export enum OS {
   iOS = 'iOS',
   Android = 'Android',
   MacOS = 'Mac OS'
}

export const getDynamicLinkPath = (url: string) => {
   let linkPath;
   switch (osName) {
      case OS.iOS:
      case OS.MacOS:
         linkPath = `${process.env.REACT_APP_DYNAMIC_LINK_ENDPOINT}/?link=${url}&ibi=${process.env.REACT_APP_IOS_BUNDLE_ID}&isi=${process.env.REACT_APP_IOS_APP_ID}&ifl=https://apps.apple.com/us/app/tk-read/id1599703016&ofl=https://www.hibrary.me`;
         break;
      case OS.Android:
         linkPath = `${process.env.REACT_APP_DYNAMIC_LINK_ENDPOINT}/?link=${url}&apn=${process.env.REACT_APP_ANDROID_APP_ID}`;
         break;
      default:
         linkPath = `${process.env.REACT_APP_DYNAMIC_LINK_ENDPOINT}/?link=${url}&ibi=${process.env.REACT_APP_IOS_BUNDLE_ID}&isi=${process.env.REACT_APP_IOS_APP_ID}`;
         break;
   }
   return linkPath;
};

export const changeVideoBitrate = (bitrate: VideoBitrate, path: string) => {
   let m3u8Path = '';
   const newPath = path.split('.m3u8');
   const splitPath = newPath[0].split(',');

   switch (bitrate) {
      case VideoBitrate.Bitrate480: {
         m3u8Path += splitPath + '_' + VideoBitrate.Bitrate480 + '.m3u8';
         return m3u8Path;
      }
      case VideoBitrate.Bitrate720: {
         m3u8Path += splitPath + '_' + VideoBitrate.Bitrate720 + '.m3u8';
         return m3u8Path;
      }
      case VideoBitrate.Bitrate1080: {
         m3u8Path += splitPath + '_' + VideoBitrate.Bitrate1080 + '.m3u8';

         return m3u8Path;
      }
      default: {
         return m3u8Path;
      }
   }
};

export const convertMonth = (text: string) => {
   const splitText = text.split('/');
   const string = splitText[0] + ' ' + generateMonth(splitText[1]) + ' ' + splitText[2] + ' น.';

   return string;
};

export const generateMonth = (month: string) => {
   switch (month) {
      case '01': {
         return 'ม.ค.';
      }
      case '02': {
         return 'ก.พ.';
      }
      case '03': {
         return 'มี.ค';
      }
      case '04': {
         return 'เม.ย.';
      }
      case '05': {
         return 'พ.ค.';
      }
      case '06': {
         return 'มิ.ย.';
      }
      case '07': {
         return 'ก.ค.';
      }
      case '08': {
         return 'ส.ค.';
      }
      case '09': {
         return 'ก.ย.';
      }
      case '10': {
         return 'ต.ค.';
      }
      case '11': {
         return 'พ.ย.';
      }
      case '12': {
         return 'ธ.ค.';
      }
      default: {
         return 'เดือนไม่ถูกต้อง';
      }
   }
};
