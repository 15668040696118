import { newThemes } from "../../../interface/client";
import { ThemeAction, ThemeActionType, initialState } from "./index";

export const setTheme = (theme: newThemes): ThemeAction => {
  const newTheme: newThemes = Object.assign(initialState.normalTheme, theme);
  newTheme.Primary = theme.Primary;
  newTheme.OnPrimary = theme.OnPrimary;
  return {
    type: ThemeActionType.SetTheme,
    payload: newTheme,
  };
};
export const setDarkTheme = (enable: boolean): ThemeAction => {
  return {
    type: ThemeActionType.SetDarkTheme,
    payload: enable,
  };
};
