import { LoadingOutlined } from '@ant-design/icons';
import { Button, Divider, Dropdown, Menu } from 'antd';
import { FC, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { ContentType } from '../../../interface';
import { HibraryRootState } from '../../../redux/rootReducer';
import { showConfirmLogin } from '../../customUI/RentButton';
import { addFollow, FollowType, getFollow, GetFollowResponse, unFollow } from '../../redux/follow';
import './index.scss';

interface FollowButtonProps {
   style?: React.CSSProperties;
   buttonStyle?: React.CSSProperties;
   contentUID: string;
   contentType: ContentType;
}

export const FollowButton: FC<FollowButtonProps> = ({
   style,
   contentUID,
   contentType,
   buttonStyle
}) => {
   const [follows, setFollows] = useState<GetFollowResponse>();
   const [visible, setVisible] = useState<boolean>(false);
   const [loading, setLoading] = useState<FollowType>();

   const { authen } = useSelector(
      (state: HibraryRootState) => ({
         authen: state.auth
      }),
      shallowEqual
   );

   const onFollow = async (uid: string, type: FollowType, isFollow: boolean, subUID?: string) => {
      setLoading(type);

      if (isFollow) {
         if (type === FollowType.SubCategory) {
            await addFollow(type, uid, subUID);
         } else {
            await addFollow(type, uid);
         }
      } else {
         if (type === FollowType.SubCategory) {
            await unFollow(type, uid, subUID);
         } else {
            await unFollow(type, uid);
         }
      }
      setLoading(undefined);
   };

   const menu = (
      <Menu>
         <Menu.Item>
            {follows?.writer ? (
               <FollowItem
                  isFollow={follows?.writer.followStatus}
                  type={follows?.writer.followKey}
                  title={'นักเขียน'}
                  description={follows?.writer.name}
                  uid={follows?.writer.writerUID}
                  isDivider={true}
                  onFollowClick={onFollow}
                  loading={loading!}
               />
            ) : null}
         </Menu.Item>
         <Menu.Item>
            {follows?.publisher ? (
               <FollowItem
                  isFollow={follows?.publisher.followStatus}
                  type={follows?.publisher.followKey}
                  title={'สำนักพิมพ์'}
                  description={follows?.publisher.name}
                  uid={follows?.publisher.publisherUID}
                  isDivider={true}
                  onFollowClick={onFollow}
                  loading={loading!}
               />
            ) : null}
         </Menu.Item>
         <Menu.Item>
            {follows?.category ? (
               <FollowItem
                  isFollow={follows?.category.followStatus}
                  type={follows?.category.followKey}
                  title={'หมวดหมู่'}
                  description={follows?.category.name}
                  uid={follows?.category.catUID}
                  isDivider={true}
                  onFollowClick={onFollow}
                  loading={loading!}
               />
            ) : null}
         </Menu.Item>
         <Menu.Item>
            {follows?.subcategory ? (
               <FollowItem
                  isFollow={follows?.subcategory.followStatus}
                  type={follows?.subcategory.followKey}
                  title={'หมวดหมู่ย่อย'}
                  description={follows?.subcategory.name}
                  uid={follows?.category.catUID}
                  subUID={follows?.subcategory.subcatUID}
                  isDivider={false}
                  onFollowClick={onFollow}
                  loading={loading!}
               />
            ) : null}
         </Menu.Item>
      </Menu>
   );

   const fetchFollow = async () => {
      const data = await getFollow(contentUID, contentType);
      setFollows(data);
      setVisible(true);
   };

   const handleVisibleChange = (flag: any) => {
      setVisible(flag);
   };

   return (
      <>
         <div style={style} className="follow-container">
            <Dropdown
               overlay={menu}
               placement="bottomCenter"
               trigger={['click']}
               arrow
               visible={visible}
               onVisibleChange={handleVisibleChange}
               className="style-dropdown"
            >
               <Button
                  shape="round"
                  className="secondary-background-button"
                  style={buttonStyle}
                  onClick={() => {
                     if (authen.isLogin) {
                        if (!follows) {
                           fetchFollow();
                        } else {
                           setVisible(false);
                        }
                     } else {
                        showConfirmLogin();
                     }
                  }}
               >
                  ติดตาม +
               </Button>
            </Dropdown>
         </div>
      </>
   );
};

interface FollowItemProps {
   title: string;
   description: string;
   type: FollowType;
   isDivider?: boolean;
   uid: string;
   subUID?: string;
   isFollow: boolean;
   onFollowClick: (uid: string, type: FollowType, isFollow: boolean, subUID?: string) => void;
   loading: string;
}

export const FollowItem: FC<FollowItemProps> = ({
   title,
   isDivider,
   type,
   uid,
   subUID,
   isFollow,
   description,
   onFollowClick,
   loading
}) => {
   const [follow, setFollow] = useState<boolean>(isFollow);
   return (
      <>
         <div className="follow-item-container">
            <div className="follow-item">
               <div className="metadata">
                  <h3>{title}</h3>
                  <p>{description}</p>
               </div>
               <div className="follow-btn-item">
                  <button
                     onClick={() => {
                        setFollow(!follow);
                        onFollowClick(uid, type, !follow, subUID);
                     }}
                  >
                     {loading === type ? (
                        <LoadingOutlined color="#F5333F" />
                     ) : (
                        <img
                           src={follow ? '/images/follow/checked.svg' : '/images/follow/add.svg'}
                           alt="Add"
                        />
                     )}
                  </button>
               </div>
            </div>
         </div>
         {isDivider ? <Divider style={{ marginTop: 0, marginBottom: 0 }} /> : null}
      </>
   );
};
