import React, { FC, useEffect, useState } from "react";
import { HeartFilled, HeartOutlined } from "@ant-design/icons";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { HibraryRootState } from "../../../redux/rootReducer";
import { FetchStatus } from "../../redux/request";
import { action, request } from "../../redux/wishlist";
import { Button } from "antd";
import { ContentType } from "../../../interface";
import { showConfirmLogin } from "../../customUI/RentButton";

import "./index.scss";

interface FavoriteProps {
  contentUID: string;
  contentType: ContentType;
  isFavorite: boolean;
  classCss?: string;
}

export const FavoriteButton: FC<FavoriteProps> = ({
  contentUID,
  contentType,
  isFavorite,
  classCss,
}) => {
  const [favorite, setFavorite] = useState<boolean>(isFavorite);
  const dispatch = useDispatch();
  const { wishlistState: favoriteState, authen } = useSelector(
    (state: HibraryRootState) => ({
      authen: state.auth,
      wishlistState:
        state.wishlist.contentUID === contentUID ? state.wishlist : undefined,
    }),
    shallowEqual
  );
  useEffect(() => {
    if (favoriteState?.requestStatus === FetchStatus.Complete) {
      if (favoriteState.isFavorite !== favorite) {
        setFavorite(favoriteState!.isFavorite ?? false);
      }
    }
  }, [favoriteState]);

  const fetchUpdateWishList = async () => {
    dispatch(action.updateRequestStatus(FetchStatus.Process, contentUID));
    if (favorite) {
      await request.fetchWishlist(contentUID, contentType, false);
      dispatch(action.removeWishList(contentUID));
    } else {
      await request.fetchWishlist(contentUID, contentType, true);
      dispatch(action.addWishList(contentUID));
    }
  };

  const onBtnClick = () => {
    if (authen.isLogin) {
      fetchUpdateWishList();
    } else {
      showConfirmLogin();
    }
  };

  return (
    <>
      <Button
        shape="circle"
        onClick={onBtnClick}
        className={classCss ?? "like-button"}
        icon={favorite ? <HeartFilled /> : <HeartOutlined />}
        loading={
          favoriteState?.requestStatus === FetchStatus.Process ? true : false
        }
      />
    </>
  );
};

export default FavoriteButton;
