import 'antd/dist/antd.css';
import { Link, useLocation } from 'react-router-dom';
import { Breadcrumb } from 'antd';
import { useState, useEffect } from 'react';
import { ContentType } from '../../../interface';

export const BreadcrumbPath = {
   Home: {
      title: 'หน้าแรก',
      path: '/'
   },
   Categories: {
      title: 'หมวดหมู่',
      path: '/categories'
   },
   BookShelf: {
      title: 'ชั้นหนังสือ',
      path: '/bookShelf'
   },
   Profile: {
      title: 'ข้อมูลส่วนตัว',
      path: '/memberProfile'
   }
};

export interface BreadcrumbLink {
   title: string;
   path?: string;
}

export interface BreadcrumbProps {
   addLinks?: BreadcrumbLink[];
}

export interface BreadcrumbList {
   title?: string;
   path?: string;
   param?: any;
}

export const BreadCrumbView = (props: BreadcrumbProps) => {
   const location = useLocation();
   const [links, setLink] = useState<BreadcrumbLink[]>([BreadcrumbPath.Home]);

   useEffect(() => {
      setLink(links.concat(props.addLinks ?? []));
   }, [props.addLinks]);

   useEffect(() => {
      const newPath = creatPath(location.pathname);
      setLink(([BreadcrumbPath.Home] as BreadcrumbLink[]).concat(newPath));
   }, [location]);

   return (
      <div className="breadcrumb">
         <Breadcrumb separator=">">
            {links.map((e: BreadcrumbLink, index: number) => (
               <Breadcrumb.Item key={index}>
                  {index !== links.length - 1 ? (
                     <Link
                        to={{
                           pathname: e.path
                        }}
                     >
                        {e.title}
                     </Link>
                  ) : (
                     e.title
                  )}
               </Breadcrumb.Item>
            ))}
         </Breadcrumb>
      </div>
   );
};

const creatPath = (path: string): BreadcrumbLink[] => {
   const data: BreadcrumbLink[] = [];
   const slitPath = path.split('/');
   data.push(getContentTypeLink(slitPath![1] as ContentType));
   if (slitPath[2]) {
      const link = getSubPathData(slitPath![1] as ContentType, slitPath[2]);
      if (link) data.push(link);
   }
   return data;
};

const getContentTypeLink = (contentType: string): BreadcrumbLink => {
   let title: string;
   let path = '/';
   switch (contentType) {
      case ContentType.ebook:
         title = 'หนังสือ';
         path = '/';
         break;
      case ContentType.video:
         title = 'วิดีโอ';
         path = `/${contentType}`;
         break;
      case ContentType.audioBook:
         title = 'หนังสือเสียง';
         path = `/${contentType}`;
         break;
      case ContentType.magazine:
         title = 'นิตยสาร';
         path = `/${contentType}`;
         break;
      case 'memberProfile':
         title = 'ข้อมูลสมาชิก';
         break;
      case 'Rented':
         title = 'ชั้นหนังสือ';
         break;
      case 'search':
         title = 'หน้าค้นหา';
         break;
      default:
         title = 'อีบุ๊ก';
         break;
   }
   return {
      title: title,
      path: path
   };
};

const getSubPathData = (contentType: ContentType, path: string) => {
   switch (path) {
      case 'categories':
      case 'subcategories':
         return {
            title: 'หมวดหมู่',
            path: `/${contentType}/categories/all`
         };
      default:
         return undefined;
   }
};
