import { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';
import { ip } from '../../../request';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { StoreHomePage } from '../home';
import ScrollToTop from '../../helpers/scrollToTop';
import { Result } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import { HibraryRootState } from '../../../redux/rootReducer';
import { SearchResult, SearchPageType } from '../set/search';
import { removeSplash } from '../../helpers/removeSplash';
import { VideoDetail } from '../home/video/video-detail';
import { MemberProfileType } from './menuTab';
import { isAndroid, isIOS, isIPad13, isMobile, isTablet, osVersion } from 'react-device-detect';
import { SearchAllPageType } from '../set/search/searchAll';
import {
    ContentType,
    LicenseType,
    RentalStatus,
    SegmentType,
    SetType
} from '../../../interface/home';
import {
    ViewAllContent,
    CategoriesPage,
    CategoryContentPage,
    MagazineSetListPage
} from '../content';
import EbookDetail from '../home/ebook/ebook-detail';
import BookShelfView from '../bookshelf/bookShelfView/bookshelf';
import MemberProfile from '../profile';
import Logout from '../auth/Logout';
import TermsAndCondition from './TermsAndCondition';
import Policy from './privacyPolicy';

import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';

const BasePage = () => {
    const dispatch = useDispatch();
    const vh = window.innerHeight * 0.01;
    const [showGoToTop, setShowGoToTop] = useState(false);
    const [showMenuTab, setShowMenuTab] = useState(true);

    const { authState, clientInfo, request } = useSelector(
        (state: HibraryRootState) => ({
            authState: state.auth,
            clientInfo: state.clientInfo,
            request: state.fetch
        }),
        shallowEqual
    );

    document.documentElement.style.setProperty('--vh', `${vh}px`);

    useEffect(() => {
        console.log(`isIOs >>> ${isIOS}`);
        console.log(`isAndroid >>> ${isAndroid}`);
        console.log(`isTablet or IPad >>> ${isTablet}`);
        console.log(`osVersion >>> ${osVersion}`);
    }, []);

    useEffect(() => {
        const setDeviceUID = async () => {
            localStorage.setItem('deviceuid', await ip);
        };

        setDeviceUID();
        window.addEventListener('scroll', function () {
            const scroll = this.scrollY;
            if (scroll > 500) {
                setShowGoToTop(true);
            } else {
                setShowGoToTop(false);
            }

            if (scroll > 200) {
                setShowMenuTab(false);
            } else {
                setShowMenuTab(true);
            }
        });
    }, []);

    const gotoTop = () => {
        if (showGoToTop) {
            return (
                <img
                    src="/images/goToTop.png"
                    alt=""
                    className="gotoTop"
                    onClick={() => {
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                    }}
                />
            );
        } else {
            return '';
        }
    };
    const storeHomePageMemoEbook = useMemo(() => {
        return <StoreHomePage key={uuidv4()} contentType={ContentType.ebook} />;
    }, [authState, clientInfo]);

    const storeHomePageMemoVideo = useMemo(
        () => <StoreHomePage key={uuidv4()} contentType={ContentType.video} />,
        [authState, clientInfo]
    );

    const storeHomePageMemoAudioBook = useMemo(
        () => <StoreHomePage key={uuidv4()} contentType={ContentType.audioBook} />,
        [authState, clientInfo]
    );

    const storeHomePageMemoMagazine = useMemo(
        () => <StoreHomePage contentType={ContentType.magazine} key={uuidv4()} />,
        [authState, clientInfo]
    );

    const useWindowSize = () => {
        const [size, setsize] = useState([0, 0]);
        useLayoutEffect(() => {
            const updateSize = () => {
                setsize([window.innerWidth, window.innerHeight]);
            };
            window.addEventListener('resize', updateSize);
            updateSize();
            return () => window.removeEventListener('resize', updateSize);
        }, []);

        return size;
    };
    const [width] = useWindowSize();

    const setMobileHeight = () => {
        if (isMobile || isIPad13) {
            if (
                request.id === 'ContentDetail' ||
                request.id === 'VideoDetail' ||
                request.id === 'StoreHomeList'
            ) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    };

    const CheckMobileDevice = () => {
        const mobileCss = {
            paddingTop: '136px'
        };

        const tabletCss = {
            paddingTop: '105px'
        };

        if (isMobile && width < 768.98) {
            return mobileCss;
        } else {
            return tabletCss;
        }
    };

    return (
        <div
            className="base-page"
            style={setMobileHeight() && showMenuTab ? CheckMobileDevice() : {}}
        >
            <ScrollToTop>
                {gotoTop()}
                <Switch>
                    <Route
                        path="/"
                        exact
                        render={() => {
                            return storeHomePageMemoEbook;
                        }}
                    />
                    {/* <Route
                  path="/ebook"
                  exact
                  render={() => {
                     return storeHomePageMemoEbook;
                  }}
               /> */}
                    <Route path="/video" exact render={() => storeHomePageMemoVideo} />
                    <Route path="/audiobook" exact render={() => storeHomePageMemoAudioBook} />
                    <Route path="/magazine" exact render={() => storeHomePageMemoMagazine} />
                    <Route
                        path="/rented"
                        exact
                        render={() => <BookShelfView rentStatus={RentalStatus.Rented} />}
                    />
                    <Route
                        path="/waitingList"
                        exact
                        render={() => <BookShelfView rentStatus={RentalStatus.WaitingList} />}
                    />
                    <Route
                        path="/returned"
                        exact
                        render={() => <BookShelfView rentStatus={RentalStatus.Returned} />}
                    />
                    <Route path="/search" exact render={() => <SearchResult />} />
                    <Route
                        path="/search/:searchValue/:currentSearchType"
                        exact
                        render={(props) => (
                            <SearchResult
                                keyword={props.match.params.searchValue}
                                currentSearch={
                                    props.match.params.currentSearchType as SearchPageType
                                }
                            />
                        )}
                    />
                    <Route
                        path="/search/:searchValue/writer/:writerUID"
                        exact
                        render={(props) => (
                            <SearchResult
                                keyword={props.match.params.searchValue}
                                currentSearch={SearchPageType.Writer}
                                writerUID={props.match.params.writerUID}
                            />
                        )}
                    />
                    <Route
                        path="/search/:searchValue/publisher/:publisherUID"
                        exact
                        render={(props) => (
                            <SearchResult
                                keyword={props.match.params.searchValue}
                                currentSearch={SearchPageType.Publisher}
                                publisherUID={props.match.params.publisherUID}
                            />
                        )}
                    />
                    <Route
                        path="/search/:searchValue/writer/:contentType/:writerUID"
                        exact
                        render={(props) => (
                            <SearchResult
                                keyword={props.match.params.searchValue}
                                currentSearch={SearchPageType.Writer}
                                writerUID={props.match.params.writerUID}
                                contentType={props.match.params.contentType as ContentType}
                            />
                        )}
                    />
                    <Route
                        path="/search/:searchValue/publisher/:contentType/:publisherUID"
                        exact
                        render={(props) => (
                            <SearchResult
                                keyword={props.match.params.searchValue}
                                currentSearch={SearchPageType.Publisher}
                                publisherUID={props.match.params.publisherUID}
                                contentType={props.match.params.contentType as ContentType}
                            />
                        )}
                    />
                    <Route
                        path="/search/:searchValue/all/searchAllContentList/:contentType"
                        exact
                        render={(props) => (
                            <SearchResult
                                keyword={props.match.params.searchValue}
                                currentSearch={SearchPageType.All}
                                contentType={props.match.params.contentType as ContentType}
                                searchAllType={SearchAllPageType.SearchAllContentList}
                            />
                        )}
                    />
                    <Route
                        path="/:contentType/all"
                        exact
                        render={(props) => (
                            <ViewAllContent
                                contentType={props.match.params.contentType as ContentType}
                                segmentType={SegmentType.AllContent}
                            />
                        )}
                    />
                    <Route
                        path="/magazine-set/:setType"
                        render={(props) => (
                            <MagazineSetListPage setType={props.match.params.setType as SetType} />
                        )}
                    />
                    <Route
                        path="/video/:uid"
                        exact
                        render={(props) => (
                            <VideoDetail
                                contentType={ContentType.video}
                                uid={props.match.params.uid}
                            />
                        )}
                    />
                    <Route
                        path="/audioBook/:uid"
                        exact
                        render={(props) => (
                            <EbookDetail
                                contentType={ContentType.audioBook}
                                uid={props.match.params.uid}
                            />
                        )}
                    />
                    <Route
                        path="/magazine/:uid"
                        exact
                        render={(props) => (
                            <EbookDetail
                                contentType={ContentType.magazine}
                                uid={props.match.params.uid}
                            />
                        )}
                    />
                    <Route
                        path="/ebook/:uid"
                        exact
                        render={(props) => (
                            <EbookDetail
                                contentType={ContentType.ebook}
                                uid={props.match.params.uid}
                            />
                        )}
                    />
                    <Route
                        path="/:contentType/categories/:catId/list"
                        render={(props) => (
                            <CategoryContentPage
                                catId={props.match.params.catId}
                                contentType={props.match.params.contentType as ContentType}
                            />
                        )}
                    />
                    <Route
                        path="/:contentType/categories/:catId/subcategories/:subcatUID"
                        render={(props) => (
                            <ViewAllContent
                                contentType={props.match.params.contentType as ContentType}
                                catId={props.match.params.catId}
                                subcatId={props.match.params.subcatUID}
                                licenseType={LicenseType.Rent}
                            />
                        )}
                    />
                    <Route
                        path="/:contentType/categories/all"
                        render={(props) => (
                            <CategoriesPage
                                contentType={props.match.params.contentType as ContentType}
                            />
                        )}
                    />
                    <Route
                        path="/:contentType/writer/:writerUID"
                        render={(props) => (
                            <ViewAllContent
                                contentType={props.match.params.contentType as ContentType}
                                writerUID={props.match.params.writerUID}
                            />
                        )}
                    />

                    <Route
                        path="/:contentType/publisher/:publisherUID"
                        render={(props) => (
                            <ViewAllContent
                                contentType={props.match.params.contentType as ContentType}
                                publisherUID={props.match.params.publisherUID}
                            />
                        )}
                    />
                    <Route
                        path="/:contentType/magazineSet/:setUID"
                        render={(props) => (
                            <ViewAllContent
                                contentType={props.match.params.contentType as ContentType}
                                setUID={props.match.params.setUID}
                            />
                        )}
                    />
                    <Route
                        path="/:contentType/:segmentType/:fromBanner/:uid"
                        render={(props) => (
                            <ViewAllContent
                                contentType={props.match.params.contentType as ContentType}
                                segmentType={props.match.params.segmentType as SegmentType}
                                fromBanner={props.match.params.fromBanner}
                                uid={props.match.params.uid}
                            />
                        )}
                    />
                    <Route
                        path="/:contentType/:segmentType/:uid"
                        render={(props) => (
                            <ViewAllContent
                                contentType={props.match.params.contentType as ContentType}
                                segmentType={props.match.params.segmentType as SegmentType}
                                uid={props.match.params.uid}
                            />
                        )}
                    />
                    <Route
                        path="/memberProfile/:memberProfileType"
                        exact
                        render={(props) => (
                            <MemberProfile
                                memberProfileType={
                                    props.match.params.memberProfileType as MemberProfileType
                                }
                            />
                        )}
                    />
                    <Route path="/privacyPolicy" exact render={() => <Policy />} />
                    <Route path="/termsAndCondition" exact render={() => <TermsAndCondition />} />
                    <Route path="/logout" exact render={() => <Logout />} />
                    <Route
                        path="/error/not-found"
                        exact
                        render={() => (
                            <>
                                {dispatch(removeSplash)}
                                <Result
                                    status="404"
                                    title="404"
                                    subTitle="Sorry, the page you visited does not exist."
                                    style={{ minHeight: 'calc(100vh - 105px - 180px)' }}
                                />
                            </>
                        )}
                    />
                    <Route
                        path="/error/not-found-organization"
                        exact
                        render={() => (
                            <>
                                {dispatch(removeSplash)}
                                <Result
                                    status="500"
                                    title="009"
                                    subTitle="Sorry, not found your organization."
                                    style={{ minHeight: 'calc(100vh - 105px - 180px)' }}
                                />
                            </>
                        )}
                    />
                    <Redirect to="/error/not-found" />
                </Switch>
            </ScrollToTop>
        </div>
    );
};
export default BasePage;
