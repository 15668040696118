import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

export enum BookShelfActionType {
    InitialValue = 'InitialBookShelf',
    ClearBookShelf = 'ClearBookShelf',
    SetModalTooltip = 'SetModalTooltip',
    SetCheckbox = 'SetCheckBox'
}

interface BookShelfAction {
    type: BookShelfActionType;
    payload?: BookShelfState;
}

export interface BookShelfState {
    tooltipModal?: boolean;
    displayModalChecked?: boolean;
    modalDate?: number;
}

const initialBookShelfState: BookShelfState = {
    tooltipModal: false,
    displayModalChecked: false
    // modalDate: dayjs().unix()
};

export const reducer = persistReducer(
    {
        storage,
        key: 'hibrary-web-bookShelf'
        //   whitelist: ['tooltipModal', 'modalDate', 'displayModalChecked']
    },
    (state: BookShelfState = initialBookShelfState, action: BookShelfAction): BookShelfState => {
        switch (action.type) {
            case BookShelfActionType.InitialValue:
                return {
                    ...state,
                    tooltipModal: action.payload?.tooltipModal,
                    modalDate: action.payload?.modalDate,
                    displayModalChecked: action.payload?.displayModalChecked
                };
            case BookShelfActionType.ClearBookShelf:
                return {
                    ...state,
                    tooltipModal: false,
                    modalDate: undefined,
                    displayModalChecked: false
                };
            case BookShelfActionType.SetModalTooltip:
                return {
                    ...state,
                    tooltipModal: action.payload?.tooltipModal
                };
            case BookShelfActionType.SetCheckbox:
                return {
                    ...state,
                    displayModalChecked: action.payload?.displayModalChecked
                };
            default:
                return state;
        }
    }
);

export const bookShelfActions = {
    initialBookShelf: (payload: BookShelfState): BookShelfAction => ({
        type: BookShelfActionType.InitialValue,
        payload: payload
    }),
    clearBookShelfState: (): BookShelfAction => ({
        type: BookShelfActionType.ClearBookShelf
    }),
    setVisibleModal: (visible: boolean): BookShelfAction => ({
        type: BookShelfActionType.SetModalTooltip,
        payload: { tooltipModal: visible }
    }),
    setModalCheckBox: (checked: boolean): BookShelfAction => ({
        type: BookShelfActionType.SetCheckbox,
        payload: { displayModalChecked: checked }
    })
};
