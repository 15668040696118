import { useEffect, useState } from 'react';
import { ContentMetaData } from '../../../../interface';
import { MemoContentCollectionView } from '../../../components/contents';
import ViewAllButton from '../../../customUI/viewAllButton';
import '../pattern.scss';

interface ContentProps {
   title: string;
   subtitle?: string;
   contents: ContentMetaData[];
   path: string;
   limit?: number;
}

const Pattern4 = (props: ContentProps) => {
   const [bookList, setBookList] = useState<ContentMetaData[]>();

   useEffect(() => {
      setBookList(props.contents);
   }, []);

   return (
      <div className="pattern-4 fadeIn">
         <div className="title-section" style={{ marginBottom: '10px' }}>
            <div>
               <h2>{props.title}</h2>
               <p>{props.subtitle}</p>
            </div>

            {props.limit && bookList && bookList.length >= props.limit! && (
               <ViewAllButton path={props.path} title={props.title} />
            )}
         </div>

         <div className="content-grid-container">
            {bookList?.map((e, index) => {
               if (index < props.limit!) {
                  return <MemoContentCollectionView content={e as ContentMetaData} />;
               } else if (!props.limit) {
                  return <MemoContentCollectionView content={e as ContentMetaData} />;
               }
            })}
         </div>
      </div>
   );
};

export default Pattern4;
