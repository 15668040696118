import { Modal, Button } from 'antd';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import { HibraryRootState } from '../../../redux/rootReducer';
import { RentalStatus, ContentType } from '../../../interface/home';
import { RequestStage } from '../../redux/rental';
import { convertMonth, setContentType } from '../../redux/utilities';
import { bookShelfContent } from '../../../interface/bookShelf';
import { getContentRentalExpire, rentCancel, rentEbook } from '../../redux/rental/action';

import errorLoading from '../../assets/lotties/4386-connection-error.json';
import successLoading from '../../assets/lotties/1127-success.json';
import Lottie from 'lottie-react';
import dayjs from 'dayjs';

import './index.scss';

interface RentalModalInformation {
   title?: string;
   description?: string;
   dateInformation?: string;
   confirmTitle?: string;
   isError?: boolean;
}

export const RentalModal = () => {
   const [expireDate, setExpireDate] = useState<string>();
   const [info, setInfo] = useState<RentalModalInformation>({});
   const [visible, setVisible] = useState(false);
   const dispatch = useDispatch();
   const { rental } = useSelector(
      (state: HibraryRootState) => ({
         authState: state.auth,
         clientInfo: state.clientInfo,
         rental: state.rental
      }),
      shallowEqual
   );

   const fetchRentalExpire = async () => {
      if (rental.content?.contentUID && rental.content?.contentUID !== '') {
         const response = await getContentRentalExpire(
            rental.content?.contentUID ?? '',
            rental.content?.contentType ?? ContentType.ebook
         );
         setExpireDate(dayjs.unix(response?.expireDate ?? 0).format('DD/MM/YYYY HH:mm'));

         setInfo({
            title: `คุณต้องการยืม${setContentType(rental.content.contentType)}`,
            description: `${rental.content?.title ?? 'ทดสอบการยืม'}`,
            dateInformation: `กำหนดคืน ${convertMonth(
               dayjs.unix(response?.expireDate ?? 0).format('DD/MM/YYYY HH:mm')
            )}`,
            confirmTitle: 'ยืม'
         });
      }
   };

   const setModalInformation = (status: RentalStatus) => {
      switch (status) {
         case RentalStatus.Rent:
            fetchRentalExpire();
            break;
         case RentalStatus.Rented:
            setInfo({
               title: `คุณต้องการ${setContentType(rental.content!.contentType)}หรือไม่?`,
               description: `${rental.content?.title}`,
               confirmTitle: `คืน`
            });
            break;
         case RentalStatus.WaitingList:
            setInfo({
               title: `คุณต้องการจองคิว`,
               description: `${rental.content?.title}`,
               confirmTitle: 'จอง'
            });
            break;
         case RentalStatus.WaitingListed:
            setInfo({
               title: `คุณต้องการยกเลิกการจอง`,
               description: `${rental.content?.title}`,
               confirmTitle: 'ยกเลิกจอง'
            });
            break;
         default:
            break;
      }
   };

   useEffect(() => {
      if (rental.stage === RequestStage.Error) {
         setInfo({
            title: `พบความผิดพลาด`,
            description: `${rental.error ? rental.error.message : ''}`,
            isError: true
         });
      } else if (rental.stage === RequestStage.Complete) {
         setInfo({
            title: `ดำเนินการสำเร็จ`,
            description: rental.content?.title,
            dateInformation:
               rental.rentalStatus === RentalStatus.Rent
                  ? `กำหนดคืน ${convertMonth(expireDate as string)}`
                  : '',
            isError: true
         });
      } else {
         setModalInformation(rental.rentalStatus ?? RentalStatus.Rent);
      }
      if (rental.showConfirmModal) {
         setVisible(true);
      } else {
         setVisible(false);
      }
   }, [rental]);

   const getModalDetail = (stage: RequestStage) => {
      switch (stage) {
         case RequestStage.Complete:
            return (
               <div className="modal-detail">
                  <Lottie animationData={successLoading} className="modal-lottie" />
                  <h3>{info.description}</h3>
                  <p>{info.dateInformation}</p>
               </div>
            );

         case RequestStage.Error:
            return (
               <div className="modal-detail">
                  <Lottie animationData={errorLoading} className="modal-lottie" />
                  <h3>{info.description}</h3>
               </div>
            );

         default:
            return (
               <div>
                  <h3>{info.description}</h3>
                  <p>{info.dateInformation}</p>
               </div>
            );
      }
   };

   const onCancel = () => {
      dispatch(rentCancel());
   };

   const onConfirm = () => {
      if (![RequestStage.Complete, RequestStage.Error, RequestStage.Init].includes(rental.stage)) {
         dispatch(
            rentEbook(
               rental.content as bookShelfContent,
               rental.content?.contentType,
               rental.content?.licenseType
            )
         );
      } else {
         setVisible(false);
      }
   };

   const cancelBtn = (
      <Button
         size="middle"
         loading={rental.stage === RequestStage.Process ? true : false}
         type="primary"
         shape="round"
         key="confirm"
         onClick={onConfirm}
         className="confirm-button"
      >
         {!info.isError ? info.confirmTitle : 'ตกลง'}
      </Button>
   );

   const confirmBtn = (
      <Button
         size="middle"
         type="ghost"
         shape="round"
         key="back"
         onClick={onCancel}
         className="cancel-button"
      >
         ยกเลิก
      </Button>
   );

   const buttonSet = [confirmBtn, cancelBtn];
   const buttonConfirm = [cancelBtn];

   return (
      <Modal
         className="rental-modal"
         onCancel={onCancel}
         visible={visible}
         title={info.title}
         footer={
            [RequestStage.Complete, RequestStage.Error].includes(rental.stage)
               ? buttonConfirm
               : buttonSet
         }
      >
         {getModalDetail(rental.stage)}
      </Modal>
   );
};
