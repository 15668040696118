import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { Themes } from '../../../interface/client';
export interface ThemeState {
   normalTheme: Themes;
   darkTheme: Themes;
   darkMode: boolean;
}
export const normalTheme: Themes = {
   Nav: '#FFFFFF',
   Surfaces: '#FFFFFF',
   OnError: '#1D252C',
   OnBackground: '#1D252C',
   OnPrimaryVariants: '#FFFFFF',
   OnPrimary: '#FFFFFF',
   Primary: '#F5333F',
   PrimaryVariants: '#C30916',
   OnSurfaces: '#1D252C',
   Disable: '#E0E0E0',
   OnDisable: '#828282',
   Secondary: '#1D252C',
   Background: '#FFFFFF',
   OnNav: '#FFFFFF',
   Error: '#D7042B',
   OnSecondary: '#FFFFFF',
   GREY7: '#F2F2F2',
   GREY8: '#FFFFFF',
   GREY5: '#BDBDBD',
   GREY6: '#E0E0E0',
   GREY3: '#4F4F4F',
   GREY4: '#828282',
   GREY1: '#1C1C1E',
   GREY2: '#333333'
};

export const darkTheme: Themes = {
   Nav: '#1D252C',
   Surfaces: '#1D252C',
   OnError: '#FFFFFF',
   OnBackground: '#FFFFFF',
   OnPrimaryVariants: '#FFFFFF',
   OnPrimary: '#FFFFFF',
   Primary: '#F5333F',
   PrimaryVariants: '#C30916',
   OnSurfaces: '#1D252C',
   Disable: '#E0E0E0',
   OnDisable: '#828282',
   Secondary: '#FFFFFF',
   Background: '#FFFFFF',
   OnNav: '#FFFFFF',
   Error: '#D7042B',
   OnSecondary: '#1D252C',
   GREY7: '#F2F2F2',
   GREY8: '#FFFFFF',
   GREY5: '#BDBDBD',
   GREY6: '#E0E0E0',
   GREY3: '#4F4F4F',
   GREY4: '#828282',
   GREY1: '#1C1C1E',
   GREY2: '#333333'
};

export const initialState: ThemeState = {
   normalTheme: normalTheme,
   darkTheme: darkTheme,
   darkMode: false
};

export enum ThemeActionType {
   SetTheme = 'SetTheme[THEME]',
   SetDarkTheme = 'SetDarkTheme[THEME]'
}

export interface ThemeAction {
   type: ThemeActionType;
   payload: Themes | boolean;
}

export const reducer = persistReducer(
   {
      storage,
      key: 'hibrary-themes',
      whitelist: ['normalTheme', 'darkTheme', 'darkMode']
   },
   (state: ThemeState = initialState, action: ThemeAction): ThemeState => {
      switch (action.type) {
         case ThemeActionType.SetTheme:
            return { ...state, normalTheme: action.payload as Themes };
         case ThemeActionType.SetDarkTheme:
            return { ...state, darkMode: action.payload as boolean };
         default:
            return state;
      }
   }
);
